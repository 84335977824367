import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CategoryActions,
  DrawerActions,
  GoalActions,
  selectorCategories,
  selectorCategoriesToEdit,
  selectorFutureHabits,
  selectorFutureTasks,
  selectorUser,
} from "@/store/modules";
import { validatorGoal } from "@/utils/validator";
import { GOAL } from "@/constants";
import { getDateToday } from "@/utils/date";
import {
  Button,
  Input,
  InputDate,
  MultiSelect,
  InputCheckList,
} from "@/components/molecules";
import { Toast } from "@/services";
import Select from "@/components/Select";
import { parseToNumber } from "@/utils/string";

const Goal = ({ isEditing, goal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categoriesList = useSelector(selectorCategories);
  const { id } = useSelector(selectorUser);
  const tasksState = useSelector(selectorFutureTasks);
  const habitsState = useSelector(selectorFutureHabits);

  const [specific, setSpecific] = useState(goal ? goal.specific : "");
  const [measurable] = useState(goal ? goal.measurable : "");
  const [date, setDate] = useState(goal ? goal.date : "");
  const [startDate, setStartDate] = useState(
    goal?.startDate || goal?.createdAt || getDateToday()
  );
  const [relevant, setRelevant] = useState(goal ? goal.relevant : "");
  const categoriesEdit = useSelector((state) =>
    selectorCategoriesToEdit(state, goal?.categories || [])
  );
  const [categories, setCategories] = useState(
    goal ? categoriesEdit || [] : []
  );
  const [achievables, setAchievables] = useState(goal ? goal.achievable : []);
  const [habits, setHabits] = useState(goal ? goal.habits : []);
  const [tasks, setTasks] = useState(goal ? goal.tasks : []);
  const units = ["amount", "checkList", "habit"];
  const [targetAmount, setTargetAmount] = useState(
    goal?.targetAmount
      ? String(goal?.targetAmount)
      : goal?.measurable
      ? String(parseToNumber(goal?.measurable)) || "1"
      : null
  );
  const [progress, setProgress] = useState(goal?.progress || "");

  function handleAddGoal() {
    const idsCategories = categories.map((i) => i.uid);
    const data = {
      user_id: id,
      specific,
      measurable,
      amount: targetAmount ? parseToNumber(targetAmount) : null,
      progress: !progress || progress === "habit" ? null : progress,
      date,
      startDate,
      relevant,
      categories: idsCategories.length > 0 ? idsCategories : null,
      achievable: achievables,
      habits,
      tasks,
      uid: goal ? goal.uid : undefined,
      createdAt: goal ? goal.createdAt : getDateToday(),
      label: GOAL,
      done: goal ? goal.done : false,
    };
    const isValid = validatorGoal(data);
    if (!isValid) {
      return;
    }
    if (isEditing) {
      dispatch(
        GoalActions.updateGoalRequest({
          ...data,
          id: goal.id,
        })
      );
    } else {
      dispatch(GoalActions.addGoalRequest(data));
    }
    dispatch(DrawerActions.closeDrawer());
  }

  useEffect(() => {
    dispatch(CategoryActions.getCategoriesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Input
        label={t("goal:specific")}
        required
        value={specific}
        onChangeText={setSpecific}
        placeholder={t("goal:placeholderSpecific")}
      />
      <Input
        label={t("amount")}
        required
        value={targetAmount}
        onChangeText={(v) => {
          if (v.match(/\D/g)) {
            Toast.error(t("error:typeOnlyNumber"));
          }
          setTargetAmount(parseToNumber(v));
        }}
        placeholder={t("goal:placeholderMeasurable")}
        returnKeyType="next"
        keyboardType="numeric"
        testID="input_measurable"
      />
      <Input
        label={t("goal:relevant")}
        value={relevant}
        onChangeText={setRelevant}
        placeholder={t("goal:placeholderRelevant")}
        required
      />
      <InputDate
        testID="input_date"
        label={t("goal:date")}
        placeholder={t("goal:placeholderDate")}
        value={date}
        onChangeValue={setDate}
        required
      />
      <InputCheckList data={achievables} setData={setAchievables} />

      <MultiSelect
        type="category"
        label={t("task:categories")}
        titleModal={t("task:categories")}
        value={categories}
        placeholder={t("task:placeholderCategories")}
        setValue={setCategories}
        data={categoriesList}
        onlyPremium
        getId={(item)=> item.uid}
      />

      <Select
        value={progress.includes("habit_") ? "habit" : progress}
        placeholder={`Ex: ${t(`field`)} ${t(`amount`)}`}
        onChangeValue={setProgress}
        data={units.map((i) => ({
          value: i,
          label: t(`${i}`),
        }))}
        label={t("goal:metricProgressBy")}
      />
      {progress.includes("habit") && (
        <div style={{ marginLeft: 15, width: "97%", marginTop: -10 }}>
          <Select
            value={progress === "habit" ? null : progress}
            placeholder={`Ex: ${t(`habit`)} ${t(`exampleHabit`)}`}
            onChangeValue={setProgress}
            data={habitsState.map((i) => ({
              value: "habit_" + (i.id || i.uid),
              label: i.routine,
            }))}
            label={t("goal:selectHabit")}
          />
        </div>
      )}

      {(progress.includes("habit") || progress === "amount") && (
        <InputDate
          testID="input_start_date"
          label={t("start")}
          value={startDate}
          onChangeValue={setStartDate}
          required
        />
      )}

      <MultiSelect
        type="habit"
        label={t("goal:habits")}
        value={habits}
        placeholder={t("goal:placeholderHabits")}
        titleModal={t("goal:placeholderHabits")}
        testID="select_habits_associated"
        setValue={setHabits}
        data={habitsState}
        getId={(item) => item?.id || item?.uid}
      />

      <MultiSelect
        type="task"
        label={t("goal:tasks")}
        value={tasks}
        placeholder={t("goal:placeholderTasks")}
        titleModal={t("goal:placeholderTasks")}
        testID="select_tasks_associated"
        setValue={setTasks}
        data={tasksState}
        getId={(item) => item?.id || item?.uid}
      />

      <Button
        title={isEditing ? t("save") : t("add")}
        marginTop={20}
        onPress={handleAddGoal}
      />
    </div>
  );
};

export default Goal;

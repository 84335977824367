export const RewardTypes = {
  GET_REWARDS_REQUEST: 'reward/GET_REWARDS_REQUEST',
  GET_REWARDS_SUCCESS: 'reward/GET_REWARDS_SUCCESS',

  ADD_REWARD_REQUEST: 'reward/ADD_REWARD_REQUEST',
  ADD_REWARD_SUCCESS: 'reward/ADD_REWARD_SUCCESS',

  UPDATE_REWARD_REQUEST: 'reward/UPDATE_REWARD_REQUEST',
  UPDATE_REWARD_SUCCESS: 'reward/UPDATE_REWARD_SUCCESS',

  SYNC_REWARDS_REQUEST: 'reward/SYNC_REWARDS_REQUEST',

  DELETE_REWARD_REQUEST: 'reward/DELETE_REWARD_REQUEST',
  DELETE_REWARD_SUCCESS: 'reward/DELETE_REWARD_SUCCESS',

  SET_FAILURE: 'reward/SET_FAILURE',
  SET_INITIAL_STATE: 'reward/SET_INITIAL_STATE',
};

export interface Reward {
  uid: string;
  key: string;
  date: string;
  user_id: number;
  data?: any;
  description?: string;
  id?: number;
  action?: string;
  type?: string;
}
export interface Gallery {
  image: string;
  key: string;
}

export interface RewardState {
  rewards: Reward[];
  gallery: Gallery[];
  lastSync: null | Date;
}

import {DatePicker, Popover} from 'antd';
import moment from 'moment';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiMoreVertical} from 'react-icons/fi';
import styled, {useTheme} from 'styled-components';

const Menu = styled.div`
  span {
    color: #444;
    cursor: pointer;
    font-weight: 450;
    &:hover {
      opacity: 0.8;
      color: ${({theme}) => theme.colors.primary};
    }
  }
`;

type ActionsProps = {
  onDeleteItem: any;
  onUpdateItem: any;
  onRescheduleItem: any;
  onClickItem: any;
  defaultDate: any;
  onMoveToChecklist: any;
  onTransformInTask: any;
};
const Actions = ({
  onDeleteItem,
  onUpdateItem,
  onRescheduleItem,
  onClickItem,
  defaultDate,
  onMoveToChecklist,
  onTransformInTask,
}: ActionsProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);

  const Action = () => {
    function closePopup() {
      const elementsRightTop: any = document.getElementsByClassName(
        'ant-popover ant-popover-placement-leftTop',
      );
      const elementsRightBottom: any = document.getElementsByClassName(
        'ant-popover ant-popover-placement-leftBottom',
      );
      const elements = [...elementsRightTop, ...elementsRightBottom];
      const classHiden = 'ant-popover-hidden';
      for (const item of elements) {
        if (!item.className.includes(classHiden)) {
          item.className = item.className + ' ' + classHiden;
        }
      }
    }
    function handleReschedule(e: any) {
      e.stopPropagation();
      setOpenPopup(true);
    }
    function handleEdit(e: any) {
      closePopup();
      e.stopPropagation();
      onUpdateItem();
    }
    function handleDelete(e: any) {
      closePopup();
      e.stopPropagation();
      onDeleteItem();
    }

    return (
      <Menu>
        {onRescheduleItem && (
          <>
            <span onClick={handleReschedule}>{t('task:reschedule')}</span>
            <br />
          </>
        )}
        {onMoveToChecklist && (
          <>
            <span onClick={onMoveToChecklist}>
              {t('task:moveToOtherChecklist')}
            </span>
            <br />
          </>
        )}
        {onTransformInTask && (
          <>
            <span onClick={onTransformInTask}>{t('task:transformInTask')}</span>
            <br />
          </>
        )}
        <span onClick={handleEdit}>{t('edit')}</span>
        <br />
        <span onClick={handleDelete}>{t('delete')}</span>
        <DatePicker
          value={moment(defaultDate, 'yyyy-MM-DD')}
          onChange={v => {
            const date = v?.format('yyyy-MM-DD');
            if (date) {
              onRescheduleItem(date);
              setOpenPopup(false);
              closePopup();
            }
          }}
          open={openPopup}
          style={{visibility: 'hidden', height: 0, width: 0}}
        />
      </Menu>
    );
  };

  function handleClickMore(e: any) {
    e.stopPropagation();
    onClickItem();
  }

  return (
    <Popover
      placement="leftTop"
      title={
        <span>
          <b>{t('selectAction')}:</b>
        </span>
      }
      content={<Action />}
      trigger="click">
      <FiMoreVertical
        onClick={handleClickMore}
        color={theme.colors.onBackground}
        size={22}
        style={{cursor: 'pointer'}}
      />
    </Popover>
  );
};

export default Actions;

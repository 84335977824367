import {PERIODS} from '@/constants';
import {t} from '@/services/Locale';

export function translatePeriod(value: string): string {
  const v = PERIODS.find((i: string) => i === value);
  if (v) return t(v);
  if (value.includes('EVERY')) {
    const [,days,] = value.split('_');
    return t(`task:everyXdays`, {x: days});
  }
  return value
    ?.split(',')
    ?.map((i: string) => t(i))
    ?.join(',');
}

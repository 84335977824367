export default {
  noHabitsFilter: 'Nenhum Hábito de acordo com os filtros aplicados!',
  noHabits: `Você ainda não possui nenhum hábito ativo`,
  addHabit: 'Adicionar Hábito',
  howToAddHabit: 'Como criar hábitos',
  editHabit: 'Editar Hábito',
  deleteHabit: 'Apagar Hábito',
  deleteHabitAlert:
    'O hábito se repete frequentemente. Você deseja apagar somente essa ocorrência ({{date}}) ou também suas futuras ocorrências?',
  trigger: 'Gatilho',
  routine: 'Rotina',
  reward: 'Recompensa',
  placeholderCheckListHabit: 'Leitura, Atividade Física',
}as any;

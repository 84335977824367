import {
  Container,
  Content,
  Row,
  TextBase,
  TextSection,
  TextValue,
  ViewBase,
} from '@/components/atoms';
import {LEVELS} from '@/constants';
import {useTranslation} from 'react-i18next';
import GamificationService from '@/services/Gamification';
import { Navigation } from '@/services';
import { Header } from '@/components/molecules';

type Item = {
  label: string;
  value: string;
};

const Gamification = () => {
  const {t} = useTranslation();
  const navigation = Navigation;
  const gamificationScore = t('statistic:gamificationScore', {
    returnObjects: true,
  }) as Item[];
  return (
    <Container>
      <Header
        title={t('statistic:gamification')}
        onPressButtonLeft={() => navigation.goBack()}
      />
      <Content>
        <TextSection marginTop={10}>{t('statistic:score')}:</TextSection>
        {gamificationScore?.map(i => (
          <Row spaceBetween key={i.label}>
            <TextValue>{i.label}</TextValue>
            <TextValue> {i.value}</TextValue>
          </Row>
        ))}

        <TextSection marginTop={10}>{t('statistic:levels')}</TextSection>
        {LEVELS.map(level => (
          <Row spaceBetween>
            <TextValue>
              {GamificationService.translateLevel(level).textIndex}:
            </TextValue>
            <TextValue>
              {' '}
              {t('to').toLowerCase()} {level.scoreTop} {t('points')}
            </TextValue>
          </Row>
        ))}

        <TextSection marginTop={10}>{t('statistic:rewards')}:</TextSection>
        <ViewBase
          flexDirection="row"
          wrap
          flex={1}
          justifyContent="space-between">
          {GamificationService.rewards.map(item => (
            <ViewBase style={{width: '18%',alignItems:'center'}} padding={3}>
              <img
                src={item.image}
                style={{height:60}}
                alt="reward"
              />
              <TextBase size={12} padding={5} center>
                {t(`statistic:${item.key}`)}
              </TextBase>
            </ViewBase>
          ))}
        </ViewBase>
      </Content>
    </Container>
  );
};

export default Gamification;

import {VERSION} from '@/constants';
import {Action} from '../utils';
import {SessionState, SessionTypes} from './type';

const INITIAL_STATE: SessionState = {
  network: {
    isInternetReachable: null,
  },
  version: VERSION,
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): SessionState {
  switch (type) {
    case SessionTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case SessionTypes.SET_NETWORK:
      return {...state, network: payload.network};

    default:
      return state;
  }
}

export const SessionActions = {
  setNetwork: (network: any): Action => ({
    type: SessionTypes.SET_NETWORK,
    payload: {network},
  }),
  setInitialState: () => ({
    type: SessionTypes.SET_INITIAL_STATE,
  }),
};

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SignIn from "./pages/Auth/SignIn";
import ResetPassword from "./pages/Auth/ResetPassword";
import Tasks from "./pages/Task";
import api from './services/api';
import Habits from "./pages/Habit";
import Goals from "./pages/Goal";
import Statistics from "./pages/Statistics";
import Ranking from "./pages/Statistics/Ranking";
import General from "./pages/General";
import Categories from "./pages/General/Category";
import Gamification from "./pages/Statistics/Gamification";
import Rewards from "./pages/Statistics/Rewards";
import Report from "./pages/Statistics/Reports/Report";
import Reports from "./pages/Statistics/Reports";
import EditProfile from "./pages/General/EditProfile";
import Gallery from "./pages/Statistics/Rewards/Gallery";
import { useSelector } from "react-redux";
import FloatTimer from "./pages/Timer/FloatTimer";
import ListScreen from "./pages/List";

export default function Routes() {
  return (
      <Router>
        <Switch>
          <PrivateRoute path="/activities" exact>
            <Tasks />
          </PrivateRoute>
          <PrivateRoute path="/habits" exact>
            <Habits />
          </PrivateRoute>
          <PrivateRoute path="/lists" exact>
            <ListScreen />
          </PrivateRoute>
          <PrivateRoute path="/goals" exact>
            <Goals />
          </PrivateRoute>
          <PrivateRoute path="/statistics" exact>
            <Statistics />
          </PrivateRoute>
          <PrivateRoute path="/settings" exact>
            <General />
          </PrivateRoute>

          <PrivateRoute path="/gamification" exact>
            <Gamification />
          </PrivateRoute>
          <PrivateRoute path="/gallery" exact>
            <Gallery />
          </PrivateRoute>
          <PrivateRoute path="/my-rewards" exact>
            <Rewards />
          </PrivateRoute>
          <PrivateRoute path="/ranking" exact>
            <Ranking />
          </PrivateRoute>
          <PrivateRoute path="/reports" exact>
            <Reports />
          </PrivateRoute>
          <PrivateRoute path="/report" exact>
            <Report />
          </PrivateRoute>
          <PrivateRoute path="/edit-profile" exact>
            <EditProfile />
          </PrivateRoute>
          <PrivateRoute path="/categories" exact>
            <Categories />
          </PrivateRoute>

          <Route path={"/reset-password"} exact> 
            <ResetPassword />
          </Route>

          <DefaultRoute path="/">
            <SignIn />
          </DefaultRoute>
        </Switch>
        <FloatTimer />
      </Router>
  );
}


function PrivateRoute({ children, ...rest }) {
  const token = useSelector(stt=> stt.user?.token);
  if(token){
    api.defaults.headers.Authorization = token;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
      token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
function DefaultRoute({ children, ...rest }) {
  const token = useSelector(stt=> stt.user?.token);
  if(token){
    api.defaults.headers.Authorization = token;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
      token ? (
        <Redirect
          to={{
            pathname: "/activities",
            state: { from: location }
          }}
        />
        ) : (
          children
        )
      }
    />
  );
}
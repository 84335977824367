import {
  getScoreBetweenInteval,
  getTotalDaysRepetionActivityBetweenInterval,
  getTriadTime,
} from '@/services/Statistics';
import {
  ActivityInDate,
  ActivityModel,
  Category,
  SelectorStatistics,
  SelectorTriadTime,
  SelectorWeeklyChart,
  State,
  StatisticState,
  selectorCategories,
} from '@/store/modules';
import {
  getDateToday,
  formatDate,
  differenceInWeeks,
  subWeeks,
  startOfWeek,
  endOfWeek,
  formatDateToServer,
} from '@/utils/date';

export const selectorStatistic = (state: State): StatisticState =>
  state.statistic;

export const selectorStatistics = (state: State): SelectorStatistics => {
  return {
    tasks: {
      total: state.task.tasks.reduce(
        (acc, item) =>
          acc +
          getTotalDaysRepetionActivityBetweenInterval(
            item,
            item.startDate,
            getDateToday(),
          ),
        0,
      ),
      done: state.task.tasks.reduce(
        (acc, item) => acc + item.dones.filter(i => i.done).length,
        0,
      ),
    },
    habits: {
      total: state.habit.habits.reduce(
        (acc, item) =>
          acc +
          getTotalDaysRepetionActivityBetweenInterval(
            item,
            item.startDate,
            getDateToday(),
          ),
        0,
      ),
      done: state.habit.habits.reduce(
        (acc, item) => acc + item.dones.filter(i => i.done).length,
        0,
      ),
    },
    goals: {
      total: state.goal.goals.length,
      done: state.goal.goals.filter(i => i.done).length,
    },
  };
};

export const selectorTriadTime = (state: State): SelectorTriadTime => {
  return getTriadTime(
    formatDateToServer(state.user.createdAt),
    getDateToday(),
    state.task.tasks,
  );
};

export const selectorWeeklyChart = (state: State): SelectorWeeklyChart => {
  const {user} = state;
  const {habits} = state.habit;
  const {goals} = state.goal;
  const {tasks} = state.task;
  const weekStartsOn =state.app.startOfWeek || 0

  const numWeeksUser = differenceInWeeks(new Date(), user.createdAt);
  let countPointsChart = numWeeksUser;
  if (countPointsChart > 5) {
    countPointsChart = 5;
  }

  const dataChart: SelectorWeeklyChart = {
    labels: [],
    datasets: [],
  };

  let datePoint = formatDate(
    subWeeks(startOfWeek(new Date(),{weekStartsOn}), 1),
    'yyyy-MM-dd',
  );
  for (let i = 0; i < countPointsChart; i += 1) {
    dataChart.labels.push(String(numWeeksUser - countPointsChart + i + 1));
    dataChart.datasets.push(
      getScoreBetweenInteval(
        datePoint,
        formatDate(endOfWeek(datePoint,{weekStartsOn}), 'yyyy-MM-dd'),
        {
          goals,
          tasks,
          habits,
        },
      ),
    );
    datePoint = formatDate(subWeeks(datePoint, 1), 'yyyy-MM-dd');
  }
  return dataChart;
};
export const selectorWheelOfLife = (
  state: State,
): (Category & {total: number})[] => {
  const {tasks} = state.task;
  const {goals} = state.goal;
  const {habits} = state.habit;

  const mapTotalCategories = [...tasks, ...habits, ...goals]
    .filter((i: ActivityModel) => i?.categories)
    .map((i: ActivityModel) => {
      const total =
        'dones' in i
          ? i?.dones?.filter((j: ActivityInDate) => j.done).length
          : i?.specific && i?.done
          ? 1
          : 0;
      return {
        categories: i?.categories?.map((j: string) => ({uid: j, total})) || [],
      };
    })
    .map(i => i.categories)
    .flat() as any;
  return selectorCategories(state).map(i => ({
    ...i,
    total: mapTotalCategories
      .filter((c: ActivityModel) => c.uid === i.uid)
      .reduce((acc: number, item: any) => acc + item?.total || 0, 0),
  }));
};

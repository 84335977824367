import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Container,
  Row,
  Section,
  TextSection,
  TextBase,
  Fixed,
  ViewBase,
  Option,
} from '@/components/atoms';
import {
  UserActions,
  AppActions,
  selectorTheme,
  selectorLanguage,
  selectorVersion,
  selectorUser,
} from '@/store/modules';
import {Analytics} from '@/services';
import {ROUTES, URL} from '@/constants';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {ButtonSection} from '@/components/molecules';
import Header from '@/components/Header';
import {useHistory} from 'react-router';

const General: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const version = useSelector(selectorVersion);
  const currentTheme = useSelector(selectorTheme);
  const currentLanguage = useSelector(selectorLanguage);
  const {language} = useSelector(selectorUser);

  function getOut() {
    dispatch(UserActions.getOutRequest());
  }
  function handleNavigateToBlog() {
    Analytics.setUserProperty(Analytics.events.CLICK_BLOG);
    window.open(URL.BLOG, '_blank');
  }
  function handleNavigateToEbooks() {
    Analytics.setUserProperty(Analytics.events.CLICK_EBOOK_COMBO);
    window.open(URL.EBOOKS_COMBO, '_blank');
  }
  function handleNavigateToPolicyPrivacy() {
    Analytics.setUserProperty(Analytics.events.CLICK_TERMS_PRIVACY);
    window.open(URL.PRIVACY_POLICE, '_blank');
  }
  function handleNavigateToTermsOfUse() {
    Analytics.setUserProperty(Analytics.events.CLICK_TERMS_USE);
    window.open(URL.TERMS, '_blank');
  }
  function handleNavigateToInstagram(event: string) {
    Analytics.setUserProperty(event);
    window.open(URL.INSTA_WEB, '_blank');
  }
  function handleOpenEmail() {
    Analytics.setUserProperty(Analytics.events.CLICK_CONTACT_MAIL);
    window.open(URL.EMAIL, '_blank');
  }

  function setLanguage(language: string) {
    dispatch(UserActions.setLanguage(language));
  }
  // function setStartOfWeek(startOfWeek: number) {
  //   dispatch(
  //     AppActions.addAtribute({
  //       startOfWeek: startOfWeek,
  //     }),
  //   );
  // }

  function setTheme(newTheme: string) {
    dispatch(
      AppActions.addAtribute({
        theme: newTheme,
      }),
    );
    Analytics.setUserProperty(Analytics.events.THEME, newTheme);
  }

  return (
    <Container ignorePadding>
      <Fixed>
        <Header />
      </Fixed>
      <ViewBase style={{padding: '5% 5%'}}>
        <Section>
          <TextSection>{t('general:profile')}</TextSection>
          <ButtonSection
            title={t('general:updateProfile')}
            onPress={() => history.push(ROUTES.EDIT_PROFILE)}
            testID="update_profile"
          />
          <ButtonSection
            testID="categories"
            title={`${t('categories')} (${t('statistic:wheelOfLife')})`}
            onPress={() => history.push(ROUTES.CATEGORIES)}
          />
        </Section>
        <Section>
          <TextSection>{t('general:theme')}</TextSection>
          <Row>
            <Option
              active={currentTheme === 'dark'}
              onClick={() => setTheme('dark')}>
              {t('general:themeDark')}
            </Option>
            <Option
              active={currentTheme === 'default'}
              onClick={() => setTheme('default')}>
              {t('general:themeDefault')}
            </Option>
          </Row>
        </Section>

        <Section>
          <TextSection>{t('general:language')}</TextSection>
          <Row>
            <Option
              active={language === 'pt'}
              onClick={() => setLanguage('pt')}>
              {t('general:pt')}
            </Option>
            <Option
              active={language === 'en'}
              onClick={() => setLanguage('en')}>
              {t('general:en')}
            </Option>
            <Option
              active={language === 'es'}
              onClick={() => setLanguage('es')}>
              {t('general:es')}
            </Option>
          </Row>
        </Section>
        {/* <Section>
          <TextSection>{t('general:startOfWeek')}</TextSection>
          <Row>
            <Option active={app.startOfWeek === 0} onClick={()=> setStartOfWeek(0)}>{t('DOM')}</Option>
            <Option active={app.startOfWeek === 1} onClick={()=> setStartOfWeek(1)}>{t('SEG')}</Option>
          </Row>
        </Section> */}

        {currentLanguage === 'pt' && (
          <Section>
            <TextSection>{t('general:contents')}</TextSection>
            <ButtonSection
              title={t('general:blog')}
              onPress={handleNavigateToBlog}
            />
            <ButtonSection
              title={t('general:ebooks')}
              onPress={handleNavigateToEbooks}
            />
          </Section>
        )}
        <Section>
          <TextSection>{t('general:contact')}</TextSection>
          <ButtonSection title={t('general:email')} onPress={handleOpenEmail} />
          <ButtonSection
            title={t('general:instagram')}
            onPress={() =>
              handleNavigateToInstagram(Analytics.events.CLICK_CONTACT_INSTA)
            }
          />
        </Section>
        <Section>
          <TextSection>{t('general:terms')}</TextSection>
          <ButtonSection
            title={t('general:privacyPolicy')}
            onPress={handleNavigateToPolicyPrivacy}
          />
          <ButtonSection
            title={t('general:termsOfUse')}
            onPress={handleNavigateToTermsOfUse}
          />
        </Section>
        <Section>
          <Row spaceBetween alignCenter>
            <TextSection>{t('general:version')}</TextSection>
            <TextBase color={theme.colors.light}>{version}</TextBase>
          </Row>
        </Section>
        <Section>
          <ButtonSection
            title={t('general:getOut')}
            onPress={getOut}
            textColor={theme.colors.red}
          />
        </Section>
      </ViewBase>
    </Container>
  );
};
export default General;

import colors, {Colors} from '../colors';

export default {
  ...colors,
  // containers
  background: '#fff',
  onBackground: '#333',
  surface: '#f6f6f6',
  onSurface: '#777',
  surfaceLight: '#eee',
  regular: '#aaa',

  list: '#fcfcfc',
  // texts
  placeholder: colors.lighter,
  textSection: colors.dark,
  textLabel: colors.darker,
  text: colors.regular,

  activeTabBar: colors.primary,
  inactiveTabBar: '#aaa',

  shadow: '#ddd',
} as Colors;

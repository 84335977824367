export default {
  noHabitsFilter: '¡Sin hábito según los filtros aplicados!',
  noHabits: `Aún no tienes hábitos activos`,
  addHabit: 'Agregar hábito',
  howToAddHabit: 'Cómo crear hábitos',
  editHabit: 'Editar hábito',
  deleteHabit: 'Eliminar hábito',
  deleteHabitAlert:
    'El hábito se repite a menudo. ¿Desea eliminar solo esta ocurrencia ({{date}}) o también sus ocurrencias futuras? ',
  trigger: 'Gatillo',
  routine: 'Rutina',
  reward: 'Recompensa',
  placeholderCheckListHabit: 'Lectura, Meditación',

} as any;

import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DrawerActions,
  GoalActions,
  selectorGoal,
  selectorGoalFilter,
} from '@/store/modules';
import {Fixed, Container, Label} from '@/styles/global';
import {formatDate} from '@/utils/date';
import Header from '../../components/Header';
import {parseToNumber} from '@/utils/string';
import CardGoal from '@/components/CardGoal';
import {useTheme} from 'styled-components';
import SectionList from '@/components/molecules/SectionList';
import {ListEmpty} from '@/components/molecules';
import {useTranslation} from 'react-i18next';
import {Loading, Option, Row, ViewBase} from '@/components/atoms';
import {colors} from '@/styles';
import {handleFilter, handleSort} from '@/utils/activity';
import {FiFilter} from 'react-icons/fi';
import Filter from '@/components/Filter';
import {AiOutlineOrderedList} from 'react-icons/ai';

const Goal = () => {
  const theme = useTheme();
  const {goals, loading, lastSync} = useSelector(selectorGoal);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {filterBy, sortBy} = useSelector(selectorGoalFilter);
  const countFilters =
    filterBy.status.length + filterBy.types.length + filterBy.categories.length;
  const refFilter = useRef<any>();
  const refSort = useRef<any>();

  useEffect(() => {
    if (!lastSync) {
      dispatch(GoalActions.getGoalsRequest());
    }
  }, []);

  const data = goals
    .filter(i => handleFilter(i, filterBy))
    .sort((a, b) => handleSort(a, b, sortBy, 'date'))
    .reduce((acc: any, item: any) => {
      const title = formatDate(item.date, 'yyyy');
      const year = acc.find((i: any) => i.title === title);
      if (year) {
        year.data.push(item);
        return acc.map((i: any) => (i.title === title ? year : i));
      }
      return [...acc, {title, data: [item]}];
    }, [])
    .sort((a: any, b: any) =>
      sortBy.hour === 1
        ? parseToNumber(a.title) - parseToNumber(b.title)
        : parseToNumber(b.title) - parseToNumber(a.title),
    );

  const onOpenFilter = () => {
    refFilter.current.open();
  };

  return (
    <Container>
      <Fixed>
        <Header />
      </Fixed>
      <ViewBase style={{padding: '5% 5%'}}>
        <Filter ref={refFilter} type="goal" filter />
        <Filter ref={refSort} type="goal" sort />
        <Row style={{marginBottom: 20, marginLeft: -20}}>
          <Option
            active={false}
            onClick={onOpenFilter}
            style={{
              fontSize: 12,
            }}>
            {t('filter')}
            <FiFilter color={theme.colors.onSurface} style={{marginLeft: 3}} />
            {countFilters > 0 && (
              <span
                style={{
                  color: theme.colors.white,
                  padding: 1,
                  borderRadius: 10,
                  fontFamily: 'Poppins-Bold',
                  fontSize: 8,
                  backgroundColor: theme.colors.primary,
                  marginLeft: -3,
                  marginBottom: -7,
                }}>
                {countFilters}
              </span>
            )}
          </Option>
          <Option
            active={false}
            onClick={() => refSort.current.open()}
            style={{
              fontSize: 12,
            }}>
            {t('sort')}
            <AiOutlineOrderedList
              color={theme.colors.onSurface}
              style={{marginLeft: 3}}
            />
          </Option>
        </Row>

        <SectionList
          sections={data as any}
          ListEmptyComponent={
            loading ? (
              <ViewBase center>
                <Loading size={35} color={colors.primary} />
              </ViewBase>
            ) : (
              <ListEmpty
                title={t(
                  data.length === 0 ? 'goal:noGoals' : 'goal:noGoalsFilter',
                )}
                text={data.length === 0 ? t('listEmptyGoalsText') : ''}
                titleButton={t('createGoal')}
                onPress={() =>
                  dispatch(
                    DrawerActions.setDrawer({
                      action: 'ADD_GOAL',
                      payload: undefined,
                    }),
                  )
                }
              />
            )
          }
          renderSectionHeader={({section}) => (
            <Label color={theme.colors.primary}>{section.title}</Label>
          )}
          keyExtractor={(item: any) => item.id}
          renderItem={({item}) => <CardGoal goal={item} tamMax={60} />}
        />
      </ViewBase>
    </Container>
  );
};

export default Goal;

import React, {useState, useMemo} from 'react';
import {Required, InputContainer} from '@/components/atoms';
import {useTranslation} from 'react-i18next';
import {Select as SelectAnt} from 'antd';
import {useTheme} from 'styled-components';
import {Label} from '@/styles/global';
const {Option} = SelectAnt;

interface Props {
  type: 'category' | 'task' | 'habit';
  onlyPremium?: boolean;
  titleModal: string;
  label: string;
  value: Array<any>;
  placeholder: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  data: any[];
  required?: boolean;
  testID?: string;
  getId?: any;
}
type Info = {
  description: string;
  textButtonAdd: string;
  keyLabel: string;
  dataVoid: string;
};
const MultiSelect = ({
  type,
  label,
  value,
  placeholder,
  setValue,
  data,
  required,
  getId,
}: Props): JSX.Element => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [itemsSelected, setItemSelected] = useState<any[]>(
    (value?.map(getId) || []) as any[],
  );

  const {keyLabel} = useMemo<Info>(() => {
    const params = {
      task: {
        description: t('task:multiSelect.task.description'),
        textButtonAdd: t('task:multiSelect.task.textButtonAdd'),
        keyLabel: 'title',
        dataVoid: t('task:multiSelect.task.dataVoid'),
      },
      habit: {
        keyLabel: 'routine',
        description: t('task:multiSelect.habit.description'),
        textButtonAdd: t('task:multiSelect.habit.textButtonAdd'),
        dataVoid: t('task:multiSelect.habit.dataVoid'),
      },
      category: {
        description: t('task:multiSelect.category.description'),
        textButtonAdd: t('task:addNewCategory'),
        keyLabel: 'name',
      },
    }[type];
    return params as Info;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function translate(v: string): string {
    if (typeof v !== 'string') return '';
    if (type === 'category') {
      return t(v);
    }
    return v;
  }

  function onChange(v: any[]) {
    setItemSelected(v);
    const value = v.map(j => data.find(i => getId(i) === j)).filter(i => !!i);
    setValue(value);
  }

  return (
    <InputContainer>
      <Label>
        {label} {required && <Required>*</Required>}{' '}
      </Label>
      <SelectAnt
        mode="multiple"
        allowClear
        value={itemsSelected}
        placeholder={placeholder}
        onChange={onChange}
        className={theme.title === 'dark' ? 'input-dark' : 'input-light'}>
        {data.map(i => (
          <Option key={getId(i)} value={getId(i)}>
            {translate(i[keyLabel])}
          </Option>
        ))}
      </SelectAnt>
    </InputContainer>
  );
};

MultiSelect.defaultProps = {
  required: false,
  onlyPremium: false,
  testID: undefined,
  getId: (item: any) => item?.id || item?.uid,
};
export default MultiSelect;

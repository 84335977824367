
import { differenceInMinutes, setHours, setMinutes} from 'date-fns';

export const blocks = Array.from({length: 24 * 4}).map((hour, index) => {
  const hours = Math.floor(index / 4);
  const min = (index % 4) * 15;
  return `${hours <= 9 ? `0${hours}` : hours}:${min || '00'}`;
});

export const DEFAULT_SIZE_CARD = 20
export const SIZE_BLOCK = 15
export const SIZE_HOUR_BLOCK = 60

export function getNormalDuration(duration: number) {
  const rest = duration % 15;
  const complement = 15 - rest;
  if (rest < 7) return duration - rest;
  return duration + complement;
}
export const filterTaskWithoutHours = (i: any) => !i.startHour || !i.endHour || !(i.endHour > i.startHour)
export function getDate(hour: string) {
  const [hh, mm] = hour.split(':').map(i => parseInt(i));
  return setHours(setMinutes(new Date(), mm), hh);
}
export function getDifferenceInMinutes(endHour: string, startHour: string) {
  return differenceInMinutes(getDate(endHour), getDate(startHour));
}
export function getHeight(endHour: string, startHour: string) {
  const minutes = getDifferenceInMinutes(endHour, startHour);
  const normal = (minutes / 30) * 30;
  return Math.max(normal, 17);
}
export function getTop(startHour: string) {
  const minutes = getDifferenceInMinutes(startHour, '00:00');
  const normal = (minutes / 30) * 30;
  return Math.max(normal, 17);
}

export function findBestTimeIntervalBetweenPeriod(
  startHour: string,
  endHour: string,
  activities: any[],
) {
  var start = startHour;
  var end = endHour;
  const conflicts = activities.filter(
    i => i.startHour < endHour && i.endHour > startHour,
  );
  if (conflicts.length) {
    conflicts.forEach(item => {
      if (item.startHour > start && item.startHour < end) {
        end = item.startHour;
      }
      if (item.endHour > start && end > item.endHour) {
        start = item.endHour;
      }
    });
  }
  return {startHour: start, endHour: end};
}

export const enableResize = {
  bottom: true,
  top: false,
  right: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};
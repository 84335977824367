import React, {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  RankingActions,
  selectorRanking,
  selectorUserId,
  UserRanking,
} from '@/store/modules';
import {useTranslation} from 'react-i18next';
import {Container, Content, Row} from '@/components/atoms';
import {
  ContentWeek,
  TextSubHeader,
  ButtonTransparent,
  TextHeaderTable,
} from './styles';
import {Header, ListItemPositionRanking} from '@/components/molecules';
import {useHistory, useLocation} from 'react-router';

type RouteParams = {
  type: string;
};

const Ranking = (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation<RouteParams>();
  const type = location.state.type;
  const dispatch = useDispatch();
  const ranking = useSelector(selectorRanking);
  const id = useSelector(selectorUserId);
  const {data = [], period} = useMemo<{
    data: UserRanking[];
    period: 'weekly' | 'monthly' | 'yearly';
  }>(
    () => ({period: ranking.period, data: ranking[ranking.period]}),
    [ranking],
  );
  const ownerPosition = useMemo<number | null>(
    () => data.find(item => item.user.id === id)?.position || null,
    [data, id],
  );

  useEffect(() => {
    dispatch(RankingActions.getRankingRequest(period, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <Container>
      <Header
        title={
          type === 'premium'
            ? t('statistic:rankingPremium')
            : t('statistic:rankingGlobal')
        }
        onPressButtonLeft={() => history.back()}>
        <ContentWeek>
          <ButtonTransparent
            onClick={() => dispatch(RankingActions.setPeriod('weekly'))}>
            <TextSubHeader active={period === 'weekly'}>
              {t('task:weekly').toUpperCase()}
            </TextSubHeader>
          </ButtonTransparent>
          <ButtonTransparent
            onClick={() => dispatch(RankingActions.setPeriod('monthly'))}>
            <TextSubHeader active={period === 'monthly'}>
              {t('task:monthly').toUpperCase()}
            </TextSubHeader>
          </ButtonTransparent>
          <ButtonTransparent
            onClick={() => dispatch(RankingActions.setPeriod('yearly'))}>
            <TextSubHeader active={period === 'yearly'}>
              {t('task:yearly').toUpperCase()}
            </TextSubHeader>
          </ButtonTransparent>
        </ContentWeek>
      </Header>
      <Content>
        <Row spaceBetween marginTop={60}>
          <TextHeaderTable>
            {t('statistic:top')} {data.length}
          </TextHeaderTable>
          {ownerPosition && (
            <TextHeaderTable>
              {t('statistic:yourPosition')}: {ownerPosition}{' '}
              {ownerPosition === 1 && '🏆'}{' '}
            </TextHeaderTable>
          )}
        </Row>
        {data.map(item => (
          <ListItemPositionRanking
            position={item.position}
            name={item?.user?.name}
            score={item[period]}
            level={item.level}
            premium={item?.user?.premium}
          />
        ))}
      </Content>
    </Container>
  );
};
export default Ranking;

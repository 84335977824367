import {useEffect, useState} from 'react';
import {
  Container,
  Logo,
  Form,
  Language,
  TextError,
  ContainerForm,
} from './styles';
import {useDispatch} from 'react-redux';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {useTranslation} from 'react-i18next';
import {UserActions} from '@/store/modules';
import {Row, TextLabel, ViewBase} from '@/components/atoms';
import {Button, Input} from '@/components/molecules';
import firebase from 'firebase';
import {useTheme} from 'styled-components';
import {Label} from '@/styles/global';
import {Link} from 'react-router-dom';
import Separator from '@/components/molecules/Separator';
import {useLanguage, useParams} from './hooks';
import {validateEmail} from '@/utils/validator';

export default function SignIn() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const theme = useTheme();
  const {language, setLanguage, languages} = useLanguage();
  const params = useParams();
  const [email, setEmail] = useState(params.email || '');
  const [password, setPassword] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [error, setError] = useState('');
  const [loadingLoginGoogle, setLoadingLoginGoogle] = useState(false);
  const [loadingLoginApple, setLoadingLoginApple] = useState(false);

  useEffect(() => {
    if (error) setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  function handleLogin(e?: any) {
    if (e) {
      e.preventDefault();
    }
    if (!validateEmail(email)) return setError(t('error:emailIsRequired'));
    if (!password) return setError(t('error:validation'));
    setLoadingLogin(true);
    dispatch(UserActions.loginRequest(email, password));
  }

  function handleGoogleLogin(
    info: GoogleLoginResponse | GoogleLoginResponseOffline,
  ): void {
    setLoadingLoginGoogle(true);
    if ('profileObj' in info) {
      const {email, name} = info.profileObj;
      dispatch(
        UserActions.loginGoogleRequest('google', name, email, info.tokenId),
      );
    }
  }

  async function handleAppleSigin() {
    setLoadingLoginApple(true);
    const provider = new firebase.auth.OAuthProvider('apple.com');
    const data = await firebase.auth().signInWithPopup(provider);
    const {user, additionalUserInfo, credential}: any = data;
    const emailGenerated = `${additionalUserInfo?.profile?.sub}@icloud.com`;
    dispatch(
      UserActions.loginGoogleRequest(
        'apple',
        user?.displayName || additionalUserInfo?.profile?.email || '',
        user?.email || emailGenerated,
        credential.idToken,
      ),
    );
    setLoadingLoginApple(false);
  }

  function getUrlResetPassword() {
    let url = '/reset-password';
    url = url + '?ln=' + language;
    if (email) url = url + '&email=' + email;
    return url;
  }

  return (
    <Container>
      <ContainerForm>
        <Logo />
        <>
          <Form onSubmit={handleLogin}>
            <Input
              label={t('auth:email')}
              value={email}
              onChangeText={setEmail}
              placeholder="Ex: joao@gmail.com"
              type="email"
            />
            <Input
              value={password}
              label={t('auth:password')}
              onChangeText={setPassword}
              placeholder="Ex: **********"
              type="password"
              onSubmitEditing={() => handleLogin()}
            />
            <ViewBase style={{width: '100%', textAlign: 'right'}}>
              <Row spaceBetween>
                <ViewBase />
                <Link to={getUrlResetPassword()}>
                  <Label>{t('auth:forgotPassword')}</Label>
                </Link>
              </Row>
            </ViewBase>
            <TextError>{error}</TextError>
            <Button
              marginTop={16}
              title={t('auth:signin')}
              onPress={handleLogin}
              loading={loadingLogin}
            />
            <Separator />
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string}
              onSuccess={handleGoogleLogin}
              onFailure={e => {
                setLoadingLoginGoogle(false);
              }}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <Button
                  title={t('auth:signinWithGoogle')}
                  onPress={() => {
                    setLoadingLoginGoogle(true);
                    renderProps.onClick();
                  }}
                  loading={loadingLoginGoogle}
                  backgroundColor={theme.colors.red}
                />
              )}
            />
            <Button
              title={t('auth:signinWithApple')}
              onPress={handleAppleSigin}
              loading={loadingLoginApple}
              marginTop={12}
              backgroundColor={theme.colors.dark}
            />
          </Form>

          <TextLabel marginTop={20} size={13}>
            {t('general:language')}:
          </TextLabel>
          <Row alignCenter>
            {languages.map(item => (
              <Language
                key={item.id}
                onClick={() => setLanguage(item.id)}
                active={language === item.id}>
                {item.icon}
              </Language>
            ))}
          </Row>
        </>
      </ContainerForm>
    </Container>
  );
}

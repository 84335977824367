export const AppTypes = {
  ADD_ATRIBUTE: 'app/ADD_ATRIBUTE',
  SET_INITIAL_STATE: 'app/SET_INITIAL_STATE',
};

export enum CardActivityColorBy {
  Label = 'label',
  Categories = 'categories'
}

export enum ModeView {
  Agenda = 'agenda',
  List = 'list',
  Pending = 'pending',
  Timeline = 'timeline',
}

export enum ModeInterval {
  Agenda = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Days3 = 'days3',
  Daily = 'daily',
}

export enum Language {
  Pt = 'pt',
  Es = 'es',
  En = 'en',
}

export interface AppState {
  theme: string;
  getAllActivities: boolean;
  showImportCalendar: boolean;
  showRateApp: boolean;
  storageVersion: number;
  showPremiumVersion: {
    attemp: number;
    lastDate: null | string;
  };
  dueDatePremium: null | string;
  lastCheckPremium?: string;
  language?: Language | null;
  mode?: ModeView;
  modeInterval?: ModeInterval;
  showTutorialApp?: boolean;
  showCheckList?: boolean;
  startOfWeek?: number;
  cardActivityColorBy?: CardActivityColorBy;
}

import Gamification from '@/services/Gamification';
import {Action} from '../utils';
import {Reward, RewardState, RewardTypes} from './type';

const INITIAL_STATE: RewardState = {
  lastSync: null,
  rewards: [],
  gallery: Gamification.rewards,
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): RewardState {
  switch (type) {
    case RewardTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case RewardTypes.GET_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: payload.data,
        lastSync: new Date(),
      };
    case RewardTypes.ADD_REWARD_REQUEST:
    case RewardTypes.ADD_REWARD_SUCCESS:
      return {
        ...state,
        rewards: [...state.rewards, payload.reward],
      };
    case RewardTypes.UPDATE_REWARD_REQUEST:
    case RewardTypes.UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.map(c =>
          c.uid === payload.reward.uid ? payload.reward : c,
        ),
      };

    case RewardTypes.DELETE_REWARD_REQUEST:
    case RewardTypes.DELETE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.filter(c => c.uid !== payload.reward.uid),
      };

    default:
      return state;
  }
}

export const RewardActions = {
  addRewardRequest: (reward: Reward): Action => ({
    type: RewardTypes.ADD_REWARD_REQUEST,
    payload: {reward},
  }),
  addRewardSuccess: (reward: Reward): Action => ({
    type: RewardTypes.ADD_REWARD_SUCCESS,
    payload: {reward},
  }),
  updateRewardRequest: (reward: Reward): Action => ({
    type: RewardTypes.UPDATE_REWARD_REQUEST,
    payload: {reward},
  }),
  updateRewardSuccess: (reward: Reward): Action => ({
    type: RewardTypes.UPDATE_REWARD_SUCCESS,
    payload: {reward},
  }),
  deleteRewardRequest: (reward: Reward): Action => ({
    type: RewardTypes.DELETE_REWARD_REQUEST,
    payload: {reward},
  }),
  deleteRewardSuccess: (reward: Reward): Action => ({
    type: RewardTypes.DELETE_REWARD_SUCCESS,
    payload: {reward},
  }),
  getRewardRequest: () => ({
    type: RewardTypes.GET_REWARDS_REQUEST,
  }),
  getRewardSuccess: (data: Reward[]): Action => ({
    type: RewardTypes.GET_REWARDS_SUCCESS,
    payload: {data},
  }),
  setFailure: () => ({
    type: RewardTypes.SET_FAILURE,
  }),
  syncRewardsRequest: () => ({
    type: RewardTypes.SYNC_REWARDS_REQUEST,
  }),
  setInitialState: () => ({
    type: RewardTypes.SET_INITIAL_STATE,
  }),
};

import {api} from '@/services';
import {Goal, HabitModel, TaskModel} from '@/store/modules';

export interface ParamsListActivities {
  lowerDate: string;
  upperDate: string;
}

export interface ListActivities {
  goals: Goal[];
  tasks: TaskModel[];
  habits: HabitModel[];
}

export async function listActivities(
  params: ParamsListActivities,
): Promise<ListActivities> {
  const {data} = await api.get('activities', {params});
  return data;
}
export async function getLists(
): Promise<TaskModel[]> {
  const {data} = await api.get('lists');
  return data;
}

/* eslint-disable no-param-reassign */
import {t} from '@/services/Locale';
import {
  Activity,
  ActivityModel,
  Category,
  Goal,
  HabitModel,
  TaskModel,
} from '@/store/modules';
import colors from '@/styles/colors';
import {
  isPast,
  setHours,
  setMinutes,
  subDays,
  formatDateToServer,
  getDateToday,
  isBetweenDate,
  differenceInDays,
  addDays,
} from '@/utils/date';

import {idNumber} from './uid';
import {endOfMonth, endOfYear, startOfMonth, startOfYear} from 'date-fns';
import {CIRCUMSTANTIAL, GOAL, HABITO, IMPORTANT, URGENT} from '@/constants';
import { parseToNumber } from './string';

export const isHabit = (item: any) => 'routine' in item;
export const isGoal = (item: any) => 'specific' in item;
export const isTask = (item: any) => 'title' in item;

type GetActivitiesInDate = {
  date: string;
  habits: HabitModel[];
  goals: Goal[];
  tasks: TaskModel[];
};

export const mapActivityInDate = (activity:any, activityInDate:any) => ({
  ...activity,
  ...activityInDate,
  checkList: activity?.checkList,
  checkListInDate: isTask(activity)
    ? activityInDate?.checkList?.length
      ? activityInDate?.checkList
      : activity?.checkListDaily && activity?.checkList?.length
      ? activity.checkList.map((i:any) => ({...i, done: false}))
      : null
    : isHabit(activity)
    ? activityInDate?.checkList?.length
      ? activityInDate?.checkList
      : activity?.checkList?.length
      ? activity.checkList.map((i:any) => ({...i, done: false}))
      : null
    : activity?.checkList,
});

export const getActivities = ({
  tasks,
  upperDate,
  lowerDate,
  habits,
  goals,
  mode,
}: any) => {
  return Array.from(
    {length: differenceInDays(upperDate, lowerDate) + 1},
    (v, k) => formatDateToServer(addDays(lowerDate, k)),
  ).map(date => ({
    date,
    data: [...tasks, ...habits, ...goals]
      .filter(i => isBetweenDate(date, i))
      .map(i => {
        const inDate =
          'dones' in i
            ? i.dones.find((i: any) => i.date === date) || {
                done: false,
                amount: 0,
              }
            : i;
        return {
          ...i,
          date,
          done: inDate?.done || false,
          note: 'note' in inDate ? inDate?.note : null,
          amount:
            !!inDate && 'amount' in inDate ? inDate.amount || 0 : undefined,
          checkListInDate:
            'title' in i
              ? 'checkList' in inDate && inDate.checkList
                ? inDate.checkList
                : 'checkListDaily' in i &&
                  i.checkListDaily &&
                  'checkList' in i &&
                  i.checkList
                ? i.checkList.map((i: any, index: number) => ({
                    ...i,
                    done: false,
                  }))
                : null
              : 'routine' in i
              ? !!inDate && 'checkList' in inDate && inDate?.checkList?.length
                ? inDate?.checkList
                : i?.checkList?.length
                ? i.checkList.map((i: any) => ({...i, done: false}))
                : null
              : 'checkList' in i
              ? i.checkList
              : null,
        };
      })
      .filter(i => !i.deleted && (mode === 'pending' ? !i.done : true)),
  }));
};

export function getBorderColor(
  activity: any,
  cardActivityColorBy: string,
  categories: any[],
): string {
  if (
    activity?.categories &&
    activity?.categories?.length > 0 &&
    cardActivityColorBy === 'categories'
  ) {
    const categoriesList = activity.categories;
    const color = categories.find(c => categoriesList.includes(c.uid))?.color;
    if (color) return color;
  }
  if ('routine' in activity) return colors.green;
  if (activity.label === IMPORTANT) return colors.blue;
  if (activity.label === URGENT) return colors.red;
  if (activity.label === CIRCUMSTANTIAL) return colors.yellow;
  return colors.goal;
}
export const getId = (item: any) => item.id || item.uid;

function parseBooleanToNumber(done: boolean): number {
  if (done) return 1;
  return 0;
}

export function handleSort(
  prev: any,
  next: any,
  sortBy: any,
  keySort: 'startHour' | 'startDate' | 'date' = 'startHour',
): number {
  if (sortBy.status !== null) {
    if (next.done === prev.done) {
      const p = prev[keySort];
      const n = next[keySort];
      return parseToNumber(p) - parseToNumber(n);
    }
    if (sortBy.status) {
      return parseBooleanToNumber(next.done) - parseBooleanToNumber(prev.done);
    }
    return parseBooleanToNumber(prev.done) - parseBooleanToNumber(next.done);
  }
  if (sortBy.hour !== null) {
    const p = prev[keySort];
    const n = next[keySort];
    if (sortBy.hour === 1) {
      return parseToNumber(p) - parseToNumber(n);
    }
    return parseToNumber(n) - parseToNumber(p);
  }
  if (sortBy?.label) {
    const urgents = [URGENT, IMPORTANT, GOAL, HABITO, CIRCUMSTANTIAL].reverse();
    const important = [
      IMPORTANT,
      GOAL,
      HABITO,
      URGENT,
      CIRCUMSTANTIAL,
    ].reverse();
    const p =
      sortBy?.label === IMPORTANT
        ? important.findIndex(i => i === prev.label)
        : urgents.findIndex(i => i === prev.label);
    const n =
      sortBy?.label === IMPORTANT
        ? important.findIndex(i => i === next.label)
        : urgents.findIndex(i => i === next.label);
    return n - p;
  }
  return 1;
}

export function handleFilter(activity: Activity, filterBy: any): boolean {
  if (
    filterBy.status.length !== 0 &&
    !filterBy.status.includes(activity.done)
  ) {
    return false;
  }
  if (filterBy.types.length !== 0 && !filterBy.types.includes(activity.label)) {
    return false;
  }

  if (filterBy?.date && filterBy?.date?.length) {
    const mapper = {
      thisYear: {
        lower: formatDateToServer(startOfYear(new Date())),
        upper: formatDateToServer(endOfYear(new Date())),
      },
      thisMonth: {
        lower: formatDateToServer(startOfMonth(new Date())),
        upper: formatDateToServer(endOfMonth(new Date())),
      },
      future: {
        lower: formatDateToServer(new Date()),
        upper: '3000-01-01',
      },
      past: {
        lower: '2000-01-01',
        upper: formatDateToServer(new Date()),
      },
    };
    const {upper, lower} = filterBy.date.reduce((acc: any, i: string) => {
      const item = mapper[i as 'past'];
      const lower = acc?.lower
        ? acc.lower < item.lower
          ? acc.lower
          : item.lower
        : item.lower;
      const upper = acc?.upper
        ? acc.upper > item.upper
          ? acc.upper
          : item.upper
        : item.upper;
      return {
        lower,
        upper,
      };
    }, {}) as unknown as {upper: any; lower: any};

    return activity.date >= lower && activity.date <= upper;
  }
  if (
    filterBy.categories.length !== 0 &&
    (!activity.categories ||
      !filterBy.categories.some((i: any) => activity?.categories?.includes(i)))
  ) {
    return false;
  }
  return true;
}

export function getStatisticActivity(activity: HabitModel | TaskModel): {
  sequencesDone: number[];
  style: any;
} {
  const dateCreated = activity?.startDate;
  let dateAccumulator = new Date(getDateToday()).toISOString().split('T')[0];
  let amountDaysHabitDoneCurrent = 0;
  const sequencesDone = [];
  let style = {};
  const styleHabitDone = {
    startingDay: true,
    selected: true,
    endingDay: true,
    color: colors.green,
    textColor: 'white',
  };
  const styleHabitFailure = {
    startingDay: true,
    selected: true,
    endingDay: true,
    color: colors.red,
    textColor: 'white',
  };
  const styleHabitDisable = {
    disabled: true,
    disableTouchEvent: true,
  };
  while (dateCreated <= dateAccumulator) {
    let styleHabit = {};
    const dateDone = activity?.dones?.find(
      // eslint-disable-next-line no-loop-func
      item => item.date === dateAccumulator,
    )?.done;
    if (dateDone) {
      amountDaysHabitDoneCurrent += 1;
      styleHabit = styleHabitDone;
    } else if (!isBetweenDate(formatDateToServer(dateAccumulator), activity)) {
      styleHabit = styleHabitDisable;
    } else {
      styleHabit = styleHabitFailure;
      sequencesDone.push(amountDaysHabitDoneCurrent);
      amountDaysHabitDoneCurrent = 0;
    }
    style = {
      [dateAccumulator]: styleHabit,
      ...style,
    };
    // eslint-disable-next-line prefer-destructuring
    dateAccumulator = subDays(new Date(dateAccumulator), 1)
      .toISOString()
      .split('T')[0];
  }
  sequencesDone.push(amountDaysHabitDoneCurrent);
  return {sequencesDone, style};
}

export function getActivitiesInDate({
  date,
  habits,
  goals,
  tasks,
}: GetActivitiesInDate): Activity[] {
  const actitiviesToday = [...tasks, ...goals, ...habits]
    .filter(item => isBetweenDate(date, item))
    .map(item => {
      let day = 'dones' in item ? item.dones.find(i => i.date === date) : item;
      return {
        ...item,
        date,
        done: !!day?.done,
        amount: day && 'amount' in day ? (day.amount as number) : null,
      };
    });
  return actitiviesToday;
}

function getInfoNotification(
  activity:
    | {
        title: string;
        description: string | null;
      }
    | {
        routine: string;
        reward: string;
      }
    | {
        specific: string;
        measurable: string;
      },
) {
  if ('title' in activity)
    return {
      title: activity.title,
      message: activity.description,
      action: 'CHECK_TASK_REQUEST',
    };
  if ('routine' in activity)
    return {
      title: activity.routine,
      message: activity.reward,
      action: 'CHECK_HABIT_REQUEST',
    };
  if ('specific' in activity)
    return {
      title: activity.specific,
      message: activity.measurable,
      action: 'CHECK_GOAL_REQUEST',
    };
  return null;
}

export function getNotificationData(
  activity: ActivityModel,
  premium: boolean,
): any | null {
  const data =
    'specific' in activity
      ? {
          ...activity,
          startHour: '10:00',
          receiveNotification: true,
          startDate: activity.date,
        }
      : activity;
  if (!data.receiveNotification || !data.startHour) {
    return null;
  }
  const notify = getInfoNotification(data);
  if (!notify) {
    return null;
  }
  // eslint-disable-next-line radix
  const [hour, min] = data.startHour.split(':').map(i => parseInt(i));
  const activityDate = 'date' in data ? data.date : getDateToday();
  const date = setHours(setMinutes(activityDate, min), hour);
  if (isPast(date)) return null;
  const notification = {
    id: String(idNumber()),
    title: notify.title,
    message: notify.message || t(activity.label),
    date,
    sound: premium
      ? 'soundNotification' in activity
        ? activity.soundNotification || 'default'
        : 'default'
      : 'default',
    actions: premium ? [t('done')] : undefined,
    data: {
      id: activity.id,
      uid: activity.uid,
      date: activityDate,
      action: notify.action,
    },
  };
  return notification;
}

export const getBorderColorByLabel = (label: string) => {
  return {
    [IMPORTANT]: colors.blue,
    [URGENT]: colors.red,
    [CIRCUMSTANTIAL]: colors.yellow,
    [HABITO]: colors.green,
    [GOAL]: colors.goal,
  }[label] as string;
};

export function getBorderColorActivity(
  activity: Activity,
  categories: Category[],
  cardActivityColorBy: string,
) {
  if (
    activity?.categories &&
    activity?.categories?.length > 0 &&
    cardActivityColorBy === 'categories'
  ) {
    const categoriesList = activity.categories;
    const color = categories.find(c => categoriesList.includes(c.uid))?.color;
    if (color) return color;
  }
  if ('title' in activity) return getBorderColorByLabel(activity.label);
  if ('routine' in activity) return colors.green;
  return colors.goal;
}


function hexToRgb(hex: string) {
  // Remove the leading # if present
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return {r, g, b};
}

function getLuminance({r, g, b}: {r: number; g: number; b: number}) {
  // Convert RGB to sRGB
  r /= 255;
  g /= 255;
  b /= 255;

  // Apply gamma correction
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  // Calculate luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function getTextColorByBackground(hex: string) {
  const rgb = hexToRgb(hex);
  const luminance = getLuminance(rgb);

  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}

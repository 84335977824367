export default {
  noActivitiesFilter: "No activity according to applied filters",
  doneAllActivities: `Congratulations! 🚀 \nYou've performed all the activities of the day! 🎯`,
  noActivitiesToday: `You don't have any activities for the day`,
  noActivitiesInPeriod: `You don't have any activities for the period`,
  noTaskInThisList: `You do not have any Tasks in this list`,
  noTaskInThisListFilter: `No tasks according to filters.`,
  
  addTask: "Add Task",
  howToAddTask: "How to manage tasks",
  editTask: "Edit Task",
  deleteTask: "Delete Task",
  updateTask: 'Edit Task',
  updateTaskAlert: `This task repeats frequently. Do you want to edit just this occurrence ({{date}}) or also its future occurrences?`,
  deleteDesc: "Are you sure you want to delete this activity?",
  deleteTaskAlert: `This task is repeat oftenly. Do you want to delete just this occurrence ({{date}}) or also its future occurrences?`,
  title: "Title",
  label: "Label",
  categories: "Categories",
  description: "Description",
  startDate: "Day",
  endDate: "End",
  hour: "Hour",
  start: "Start",
  end: "End",
  checkList: "Check-list",
  notification: "Notification",
  period: "Period",
  notRepeat: "Do not repeat",
  daily: "Daily",
  segSex: "MON to FRI",
  segSab: "MON to SAT",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
  custom: "Custom",

  all: "All Period",
  "7days": "7 Days",
  "30days": "30 Days",
  thisMonth: "This Month",
  thisYear: "This Year",
  "12month": "12 Months",
  
  hasDate: "Set Date",
  hasDateDesc: "If not activated, the task is visible in the lists screen",
  inbox: 'Inbox',
  allTask: 'All',

  placeholderTitle: "Ex: study for exam",
  placeholderLabel: "Select Type",
  placeholderCategories: "Ex: Intellectual, Professional",
  placeholderDescription: "Ex: Chapter 1, 2 and 3",
  placeholderPeriodDesc:
    "The activity will be repeated on all selected days, until the defined end date.",
  placeholderPeriodInterval: "Repeat every {{x}} days",
  everyXdays: "Every {{x}} days",
  intervalDays: "Range of days:",
  nextDays: "Next occurrences:",
  placeholderPeriodIntervalDesc:
    "The activity will be repeated, interspersing the number of days informed, until the defined end date.",
  errorIntervalDays: "Enter the range of days!",

  placeholderPeriod: "Select the days of the week",
  placeholderNotification: "Activity time notifications",
  exampleCheckList: "Ex: Task 1",

  addNewCategory: "Add New Category",
  newCategory: "New Category",
  placeholderCategory: "Ex: Project X",
  "multiSelect.task.description":
    "Creating tasks that align with your goals increase your chances of achieving them!",
  "multiSelect.task.textButtonAdd": "Add new Task",
  "multiSelect.task.dataVoid":
    "You don't have any tasks, add a new task right now!",

  "multiSelect.habit.description":
    "Creating habits that are in line with your goals increase your chances of reaching them!",
  "multiSelect.habit.textButtonAdd": "Add new Habit",
  "multiSelect.habit.dataVoid":
    "You don't have any habit, add a new habit right now!",

  "multiSelect.category.description":
    "Select the categories referring to your activity, based on the Wheel of Life's tool.",
  reschedule: "Reschedule",
  placeholderReschedule:
    "Reschedule the task for the next day while it is not completed.",
    transformInTask: 'Transform into Task',
    moveToChecklist: 'Move to Checklist',
    moveToOtherChecklist: 'Move to another Checklist',
    move: 'Move',
    targetTask: 'Target Task',
    targetTaskDesc: 'Task to which the checklist item will belong.',
    itemOfChecklist: 'Checklist Item',
    selectTask: 'Select Task',
    periodOfItem: 'Item frequency',
    periodOfItemDesc:
      'Frequency that the checklist item {{item}} should have in the checklist in the target task ({{task}})',
    'checklist-oneday': 'Only one occurrence',
    'checklist-daily': 'All occurrences',
} as any;

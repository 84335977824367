import {
  GOAL,
  HABITO,
  CIRCUMSTANTIAL,
  URGENT,
  IMPORTANT,
  JUST_TODAY,
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
  CUSTOM,
  SEG_SEX,
  SEG_SAB,
} from '@/constants';

export default {
  task: 'Tarefa',
  tasks: 'Tarefas',

  habit: 'Hábito',
  habits: 'Hábitos',
  trigger: 'Gatilho',
  reward: 'Recompensa',
  routine: 'Rotina',
  exampleHabit: 'Leitura',
  exampleTrigger: 'Deixar livro ao lado da cama',
  exampleRoutine: 'Ler 20 páginas',
  exampleReward: 'Adquirir conhecimento',
  notifications: 'Notificações',

  goal: 'Meta',
  goals: 'Metas',
  
  exampleDailyGoal: 'Ex: 20 páginas',
  dailyGoal: 'Meta diária',
  addProgress: 'Adicionar Progresso',
  placeholderAmount: 'Ex: 30',
  unity: 'Unidade',
  amount: 'Quantidade',
  suggestions: 'Sugestões',
  field: 'Campo',

  important: 'Importante',
  urgent: 'Urgente',
  circumstantial: 'Circunstancial',
  [HABITO]: 'Hábito',
  [GOAL]: 'Meta',
  [IMPORTANT]: 'Importante',
  [URGENT]: 'Urgente',
  [CIRCUMSTANTIAL]: 'Circunstancial',

  [JUST_TODAY]: 'Não Repete',
  [DAILY]: 'Diária',
  [WEEKLY]: 'Semanal',
  [MONTHLY]: 'Mensal',
  [YEARLY]: 'Anual',
  [CUSTOM]: 'Personalizado',
  [SEG_SEX]: 'SEG á SEX',
  [SEG_SAB]: 'SEG á SAB',

  DOM: `DOM`,
  SEG: `SEG`,
  TER: `TER`,
  QUA: `QUA`,
  QUI: `QUI`,
  SEX: `SEX`,
  SAB: `SAB`,

  to: 'até',
  or: 'OU',
  change: 'Alterar',
  clean: 'Limpar',
  never: 'Nunca',

  decrescent: 'Decrescente',
  crescent: 'Crescente',
  filter: 'Filtrar',
  sort: 'Ordenar',

  update: 'Update',
  updateApp: 'Atualize o aplicativo!',
  updateAppStore: 'Atualize o aplicativo pela Loja',
  updateAppDesc:
    'Essa versão foi descontinuada, para continuar utilizando o Flynow, atualize o aplicativo para a versão mais recente!',

  tip: 'DICA:',
  next: 'Seguinte',
  previous: 'Voltar',
  back: 'Voltar',
  onlyThis: 'Só essa ocorrência',
  thisAndNext: 'Essa e as próximas',
  allOcorrences: 'Todas ocorrências',

  days: 'dias',
  daily: 'Dia',
  days3: '3 Dias',
  week: 'Semana',
  weekly: 'Semanal',
  monthly: 'Mensal',

  select: 'Selecionar',
  save: 'Salvar',
  yes: 'SIM',
  no: 'NÃO',
  ok: 'OK',
  share: 'Compartilhar',
  add: 'Adicionar',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  addNote: 'Adicionar Anotação',
  edit: 'Editar',
  delete: 'Apagar',
  duplicate: 'Duplicar',
  thisYear: 'Este ano',
  past: 'Passado',
  future: 'Futuro',
  searchByTitle: 'Pesquisar por título...',
  searchActivity: 'Buscar Atividade',
  tools: 'Ferramentas',

  today: 'Hoje',
  yesterday: 'Ontem',
  tomorrow: 'Amanhã',
  activities: 'Atividades',

  selectAction: 'Escolha uma Ação',
  addNew: 'Adicionar novo(a):',
  maskAs: 'Marcar como {{status}}',
  detailsDay: 'Detalhes do dia',
  details: 'Detalhes',
  date: 'Data',
  status: 'Status',
  plan: 'Plano',
  points: 'Pontos',
  note: 'Anotação',
  notes: 'Anotações',
  listVoid: 'Nenhum item no período!',
  placeholderNote: 'Insira uma anotação para este dia em específico',

  descPremiumDeleteActivities:
    'Com a versão premium você tem atividades ilimitas e diversas outras funcionalidades',
  deleteActivity: 'Apagar {{activity}}',
  descDelete:
    'O limite de {{activity}} futuros é {{limit}}. Mas você já possui {{total}}.\nApague {{deleteMin}} {{activity}} para adicionar outro.',

  statisticHabit: 'Estatísticas do Hábito',
  statisticTask: 'Estatísticas da Tarefa',
  history: 'Histórico',
  performance: 'Desempenho',
  totalDaysDone: 'Total de dias realizados',
  totalDaysNotDone: 'Total de dias não realizados',
  currentSequence: 'Sequencia atual',
  biggerSequence: 'Maior Sequencia',
  biggestSequences: 'Maiores Sequencias',
  lastSequences: 'Últimas Sequencias',
  detailsHabit: 'Detalhes do Hábito',
  detailsTask: 'Detalhes da Tarefa',
  noActivitiesAdded: 'Nenhuma atividade foi adicionada!',

  title: 'Título',
  label: 'Tipo',
  categories: 'Categorias',
  description: 'Descrição',
  startDate: 'Dia',
  endDate: 'Fim',
  hour: 'Horário',
  start: 'Início',
  startHour: 'Horário de início',
  endHour: 'Horário de fim',
  end: 'Fim',
  achievable: 'Check List',
  checkList: 'Check-list',
  checkListDaily: 'Check-list diário',
  progress: 'Progresso',
  notification: 'Notificação',
  active: 'Ativada',
  inactive: 'Desativada',
  period: 'Frequência',
  sound: 'Som',
  soundNotification: 'Som da Notificação',
  soundDefault: 'Som padrão do sistema',
  selectNotification: 'Selecione uma notificação',

  specific: 'Objetivo',
  measurable: 'Quantidade',
  relevant: 'Importância',

  Profissional: 'Profissional',
  Intelectual: 'Intelectual',
  Finanças: 'Finanças',
  Social: 'Social',
  Relacionamento: 'Relacionamento',
  Família: 'Família',
  Lazer: 'Lazer',
  Saúde: 'Saúde',
  Espiritual: 'Espiritual',
  Emocional: 'Emocional',

  'sound.default': 'Padrão',
  'sound.alarm': 'Alarme - Clock',
  'sound.morningclockalarm': 'Alarme - Morning Clock',
  'sound.notification': 'Notificação - Soft',
  'sound.positivenotification': 'Notificação - Positive',
  'sound.bellnotification': 'Notificação - Bell',
  none: 'Nenhum',
  backgroundNoise: 'Som de fundo',

  discardChanges: 'Descartar alterações?',
  discardChangesDesc:
    'Você possui mudanças não salvas, tem certeza que deseja sair?',
  done: 'Concluir',
  notDone: 'Não Concluir',
  message: 'Mensagem',
  actions: 'Ações',
  selectDate: 'Selecionar Data',
  agenda: 'Agenda',
  pending: 'Pendências',
  list: 'Lista',
  lists: "Listas",
  modeView: 'Visualização',

  import: 'Importar',
  importCalendar: 'Importar Calendário',
  eventsFromCalendar: 'Eventos do Calendário',
  askImportCalendar: 'Deseja importar suas atividades do calendário?',
  selectCalendars: 'Selecione o(s) calendário(s):',
  selectEvents: 'Selecione o(s) calendário(s):',
  emptyListEvents: 'Nenhum evento disponível.',

  color: 'Cor',
  selecionColor: 'Selecionar Cor',
  addCategory: 'Nova Categoria',
  custom: 'Customizadas',
  geral: 'Geral',
  others: 'Outros',
  fullDay: 'Dia Inteiro',
  restoreNotifications: 'Restaurar Notificações',
  restoreNotificationsMsg:
    'Todas as notificações serão agendadas nos proximos segundos...',
  testNotification: 'Testar Notificação',

  detailsGoal: 'Detalhes da meta',
  statisticGoal: 'Estatísticas das metas',

  timer: 'Temporizador',
  stopwatch: 'Cronômetro',
  reset: 'Redefinir',
  pause: 'Pausar',
  resume: 'Retomar',
  toStart: 'Iniciar',
  time: 'Tempo',
  finishAndSave: 'Finalizar e Salvar',
  placeholderTimerNotification: 'Som da notificação ao expirar o tempo.',
  focusTimerFinished: 'Temporizador de foco finalizado! 🚀🎉',
  submit: 'Enviar',

  updateYourEmail: 'Atualize o seu email!',
  updateYourEmailDesc:
    'Você optou por ocultar seu email no login social, isso pode causar algumas inconsistências em alguns de nossos serviços. Informe o seu email real.',

  listTasksEmpty:
    'O gerenciamento de tarefas te torna mais produtivo e te aproxima de seus objetivos, crie novas tarefas!',
  shareThisReward: 'Compartilhe essa conquista com seus amigos!',
  removeFiltersToSee:
    'Para visualizar as atividade dessa data, remova os filtros!',
  createTask: 'Criar Tarefa',
  createHabit: 'Criar novo Hábito',
  createGoal: 'Criar Meta',
  listEmptyRewards: 'Nenhuma conquista ainda :(',
  listEmptyRewardsText:
    'Crie metas, tarefas e hábitos para atingir sua primeira conquista!',
  listEmptyHabitsText:
    'Os hábitos te ajudam a manter a disciplina na busca de seus objetivos, crie um novo hábito!',

  listEmptyGoalsText:
    'As metas direcionam você em busca de seus objetivos, crie sua primeira meta!',

  onlyPremiumText:
    'Torne-se premium para ter acesso a essa e outras diversas funcionalidades que vão melhorar sua produtivade!',
  listEmptyNotifications: 'Nenhuma notificação agendada',
  listEmptyNotificationsText:
    'Se você tiver tarefas ou hábitos com notificações ativadas, clique em Restaurar Notificações para reagendar suas notificações.',
  listEmptyReportText:
    'Você ainda não completou um periodo completo de uso do app, para a criação do relatório.',

  
  addTimeManual: 'Adicionar tempo manualmente',
  goToday: 'Ir para a data de hoje',
  doWantSaveTime: 'Salvar tempo extra?',
  extraTime: 'Tempo Extra',

  newList: 'Nova Lista',
  columns: 'Colunas',
}as any;

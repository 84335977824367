import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import i18next from 'i18next';
import themes from './styles/themes/index';
import {ThemeProvider} from 'styled-components';
import {ToastContainer} from 'react-toastify';
import {colors} from './styles';
import Routes from './Routes';
import {PurchaseActions, selectorTheme, selectorUser} from './store/modules';
import FloatButton from './components/FloatButton';
import ModalGlobal from './components/molecules/ModalGlobal';
import {Analytics, api, Sentry} from './services';
import {io} from 'socket.io-client';
import {differenceInBusinessDays} from 'date-fns';
import pt from 'antd/es/locale/pt_BR';
import es from 'antd/es/locale/es_ES';
import en from 'antd/es/locale/en_US';
import {ConfigProvider} from 'antd';

export default function Config() {
  const dispatch = useDispatch();
  const theme = useSelector(selectorTheme);
  const user = useSelector(selectorUser);

  useEffect(() => {
    if (user.id && user.language) {
      i18next.changeLanguage(user.language);
    }
    if (
      user.id &&
      (!user.lastDateCheckPremium ||
        differenceInBusinessDays(new Date(), user.lastDateCheckPremium) <= 3)
    ) {
      dispatch(PurchaseActions.checkPremiumRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, user.language]);

  const locale = useMemo(() => {
    if (user.language === 'es') return es;
    if (user.language === 'en') return en;
    return pt;
  }, [user.language]);

  useEffect(() => {
    if (!user.id) return;
    if (user.token) {
      api.defaults.headers.Authorization = user.token;
    }
    Analytics.setUser(user);
    Sentry.setUser({id: String(user.id), email: user.email});
    i18next.changeLanguage(user.language);

    const socket = io(api.defaults.baseURL as string, {
      query: {id: String(user.id), platform: 'web'},
    });
    socket.on('queue', action => {
      if (action.platform === 'web') return;
      dispatch({...action, type: action.action});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <ThemeProvider theme={theme === 'dark' ? themes.dark : themes.default}>
      <ConfigProvider locale={locale}>
        <ToastContainer
          toastStyle={{
            color: colors.white,
            borderColor: '#eee',
            backgroundColor: colors.primary,
          }}
        />
        <Routes />
        {user.id > 0 && <FloatButton />}
        <ModalGlobal />
      </ConfigProvider>
    </ThemeProvider>
  );
}

import React, {useEffect, useState} from 'react';
import {isPast, parseISO} from 'date-fns';
import {getCurrentWeek, getDateToday, isBetweenDate} from '../../utils/date';
import {ContainerCard, DetailsHabit} from './styles';
import Week from '../Week';
import {colors} from '@/styles';
import {
  CurrentDay as Day,
  HabitModel,
  selectorStartOfWeek,
} from '@/store/modules';
import {ActivityService} from '@/services';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
type CurrentDay = Day & {inDate?: any};
interface Props {
  habit: HabitModel;
  textColorLabel?: string;
  backgroundColor?: string;
  onPressHabit(habit: HabitModel): void;
}

const CardHabit = ({
  habit,
  textColorLabel,
  backgroundColor,
  onPressHabit,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const weekStartsOn = useSelector(selectorStartOfWeek);
  const [currentWeek, setCurrentWeek] = useState(
    getCurrentWeek(getDateToday(), weekStartsOn),
  );

  useEffect(() => {
    customWeek(currentWeek);
    // eslint-disable-next-line
  }, [habit.dones]);

  function customWeek(week: CurrentDay[]) {
    setCurrentWeek(
      week.map(item => {
        const day = habit.dones.find(i => i.date === item.date) || {
          done: false,
        };
        const isDayValid = isDateBetweenValidPeriod(item.date);
        return {
          ...item,
          inDate: day,
          dateContainerStyle: isDayValid
            ? {
                backgroundColor: day.done ? colors.green : colors.red,
                borderRadius: 12,
              }
            : {},
          textStyle: isDayValid
            ? {
                color: colors.white,
                fontFamily: 'Poppins-Bold',
              }
            : {
                fontWeight: 'normal',
                color: colors.light,
              },
        };
      }),
    );
  }

  function onChangeWeek(week: CurrentDay[]) {
    customWeek(week);
  }

  function isDateBetweenValidPeriod(date: string) {
    return (
      isBetweenDate(date, habit) &&
      date >= habit.startDate &&
      isPast(parseISO(date))
    );
  }

  function handlePressDate(day: CurrentDay) {
    ActivityService.done({
      ...habit,
      ...day?.inDate,
      date: day.date,
    });
  }

  return (
    <ContainerCard>
      <DetailsHabit
        style={{cursor: 'pointer'}}
        onClick={() => onPressHabit(habit)}>
        <span>
          {habit.routine}{' '}
          {habit?.goal?.amount
            ? `(${habit?.goal?.amount} ${t('unity:' + habit?.goal?.unity)})`
            : ''}
        </span>
        <span>{habit.startHour}</span>
      </DetailsHabit>
      <Week
        currentWeek={currentWeek}
        onChangeWeek={onChangeWeek}
        isDateBetweenValidPeriod={isDateBetweenValidPeriod}
        onDateSelected={handlePressDate}
      />
    </ContainerCard>
  );
};

export default CardHabit;

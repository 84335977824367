import {call, put, select} from 'redux-saga/effects';
import {api} from '@/services';
import {ReportActions, ModalActions} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import {Action} from '../utils';

export function* getReports(): SagaIterator<void> {
  const net = yield select(state => state.session.network);
  try {
    if (!net.isInternetReachable) return;
    const {data} = yield call(api.get, 'reports');
    yield put(ReportActions.getReportsSuccess(data));
  } catch (err) {
    //
  }
}
export function* createReport({payload}: Action): SagaIterator<void> {
  try {
    const {data} = yield call(api.get, 'reportStatistics', {params: payload});
    yield put(ReportActions.createReportSuccess(data));
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}

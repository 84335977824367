export type Colors = {
  white: string;
  lighter: string;
  light: string;
  regular: string;
  dark: string;
  darker: string;
  black: string;

  title: string;
  primary: string;
  onPrimary: string;

  degrade: [string, string];
  degradeGrey: [string, string];

  darkTransparent: string;
  transparent: string;

  premium: string;
  active: string;

  green: string;
  red: string;
  blue: string;
  yellow: string;
  goal: string;
};

export default {
  white: '#fff',
  lighter: '#ddd',
  light: '#999',
  regular: '#666',
  dark: '#333',
  darker: '#222',
  black: '#000',

  primary: '#AA20FF',
  onPrimary: '#fff',
  degrade: ['#7965CA', '#AA20FF'],

  darkTransparent: 'rgba(0, 0, 0, 0.8)',
  transparent: 'transparent',

  premium: '#F0BA28',
  active: '#03C060',

  green: '#90EE90',
  red: '#FF6347',
  blue: '#56CCF2',
  yellow: '#F2994A',
  goal: '#1565C0'

} as Colors;

import {put} from 'redux-saga/effects';
import {Analytics, Error, Sentry, Toast} from '@/services';
import {ERROR} from '@/constants';
import {UserActions} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import {Action} from '../utils';

export function* setError(action: Action): SagaIterator<void> {
  const {error} = action.payload;
  try {
    const err = Error.handle(error);
    Toast.error(err.title)
    if (err.code === ERROR.TOKEN_INVALID) {
      yield put(UserActions.getOutRequest());
    }
    Analytics.log(Analytics.events.ERR, {
      title: err.title,
      description: err.message,
    });
  } catch (err: any) {
    Toast.error(err.message)
    Sentry.captureException(err, {description: 'in setError (modal/saga.js)'});
  }
}

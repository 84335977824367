import styled from 'styled-components';

export const Container = styled.div`
  
`;
export const Content = styled.div`
  display:flex;
  flex:1;
  justify-content:space-around;
  padding:0 20px;
  padding-top:125px;
  background: ${({theme})=> theme.colors.background};
`;
export const Column = styled.div`
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;
`;

import {
  GOAL,
  HABITO,
  CIRCUMSTANTIAL,
  URGENT,
  IMPORTANT,
  JUST_TODAY,
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
  CUSTOM,
  SEG_SEX,
  SEG_SAB,
} from "@/constants";

export default {
  task: "Tarea",
  tasks: "Tareas",

  habit: "Hábito",
  habits: "Hábitos",
  trigger: "Gatillo",
  reward: "Recompensa",
  routine: "Rutina",
  exampleHabit: "Leer",
  exampleTrigger: "Dejar el libro junto a la cama",
  exampleRoutine: "Leer 20 páginas de un libro",
  exampleReward: "Adquirir conocimientos",

  goal: "Objetivo",
  goals: "Objetivos",

  exampleDailyGoal: "Ej: 20 páginas",
  dailyGoal: "Objetivo diario",
  addProgress: 'Adicionar Progresso',
  placeholderAmount: 'Ex: 30',
  unity: "Unidad",
  amount: "Cantidad",
  suggestions: "Sugerencias",
  field: "Campo",

  important: "Importante",
  urgent: "Urgente",
  circumstantial: "Circunstancial",
  [HABITO]: "Hábito",
  [GOAL]: "Objetivo",
  [IMPORTANT]: "Importante",
  [URGENT]: "Urgente",
  [CIRCUMSTANTIAL]: "Circunstancial",

  [JUST_TODAY]: "No Repetir",
  [DAILY]: "Diario",
  [WEEKLY]: "Semanal",
  [MONTHLY]: "Mensual",
  [YEARLY]: "Anual",
  [CUSTOM]: "Personalizado",
  [SEG_SEX]: "LUN á VIE",
  [SEG_SAB]: "LUN á SAB",

  DOM: `DOM`,
  SEG: `LUN`,
  TER: `MAR`,
  QUA: `MIÉ`,
  QUI: `JUE`,
  SEX: `VIE`,
  SAB: `SAB`,

  to: "Hasta",
  or: "O",
  change: "Cambio",
  clean: "Limpio",
  never: "Nunca",

  decrescent: "Descendente",
  crescent: "Creciente",
  filter: "Filtro",
  sort: "Ordenar",

  update: "Actualizar",
  updateApp: "¡Actualiza la aplicación!",
  updateAppStore: "Actualiza la aplicación desde la tienda",
  updateAppDesc:
    "¡Esta versión ha sido descontinuada, para continuar usando Flynow, actualice la aplicación a la última versión!",

  tip: "SUGERENCIA:",
  next: "Siguiente",
  previous: "Vuelve",
  back: "Vuelve",
  onlyThis: "Solo esta ocurrencia",
  thisAndNext: "Este y los siguientes",
  allOcorrences: 'Todas ocurrencias',
  days: "dias",
  daily: 'Dia',
  days3: '3 Dias',
  week: "Semana",
  weekly: 'Semanal',
  monthly: 'Mensal',

  select: "Seleccione",
  save: "Guardar",
  yes: "SÍ",
  no: "NO",
  ok: "OK",
  share: "Cuota",
  add: "Agregar",
  confirm: "Confirmar",
  cancel: "Cancelar",
  addNote: "Añadir la nota",
  edit: "Editar",
  delete: "Eliminar",
  duplicate: 'Duplicar',
  thisYear: 'Este año',
  past: 'Pasado',
  future: 'Futuro',
  searchByTitle: 'Buscar por título...',
  searchActivity: 'Actividad de búsqueda',
  tools: 'Herramientas',

  today: 'Hoy',
  yesterday: 'Ayer',
  tomorrow: 'Mañana',
  activities: 'Actividades',


  selectAction: "Elige una acción",
  addNew: "Agregar nuevo:",
  maskAs: "Marcar como {{status}}",
  detailsDay: "Detalles del día",
  details: "Detalles",
  date: "Fecha",
  status: "Estado",
  plan: "Plan",
  points: "Puntos",
  note: "Anotación",
  notes: "Notas",
  listVoid: "¡Ningún elemento en el período!",
  placeholderNote: "Ingrese una nota para este día en particular",

  descPremiumDeleteActivities:
    "Con la versión premium tienes actividades ilimitadas y muchas otras funciones",
  deleteActivity: "Eliminar {{actividad}}",
  descDelete:
    "El límite para la {{activity}} futura es {{limit}}. Pero ya tienes {{total}}. \nDelete {{deleteMin}} {{activity}} para agregar otra. ",

  statisticHabit: "Estadísticas de hábitos",
  statisticTask: "Estadísticas de tareas",
  history: "Historia",
  performance: "Rendimiento",
  totalDaysDone: "Total de días terminados",
  totalDaysNotDone: "Total de días no hechos",
  currentSequence: "Secuencia actual",
  biggerSequence: "Secuencia más grande",
  biggestSequences: 'Mejores Secuencias',
  lastSequences: 'Últimas Secuencias',
  detailsHabit: "Detalles de hábitos",
  detailsTask: "Detalles de la tarea",
  noActivitiesAdded: "¡No se han agregado actividades!",

  title: "Título",
  label: "Tipo",
  categories: "Categorías",
  description: "Descripción",
  startDate: "Día",
  endDate: "Fin",
  hour: "Hora",
  start: "Inicio",
  end: "Fim",
  startHour: "Hora de inicio",
  endHour: "Hora de fim",
  achievable: "Check List",
  checkList: "Check-list",
  checkListDaily: 'Check-list diario',
  progress: "Progreso",
  notification: "Notificación",
  notifications: "Notificaciones",
  active: "Habilitado",
  inactive: "Deshabilitado",
  period: "Frecuencia",
  sound: "Sonido",
  soundNotification: "Sonido de Notificación",
  soundDefault: "Sonido del sistema predeterminado",
  selectNotification: "Seleccionar una notificación",

  specific: "Objetivo",
  measurable: "Cantidad",
  relevant: "Importancia",

  Profissional: "Profesional",
  Intelectual: "Intelectual",
  Finanças: "Finanzas",
  Social: "Social",
  Relacionamento: "Relación",
  Família: "Familia",
  Lazer: "Ocio",
  Saúde: "Salud",
  Espiritual: "Espiritual",
  Emocional: "Emocional",

  "sound.default": "Predeterminada",
  "sound.alarm": "Alarma - Clock",
  "sound.morningclockalarm": "Alarma - Morning Clock",
  "sound.notification": "Notificación - Soft",
  "sound.positivenotification": "Notificación - Positive",
  "sound.bellnotification": "Notificación - Bell",
  none: 'Ninguno',
  backgroundNoise: 'Sonido de fondo',
  
  discardChanges: "¿Descartar cambios?",
  discardChangesDesc:
    "Tiene cambios sin guardar, ¿está seguro de que desea salir?",
  done: "Concluir",
  notDone: "No concluir",
  message: "Mensaje",
  actions: "Acciones",
  selectDate: "Seleccionar fecha",
  agenda: "Agenda",
  pending: "Pendencias",
  list: "Lista",
  lists: "Listas",
  modeView: "visualización",

  import: "Importar",
  importCalendar: "Importar calendario",
  eventsFromCalendar: "Eventos del calendario",
  askImportCalendar: "¿Desea importar las actividades de su calendario?",
  selectCalendars: "Seleccione el (los) calendario (s):",
  selectEvents: "Seleccione el (los) calendario (s):",
  emptyListEvents: "No hay eventos disponibles.",

  color: "Color",
  selectionColor: "Seleccionar color",
  addCategory: "Nueva categoría",
  custom: "Personalizado",

  all: "Todo el Período",
  "7days": "7 Días",
  "30days": "30 Días",
  thisMonth: "Este Meses",
  "12month": "12 Meses",

  geral: "General",
  others: "Otros",
  fullDay: "Día Completo",
  restoreNotifications: "Restaurar notificaciones",
  restoreNotificationsMsg:
    "Todas las notificaciones se programarán en los próximos segundos ...",
  testNotification: "Notificación de prueba",

  detailsGoal: "Detalhes da meta",
  statisticGoal: "Estatísticas de metas",

  timer: "Temporizador",
  stopwatch: "Cronómetro",
  reset: "Restablecer",
  pause: "Pausa",
  resume: "Reanudar",
  toStart: "Inicio",
  time: "Tiempo",
  finishAndSave: "Finalizar y guardar",
  placeholderTimerNotification: "Sonido de notificación de tiempo de espera.",
  focusTimerFinished: "¡Temporizador de enfoque terminado! 🚀🎉",
  submit: "Enviar",

  listTasksEmpty:
    'La gestión de tareas te hace más productivo y te acerca a tus objetivos, ¡crea nuevas tareas!',
  shareThisReward: '¡Comparte este logro con tus amigos!',
  removeFiltersToSee:
    '¡Para ver la actividad de esta fecha, elimine los filtros!',
  createTask: 'Crear tarea',
  createHabit: 'Crear nuevo hábito',
  createGoal: 'Crear Meta',
  listEmptyRewards: 'Aún no hay logros :(',
  listEmptyRewardsText:
    '¡Crea metas, tareas y hábitos para lograr tu primer logro!',
  listEmptyHabitsText:
    'Los hábitos te ayudan a mantener la disciplina en la búsqueda de tus objetivos, ¡crea un nuevo hábito!',
  listEmptyGoalsText:
    'Los objetivos te dirigen en la búsqueda de tus objetivos, ¡crea tu primer objetivo!',
  onlyPremiumText:
    '¡Conviértase en premium para tener acceso a esta y muchas otras características que mejorarán su productividad!',

  listEmptyNotifications: 'No hay notificaciones programadas',
  listEmptyNotificationsText:
    'Si tiene tareas o hábitos con notificaciones habilitadas, haga clic en Restablecer notificaciones para reprogramar sus notificaciones.',
  listEmptyReportText:
    'Todavía no ha completado un período completo de uso de la aplicación, para la creación del informe.',


    
  addTimeManual: 'Agregar tiempo manualmente',
  goToday: 'Ir a la fecha de hoy',
  extraTime: 'Tiempo Extra',
  doWantSaveTime: '¿Ahorrar tiempo extra?',
  newList: 'Nova Lista',
  columns: 'Colunas',

} as any;

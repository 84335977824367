export const EVENTS = {
  // notification - limit 10
  ID: 'id',
  EMAIL: 'email',
  PREMIUM: 'premium',
  TEST: 'test',
  GET_REWARDS: 'GET_REWARDS',
  SELECT_DATE_ACTIVITIES: 'SELECT_DATE_ACTIVITIES',
  SELECT_MODE_VIEW_: 'SELECT_MODE_VIEW_',
  FILTER_ACTIVITIES: 'FILTER_ACTIVITIES',
  SELECT_ACTION_SWIPE_UPDATE: 'SELECT_ACTION_SWIPE_UPDATE',
  SELECT_ACTION_SWIPE_DELETE: 'SELECT_ACTION_SWIPE_DELETE',
  SELECT_ACTION_SWIPE_NOTE: 'SELECT_ACTION_SWIPE_NOTE',
  SORT_ACTIVITIES: 'SORT_ACTIVITIES',
  SWIPE_DONE_ACTIVITY: 'SWIPE_DONE_ACTIVITY',
  GET_HABITS: 'GET_HABITS',
  GET_TASKS: 'GET_TASKS',
  GET_GOALS: 'GET_GOALS',
  GET_CATEGORIES: 'GET_CATEGORIES',
  CLICK_VIEW_TUTORIAL: 'CLICK_VIEW_TUTORIAL',
  HANDLE_SCHEDULE_NOTIFICATION: 'HANDLE_SCHEDULE_NOTIFICATION',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  NOTIFICATION_RESUME_DAY: 'NOTIFICATION_RESUME_DAY',
  QUEUE_SYNC: 'QUEUE_SYNC',
  SYNC_REWARD: 'SYNC_REWARD',
  SWIPE_NEXT_DAY: 'SWIPE_NEXT_DAY',
  SWIPE_PREV_DAY: 'SWIPE_PREV_DAY',
  OPEN_OPTIONS_SCREEN_ACTIVITIES: 'OPEN_OPTIONS_SCREEN_ACTIVITIES',
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  SYNC_USER: 'SYNC_USER',
  CLICK_IMPORT_CALENDAR: 'CLICK_IMPORT_CALENDAR',
  SHOW_TUTORIAL_APP: 'SHOW_TUTORIAL_APP',
  SHOW_IMPORT_CALENDAR: 'SHOW_IMPORT_CALENDAR',
  CHECK_PREMIUM: 'CHECK_PREMIUM',
  CLICK_SELECT_THEME: 'CLICK_SELECT_THEME',
  SHOW_PREMIUM_VERSION: 'ShowPremiumVersion',
  CLICK_GET_PREMIUM: 'Click_Get_Premium',
  ERROR_GET_PREMIUM: 'Error_Get_Premium',
  ADD_TASK: 'Add_Task',
  ADD_HABIT: 'Add_Habit',
  ADD_CATEGORY: 'Add_Category',
  UPDATE_CATEGORY: 'Update_Category',
  DELETE_CATEGORY: 'Delete_Category',
  ADD_GOAL: 'Add_Goal',

  // firebase
  CLICK_BLOG: 'Click_Blog',
  CLICK_EBOOK_COMBO: 'Click_Ebook_Combo',
  CLICK_TERMS_PRIVACY: 'Click_Terms_PrivacyPolicy',
  CLICK_TERMS_USE: 'Click_Terms_TermsOfUse',
  CLICK_CONTACT_INSTA: 'Click_Contact_Instagram',
  CLICK_CONTACT_MAIL: 'Click_Contact_Mail',

  ERR_GOOGLE_SIGNIN: 'Err_GoogleSignin',
  QNT_DAYS: 'qntDays',
  LAST_DATE: 'lastDate',
  CLICK_SELECT_SOUND: 'Click_SelectSound',
  NO_PERMISSION_SELECT_SOUND: 'NoPermission_Select_Sound',
  RATE_APP: 'Rate_App',
  SHARE_APP: 'Share_App',
  FOLLOW_INSTA: 'Follow_Insta',
  CLICK_NAVIGATE_TO_PREMIUM: 'Click_Navigate_To_Premium',
  NAVIGATE_TO_PREMIUM_FROM_AGENDA: 'NAVIGATE_TO_PREMIUM_FROM_AGENDA',
  NAVIGATE_TO_PREMIUM_FROM_NOTIFICATION:
    'NAVIGATE_TO_PREMIUM_FROM_NOTIFICATION',
  NAVIGATE_TO_PREMIUM_FROM_LIST_PENDING:
    'NAVIGATE_TO_PREMIUM_FROM_LIST_PENDING',

  SCORE: `score`,
  LEVEL: 'level',
  THEME: 'theme',
  LANGUAGE: 'language',

  DELETE_USER: `Delete_User`,
  LOGOUT: `Logout`,
  REGISTER_EMAIL: `Register_Email`,
  REGISTER_GOOGLE: `Register_Google`,

  CHECK_TASK: `Check_Task`,
  CHECK_HABIT: `Check_Habit`,
  ADD_NOTE: `Add_Note`,
  CHECK_GOAL: `Check_Goal`,
  DELETE_TASK: `Delete_Task`,
  DELETE_HABIT: `Delete_Habit`,
  DELETE_TASK_DATE: `Delete_Task_Date`,
  DELETE_HABIT_DATE: `Delete_Habit_Date`,
  DELETE_GOAL: `Delete_Goal`,
  UPDATE_TASK: `Update_Task`,
  UPDATE_HABIT: `Update_Habit`,
  UPDATE_GOAL: `Update_Goal`,

  DONE_ALL_ACTIVITIES: `Done_All_Activities`,

  NO_PERMISSION_VIEW_REPORT: 'NoPermission_View_Report',
  REACHED_LIMIT_GOAL: 'Reached_Limit_Goal',
  REACHED_LIMIT_HABIT: 'Reached_Limit_Habit',
  REACHED_LIMIT_TASK: 'Reached_Limit_Task',

  SHARE_STATISTICS_GERAL: 'Share_Statistics_Geral',
  SHARE_REPORT: 'Share_Report',
  SHARE: `Share_`,

  CLICK_REPORT: 'Click_Report_',
  VIEW_REPORT: 'View_Report_',

  SHOW_RATE_APP: `ShowRateApp`,
  ERROR_SHOW_RATE_APP: `ErrorShowRateApp`,

  IMPORT_CALENDAR_ACCEPT: `IMPORT_CALENDAR_ACCEPT`,
  IMPORT_CALENDAR_REJECT: `IMPORT_CALENDAR_REJECT`,

  ERR: `Err`,

  GET_PREMIUM: `Get_Premium`,
  CANCEL_PREMIUM: `Cancel_Premium`,

  VIEW_AD: `View_Ad_`,
  CLICK_AD: `Click_Ad_`,

  ADD_REWARD: `ADD_REWARD`,
  UPDATE_REWARD: `UPDATE_REWARD`,
  DELETE_REWARD: `DELETE_REWARD`,

  donation_confirmed: 'donation_confirmed',
  donation_click: 'donation_click',
  donation_click_after_: 'donation_click_after_',

  finish_stopwatch: 'finish_stopwatch',
  resume_stopwatch: 'resume_stopwatch',
  stop_stopwatch: 'stop_stopwatch',
  start_stopwatch: 'start_stopwatch',
  pause_stopwatch: 'pause_stopwatch',

  finish_timer: 'finish_timer',
  resume_timer: 'resume_timer',
  cancel_timer: 'cancel_timer',
  start_timer: 'start_timer',
  pause_timer: 'pause_timer',
  select_time_timer: 'select_time_timer',
};

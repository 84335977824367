import React, {useEffect, useState} from 'react';
import {addMinutes} from 'date-fns';
import {
  addDays,
  formatDate,
  formatDateToServer,
  getDateToday,
} from '@/utils/date';
import {useDispatch} from 'react-redux';
import {DrawerActions} from '@/store/modules';
import {PERIOD} from '@/constants';
import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import {
  BlockTimeAgenda,
  ContainerCard,
  ContainerDateAgenda,
  MarkerTime,
} from './styles';
import {
  DEFAULT_SIZE_CARD,
  SIZE_BLOCK,
  SIZE_HOUR_BLOCK,
  blocks,
  enableResize,
  filterTaskWithoutHours,
  findBestTimeIntervalBetweenPeriod,
  getDate,
  getHeight,
  getNormalDuration,
  getTop,
} from './utils';
import {ViewBase} from '@/components/atoms';

import CardAgenda from './CardAgenda';

type AgendaProps = {
  date: string;
  data: any[];
  updateActivity: any;
  offsetTop: number;
  onDragEnd: any;
};

export default function AgendaDay({
  date,
  data,
  updateActivity,
  offsetTop,
  onDragEnd,
}: AgendaProps) {
  const dispatch = useDispatch();
  const [dateNow, setDateNow] = useState(new Date());
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setDateNow(new Date()), 60000);
    return () => clearInterval(interval);
  }, []);

  const withHours = data
    .filter((i: any) => i.startHour && i.endHour && i.endHour > i.startHour)
    .map(i => ({
      ...i,
      top: getTop(i.startHour),
      height: getHeight(i.endHour, i.startHour),
    }));
  const allDaysTasks = data.filter(filterTaskWithoutHours);

  const handleResize = (height: number, item: any) => {
    const duration = Math.max(item.height + height, 10);
    const endHour = formatDate(
      addMinutes(getDate(item.startHour), getNormalDuration(duration)),
      'HH:mm',
    );
    updateActivity(
      {
        ...item,
        endHour,
        top: undefined,
        height: undefined,
      },
      item.period !== PERIOD.JUST_TODAY,
      true,
    );
  };

  function onPressNewActivity(item: string) {
    const [h] = item.split(':');
    const startHour = h + ':00';
    const endHour = formatDate(
      addMinutes(
        getDate(startHour),
        startHour === '23:00' ? SIZE_HOUR_BLOCK - 1 : SIZE_HOUR_BLOCK,
      ),
      'HH:mm',
    );
    const bestHour = findBestTimeIntervalBetweenPeriod(
      startHour,
      endHour,
      data,
    );
    const activity = {
      startHour: bestHour.startHour,
      endHour: bestHour.endHour,
      startDate: date,
    };
    dispatch(
      DrawerActions.setDrawer({action: `ADD_TASK`, payload: {activity}}),
    );
  }

  const offset =
    offsetTop -
    data.filter(filterTaskWithoutHours).length * (DEFAULT_SIZE_CARD + 1);

  const handleDrag = (e: any, data: any) => {
    setTimeout(() => {
      setIsDragging(false);
    }, 100);

    if (
      Math.round(data.x / data.node.offsetWidth) === 0 &&
      Math.abs(data.y) < 10
    ) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const taskId = data.node.getAttribute('data-id');
    const task = withHours.find(t => String(t.id) === taskId);
    if (task) {
      const newTop = getNormalDuration(data.y + task.top);
      const duration = getHeight(task.endHour, task.startHour);
      const startHour = formatDate(
        addMinutes(getDate('00:00'), newTop),
        'HH:mm',
      );
      const endHour = formatDate(
        addMinutes(getDate(startHour), duration),
        'HH:mm',
      );
      const destDate = formatDateToServer(
        addDays(date, Math.round(data.x / data.node.offsetWidth)),
      );
      task.startHour = startHour;
      task.endHour = endHour;
      onDragEnd({
        originDate: task.date,
        destDate,
        task,
      });
    }
  };
  return (
    <ViewBase flex={1}>
      {allDaysTasks.map(item => (
        <CardAgenda
          key={item.id + '#' + date}
          activity={item}
          height={DEFAULT_SIZE_CARD}
          style={{cursor: 'pointer'}}
        />
      ))}
      <ContainerDateAgenda key={date} offset={offset}>
        {blocks.map((item, index) => (
          <BlockTimeAgenda
            index={index}
            onClick={() => onPressNewActivity(item)}
            style={{height: SIZE_BLOCK}}></BlockTimeAgenda>
        ))}
        {withHours.map((item, index) => (
          <ContainerCard
            key={item.id + '#' + date}
            style={{
              height: item.height - 2,
              top: item.top,
              cursor: isDragging ? 'grabbing' : 'pointer',
            }}>
            <Resizable
              size={{height: item.height - 2, width: '100%'}}
              onResizeStop={(e, direction, ref, d) => {
                handleResize(d.height, item);
              }}
              enable={enableResize}>
              <Draggable
                key={item.id}
                axis="both"
                handle=".handle"
                position={{x: 0, y: 0}}
                onDrag={(e, data) =>
                  setIsDragging(
                    Math.round(data.x / data.node.offsetWidth) !== 0 ||
                      Math.abs(data.y) > 10,
                  )
                }
                onStop={handleDrag}>
                <CardAgenda
                  className="handle"
                  data-id={item.id}
                  activity={item}
                  disabled={isDragging}
                  height={item.height - 2}
                  style={{
                    top: item.top,
                  }}
                />
              </Draggable>
              <ViewBase height={1}></ViewBase>
            </Resizable>
          </ContainerCard>
        ))}

        {date === getDateToday() && (
          <MarkerTime
            marginTop={
              dateNow.getHours() * SIZE_HOUR_BLOCK + dateNow.getMinutes()
            }></MarkerTime>
        )}
      </ContainerDateAgenda>
    </ViewBase>
  );
}

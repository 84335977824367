import { Notification } from "../task";

export type ActivityInDate = {
  id?: number;
  date: string;
  done?: boolean | null;
  amount?: number | null;
  deleted?: boolean | null;
  note?: string | null;
  timer?:number | null;
  stopwatch?:number | null;
};

export type HabitModel = {
  id?: number;
  uid?: string;
  user_id: number;
  trigger: string;
  routine: string;
  reward: string;
  goal: {
    amount:number;
    unity:string;
  };
  startDate: string;
  endDate: string;
  endHour: string | null;
  startHour: string | null;
  period: string;
  receiveNotification: boolean;
  soundNotification: string | null;
  notifications: Notification[];
  categories: Array<string> | null;
  createdAt: string;
  updatedAt?: string;
  dones: ActivityInDate[];
  label: string;
};

export type Habit = HabitModel & {
  date: string;
  done: boolean;
  amount: null | number;
};

export const HabitTypes = {
  ADD_HABIT: 'ADD_HABIT',
  ADD_HABIT_REQUEST: 'habit/ADD_HABIT_REQUEST',
  ADD_HABIT_SUCCESS: 'habit/ADD_HABIT_SUCCESS',
  SET_FAILURE: 'habit/SET_FAILURE',

  UPDATE_HABIT: 'UPDATE_HABIT',
  EDIT_HABIT_REQUEST: 'habit/EDIT_HABIT_REQUEST',
  EDIT_HABIT_SUCCESS: 'habit/EDIT_HABIT_SUCCESS',

  GET_HABITS_REQUEST: 'habit/GET_HABITS_REQUEST',
  GET_HABITS_SUCCESS: 'habit/GET_HABITS_SUCCESS',

  GET_HABIT_REQUEST: 'habit/GET_HABIT_REQUEST',
  GET_HABIT_SUCCESS: 'habit/GET_HABIT_SUCCESS',

  CHECK_HABIT: 'CHECK_HABIT',
  CHECK_HABIT_REQUEST: 'habit/CHECK_HABIT_REQUEST',
  CHECK_HABIT_SUCCESS: 'habit/CHECK_HABIT_SUCCESS',

  UPDATE_HABIT_DATE: 'UPDATE_HABIT_DATE',
  UPDATE_HABIT_DATE_REQUEST: 'habit/UPDATE_HABIT_DATE_REQUEST',
  UPDATE_HABIT_DATE_SUCCESS: 'habit/UPDATE_HABIT_DATE_SUCCESS',

  DELETE_HABIT: 'DELETE_HABIT',
  DELETE_HABIT_REQUEST: 'habit/DELETE_HABIT_REQUEST',
  DELETE_HABIT_SUCCESS: 'habit/DELETE_HABIT_SUCCESS',

  SET_INITIAL_STATE: 'habit/SET_INITIAL_STATE',
};

export type AddHabit = {
  uid: string;
  user_id: number;
  trigger: string;
  routine: string;
  reward: string;
  startDate: string;
  endDate: string;
  endHour: string | null;
  startHour: string | null;
  period: string;
  receiveNotification: boolean;
  soundNotification: string | null;
  categories: Array<string> | null;
  createdAt: string;
  updatedAt?: string;
  label: string;
  dones: Array<ActivityInDate>;
};
export type CheckHabit = {
  id: number;
  uid: string;
  done: boolean;
  date: string;
  amount: number;
  label: string;
  receiveNotification: boolean;
};
export interface HabitState {
  habits: Array<HabitModel>;
  loading: boolean;
  lastSync: null | Date;
}

import {call, put} from 'redux-saga/effects';
import {Analytics, api, Gamification} from '@/services';
import {SagaIterator} from '@redux-saga/types';
import {StatisticActions} from './duck';

export function* getStatistics(): SagaIterator<void> {
  try {
    const {data} = yield call(api.get, 'user/statistics');
    yield put(StatisticActions.getStatisticsSuccess(data));
    Analytics.setUserProperty(Analytics.events.SCORE, data.score);
    Analytics.setUserProperty(
      Analytics.events.LEVEL,
      Gamification.getLevelUser(data.score).textIndex,
    );
  } catch (err) {
    yield put(StatisticActions.setFailure());
  }
}

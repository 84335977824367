import React from 'react';
import {Row, TextBase, ViewBase} from '@/components/atoms';
import {useTheme} from 'styled-components';
import {arrangeBigString} from '@/utils/string';
import {useTranslation} from 'react-i18next';
import {Card, Label, CardUserPosition, Position, Username} from './styles';

interface Props {
  name: string;
  level: string;
  score: number;
  premium: boolean;
  position: number;
}
const ListItemPositionRanking = ({
  name,
  level,
  score,
  premium,
  position,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Card premium={premium}>
      <ViewBase>
        <Position>{position}</Position>
        {premium && (
          <TextBase
            size={theme.metrics.font.smaller / 1.7}
            center
            color={theme.colors.white}>
            {t('premium').toUpperCase()}
          </TextBase>
        )}
      </ViewBase>
      <CardUserPosition>
        <Row alignItems="center">
          <Username premium={premium}>
            {arrangeBigString(name, premium ? 15 : 17)}
          </Username>
        </Row>
        <ViewBase alignItems="flex-end">
          <Label premium={premium}>
            {arrangeBigString(level, premium ? 12 : 15)}
          </Label>
          <Label premium={premium}>{score}</Label>
        </ViewBase>
      </CardUserPosition>
    </Card>
  );
};

export default ListItemPositionRanking;

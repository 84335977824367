export const CalendarTypes = {
  GET_ACTIVITIES_REQUEST: 'calendar/GET_ACTIVITIES_REQUEST',
  GET_ACTIVITIES_SUCCESS: 'calendar/GET_ACTIVITIES_SUCCESS',

  SET_CURRENT_DAY: 'calendar/SET_CURRENT_DAY',
  SET_CURRENT_WEEK: 'calendar/SET_CURRENT_WEEK',

  NEXT_DAY: 'calendar/NEXT_DAY',
  PREV_DAY: 'calendar/PREV_DAY',

  NEXT_WEEK: 'calendar/NEXT_WEEK',
  PREV_WEEK: 'calendar/PREV_WEEK',

  NEXT: 'calendar/NEXT',
  PREV: 'calendar/PREV',
  SET_INTERVAL: 'calendar/SET_INTERVAL',

  SET_FAILURE: 'calendar/SET_FAILURE',
  SET_INITIAL_STATE: 'calendar/SET_INITIAL_STATE',
};

export interface CurrentDay {
  dayOfWeek: string;
  date: string;
}

export interface CalendarState {
  currentDay: CurrentDay;
  currentWeek: Array<CurrentDay>;
  loading: boolean;
  lowerDate: string;
  upperDate: string;
}

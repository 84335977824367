export const QueueTypes = {
  ADD: 'queue/ADD',
  REMOVE: 'queue/REMOVE',
  SET_INITIAL_STATE: 'queue/SET_INITIAL_STATE',
  SYNC: 'queue/SYNC',
  ASSIGN: 'queue/ASSIGN',
};

export type Queue = {
  id: string;
  type: string;
  payload: any;
  attempt: number;
  attemptAfter: Date;
};
export type QueueSync = {
  _id: string;
  userId: number;
  action: string;
  payload: any;
};

export interface QueueState {
  data: Array<Queue>;
  sync: Array<QueueSync>;
  lastSync?: Date;
}

const {height} = window.screen
const screenSMALL = 610;
const screenBIG = 770;

export type Metrics = {
  fontSize(v:number):number;
  space: {
    header: number;
    smaller: number;
    small: number;
    medium: number;
    big: number;
    bigger: number;
  };
  font: {
    smaller: number;
    small: number;
    medium: number;
    big: number;
    bigger: number;
  };
  component: {
    input: number;
  };
};
export const fontSize = (v:number)=> v

export default {
  fontSize,
  space: {
    header:
      height > screenSMALL
        ? height > screenBIG
          ? Math.round(height / 5.2)
          : Math.round(height / 4.8)
        : Math.round(height / 4.5),
    smaller: height > screenSMALL ? (height > screenBIG ? 5 : 4) : 3,
    small: height > screenSMALL ? (height > screenBIG ? 10 : 9) : 8,
    medium: height > screenSMALL ? (height > screenBIG ? 15 : 13) : 12,
    big: height > screenSMALL ? (height > screenBIG ? 20 : 18) : 15,
    bigger: height > screenSMALL ? (height > screenBIG ? 25 : 22) : 20,
  },
  font: {
    smaller: height > screenSMALL ? (height > screenBIG ? 12 : 13) : 11,
    small: height > screenSMALL ? (height > screenBIG ? 14 : 14) : 12,
    medium: height > screenSMALL ? (height > screenBIG ? 16 : 16.5) : 15,
    big: height > screenSMALL ? (height > screenBIG ? 20 : 21) : 19,
    bigger: height > screenSMALL ? (height > screenBIG ? 26 : 27) : 24,
  },
  component: {
    input: height > screenSMALL ? (height > screenBIG ? 38 : 32) : 29,
  },
} as Metrics;

import {api} from '@/services';
import { GoalDate } from '@/store/modules';

interface AddGoalApi {
  user_id: number;
  uid: string;
  label: string;
  specific: string;
  measurable: string;
  date: string;
  achievable: any[];
  relevant: string;
  createdAt: string;
  done: boolean;
  habits: any[];
  tasks: any[];
  categories: string[];
}
interface GoalApi extends AddGoalApi {
  id: number;
  updatedAt: string;
}
interface UpdateGoalApi {
  id: number;
  uid: string;
  user_id: number;
  specific?: string;
  measurable?: string;
  date?: string;
  achievable?: any[];
  relevant?: string;
  done?: boolean;
  createdAt?: string;
  categories?: string[];
  updatedAt?: string;
}
interface CheckGoalApi {
  id: number;
  uid: string;
  done: boolean;
}
interface DeleteGoalApi {
  id: number;
  uid: string;
}

export async function updateGoalDate(
  goal: UpdateGoalApi,
  data: GoalDate,
): Promise<GoalApi> {
  const id = goal.id || goal.uid;
  const {data: response} = await api.put(`goals/${id}/date/${data.date}`, data);
  return response;
}

export async function addGoal(goal: AddGoalApi): Promise<GoalApi> {
  const {data} = await api.post('goals', goal);
  return data;
}

export async function listGoals(): Promise<GoalApi> {
  const {data} = await api.get('goals');
  return data.map((i:any)=> ({...i, targetAmount: i.amount, amount:undefined}));
}

export async function updateGoal(goal: UpdateGoalApi): Promise<GoalApi> {
  const {id, uid, ...goalEdited} = goal;
  const {data} = await api.put(`goals/${id || uid}`, goalEdited);
  return data;
}

export async function deleteGoal(goal: DeleteGoalApi): Promise<GoalApi> {
  const id = goal?.id || goal?.uid;
  const {data} = await api.delete(`goals/${id}`);
  return data;
}

export async function checkGoal(goal: CheckGoalApi): Promise<GoalApi> {
  const id = goal.id || goal.uid;
  const {data} = await api.put(`goals/${id}/check`, goal);
  return data;
}

import {uid} from '@/utils/uid';
import {Action} from '../utils';
import {Queue, QueueState, QueueTypes} from './type';

const INITIAL_STATE: QueueState = {
  data: [],
  sync: [],
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): QueueState {
  switch (type) {
    case QueueTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case QueueTypes.ADD:
      return {
        ...state,
        data: [...state.data, payload],
      };

    case QueueTypes.REMOVE:
      return {
        ...state,
        data: state.data.filter(task => task.id !== payload.id),
      };
    case QueueTypes.ASSIGN:
      return {
        ...state,
        ...payload.data,
      };

    default:
      return state;
  }
}

export const QueueActions = {
  remove: (id: string): Action => ({
    type: QueueTypes.REMOVE,
    payload: {id},
  }),
  add: (action: Queue | Action<any>): Action<Queue> => {
    const payload = {
      id: uid(),
      type: action.type,
      payload: action.payload,
      attempt: 'attempt' in action ? action.attempt : 1,
      attemptAfter: 'attemptAfter' in action ? action.attemptAfter : new Date(),
    };
    return {
      type: QueueTypes.ADD,
      payload,
    };
  },
  setInitialState: () => ({
    type: QueueTypes.SET_INITIAL_STATE,
  }),
  syncQueue: () => ({
    type: QueueTypes.SYNC,
  }),
  assign: (data: any): Action => ({
    type: QueueTypes.ASSIGN,
    payload: {data},
  }),
};

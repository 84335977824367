import Header from '../../components/Header';
import {Fixed, Container} from '../../styles/global';
import {useDispatch, useSelector} from 'react-redux';
import CardHabit from '@/components/CardHabit';
import {getDateToday} from '@/utils/date';
import {useEffect} from 'react';
import {
  HabitActions,
  DrawerActions,
  selectorHabitsActive,
  HabitModel,
  selectorHabit,
  selectorCalendar,
} from '@/store/modules';
import {FlatList, ListEmpty} from '@/components/molecules';
import {useTranslation} from 'react-i18next';
import {Loading, ViewBase} from '@/components/atoms';
import {colors} from '@/styles';

const Habits = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const habits = useSelector(selectorHabitsActive);
  const {lowerDate, upperDate} = useSelector(selectorCalendar);
  const {loading, lastSync} = useSelector(selectorHabit);

  useEffect(() => {
    if (
      !lastSync ||
      !(lowerDate <= getDateToday() && upperDate >= getDateToday())
    ) {
      dispatch(HabitActions.getHabitsRequest());
    }
  }, []);

  function onPressHabit(habit: HabitModel) {
    dispatch(
      DrawerActions.setDrawer({
        action: 'DETAILS_HABIT',
        payload: {activity: {...habit, date: getDateToday()}},
      }),
    );
  }

  return (
    <Container>
      <Fixed>
        <Header />
      </Fixed>
      <ViewBase style={{padding: '5% 5%'}}>
        <FlatList
          data={habits}
          ListEmptyComponent={
            loading ? (
              <ViewBase center>
                <Loading size={35} color={colors.primary} />
              </ViewBase>
            ) : (
              <ListEmpty
                title={t(
                  habits.length === 0
                    ? 'habit:noHabits'
                    : 'habit:noHabitsFilter',
                )}
                text={habits.length === 0 ? t('listEmptyHabitsText') : ''}
                titleButton={t('createHabit')}
                onPress={() =>
                  dispatch(
                    DrawerActions.setDrawer({
                      action: 'ADD_HABIT',
                      payload: undefined,
                    }),
                  )
                }
              />
            )
          }
          keyExtractor={item => String(item.id || item.uid)}
          renderItem={({item}) => (
            <CardHabit key={item.id} habit={item} onPressHabit={onPressHabit} />
          )}
        />
      </ViewBase>
    </Container>
  );
};

export default Habits;

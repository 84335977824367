import {Category, State} from '@/store/modules';

export const selectorCategories = (state: State): Category[] =>
  state.category.categories.filter(i=> !i.deletedAt);


export const selectorCategoriesToEdit = (
  state: State,
  categories: string[],
): Category[] =>
  categories
    .map(i => state.category.categories.find(j => j.uid === i))
    .filter(i => i !== undefined) as Category[];

import Gamification, {Level} from '@/services/Gamification';
import {Action} from '../utils';
import {StatisticState, StatisticTypes} from './type';

const INITIAL_STATE: StatisticState = {
  score: 0,
  level: Gamification.getLevelUser(0),
  percent: {
    tasks: {total:0,done:0},
    habits: {total:0,done:0},
    goals: {total:0,done:0},
  },
  dataChart: {
    labels: [],
    datasets: []
  },
  triadTime: {
    circumstantial: 0,
    important: 0,
    urgent: 0,
  },
  weeklyChart: {
    labels: [],
    datasets: [],
  },
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): StatisticState {
  switch (type) {
    case StatisticTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case StatisticTypes.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        ...payload.statistic,
      };

    case StatisticTypes.ADD_SCORE:
      return {
        ...state,
        score: state.score + payload.score,
        dataChart: {
          ...state.dataChart,
          datasets:
            !!state.dataChart.datasets &&
            state.dataChart.datasets.map((item: any, i: number, array: any[]) =>
              i === array.length - 1 ? item + payload.score : item,
            ),
        },
      };

    case StatisticTypes.SET_LEVEL:
      return {...state, level: payload.level};

    default:
      return state;
  }
}

export const StatisticActions = {
  getStatisticsRequest: () => ({
    type: StatisticTypes.GET_STATISTICS_REQUEST,
  }),
  getStatisticsSuccess: (statistic: StatisticState): Action => ({
    type: StatisticTypes.GET_STATISTICS_SUCCESS,
    payload: {statistic},
  }),
  setFailure: () => ({
    type: StatisticTypes.SET_FAILURE,
  }),
  addScore: (score: number): Action => ({
    type: StatisticTypes.ADD_SCORE,
    payload: {score},
  }),
  setLevel: (level: Level): Action => ({
    type: StatisticTypes.SET_LEVEL,
    payload: {level},
  }),
  setInitialState: () => ({
    type: StatisticTypes.SET_INITIAL_STATE,
  }),
};

// import React from 'react';
// import {useTheme} from 'styled-components';

interface Props {
  name: string;
  size?: number;
  color?: string;
  testID?: string;
  onPress?(): void;
  style?: any;
}

// type GetComponent = {
//   Component: typeof Icon | null;
//   key: string | null;
// };
// function getComponent(name: string) {
//   return (
//     {
//       right: {Component: AntDesign, key: 'right'},
//       left: {Component: AntDesign, key: 'left'},
//       plus: {Component: AntDesign, key: 'plus'},
//       play: {Component: AntDesign, key: 'play'},
//       pausecircle: {Component: AntDesign, key: 'pausecircle'},
//       arrowleft: {Component: AntDesign, key: 'arrowleft'},
//       calendar: {Component: AntDesign, key: 'calendar'},
//       instagram: {Component: AntDesign, key: 'instagram'},
//       piechart: {Component: AntDesign, key: 'piechart'},
//       edit: {Component: AntDesign, key: 'edit'},
//       infocirlceo: {Component: AntDesign, key: 'infocirlceo'},
//       delete: {Component: AntDesign, key: 'delete'},

//       list: {Component: Ionicons, key: 'list'},
//       close: {Component: Ionicons, key: 'close'},
//       trophy: {Component: Ionicons, key: 'trophy'},
//       'chevron-down': {Component: Ionicons, key: 'chevron-down'},
//       'settings-sharp': {Component: Ionicons, key: 'settings-sharp'},
//       'rocket-sharp': {Component: Ionicons, key: 'rocket-sharp'},
//       'share-social': {Component: Ionicons, key: 'share-social'},
//       'ellipsis-vertical': {Component: Ionicons, key: 'ellipsis-vertical'},

//       bullseye: {Component: MaterialCommunityIcons, key: 'bullseye'},
//       'share-variant': {
//         Component: MaterialCommunityIcons,
//         key: 'share-variant',
//       },
//       'square-edit-outline': {
//         Component: MaterialCommunityIcons,
//         key: 'square-edit-outline',
//       },
//       'file-edit': {Component: MaterialCommunityIcons, key: 'file-edit'},
//       'filter-variant': {
//         Component: MaterialCommunityIcons,
//         key: 'filter-variant',
//       },
//     }[name] || {Component: null, key: null}
//   );
// }

const Icon: React.FC<Props> = ({
  name,
  size,
  color,
  onPress,
  style,
  testID,
}: Props) => {
  return null
};

Icon.defaultProps = {
  size: 22,
  style: {},
  onPress: undefined,
  color: undefined,
  testID: undefined,
};
export default Icon;

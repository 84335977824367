import { colors } from '@/styles';
import styled from 'styled-components';

interface RowProps {
  width?:number;
}
interface ActiveProps {
  color?:string
  height?:number
  score:string;
}
interface ContainerProps {
  colorSurface?:string
  height?:number
}
interface TextProps {
  marginLeft?:number;
}

export const Container = styled.div<ContainerProps>`
  flex-direction: row;
  display: flex;
  height: ${({height})=> height ||20}px;
  flex: 1;
  background: ${({theme, colorSurface})=> colorSurface || theme.colors.surface};
  border-radius: 10px;
  margin: 0px 5px;
`;
export const Active = styled.div<ActiveProps>`
  display: flex;
  height: ${({height})=> height ||20}px;
  flex: ${({score}) => parseInt(score) / 100};
  background: ${({theme,color})=> color || theme.colors.primary};
  border-radius: 10px;
`;
export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${({width}) => width || 100}%;
  margin-top: 5px;
`;
export const TextScore = styled.span<TextProps>`
  font-size: 10px;
  font-family: 'Poppins-Bold';
  color: ${({color}) => color || colors.primary};
  margin-left: ${({marginLeft}) => marginLeft || 0}px;
`;

export default {
  signin: 'Entrar',
  signinWithGoogle: 'Login com Google',
  signinWithApple: 'Login com Apple',
  register: 'Cadastrar',
  recoverPassword: 'Recuperar Senha',

  name: 'Nome',
  email: 'Email',
  password: 'Senha',
  newPassword: 'Nova Senha',
  confirmPassword: 'Confirmar Senha',
  placeholderName: 'Digite seu nome',
  placeholderEmail: 'Digite seu email',
  placeholderPassword: 'Digite sua senha',
  placeholderConfirmPassword: 'Confirme sua senha',
  placeholderCode: 'Informe o código de verificação',

  forgotPassword: 'Esqueceu sua senha?',
  resetPassword: 'Redefinir Senha',
  registerNow: 'Não tem uma conta? Cadastre-se agora',
  goToLogin: 'Já tem uma conta? Faça o login',

  titleValidateCode: 'Validar Código',
  titleNewPassowrd: 'Nova Senha',

  code: 'Código',
  codeSent: 'Código enviado!',
  sendCode: 'Enviar Código',
  checkCode: 'Validar',
  msgCodeSent: 'Você receberá um código no seu email em até 60 segundos.',
  codeInvalid: 'Código inválido!',
  msgCodeInvalid: 'Verifique seu email e informe o código correto.',

  success: 'Sucesso!',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  changePasswordSuccess: 'Senha alterada com sucesso!',
}as any;

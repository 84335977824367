import {differenceInWeeks, getWeekOfMonth, week} from '@/utils/date';
import {colors} from '@/styles';

export function getLabelsGraph(report: any): any {
  if (report.period === 'weekly') return week;
  return ['SEM 1', `SEM 2`, `SEM 3`, `SEM 4`, `SEM 5`, `SEM 6`].slice(
    0,
    differenceInWeeks(report.upperDate, report.lowerDate),
  );
}
export function getDataChartByWeek(
  lowerDate: string,
  upperDate: string,
  period: string,
  dataChart: any,
): any {
  const numberWeeks = differenceInWeeks(upperDate, lowerDate);
  const weeks = [];
  if (period === 'weekly')
    return [
      {
        data: dataChart,
        color: () =>
          [
            colors.primary,
            colors.green,
            colors.blue,
            colors.yellow,
            colors.red,
          ][0],
        backgroundColor: [
          colors.primary,
          colors.green,
          colors.blue,
          colors.yellow,
          colors.red,
        ][0],
      },
    ];
  for (let i = 0; i <= numberWeeks; i += 1) {
    weeks.push({
      data: dataChart.filter(
        (item: any) => getWeekOfMonth(item.date) - 1 === i,
      ),
      color: () =>
        [colors.primary, colors.green, colors.blue, colors.yellow, colors.red][
          i
        ],
      fillShadowGradient: [
        colors.primary,
        colors.green,
        colors.blue,
        colors.yellow,
        colors.red,
      ][0],
    });
    if (weeks[i].data.length !== 7) {
      const added = 7 - weeks[i].data.length;
      const obj = weeks[i].data[0];
      const arr = [];
      for (let j = 0; j < added; j += 1) {
        arr.push(obj);
      }
      weeks[i].data = arr.concat(weeks[i].data);
    }
  }
  return weeks;
}

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Content, TextBase, TextLabel, ViewBase} from '@/components/atoms';
import {
  ModalActions,
  selectorRewards,
} from '@/store/modules';
import {useTranslation} from 'react-i18next';
import Gamification from '@/services/Gamification';
import { Header } from '@/components/molecules';
import { useHistory } from 'react-router';

const Gallery: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory()
  const dispatch = useDispatch();
  const rewards = useSelector(selectorRewards);

  function showDetailsReward(reward: {key: string; image: string}) {
    dispatch(
      ModalActions.setModalContent({
        height: 550,
        title: t(`statistic:${reward.key}`),
        children: (
          <ViewBase center>
            <img
              src={reward.image}
              style={{
                height: 70,
                alignSelf: 'center',
                marginBottom: 10,
              }}
              alt="reward"
            />
            <TextLabel>{t('statistic:howGet')}:</TextLabel>
            <TextBase marginBottom={20}>
              {t(`statistic:desc_${reward.key}`)}
            </TextBase>
          </ViewBase>
        ),
      }),
    );
  }
  const onPressButtonLeft = () => {
    history.back()
  };

  return (
    <Container>
      <Header
        title={t('statistic:gallery')}
        onPressButtonLeft={onPressButtonLeft}
      />
      <Content>
        <ViewBase
          flexDirection="row"
          wrap
          flex={1}
          justifyContent="space-between">
          {Gamification.rewards.map(item => (
            <ViewBase style={{width: '18%',alignItems:'center', cursor:'pointer'}} padding={3} onClick={() => showDetailsReward(item)}>
              <img
                src={item.image}
                style={{
                  height:60,
                  opacity: rewards.some(i => i.key === item.key) ? 1 : 0.2,
                }}
                alt="reward"
                />
              <TextBase size={12} padding={5} center>
                {t(`statistic:${item.key}`)}
              </TextBase>
            </ViewBase>
          ))}
        </ViewBase>
      </Content>
    </Container>
  );
};
export default Gallery;

import colors from '../colors';

const theme = {
  ...colors,
  // containers
  background: '#111',
  onBackground: '#eee',
  surface: '#222',
  onSurface: '#bbb',
  surfaceLight: '#333',
  regular: '#666',
  // texts
  placeholder: '#555',
  textSection: colors.white,
  textLabel: '#ddd',
  text: '#ccc',
  list: '#151515',
  // tabBar
  activeTabBar: colors.white,
  inactiveTabBar: colors.light,
  // shadow
  shadow: '#050505',
};
export default theme;

export default {
  title: 'Estatísticas',
  viewGamitification: 'Entenda a gamificação',
  score: 'Pontuação',
  totalScorePeriod: 'Total de pontos no período',
  gamification: 'Gamificação',
  level: 'Nível',
  levels: 'Níveis',
  general: 'Geral',
  rewards: 'Recompensas',
  noRewards: 'Você ainda não obteve nenhuma conquista :(',
  rankings: 'Rankings',
  rankingPremium: 'Ranking Premium 👑',
  rankingGlobal: 'Ranking Global',
  reports: 'Relatórios',
  viewReportsWeekly: 'Visualizar Relatórios Semanais',
  viewReportsMonthly: 'Visualizar Relatórios Mensais',
  reportWeekly: 'Relatório Semanal',
  reportMonthly: 'Relatório Mensal',
  reportsWeekly: 'Relatórios Semanais',
  reportsMonthly: 'Relatórios Mensais',
  noReports: 'Nenhum relatório disponível ainda.',
  performed: 'Realizado',
  notPerformed: 'Não Realizado',
  weeklyGrowth: 'Evolução Semanal',
  growth: 'Evolução',
  rateDay: 'Média diária',
  triadTime: 'Tríade do Tempo',
  wheelOfLife: 'Roda da Vida',
  historicMonthly: 'Histórico Mensal',
  historicWeekly: 'Histórico Semanal',
  consistency: 'Consistência',

  addScore: 'Você ganhou {{score}} pontos!',

  onlyPremium: 'Somente Premium',
  top: 'TOP',
  yourPosition: 'SUA POSIÇÃO',
  seeDetails: 'Ver detalhes',
  noScore: 'Você ainda não possui pontuação',
  period: 'Período',
  noHabitsPeriod: 'Você não possui nenhum hábito ativo durante o período.',
  noTasksPeriod: 'Você não possui nenhuma tarefa durante o período.',
  noGoalsPeriod: 'Você não possui nenhuma meta para esse período.',
  noWeekYet: 'Você ainda não completou nenhuma semana de uso do aplicativo.',
  achievements: 'Realizações',

  tasksDone: '✅  Tarefas realizadas',
  habitsDone: '🚀  Hábitos realizados',
  goalsDone: '🎯  Metas cumpridas',
  congratulations: 'Parabéns',
  doneAllActivities: 'Você realizou todas as atividades do dia!',
  descGetPremium: `Você ganhou um DIAMANTE por se tornar PREMIUM 👑 !\n\nTodas as funções premium já foram liberadas para você!🚀 `,
  getMedal: 'Você ganhou uma medalha por atingir sua meta!',
  nextLevelDesc: `Você avançou de nível!🚀  ${'\n'}Seu nível agora é {{currentLevel}}`,
  currentScore: 'Pontuação atual',
  prevLevel: 'Nível anterior',
  nextLevel: 'Próximo nível',
  currentLevel: 'Nível atual',

  TRÓFEU: 'TRÓFEU',
  'MEDALHA DE PRATA': 'MEDALHA DE PRATA',
  'MEDALHA DE OURO': 'MEDALHA DE OURO',
  MEDALHA: 'MEDALHA',
  DIAMANTE: 'DIAMANTE',
  CONQUISTA: 'CONQUISTA',

  gamificationScore: [
    {
      label: 'Adicionar tarefa não periódica:',
      value: '2 pts',
    },
    {
      label: 'Adicionar tarefa periódica:',
      value: '10 pts',
    },
    {
      label: 'Adicionar meta:',
      value: '10 pts',
    },
    {
      label: 'Adicionar hábito:',
      value: '10 pts',
    },
    {
      label: 'Concluir tarefa IMPORTANTE:',
      value: '20 pts',
    },
    {
      label: 'Concluir tarefa URGENTE:',
      value: '15 pts',
    },
    {
      label: 'Concluir tarefa CIRCUNSTANCIAL:',
      value: '10 pts',
    },
    {
      label: 'Concluir hábito:',
      value: '20 pts',
    },
  ],
  gamificationRewards: [
    {
      label: 'Concluir uma meta:',
      value: '1 medalha',
    },
    {
      label: 'Líder do ranking mensal:',
      value: '1 troféu',
    },
    {
      label: '2º lugar no ranking mensal:',
      value: '1 medalha de prata',
    },
    {
      label: '3º lugar no ranking mensal:',
      value: '1 medalha de bronze',
    },
    {
      label: 'Líder do ranking semanal:',
      value: '1 medalha',
    },
  ],

  Procrastinador: 'Procrastinador',
  Iniciante: 'Iniciante',
  Esforçado: 'Esforçado',
  Disciplinado: 'Disciplinado',
  Realizador: 'Realizador',
  Incrível: 'Incrível',
  Magnifico: 'Magnifico',
  Genial: 'Genial',
  Fenomenal: 'Fenomenal',
  Invencível: 'Invencível',

  add_first_goal: 'Adicionar 1° Meta',
  add_first_habit: 'Adicionar 1° Hábito',
  sequence_habit_3: 'Sequência de 3 dias',
  sequence_habit_7: 'Sequência de 7 dias',
  sequence_habit_30: 'Sequência de 30 dias',
  done_activity_30: 'Realizar atividade 30 dias',
  done_activity_100: 'Realizar atividade 100 dias',
  done_activity_500: 'Realizar atividade 500 dias',
  sequence_habit_100: 'Sequência de 100 dias',
  done_goal: 'Atingir Meta',
  get_premium: 'Tornar Premium',
  level_2: 'Nível 2',
  level_3: 'Nível 3',
  level_4: 'Nível 4',
  level_5: 'Nível 5',
  level_6: 'Nível 6',
  '3_ranking_weekly': '3° Lugar - Ranking Semanal',
  '2_ranking_weekly': '2° Lugar - Ranking Semanal',
  '1_ranking_weekly': '1° Lugar - Ranking Semanal',
  '3_ranking_monthly': '3° Lugar - Ranking Mensal',
  '2_ranking_monthly': '2° Lugar - Ranking Mensal',
  '1_ranking_monthly': '1° Lugar - Ranking Mensal',
  '3_ranking_yearly': '3° Lugar - Ranking Anual',
  '2_ranking_yearly': '2° Lugar - Ranking Anual',
  '1_ranking_yearly': '1° Lugar - Ranking Anual',
  myRewards: 'Minhas Conquistas',
  gallery: 'Galeria',
  howGet: 'Como obter',
  desc_add_first_goal: 'Adicione sua 1° Meta',
  desc_add_first_habit: 'Adicione seu 1° Hábito',
  desc_sequence_habit_3: 'Realize um hábito 3 dias consecutivos',
  desc_sequence_habit_7: 'Realize um hábito 7 dias consecutivos',
  desc_sequence_habit_30: 'Realize um hábito 30 dias consecutivos',
  desc_sequence_habit_100: 'Realize um hábito 100 dias consecutivos',
  desc_done_goal: 'Conclua uma Meta',
  desc_get_premium: 'Torne-se Premium',
  desc_level_2: 'Avançe para o Nível 2',
  desc_level_3: 'Avançe para o Nível 3',
  desc_level_4: 'Avançe para o Nível 4',
  desc_level_5: 'Avançe para o Nível 5',
  desc_level_6: 'Avançe para o Nível 6',
  desc_3_ranking_weekly: 'Conquiste o 3° Lugar do Ranking Semanal',
  desc_2_ranking_weekly: 'Conquiste o 2° Lugar do Ranking Semanal',
  desc_1_ranking_weekly: 'Conquiste o 1° Lugar do Ranking Semanal',
  desc_3_ranking_monthly: 'Conquiste o 3° Lugar do Ranking Mensal',
  desc_2_ranking_monthly: 'Conquiste o 2° Lugar do Ranking Mensal',
  desc_1_ranking_monthly: 'Conquiste o 1° Lugar do Ranking Mensal',
  desc_3_ranking_yearly: 'Conquiste o 3° Lugar do Ranking Anual',
  desc_2_ranking_yearly: 'Conquiste o 2° Lugar do Ranking Anual',
  desc_1_ranking_yearly: 'Conquiste o 1° Lugar do Ranking Anual',
  desc_done_activity_30: 'Realize uma tarefa ou hábito 30 vezes',
  desc_done_activity_100: 'Realize uma tarefa ou hábito 100 vezes',
  desc_done_activity_500: 'Realize uma tarefa ou hábito 500 vezes',

  syncRewardsRequest: 'Sincronizando suas Recompensas...',
  syncRewardsSuccess: 'Sincronização realizada com successo!',
  syncRewardsFailure:
    'A sincronização não foi concluída, tente novamente mais tarde!',

  gotReward: 'Parabéns, nova conquista!',
  reason: 'Motivo: ',
  reportDailyTitle: 'Resumo do dia! 📊 🚀',
  reportDailyMessage:
    'Total de atividades: {{totalActivities}}\nConcluídas: {{totalDone}}\nPorcentagem: {{ratio}}%',
}as any;

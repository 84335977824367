export const ReportTypes = {
  GET_REPORTS_REQUEST: 'report/GET_REPORTS_REQUEST',
  GET_REPORTS_SUCCESS: 'report/GET_REPORTS_SUCCESS',

  CREATE_REPORT_REQUEST: 'report/CREATE_REPORT_REQUEST',
  CREATE_REPORT_SUCCESS: 'report/CREATE_REPORT_SUCCESS',

  SET_INITIAL_STATE: 'report/SET_INITIAL_STATE',
};

export type Report = {
  statistics: any;
  lowerDate: string;
  upperDate: string;
  period: string;
  text: string;
};
export interface ReportState {
  reports: Array<Report>;
  loading: boolean;
}

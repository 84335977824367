import {
  addDays,
  getDayOfWeek,
  getCurrentWeek,
  getDateToday,
  formatDateToServer,
  subDays,
  getIntervalDate,
} from '@/utils/date';
import {Action} from '../utils';
import {CalendarState, CalendarTypes, CurrentDay} from './type';
import { MODE_INTERVAL, MODE_VIEW } from '@/constants';

const INITIAL_STATE: CalendarState = {
  currentDay: {
    dayOfWeek: getDayOfWeek(new Date()),
    date: getDateToday(),
  },
  currentWeek: getCurrentWeek(getDateToday()),
  loading: false,
  lowerDate: getDateToday(),
  upperDate: getDateToday(),
};

const getDaysByModeInterval = (modeInterval: string) =>
  ({
    [MODE_INTERVAL.daily]: 1,
    [MODE_INTERVAL.days3]: 2,
    [MODE_INTERVAL.weekly]: 7,
    [MODE_INTERVAL.monthly]: 25,
  }[modeInterval] || 1);

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): CalendarState {
  switch (type) {
    case CalendarTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;
    case CalendarTypes.GET_ACTIVITIES_REQUEST:
      return {...state, loading: true};
    case CalendarTypes.GET_ACTIVITIES_SUCCESS:
      return {...state, loading: false};
    case CalendarTypes.SET_FAILURE:
      return {...state, loading: false};

    case CalendarTypes.SET_CURRENT_DAY:
      return {...state, currentDay: payload.currentDay};
      
    case CalendarTypes.SET_INTERVAL:
      return {...state, lowerDate: payload.lowerDate, upperDate: payload.upperDate};

    case CalendarTypes.SET_CURRENT_WEEK:
      return {...state, currentWeek: payload.currentWeek};
    case CalendarTypes.NEXT_DAY:
      return {
        ...state,
        currentDay: {
          date: formatDateToServer(addDays(state.currentDay.date, 1)),
          dayOfWeek: getDayOfWeek(addDays(state.currentDay.date, 1)),
        },
        currentWeek: getCurrentWeek(
          formatDateToServer(addDays(state.currentDay.date, 1)),
        ),
      };
    case CalendarTypes.PREV_DAY:
      return {
        ...state,
        currentDay: {
          date: formatDateToServer(addDays(state.currentDay.date, -1)),
          dayOfWeek: getDayOfWeek(addDays(state.currentDay.date, -1)),
        },
        currentWeek: getCurrentWeek(
          formatDateToServer(addDays(state.currentDay.date, -1)),
        ),
      };

      case CalendarTypes.PREV_WEEK:
      const prevWeek = getCurrentWeek(formatDateToServer(subDays(state.currentWeek[0].date,1)));
      return {...state, currentDay: prevWeek[0], currentWeek:prevWeek};

    case CalendarTypes.NEXT_WEEK:
      const nextWeek = getCurrentWeek(formatDateToServer(addDays(state.currentWeek[6].date,1)));
      return {...state, currentDay: nextWeek[0], currentWeek:nextWeek};


      case CalendarTypes.NEXT:
        const qntDaysN = getDaysByModeInterval(payload.interval);
        return {
          ...state,
          ...getIntervalDate(
            MODE_VIEW.list,
            payload.interval,
            formatDateToServer(addDays(state.upperDate, qntDaysN)),
            payload.weekStartsOn,
          ),
        };
      case CalendarTypes.PREV:
        const qntDays = getDaysByModeInterval(payload.interval);
        return {
          ...state,
          ...getIntervalDate(
            MODE_VIEW.list,
            payload.interval,
            formatDateToServer(subDays(state.lowerDate, qntDays)),
            payload.weekStartsOn,
          ),
        };

    default:
      return state;
  }
}

export const CalendarActions = {
  getActivitiesRequest: (
    lowerDate?: string,
    upperDate?: string,
  ): Action<{lowerDate?: string; upperDate?: string}> => ({
    type: CalendarTypes.GET_ACTIVITIES_REQUEST,
    payload: {lowerDate, upperDate},
  }),
  getActivitiesSuccess: () => ({
    type: CalendarTypes.GET_ACTIVITIES_SUCCESS,
  }),
  setCurrentDay: (currentDay: CurrentDay): Action => ({
    type: CalendarTypes.SET_CURRENT_DAY,
    payload: {currentDay},
  }),
  setInterval: (lowerDate:string, upperDate:string): Action => ({
    type: CalendarTypes.SET_INTERVAL,
    payload: {upperDate, lowerDate},
  }),
  setCurrentWeek: (currentWeek: CurrentDay[]): Action => ({
    type: CalendarTypes.SET_CURRENT_WEEK,
    payload: {currentWeek},
  }),
  nextDay: () => ({
    type: CalendarTypes.NEXT_DAY,
  }),
  prevDay: () => ({
    type: CalendarTypes.PREV_DAY,
  }),
  setFailure: () => ({
    type: CalendarTypes.SET_FAILURE,
  }),
  nextWeek: () => ({
    type: CalendarTypes.NEXT_WEEK,
  }),
  prevWeek: () => ({
    type: CalendarTypes.PREV_WEEK,
  }),
  next: (interval: string, weekStartsOn: number) => ({
    type: CalendarTypes.NEXT,
    payload: {interval, weekStartsOn},
  }),
  prev: (interval: string, weekStartsOn: number) => ({
    type: CalendarTypes.PREV,
    payload: {interval, weekStartsOn},
  }),
  setInitialState: () => ({
    type: CalendarTypes.SET_INITIAL_STATE,
  }),
};

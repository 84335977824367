import {call, put, select} from 'redux-saga/effects';
import {Analytics, Gamification, Sentry, Toast} from '@/services';
import {getDateToday} from '@/utils/date';
import {getStatisticActivity} from '@/utils/activity';
import {uid} from '@/utils/uid';
import {t} from '@/services/Locale';
import {SagaIterator} from '@redux-saga/types';
import {
  UserActions,
  ModalActions,
  RewardActions,
  HabitModel,
  TaskModel,
  Goal,
} from '@/store/modules';
import * as Api from '@/services/api/reward';
import {Action} from '../utils';

export function* getRewards(): SagaIterator<void> {
  try {
    const data = yield call(Api.listReward);
    yield put(RewardActions.getRewardSuccess(data));
    Analytics.log(Analytics.events.GET_REWARDS);
  } catch {
    //
  }
}

export function* addReward(action: Action): SagaIterator<void> {
  const {reward} = action.payload;
  try {
    Analytics.setUserProperty(reward.key);
    yield put(
      ModalActions.setModalContent({
        height: 700,
        mode: 'share',
        type: reward.key,
        data: reward.data,
      }),
    );
    yield call(Api.addReward,reward);
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}

export function* updateReward(action: Action): SagaIterator<void> {
  try {
    Analytics.setUserProperty(Analytics.events.UPDATE_REWARD, getDateToday());
    const {reward} = action.payload;
    yield call(Api.updateReward,reward);
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}

export function* deleteReward(action: Action): SagaIterator<void> {
  try {
    Analytics.setUserProperty(Analytics.events.DELETE_REWARD, getDateToday());
    const {reward} = action.payload;
    yield call(Api.deleteReward,reward);
  } catch (err) {
    yield put(ModalActions.setError(err));
  }
}

export function* syncRewards(): SagaIterator<void> {
  try {
    Toast.show(t('statistic:syncRewardsRequest'));
    const rewards = [];
    const {
      goals,
      tasks,
      score,
      habits,
      userId,
    }: {
      goals: Goal[];
      habits: HabitModel[];
      score: number;
      tasks: TaskModel[];
      userId: number;
    } = yield select(state => ({
      goals: state.goal.goals as Goal[],
      habits: state.habit.habits.filter(
        (i: HabitModel) => i.endDate >= getDateToday(),
      ),
      tasks: state.task.tasks.filter(
        (i: TaskModel) => i.endDate >= getDateToday(),
      ),
      score: state.statistic.score,
      userId: state.user.id,
    }));
    if (goals.length > 0) {
      rewards.push({
        key: 'add_first_goal',
        data: {goal: {...goals[0]}},
        date: goals[0].createdAt,
      });
    }
    if (habits.length > 0) {
      rewards.push({
        key: 'add_first_habit',
        data: {habit: {...habits[0], dones: undefined}},
        date: habits[0].createdAt,
      });
      habits.map((i: HabitModel) => {
        const params = {
          date: getDateToday(),
          data: {activity: {...i, dones: undefined}},
        };
        const totalDone = i.dones?.filter(j => j.done).length;
        const {sequencesDone} = getStatisticActivity(i);
        if (totalDone >= 30)
          rewards.push({
            ...params,
            key: 'done_activity_30',
          });
        if (totalDone >= 100)
          rewards.push({
            ...params,
            key: 'done_activity_100',
          });
        if (totalDone >= 500)
          rewards.push({
            ...params,
            key: 'done_activity_500',
          });

        if (sequencesDone.some(j => j >= 3))
          rewards.push({
            ...params,
            key: 'sequence_habit_3',
          });
        if (sequencesDone.some(j => j >= 7))
          rewards.push({
            ...params,
            key: 'sequence_habit_7',
          });
        if (sequencesDone.some(j => j >= 30))
          rewards.push({
            ...params,
            key: 'sequence_habit_30',
          });
        if (sequencesDone.some(j => j >= 100))
          rewards.push({
            ...params,
            key: 'sequence_habit_100',
          });
          return null
      });
    }
    tasks.map(i => {
      const params = {
        date: getDateToday(),
        data: {activity: {...i, dones: undefined}},
      };
      const totalDone = i.dones?.filter(j => j.done).length;
      if (totalDone >= 30)
        rewards.push({
          ...params,
          key: 'done_activity_30',
        });
      if (totalDone >= 100)
        rewards.push({
          ...params,
          key: 'done_activity_100',
        });
      if (totalDone >= 500)
        rewards.push({
          ...params,
          key: 'done_activity_500',
        });
      return null
    });
    const [level] = Gamification.getLevelUser(score).textIndex.split('-');
    // eslint-disable-next-line radix
    Array.from({length: parseInt(level) - 1}, (v, k) =>
      rewards.push({
        key: `level_${k + 2}`,
        date: getDateToday(),
      }),
    );
    const rewardsWithUserId = rewards.map(i => ({
      ...i,
      user_id: userId,
      uid: uid(),
    }));
    yield call(Api.syncReward, rewardsWithUserId);
    yield put(UserActions.updateUserSuccess({syncReward: true}));
    yield put(RewardActions.getRewardRequest());
    Toast.show(t('statistic:syncRewardsSuccess'));
    Analytics.log(Analytics.events.SYNC_REWARD);
  } catch (err:any) {
    Toast.show(t('statistic:syncRewardsFailure'));
    Sentry.captureException(err, {
      description: 'syncRewards in reward/saga.ts',
    });
  }
}

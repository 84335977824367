import React, {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import {AppActions} from '@/store/modules/app';

import {Container, Item, Left, Logo, Right, TextLogo} from './styles';

function Header() {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AppActions.addAtribute({pathname}));
  }, [pathname, dispatch]);

  return (
    <Container>
      <Left>
        <Logo />
        <TextLogo>FLYNOW</TextLogo>
      </Left>
      <Right>
        <Link to="activities">
          <Item active={pathname.includes('activities')}>
            {t('tasks').toUpperCase()}
          </Item>
        </Link>
        <Link to="lists">
          <Item active={pathname.includes('lists')}>
            {t('lists').toUpperCase()}
          </Item>
        </Link>
        <Link to="habits">
          <Item active={pathname.includes('habits')}>
            {t('habits').toUpperCase()}
          </Item>
        </Link>
        <Link to="goals">
          <Item active={pathname.includes('goals')}>
            {t('goals').toUpperCase()}
          </Item>
        </Link>
        <Link to="statistics">
          <Item active={pathname.includes('statistics')}>
            {t('statistic:title').toUpperCase()}
          </Item>
        </Link>
        <Link to="settings">
          <Item active={pathname.includes('settings')}>
            {t('tab:general').toUpperCase()}
          </Item>
        </Link>
      </Right>
    </Container>
  );
}

export default Header;

import React from 'react';
import { Input as InputAnt } from 'antd';
import { InputContainer, Label } from '../../styles/global';
import { useTheme } from 'styled-components';
import themes from '@/styles/themes';

const Input = ({placeholder,value,onChangeText,label,...rest}) => {
  const {title} = useTheme();
  const titleTheme = rest?.theme || title;
  const colorLabel = themes[titleTheme].colors.textLabel;
  return (
    <InputContainer>
      <Label color={colorLabel}>{label}</Label>
      <InputAnt placeholder={placeholder} value={value} onChange={(e)=>onChangeText(e.target.value)} 
        {...rest}
        className={titleTheme === 'dark' ? 'input-dark': 'input-light'}
        onPressEnter={rest.onSubmitEditing}
      />
    </InputContainer>
  );
}

export default Input;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Content, Form, ViewBase } from "@/components/atoms";
import { selectorUser, UserActions } from "@/store/modules";
import { Toast } from "@/services";
import { useTranslation } from "react-i18next";
import { Button, Header, Input } from "@/components/molecules";
import { useHistory } from "react-router";
import { ROUTES } from "@/constants";
import { validateEmail } from "@/utils/validator";

const EditProfile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useHistory();
  const { name, email } = useSelector(selectorUser);

  const [editedName, setEditedName] = useState(name);
  const [editedEmail, setEditedEmail] = useState(email);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  function handleUpdateProfile() {
    if (!name || !email) {
      Toast.show(t("error:msgValidateFailure"));
      return;
    }
    if (validateEmail(email)) {
      Toast.show(t("error:validationEmail"));
      return;
    }
    if (passwordConfirm !== password) {
      Toast.show(t("error:msgCheckPasswordAgain"));
      return;
    }
    const user = {
      name: editedName,
      email: editedEmail,
    } as { name: string; email: string; password?: string };
    if (password && passwordConfirm) {
      user.password = password;
    }
    dispatch(UserActions.updateUserRequest(user));
    navigation.push(ROUTES.STATISTICS);
  }

  return (
    <Container>
      <Header />
      <Content>
        <Form>
          <Input
            label={t("auth:name")}
            required
            value={editedName}
            onChangeText={setEditedName}
            placeholder="Ex: Flynow"
          />

          <Input
            label={t("auth:email")}
            required
            value={editedEmail}
            onChangeText={setEditedEmail}
            placeholder="Ex: app.flynow@gmail.com"
          />

          <Input
            label={t("auth:newPassword")}
            value={password}
            onChangeText={setPassword}
            placeholder="**********"
            required={!!(password || passwordConfirm)}
            type="password"
          />
          <Input
            label={t("auth:confirmPassword")}
            value={passwordConfirm}
            onChangeText={setPasswordConfirm}
            placeholder="**********"
            required={!!(password || passwordConfirm)}
            type="password"
          />

          <ViewBase flex={1} center>
            <Button
              title={t("save")}
              marginTop={30}
              onPress={handleUpdateProfile}
              testID="button_save"
              width={"45%"}
            />
          </ViewBase>
        </Form>
      </Content>
    </Container>
  );
};
export default EditProfile;

import firebase from "firebase";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL:  process.env.REACT_APP_FIREBASE_DB_URL,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:  process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId:  process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURENMENT_ID
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.auth().signInAnonymously();

import React from 'react';
import {Label,InputContainer} from '../../styles/global';
import { TimePicker as TimePickerAnt} from 'antd';
import moment from 'moment';
import { useTheme } from 'styled-components';

const TimePicker = ({placeholder,value,onChangeValue,label,}) => {
  const formatString = 'HH:mm'
  const theme = useTheme()
  return (
    <InputContainer>
      <Label>{label}</Label>
      <TimePickerAnt
        value={value ? moment(value,formatString) : undefined}
        format={formatString}
        placeholder={placeholder}
        onChange={value=> {
          onChangeValue(value.format(formatString))
        }}
        className={theme.title === 'dark' ? 'input-dark': 'input-light'}
        style={{background: theme.colors.background, borderColor:theme.colors.surface, color: theme.colors.onBackground}}

      />
    </InputContainer>
  );
}

export default TimePicker;
import {Action} from '../utils';
import {Report, ReportState, ReportTypes} from './type';

const INITIAL_STATE: ReportState = {
  reports: [],
  loading: true,
};

export default function reducer(
  state = INITIAL_STATE,
  {payload, type}: Action,
): ReportState {
  switch (type) {
    case ReportTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case ReportTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: payload.reports,
      };

    case ReportTypes.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        reports: [...state.reports, payload.report],
      };

    default:
      return state;
  }
}

export const ReportActions = {
  getReportsRequest: () => ({
    type: ReportTypes.GET_REPORTS_REQUEST,
  }),
  getReportsSuccess: (reports: Array<Report>): Action => ({
    type: ReportTypes.GET_REPORTS_SUCCESS,
    payload: {reports},
  }),
  createReportRequest: ({
    period,
    lowerDate,
    upperDate,
  }: {
    period: string;
    lowerDate: string;
    upperDate: string;
  }): Action => ({
    type: ReportTypes.CREATE_REPORT_REQUEST,
    payload: {period, lowerDate, upperDate},
  }),
  createReportSuccess: (report: Report): Action => ({
    type: ReportTypes.CREATE_REPORT_SUCCESS,
    payload: {report},
  }),
  setInitialState: () => ({
    type: ReportTypes.SET_INITIAL_STATE,
  }),
};

import {TextBase} from '@/components/atoms';
import styled from 'styled-components';

type BlockTimeAgendaProps = {
  index: number;
};

type MarkerTimeProps = {
  marginTop: number;
};

export const MarkerTime = styled.div<MarkerTimeProps>`
  height: 2px;
  background: red;
  position: absolute;
  z-index: 19;
  width: 100%;
  margin-top: ${({marginTop}) => marginTop}px;
`;
export const BlockTimeLabel = styled(TextBase)`
  height: 65px;
  font-size: 10px;
  margin-top: -5px;
  margin-right: 5px;
`;
export const BlockTimeAgenda = styled.div<BlockTimeAgendaProps>`
  cursor: pointer;
  height: 15px;
  border-left: 1px solid ${({theme}) => theme.colors.surface};
  ${({theme, index}) =>
    index % 4 === 0 && `border-top: 1px solid ${theme.colors.surface};`}
`;

type ContainerDateAgendaProps = {
  offset: number;
};
export const ContainerDateAgenda = styled.div<ContainerDateAgendaProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-top: ${({offset}) => offset}px;
`;

export const ContainerCard = styled.div`
  position: absolute;
  width: 95%;
`;

type ContentCardProps = {
  borderColor: string;
};

export const ContentCard = styled.div<ContentCardProps>`
  border-right: 6px solid ${({borderColor}) => borderColor};
  box-shadow: 1px 1px 3px 1px ${({theme}) => theme.colors.shadow};
  background-color: ${({theme}) =>
    theme.title === 'dark' ? theme.colors.darker : theme.colors.surface};
  font-size: 10px;
  margin: 1px;
  margin-left: 3px;
  border-radius: 5px;
  z-index: 18;
  padding-top: 3px;
  padding-left: 5px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentDays = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

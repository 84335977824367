import React from 'react';
import {ButtonTransparent, TextBase} from '@/components/atoms';
import {useTheme} from 'styled-components';
import CheckBox from '../CheckBox';

interface Props {
  item: any;
  keyLabel: string;
  onCheckItem(item: any): void;
  renderValue?(value: string): any;
  value: boolean;
  testID?: string;
  textStyle?: any;
  style?: any;
}

const ListItemCheckList = ({
  item,
  keyLabel,
  renderValue,
  value,
  onCheckItem,
  textStyle,
  testID,
  style,
}: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <ButtonTransparent
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 3,
        ...style,
      }}>
      <CheckBox
        onChangeValue={() => onCheckItem(item)}
        value={value}
        color={theme.colors.text}
      />
      <TextBase
        style={{
          width: '93%',
          fontSize: 13,
          ...textStyle,
          cursor: 'text',
        }}>
        {renderValue ? renderValue(item[keyLabel]) : item[keyLabel]}
      </TextBase>
    </ButtonTransparent>
  );
};
ListItemCheckList.defaultProps = {
  testID: undefined,
  renderValue: undefined,
  textStyle: {},
  style: {},
};
export default ListItemCheckList;

import {CUSTOM} from '@/constants';
import { Toast } from '@/services';
import {t} from '@/services/Locale';
import {AddHabit, AddTask, Goal, Habit, UpdateTask} from '@/store/modules';

export function validator(
  inputs: Array<string>,
  msg = ('error:validation'),
): boolean {
  if (inputs.includes('')) {
    Toast.error(msg);
    return false;
  }
  return true;
}
export function validatorTask(
  data: AddTask | UpdateTask,
): boolean {
  if (!data.title || !data.label) {
    Toast.error(t('error:validation'));
    return false;
  }
  if (data.period === CUSTOM) {
    Toast.error(t('error:periodValid'));
    return false;
  }
  return true;
}
export function validatorHabit(
  data: AddHabit | Habit,
): boolean {
  if (!data.routine || !data.trigger || !data.reward) {
    Toast.error(t('error:validation'));
    return false;
  }
  if (data.period === CUSTOM) {
    Toast.error(t('error:periodValid'));
    return false;
  }
  return true;
}

export function validatorGoal(
  data: Goal,
): boolean {
  if (!data.specific || !data.date || !data.relevant) {
    Toast.error(t('error:validation'));
    return false;
  }
  return true;
}
export function validateEmail(email: string) {
  const regex = /\S+@\S+\.\S+/;
  return (email && regex.test(email))
}
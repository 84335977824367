import {api} from '@/services';
import { ActivityInDate, CheckList } from '@/store/modules';

interface AddTaskApi {
  user_id: number;
  uid?: string;
  title: string;
  label: string;
  description?: string|null;
  startDate?: string|null;
  endDate?: string|null;
  startHour?: string|null;
  endHour?: string|null;
  period: string;
  createdAt: string;
  updatedAt?: string;
  receiveNotification: boolean;
  soundNotification?: string|null;
  checkList?: any[]|null;
  categories: string[]|null;
  dones: ActivityInDate[];
}
interface UpdateTaskApi {
  id?: number;
  uid?: string;
  date: string;
  user_id: number;
  title?: string;
  label?: string;
  description?: string|null;
  startDate?: string|null;
  endDate?: string|null;
  startHour?: string|null;
  endHour?: string|null;
  period?: string;
  createdAt?: string;
  updatedAt?: string;
  receiveNotification?: boolean;
  soundNotification?: string |null;
  checkList?: CheckList[]|null;
  categories?: string[]|null;
}
interface DeleteTaskApi {
  id: number;
  uid: string;
  date: string;
}
interface CheckTaskApi {
  id?: number;
  uid?: string;
  date: string;
  done: boolean;
}
interface TaskApi extends AddTaskApi {
  id: number;
  updatedAt: string;
}

export async function addTask(task: AddTaskApi): Promise<TaskApi> {
  const {data} = await api.post('tasks', task);
  return data;
}

export async function updateTask(task: UpdateTaskApi): Promise<TaskApi> {
  const id = task.id || task.uid;
  const {data} = await api.put(`tasks/${id}`, task);
  return data;
}
export async function getTask(id: number): Promise<TaskApi> {
  const {data} = await api.get(`tasks/${id}`);
  return data;
}
export async function updateTaskDate(
  task: UpdateTaskApi,
  data: ActivityInDate,
): Promise<TaskApi> {
  const id = task.id || task.uid;
  const {data: response} = await api.put(`tasks/${id}/date/${data.date}`, data);
  return response;
}

export async function deleteTask(task: DeleteTaskApi): Promise<TaskApi> {
  const id = task?.id || task?.uid;
  const {data} = await api.delete(`tasks/${id}`, {
    data: task,
  });
  return data;
}

export async function checkTask(task: CheckTaskApi): Promise<TaskApi> {
  const id = task?.id || task?.uid;
  const {data} = await api.put(`/tasks/${id}/check`, task);
  return data;
}

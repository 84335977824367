import {useEffect, useState} from 'react';
import {Container, Logo, ContainerForm, TextError, Form} from './styles';
import {useTranslation} from 'react-i18next';
import {Button, Input} from '@/components/molecules';
import {Toast} from '@/services';
import bcrypt from 'bcryptjs';
import {resetPassword, sendEmailForResetPassword} from '@/services/api/user';
import {Row, TextSection, ViewBase} from '@/components/atoms';
import {useParams} from './hooks';
import {validateEmail} from '@/utils/validator';
import {useTheme} from 'styled-components';
import {useHistory} from 'react-router-dom';

export default function ResetPassword() {
  const {t} = useTranslation();
  const theme = useTheme();
  const params = useParams();
  const [email, setEmail] = useState(params.email || '');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [codeReceived, setCodeReceived] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (error) setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  async function handleSendCode() {
    try {
      if (!email || !validateEmail(email))
        return setError(t('error:emailIsRequired'));
      const data = await sendEmailForResetPassword(email);
      setCodeReceived(data.code);
      setStep(1);
      Toast.show(t('auth:codeSent'));
    } catch (error: any) {
      Toast.error(error.message);
    }
  }
  const handleConfirmCode = () => {
    const codeIsCorrect = bcrypt.compareSync(code, codeReceived);
    if (!code || !codeIsCorrect) {
      Toast.error(t('auth:codeInvalid'));
      return;
    }
    setStep(2);
  };
  const handleResetPassword = async () => {
    try {
      if (!passwordConfirm) return Toast.error(t('error:msgValidateFailure'));
      await resetPassword(email, passwordConfirm, code);
      Toast.show(t('auth:changePasswordSuccess'));
      setEmail(email);
      setPassword(passwordConfirm);
      history.push('/?email=' + email);
    } catch (error: any) {
      Toast.error(error.message);
    }
  };

  return (
    <Container>
      <ContainerForm>
        <Logo />
        <TextSection>{t('auth:resetPassword')}</TextSection>
        {step === 0 && (
          <Form onSubmit={handleSendCode}>
            <Input
              label={t('auth:email')}
              value={email}
              onChangeText={setEmail}
              placeholder="Ex: joao@gmail.com"
              type="email"
            />
            <TextError>{error}</TextError>
            <Button
              title={t('auth:sendCode')}
              marginTop={40}
              onPress={handleSendCode}
            />
          </Form>
        )}
        {step === 1 && (
          <Form onSubmit={handleConfirmCode}>
            <Input
              label={t('auth:code')}
              value={code}
              onChangeText={setCode}
              placeholder="Ex: 123456"
              type="email"
            />
            <TextError>{error}</TextError>
            <Button
              title={t('auth:titleValidateCode')}
              marginTop={40}
              onPress={handleConfirmCode}
            />
          </Form>
        )}
        {step === 2 && (
          <Form onSubmit={handleResetPassword}>
            <Input
              label={t('auth:newPassword')}
              value={passwordConfirm}
              onChangeText={setPasswordConfirm}
              placeholder="Ex: ***********"
              type="password"
            />
            <TextError>{error}</TextError>
            <Button
              title={t('auth:resetPassword')}
              marginTop={40}
              onPress={handleResetPassword}
            />
          </Form>
        )}
        <Row margin={24}>
          {[0, 1, 2].map(i => (
            <ViewBase
              width={24}
              height={10}
              borderRadius={6}
              backgroundColor={
                step === i ? theme.colors.primary : theme.colors.surface
              }
              margin={6}
            />
          ))}
        </Row>
      </ContainerForm>
    </Container>
  );
}

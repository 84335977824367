import React, {forwardRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {ContentLabels, LabelSelect, TextLabel} from '@/components/atoms';
import {CIRCUMSTANTIAL, GOAL, HABITO, IMPORTANT, URGENT} from '@/constants';
import {
  FilterActions,
  FilterOption,
  FilterType,
  selectorCategories,
  State,
} from '@/store/modules';
import {colors, metrics} from '@/styles';
import {arrangeBigString} from '@/utils/string';

import {TextTag} from './styles';
import BottomSheet from '../BottomSheet';
import Button from '../Button';

interface Props {
  type: FilterOption;
  height?: number;
  filter?: boolean;
  sort?: boolean;
}

type Type = {
  value: string;
  label: string;
};
type Status = {
  value: boolean;
  label: string;
};

type FilterCategoryProps = {
  filterBy: any;
  setFilterBy: any;
  TAM_MAX_STRING: number;
};
const FilterCategory = ({
  filterBy,
  setFilterBy,
  TAM_MAX_STRING,
}: FilterCategoryProps) => {
  const categories = useSelector(selectorCategories);
  const {t} = useTranslation();
  return (
    <>
      <TextLabel marginBottom={2}>{t('categories')}: </TextLabel>
      <ContentLabels style={{marginBottom: metrics.space.smaller}}>
        {categories
          .filter(i => !i.deletedAt)
          .map(category => (
            <LabelSelect
              key={category.name}
              selected={filterBy.categories?.indexOf(category.uid) >= 0}
              onClick={() => {
                setFilterBy((stt: any) => ({
                  ...stt,
                  categories: stt.categories.includes(category.uid)
                    ? stt.categories.filter((i: any) => i !== category.uid)
                    : [...stt.categories, category.uid],
                }));
              }}>
              <TextTag
                selected={filterBy.categories?.indexOf(category.uid) >= 0}>
                {arrangeBigString(
                  t(category.name).toUpperCase(),
                  TAM_MAX_STRING,
                  '.',
                )}
              </TextTag>
            </LabelSelect>
          ))}
      </ContentLabels>
    </>
  );
};

const Filter = (
  {type, filter = false, sort = false}: Props,
  ref: any,
): JSX.Element => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {sortBy: initialSortyBy, filterBy: initialFilterBy} = useSelector<
    State,
    FilterType
  >(state => state.filter[type ?? 'calendar']);
  const [sortBy, setSortBy] = useState(
    initialSortyBy?.label ? initialSortyBy : {...initialSortyBy, label: null},
  );
  const [filterBy, setFilterBy] = useState(initialFilterBy);

  const labels = [
    {
      label: t(URGENT),
      value: URGENT,
    },
    {
      label: t(IMPORTANT),
      value: IMPORTANT,
    },
  ];
  const types = [
    {
      label: t(HABITO),
      value: HABITO,
    },
    {
      label: t(GOAL),
      value: GOAL,
    },
    ...labels,
    {
      label: t(CIRCUMSTANTIAL),
      value: CIRCUMSTANTIAL,
    },
  ].filter(i =>
    type === 'list'
      ? [IMPORTANT, URGENT, CIRCUMSTANTIAL].includes(i.value)
      : true,
  );
  const status = [
    {
      label: t('statistic:performed'),
      value: true,
    },
    {
      label: t('statistic:notPerformed'),
      value: false,
    },
  ];
  const hour = [
    {
      label: t('crescent'),
      value: 1,
      selected: false,
    },
    {
      label: t('decrescent'),
      value: 0,
      selected: false,
    },
  ];
  const date = [
    {
      label: t('thisYear'),
      value: 'thisYear',
    },
    {
      label: t('task:thisMonth'),
      value: 'thisMonth',
    },
    {
      label: t('future'),
      value: 'future',
    },
    {
      label: t('past'),
      value: 'past',
    },
  ];
  function closeModal() {
    ref?.current?.close();
  }
  function save() {
    dispatch(
      FilterActions.setFilter(
        type,
        sort ? sortBy : initialSortyBy,
        filter ? filterBy : initialFilterBy,
      ),
    );
    closeModal();
  }

  const TAM_MAX_STRING = 8;

  return (
    <BottomSheet ref={ref} title={filter ? t('filter') : t('sort')}>
      <>
        {filter && (
          <>
            {/* <TextSection>{t('filter').toUpperCase()}</TextSection> */}
            <FilterCategory
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              TAM_MAX_STRING={TAM_MAX_STRING}
            />

            {['calendar', 'list'].includes(type) && (
              <>
                <TextLabel>{t('label')}: </TextLabel>
                <ContentLabels style={{marginBottom: metrics.space.smaller}}>
                  {types.map((tp: Type) => (
                    <LabelSelect
                      key={tp.label}
                      selected={filterBy.types.indexOf(tp.value) >= 0}
                      onClick={() =>
                        setFilterBy(stt => ({
                          ...stt,
                          types: stt.types.includes(tp.value)
                            ? stt.types.filter(i => i !== tp.value)
                            : [...stt.types, tp.value],
                        }))
                      }>
                      <TextTag selected={filterBy.types.indexOf(tp.value) >= 0}>
                        {arrangeBigString(
                          tp.label.toUpperCase(),
                          TAM_MAX_STRING,
                          '.',
                        )}
                      </TextTag>
                    </LabelSelect>
                  ))}
                </ContentLabels>
              </>
            )}

            {['calendar', 'goal', 'list'].includes(type) && (
              <>
                <TextLabel marginBottom={2}>{t('status')}: </TextLabel>
                <ContentLabels style={{marginBottom: metrics.space.smaller}}>
                  {status.map((st: Status) => (
                    <LabelSelect
                      key={st.label}
                      selected={filterBy.status.indexOf(st.value) >= 0}
                      onClick={() =>
                        setFilterBy(stt => ({
                          ...stt,
                          status: stt.status.includes(st.value)
                            ? stt.status.filter(i => i !== st.value)
                            : [...stt.status, st.value],
                        }))
                      }>
                      <TextTag
                        selected={filterBy.status.indexOf(st.value) >= 0}>
                        {arrangeBigString(
                          st.label.toUpperCase(),
                          TAM_MAX_STRING,
                          '.',
                        )}
                      </TextTag>
                    </LabelSelect>
                  ))}
                </ContentLabels>
              </>
            )}
            {['goal'].includes(type) && (
              <>
                <TextLabel marginBottom={2}>{t('date')}: </TextLabel>
                <ContentLabels style={{marginBottom: metrics.space.smaller}}>
                  {date.map((st: any) => (
                    <LabelSelect
                      key={st.label}
                      selected={(filterBy?.date?.indexOf?.(st.value) || 0) >= 0}
                      onClick={() =>
                        setFilterBy((stt: any) => ({
                          ...stt,
                          date: stt.date.includes(st.value)
                            ? stt.date.filter((i: any) => i !== st.value)
                            : [...stt.date, st.value],
                        }))
                      }>
                      <TextTag
                        selected={
                          (filterBy?.date?.indexOf?.(st.value) || 0) >= 0
                        }>
                        {arrangeBigString(
                          st.label.toUpperCase(),
                          TAM_MAX_STRING,
                          '.',
                        )}
                      </TextTag>
                    </LabelSelect>
                  ))}
                </ContentLabels>
              </>
            )}
          </>
        )}
        {sort && (
          <>
            <>
              <TextLabel marginBottom={2}>{t('hour')}: </TextLabel>
              <ContentLabels style={{marginBottom: metrics.space.smaller}}>
                {hour.map((hr: any) => (
                  <LabelSelect
                    key={hr.label}
                    selected={sortBy?.hour === hr.value}
                    onClick={() =>
                      setSortBy(stt =>
                        stt.hour === hr.value
                          ? {hour: null, status: null, label: null}
                          : {hour: hr.value, status: null, label: null},
                      )
                    }>
                    <TextTag selected={sortBy?.hour === hr.value}>
                      {arrangeBigString(
                        hr.label.toUpperCase(),
                        TAM_MAX_STRING,
                        '.',
                      )}
                    </TextTag>
                  </LabelSelect>
                ))}
              </ContentLabels>

              {['calendar', 'goal'].includes(type) && (
                <>
                  <TextLabel marginBottom={2}>{t('status')}: </TextLabel>
                  <ContentLabels style={{marginBottom: metrics.space.smaller}}>
                    {status.map((st: Status) => (
                      <LabelSelect
                        key={st.label}
                        selected={sortBy?.status === st.value}
                        onClick={() =>
                          setSortBy(stt =>
                            stt.status === st.value
                              ? {hour: null, label: null, status: null}
                              : {hour: null, label: null, status: st.value},
                          )
                        }>
                        <TextTag selected={sortBy?.status === st.value}>
                          {arrangeBigString(
                            st.label.toUpperCase(),
                            TAM_MAX_STRING,
                            '.',
                          )}
                        </TextTag>
                      </LabelSelect>
                    ))}
                  </ContentLabels>
                  {type === 'calendar' && (
                    <>
                      <TextLabel marginBottom={2}>{t('label')}: </TextLabel>
                      <ContentLabels
                        style={{marginBottom: metrics.space.smaller}}>
                        {labels.map((hr: Type) => (
                          <LabelSelect
                            key={hr.label}
                            selected={sortBy?.label === hr.value}
                            onClick={() =>
                              setSortBy(stt =>
                                stt.label === hr.value
                                  ? {hour: null, status: null, label: null}
                                  : {hour: null, status: null, label: hr.value},
                              )
                            }>
                            <TextTag selected={sortBy?.label === hr.value}>
                              {arrangeBigString(
                                hr.label.toUpperCase(),
                                TAM_MAX_STRING,
                                '.',
                              )}
                            </TextTag>
                          </LabelSelect>
                        ))}
                      </ContentLabels>
                    </>
                  )}
                </>
              )}
            </>
          </>
        )}

        <Button
          title={t('save')}
          testID="button_save"
          onClick={save}
          marginTop={20}
          style={{
            backgroundColor: colors.primary,
            width: 400,
            marginTop: 20,
          }}
          loading={false}
        />
      </>
    </BottomSheet>
  );
};

export default forwardRef(Filter);

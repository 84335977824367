import {api} from '@/services';
import { ActivityInDate } from '@/store/modules';

interface AddHabitApi {
  user_id: number;
  uid: string;
  routine: string;
  trigger: string;
  reward: string;
  startDate?: string;
  endDate?: string;
  endHour: string | null;
  startHour: string | null;
  createdAt: string;
  updatedAt?: string;
  receiveNotification: boolean;
  soundNotification: string | null;
  categories: Array<string> | null;
  dones: ActivityInDate[];
}
interface UpdateHabitApi {
  id?: number;
  uid?: string;
  date: string;
  user_id: number;
  routine?: string;
  trigger?: string;
  reward?: string;
  startDate?: string;
  endDate?: string;
  startHour?: string| null;
  endHour?: string| null;
  createdAt?: string;
  updatedAt?: string;
  receiveNotification?: boolean;
  soundNotification?: string| null;
  checkList?: any[];
  categories?: string[]| null;
}
type DeleteHabitApi = UpdateHabitApi;
interface CheckHabitApi {
  id?: number;
  uid?: string;
  date: string;
  done: boolean;
}
interface HabitApi extends AddHabitApi {
  id: number;
  updatedAt: string;
}

export async function addHabit(habit: AddHabitApi): Promise<HabitApi> {
  const {data} = await api.post('habits', habit);
  return data;
}

export async function listHabits(lowerDate:string, upperDate:string): Promise<HabitApi> {
  const {data} = await api.get('habits', {params:{lowerDate,upperDate}});
  return data;
}
export async function getHabit(id:number): Promise<HabitApi> {
  const {data} = await api.get('habits/'+id);
  return data;
}

export async function updateHabit(habit: UpdateHabitApi): Promise<HabitApi> {
  const {id, uid, ...habitEdited} = habit;
  const {data} = await api.put(`habits/${id || uid}`, habitEdited);
  return data;
}

export async function updateHabitDate(
  habit: UpdateHabitApi,
  data: ActivityInDate,
): Promise<HabitApi> {
  const id = habit.id || habit.uid;
  const {data: response} = await api.put(
    `habits/${id}/date/${data.date}`,
    data,
  );
  return response;
}

export async function deleteHabit(habit: DeleteHabitApi): Promise<HabitApi> {
  const id = habit?.id || habit?.uid;
  const {data} = await api.delete(`habits/${id}`, {data: habit});
  return data;
}

export async function checkHabit(habit: CheckHabitApi): Promise<HabitApi> {
  const id = habit.id || habit.uid;
  const {data} = await api.put(`habits/${id}/check`, habit);
  return data;
}

export const GoalTypes = {
  ADD_GOAL: 'ADD_GOAL',
  ADD_GOAL_REQUEST: 'goal/ADD_GOAL_REQUEST',
  ADD_GOAL_SUCCESS: 'goal/ADD_GOAL_SUCCESS',
  SET_FAILURE: 'goal/SET_FAILURE',

  UPDATE_GOAL: 'UPDATE_GOAL',
  EDIT_GOAL_REQUEST: 'goal/EDIT_GOAL_REQUEST',
  EDIT_GOAL_SUCCESS: 'goal/EDIT_GOAL_SUCCESS',

  DELETE_GOAL: 'DELETE_GOAL',
  DELETE_GOAL_REQUEST: 'goal/DELETE_GOAL_REQUEST',
  DELETE_GOAL_SUCCESS: 'goal/DELETE_GOAL_SUCCESS',

  GET_GOALS_REQUEST: 'goal/GET_GOALS_REQUEST',
  GET_GOALS_SUCCESS: 'goal/GET_GOALS_SUCCESS',

  UPDATE_GOAL_DATE_REQUEST: 'goal/UPDATE_GOAL_DATE_REQUEST',
  UPDATE_GOAL_DATE_SUCCESS: 'goal/UPDATE_GOAL_DATE_SUCCESS',

  GET_GOAL_ASSOCIATES_REQUEST: 'goal/GET_GOAL_ASSOCIATES_REQUEST',
  GET_GOAL_ASSOCIATES_SUCCESS: 'goal/GET_GOAL_ASSOCIATES_SUCCESS',

  CHECK_GOAL: 'CHECK_GOAL',
  CHECK_GOAL_REQUEST: 'goal/CHECK_GOAL_REQUEST',
  CHECK_GOAL_SUCCESS: 'goal/CHECK_GOAL_SUCCESS',
  SET_INITIAL_STATE: 'goal/SET_INITIAL_STATE',
};

export type Goal = {
  id?: number;
  uid?: string;
  user_id: number;
  specific: string;
  measurable: string;
  date: string;
  targetAmount: number;
  amount: null | number;
  relevant: string;
  progress: string;
  label: string;
  achievable: Array<{text: string; done: boolean}>;
  done: boolean;
  categories: Array<string> | null;
  tasks: Array<any>;
  habits: Array<any>;
  createdAt: string;
  updatedAt?: string;
  dates:GoalDate[]
};

export interface GoalState {
  goals: Array<Goal>;
  loading: boolean;
  lastSync: Date | null;
}
export type GoalDate = {
  date: string;
  note?: string | null;
  stopwatch?: number | null;
  timer?: number | null;
};
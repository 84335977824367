import {useEffect, useMemo, useRef, useState} from 'react';
import {
  Row,
  TextBase,
  TextLabel,
  TextSection,
  Title,
  ViewBase,
  Icon,
} from '@/components/atoms';
import {useTheme} from 'styled-components';
import {
  getTimeStringFromSecunds,
  getDateToday,
  formatHourToShow,
  addSeconds,
  differenceInSeconds,
} from '@/utils/date';
import {isSameId} from '@/utils/uid';
import {DrawerActions, selectorTimer, TimerActions} from '@/store/modules';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ButtonContainer} from './styles';
import {dataTimers, getLabel, getTimeActivity} from './utils';
import Circle from 'react-circle';
import {ActivityService, Analytics} from '@/services';
import NotificationLocal from '@/services/NotificationLocal';
import PickerTime from './PickerTime';
import Separator from '@/components/molecules/Separator';
import AddTimer from '@/components/organisms/AddTimer';
import {Select} from '@/components/molecules';
import {BACKGROUND_SOUNDS} from '@/constants/sounds';

type Props = {
  activity: any;
  setActivity: any;
};

const TimerScreen = ({activity, setActivity}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const audio = useRef<HTMLAudioElement | null>(null);
  const timerState = useSelector(selectorTimer);
  const [timers, setTimers] = useState(dataTimers);
  const theme = useTheme();
  const interval = useRef<any>();
  const hasCurrentTimer = () =>
    timerState?.activity &&
    timerState?.finishAt &&
    isSameId(timerState?.activity, activity);

  const songs = [
    {
      label: t('none'),
      value: 'none',
    },
    ...BACKGROUND_SOUNDS.map(i => ({label: i.title, value: i.url})),
  ];
  const [sound, setSound] = useState(timerState?.sound || null);

  const [render, setRender] = useState(Date.now());
  const [timeSelect, setTimeSelect] = useState<{time: number}>(
    hasCurrentTimer()
      ? {time: timerState?.time}
      : getTimeActivity(activity) || timers[0],
  );
  const time = timerState?.finishAt
    ? timerState?.status === 'pause' && timerState?.pausedAt
      ? differenceInSeconds(
          timerState?.finishAt,
          timerState?.pausedAt as string,
        )
      : Math.max(differenceInSeconds(timerState?.finishAt, new Date()), 0)
    : timeSelect.time;
  const addTimeRef = useRef<any>();
  const refTime = useRef<any>();
  const [inputTime, setInputTime] = useState(0);

  const [status, setStatus] = useState<null | any>(
    hasCurrentTimer() ? timerState?.status || 'play' : null,
  );
  const endTime = useMemo<Date | string>(() => {
    if (hasCurrentTimer()) {
      return timerState?.finishAt as string;
    }
    return addSeconds(new Date(), timeSelect.time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelect, timerState]);

  useEffect(() => {
    if (hasCurrentTimer()) {
      if (timerState?.status === 'play') onStart();
      if (timerState?.status === 'finish')
        interval.current = setInterval(() => setRender(Date.now()), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time <= 0 && status !== 'finish') {
      NotificationLocal.push(getMessageNotification());
      setStatus('finish');
      dispatch(
        TimerActions.setTimer({
          ...timerState,
          status: 'finish',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    let array = getTimeActivity(activity);
    if (array) {
      array = [array]
        .concat(dataTimers.filter(i => i.time !== array.time))
        .sort((c, b) => c.time - b.time);
    } else {
      array = dataTimers;
    }
    setTimers(array);
  }, [activity]);

  function getDate() {
    if ('specific' in activity) return getDateToday();
    return activity.date;
  }

  function getMessageNotification() {
    return `${getLabel(timeSelect.time)} - ${getTitleActivity()}`;
  }

  function onFinishTimer(incrementTime: number) {
    setStatus(null);
    ActivityService.saveTimer(activity, getDate(), incrementTime);
    setActivity({
      ...activity,
      timer: (activity.timer || 0) - time,
    });
    Analytics.log(Analytics.events.finish_timer);
    stopTimer();
  }

  function onStart() {
    NotificationLocal.requestPermission();
    setStatus('play');
    interval.current = setInterval(() => setRender(Date.now()), 1000);
  }

  function onPause() {
    setStatus('pause');
    clearInterval(interval.current as number);
    interval.current = undefined;
    dispatch(
      TimerActions.setTimer({
        ...timerState,
        status: 'pause',
        pausedAt: new Date().toISOString(),
      }),
    );
  }
  function onCancel() {
    setStatus(null);
    ActivityService.saveTimer(activity, getDate(), -timeSelect.time);
    setActivity({
      ...activity,
      timer: (activity.timer || 0) - timeSelect.time,
    });
    stopTimer();
  }

  function stopTimer() {
    clearInterval(interval.current as number);
    interval.current = undefined;
    dispatch(
      TimerActions.setTimer({
        activity: undefined,
        finishAt: undefined,
        time: undefined,
        sound: undefined,
      }),
    );
  }

  function onSelectTime(item: {time: number}) {
    setTimeSelect(item);
    Analytics.log(Analytics.events.select_time_timer);
  }

  function getTitleActivity() {
    if ('title' in activity) return activity?.title;
    if ('routine' in activity) return activity?.routine;
    if ('specific' in activity) return activity?.specific;
    return '';
  }

  const handleStartTimer = () => {
    stopSound();
    const finishAt = addSeconds(new Date(), time);
    const task = {
      ...activity,
      timer: (activity?.timer || 0) + timeSelect.time,
    };
    if (task) setActivity(task);
    dispatch(
      TimerActions.setTimer({
        activity: task,
        finishAt,
        time: timeSelect.time,
        status: 'play',
        sound,
      }),
    );
    ActivityService.saveTimer(activity, getDate(), timeSelect.time);
  };

  const handleResumeTimer = () => {
    const timePaused = differenceInSeconds(
      new Date(),
      timerState?.pausedAt as string,
    );
    const finishAt = addSeconds(timerState?.finishAt as string, timePaused);
    dispatch(
      TimerActions.setTimer({
        ...timerState,
        finishAt,
        status: 'play',
      }),
    );
  };

  const onAddTime = () => {
    addTimeRef.current?.open();
  };

  const handleAddTime = ({date, timer}: {date: string; timer: number}) => {
    if (activity) {
      ActivityService.updateInDate(activity, date, {
        date,
        timer,
        stopwatch: null,
      });
    }
    addTimeRef.current.close();
    dispatch(DrawerActions.closeDrawer());
  };

  const playSound = (url: string) => {
    audio.current = new Audio(url);
    audio.current?.play();
  };

  const stopSound = () => {
    audio.current?.pause();
  };

  return (
    <>
      <ViewBase height={300} center marginTop={10}>
        <Circle
          progress={Math.floor(
            (100 * (timeSelect.time - time)) / timeSelect.time,
          )}
          size={'300'}
          showPercentage={false}
          bgColor={theme.colors.primary}
          progressColor={theme.colors.surface}
        />
      </ViewBase>
      <ViewBase marginTop={-170} marginBottom={90}>
        <Title center>{getTimeStringFromSecunds(time)}</Title>
        <Row center>
          <TextLabel center marginRight={5}>
            {formatHourToShow(endTime)}
          </TextLabel>
          <Icon name="alarm" color={theme.colors.onBackground} />
        </Row>
      </ViewBase>
      {['play', 'pause', 'finish'].includes(status) && (
        <Row center>
          <TextLabel marginTop={15} marginBottom={15} center>
            {getTitleActivity()}
          </TextLabel>
        </Row>
      )}
      {!['play', 'pause', 'finish'].includes(status) && (
        <>
          <TextLabel>{t('time')}:</TextLabel>
          <Row wrap marginBottom={10}>
            {timers.map(i => (
              <div
                key={String(i.time)}
                onClick={() => onSelectTime(i)}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  width: 70,
                  padding: 5,
                  backgroundColor:
                    timeSelect.time === i.time
                      ? theme.colors.primary
                      : theme.colors.surface,
                  margin: 5,
                  borderRadius: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TextBase
                  color={
                    timeSelect.time === i.time
                      ? theme.colors.white
                      : theme.colors.text
                  }>
                  {getLabel(i.time)}
                </TextBase>
              </div>
            ))}
            <div
              onClick={() => refTime.current.open()}
              style={{
                display: 'flex',
                cursor: 'pointer',
                width: 70,
                padding: 5,
                backgroundColor: theme.colors.surface,
                margin: 5,
                borderRadius: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <TextBase>+</TextBase>
            </div>
          </Row>
        </>
      )}
      <Select
        data={songs}
        label={t('backgroundNoise')}
        value={sound || 'none'}
        setValue={value => {
          if (['play', 'pause', 'finish'].includes(status)) {
            dispatch(
              TimerActions.setTimer({
                ...timerState,
                sound: value !== 'none' ? value : null,
              }),
            );
          } else {
            stopSound();
            if (value !== 'none') playSound(value);
          }
          setSound(value !== 'none' ? value : null);
        }}
        renderValue={value =>
          songs.find(i => i.value === value)?.label as string
        }
      />
      {status === 'finish' ? (
        <>
          <TextLabel center>
            {t('focusTimerFinished')} ({getLabel(timerState.time as number)})
          </TextLabel>
          <ViewBase
            marginTop={12}
            padding={8}
            backgroundColor={theme.colors.surface}
            borderRadius={8}>
            <TextLabel center>{t('extraTime')}:</TextLabel>
            <Title center color={theme.colors.primary}>
              {getTimeStringFromSecunds(
                differenceInSeconds(new Date(), timerState?.finishAt as string),
              )}
            </Title>
          </ViewBase>
          <TextSection marginTop={12} center>
            {t('doWantSaveTime')}
          </TextSection>
          <Row spaceBetween marginTop={8}>
            <ButtonContainer
              onClick={() => {
                setStatus(null);
                stopTimer();
              }}>
              <TextLabel color={theme.colors.white}>{t('no')}</TextLabel>
            </ButtonContainer>
            <ButtonContainer
              onClick={() =>
                onFinishTimer(
                  differenceInSeconds(
                    new Date(),
                    timerState?.finishAt as string,
                  ),
                )
              }>
              <TextLabel color={theme.colors.white}>{t('yes')}</TextLabel>
            </ButtonContainer>
          </Row>
        </>
      ) : (
        <>
          <Row justifyContent="space-between" marginTop={15}>
            {['play', 'pause'].includes(status) ? (
              <ButtonContainer
                onClick={() => {
                  onCancel();
                  Analytics.log(Analytics.events.cancel_timer);
                }}
                backgroundColor={theme.colors.red}>
                <TextLabel color={theme.colors.white}>{t('cancel')}</TextLabel>
              </ButtonContainer>
            ) : (
              <ViewBase />
            )}
            {status === 'play' ? (
              <ButtonContainer
                onClick={() => {
                  onPause();
                  Analytics.log(Analytics.events.pause_timer);
                }}
                backgroundColor={theme.colors.regular}>
                <TextLabel color={theme.colors.white}>{t('pause')}</TextLabel>
              </ButtonContainer>
            ) : (
              <ButtonContainer
                onClick={() => {
                  Analytics.log(
                    status === 'pause'
                      ? Analytics.events.resume_timer
                      : Analytics.events.start_timer,
                  );
                  onStart();
                  if (status !== 'pause') {
                    handleStartTimer();
                  } else {
                    handleResumeTimer();
                  }
                }}>
                <TextLabel color={theme.colors.white}>
                  {status === 'pause' ? t('resume') : t('toStart')}
                </TextLabel>
              </ButtonContainer>
            )}
          </Row>
          {['play', 'pause'].includes(status) && (
            <ViewBase center>
              <ButtonContainer
                style={{marginTop: 20, width: '100%'}}
                title={t('finishAndSave')}
                onClick={() => onFinishTimer(-time)}>
                <TextLabel color={theme.colors.white}>
                  {t('finishAndSave')}
                </TextLabel>
              </ButtonContainer>
            </ViewBase>
          )}

          {Boolean(!status && activity) && (
            <ViewBase padding={20} marginTop={10}>
              <Separator />
              <ViewBase style={{cursor: 'pointer'}} center onClick={onAddTime}>
                <TextLabel color={theme.colors.primary} underline>
                  {t('addTimeManual')}
                </TextLabel>
              </ViewBase>
              <ViewBase marginBottom={theme.metrics.space.medium} />
              <AddTimer
                ref={addTimeRef}
                handleSave={handleAddTime}
                item={{
                  date: activity?.date || getDateToday(),
                  timer: (activity?.timer || 0) + (activity?.stopwatch || 0),
                }}
              />
            </ViewBase>
          )}
        </>
      )}

      <PickerTime
        ref={refTime}
        time={inputTime}
        setTime={setInputTime}
        onAddTime={(t: any) => {
          const diff = {time: t};
          setTimers(v => [...v.filter(i => i.time !== diff.time), diff]);
          setTimeSelect(diff);
        }}
      />
    </>
  );
};

export default TimerScreen;

export default {
  // welcome
  welcome: 'Welcome!',
  target: 'This app aims to help you manage your tasks, habits and goals',
  tip: 'To maximize your results, create common habits and goals. For example, if you want to get into the habit of reading, in addition to creating this habit, create goals (Monthly, Quarterly and Yearly) for this habit.',
  warningBold: 'Please read ',
  warningText:
    'the following information carefully to maximize your results using this app.',

  //  task
  introTask: 'Rate your tasks as IMPORTANT, URGENT or CIRCUMSTANTIAL',
  introImportant:
    'Meaningful tasks that will bring you results and lead you to your goals. Examples: Study new language, and Caring for health.',
  introUrgent:
    'Unexpected, last-minute tasks that have a short deadline or have already expired. Examples: Urgent meetings, Problems to be solved.',
  introCircumstantial:
    'Unnecessary tasks that do not lead to your goals or results. Example: Too much social media.',
  tipTask:
    'Make monthly and weekly schedules prioritizing important tasks. Also, plan your day the night before. Finally, eliminate possible disruptions from your environment to increase your focus.',

  // habit
  introHabit:
    'Habits take an average of 66 days to be created. They are created by repeating 3 steps: Trigger, Routine and Reward.',
  contentHabit:
    'The Trigger is a stimulus to activate the habit, that is, something to remember to perform the routine. Usually the trigger can be a Time, Place, Feeling, or an action performed previously. Routine is the behavior that must be performed frequently. Finally, the Reward is the benefit acquired by performing the routine.',
  howToCreateHabit: 'How to create habits?',
  setupHabit: 'Set the trigger, routine and reward.',

  // goal
  introGoal:
    'Set effective goals using the SMART method which consists of creating goals in a specific, measurable, achievable, relevant and temporal way.',
  howToCreateGoal: 'How to create SMART goals?',
  setupGoal:
    'Specific: What exactly is the goal? \nMeasurable: What is the size of the goal? \nAchievable: How will the goal be achieve? \nRelevant: How important is the goal? \nTime bound: When is the due date?',
  tipGoal:
    '\n# Create related goals and habits.\n\tExample:\n\tGoal: Lose 8 kg in 3 months.\n\tHabit: Exercise \n# Divide big goals into smaller goals.',
  } as any;

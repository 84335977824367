import React, { useMemo } from "react";
import { ActivityContainer, Label } from "./styles";
import { arrangeBigString } from "../../utils/string";
import { useDispatch, useSelector } from "react-redux";
import Check from "../Check";
import { Column, TextTime } from "@/styles/global";
import { differenceInDays, isPast, parseISO } from "date-fns";
import { formatDate } from "@/utils/date";
import { useTranslation } from "react-i18next";
import { DrawerActions } from "@/store/modules/drawer/duck";
import { ActivityService } from "@/services";
import { Row } from "../atoms";
import BarProgress from "../BarProgress";
import { useTheme } from "styled-components";
import { selectorCategories } from "@/store/modules";

const filterByPeriod = (i, goal) =>
  i.date >= (goal.startDate || goal.createdAt) && i.date <= goal.date;
const CardGoal = ({ goal, tamMax = 45 }) => {
  const cardActivityColorBy = useSelector(
    (state) => state.app.cardActivityColorBy || "label"
  );
  const categories = useSelector(selectorCategories);
  const borderColor = getBorderColor(goal);
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentAmount = useSelector((stt) => {
    if (!goal?.progress) return null;
    if (goal?.progress === "checkList")
      return goal.achievable.filter((i) => i.done).length;
    if (goal?.progress === "amount")
      return goal.dates
        ?.filter((i) => filterByPeriod(i, goal))
        ?.reduce((acc, item) => (item.amount || 0) + acc, 0);
    const [, id] = goal.progress.split("_");
    const habitId = parseInt(id) || id;
    const habit = stt.habit.habits.find(
      (i) => habitId && (i.id === habitId || i.uid === habitId)
    );
    if (!habit) return null;
    return habit.dones
      .filter((i) => filterByPeriod(i, goal))
      .reduce((acc, item) => acc + (item.amount || (item.done ? 1 : 0)), 0);
  });
  function getBorderColor(a) {
    if (
      a?.categories &&
      a?.categories?.length > 0 &&
      cardActivityColorBy === "categories"
    ) {
      const categoriesList = a.categories;
      const color = categories.find((c) =>
        categoriesList.includes(c.uid)
      )?.color;
      if (color) return color;
    }
    return "#1565C0";
  }
  const { t } = useTranslation();
  const { textRightTop, textRightBottom } = useMemo(
    () => ({
      textRightTop: formatDate(goal.date, "dd/MM/yy"),
      textRightBottom: isPast(parseISO(goal.date))
        ? ""
        : `${differenceInDays(parseISO(goal.date), new Date()) + 1} ${t(
            "days"
          )}`,
    }),
    [goal, t]
  );

  const handleCheckActivity = (e, goal) => {
    e.stopPropagation();
    ActivityService.done(goal);
  };
  const handleDetailsActivity = (e, goal) => {
    dispatch(
      DrawerActions.setDrawer({
        action: "DETAILS_GOAL",
        payload: { activity: { ...goal, currentAmount } },
      })
    );
  };

  return (
    <ActivityContainer
      onClick={(e) => handleDetailsActivity(e, goal)}
      borderColor={borderColor}
      height={goal.progress ? 65 : 45}
    >
      <Row spaceBetween>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Check
            value={goal.done}
            onChangeValue={(e) => handleCheckActivity(e, goal)}
          />
          <Label>{arrangeBigString(goal.specific, tamMax)}</Label>
        </div>
        <Column>
          <TextTime>{textRightTop}</TextTime>
          <TextTime>{textRightBottom}</TextTime>
        </Column>
      </Row>
      {currentAmount !== null && (
        <BarProgress
          showScore
          score={currentAmount}
          level={{
            scoreDown: 0,
            scoreTop:
              goal?.progress === "amount" || goal?.progress?.includes("habit_")
                ? goal?.targetAmount
                : goal?.achievable?.length,
          }}
          showRate
          color={borderColor}
          height={15}
          colorSurface={theme.colors.surfaceLight}
        />
      )}
    </ActivityContainer>
  );
};

export default CardGoal;

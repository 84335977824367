import React, { useEffect, useState } from "react";
import { DAILY, HABITO, INFINITY_DATE, JUST_TODAY, YEARLY } from "@/constants";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CategoryActions,
  DrawerActions,
  HabitActions,
  selectorCategories,
  selectorCategoriesToEdit,
  selectorUser,
} from "@/store/modules";
import { fixObjectNotifications, getDateToday } from "@/utils/date";
import { validatorHabit } from "@/utils/validator";
import {
  Button,
  Input,
  InputCheckList,
  InputDate,
  InputHour,
  InputPeriod,
  MultiSelect,
} from "@/components/molecules";
import InputDailyGoal from "@/components/molecules/InputDailyGoal";
import Notifications from "@/components/molecules/Notifications";

const Habit = ({ isEditing, habit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useSelector(selectorUser);
  const categoriesList = useSelector(selectorCategories);
  const [checkList, setCheckList] = useState(habit?.checkList || []);

  const [routine, setRoutine] = useState(habit?.routine || "");
  const [trigger, setTrigger] = useState(habit?.trigger || "");
  const [reward, setReward] = useState(habit?.reward || "");
  const categoriesEdit = useSelector((state) =>
    selectorCategoriesToEdit(state, habit?.categories || [])
  );
  const [categories, setCategories] = useState(categoriesEdit || []);
  const [startHour, setStartHour] = useState(habit?.startHour || "");
  const [endDate, setEndDate] = useState(habit?.endDate || INFINITY_DATE);
  const [startDate, setStartDate] = useState(
    habit?.startDate || getDateToday()
  );
  const [endHour, setEndHour] = useState(habit?.endHour || "");
  const [period, setPeriod] = useState(habit?.period || DAILY);
  const [goal, setGoal] = useState(habit?.goal || null);
  const [notifications, setNotifications] = useState(
    fixObjectNotifications(habit?.notifications) || []
  );
  function handleAddHabit() {
    const idsCategories = categories.map((i) => i.uid);
    const newHabit = {
      user_id: id,
      routine,
      trigger,
      reward,
      categories: idsCategories.length > 0 ? idsCategories : null,
      startHour,
      endHour,
      period,
      done: isEditing ? habit.done : false,
      checkList: checkList.length > 0 ? checkList : null,
      goal,
      notifications: fixObjectNotifications(notifications),
      date: isEditing ? habit.date : getDateToday(),
      startDate,
      endDate,
      dones: isEditing ? habit.dones : [],
      label: HABITO,
    };

    const data = isEditing
      ? { ...habit, ...newHabit }
      : {
          ...newHabit,
          createdAt: getDateToday(),
        };
    const isValid = validatorHabit(data);
    if (!isValid) {
      return;
    }
    if (isEditing) {
      dispatch(HabitActions.updateHabitRequest({ ...habit, ...newHabit }));
    } else {
      dispatch(
        HabitActions.addHabitRequest({
          ...newHabit,
          createdAt: getDateToday(),
        })
      );
    }
    dispatch(DrawerActions.closeDrawer());
  }

  useEffect(() => {
    dispatch(CategoryActions.getCategoriesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Input
        label={t("routine")}
        required
        value={routine}
        onChangeText={setRoutine}
        placeholder={t("exampleRoutine")}
      />
      <Input
        label={t("trigger")}
        value={trigger}
        onChangeText={setTrigger}
        placeholder={t("exampleTrigger")}
        required
      />
      <Input
        label={t("reward")}
        value={reward}
        onChangeText={setReward}
        placeholder={t("exampleReward")}
        required
      />

      <MultiSelect
        type="category"
        label={t("task:categories")}
        value={categories}
        placeholder={t("task:placeholderCategories")}
        setValue={setCategories}
        data={categoriesList}
        titleModal={t("task:categories")}
        getId={(item)=> item.uid}
      />

      <InputDate
        label={t("task:start")}
        value={startDate}
        onChangeValue={setStartDate}
      />
      <InputDate
        label={t("task:end")}
        value={endDate}
        onChangeValue={setEndDate}
      />

      <InputHour
        startHour={startHour}
        setStartHour={setStartHour}
        endHour={endHour}
        setEndHour={setEndHour}
      />

      <Notifications
        suggests={[startHour, endHour]}
        notifications={notifications}
        setNotifications={setNotifications}
      />

      <InputPeriod
        label={t("task:period")}
        value={period}
        setValue={setPeriod}
        excludePeriods={[JUST_TODAY, YEARLY]}
        startDate={startDate}
        endDate={endDate}
      />
      <InputDailyGoal goal={goal} setGoal={setGoal} />

      <InputCheckList
        data={checkList}
        setData={setCheckList}
        placeholder={t("habit:placeholderCheckListHabit")}
      />

      <Button
        title={isEditing ? t("save") : t("add")}
        marginTop={20}
        onPress={handleAddHabit}
      />
    </div>
  );
};

export default Habit;

import {
  LoginRequest,
  LoginSuccess,
  RegisterRequest,
  UpdateUser,
  UserUpdate,
} from '@/store/modules';
import {Action} from '../utils';
import {Configs, UserState, UserTypes} from './type';

const INITIAL_STATE: UserState = {
  loading: false,
  lastSync: null,
  premium: false,
  email: '',
  name: '',
  password: '',
  token: '',
  createdAt: '',
  id: 0,
  lastDateCheckPremium:null,
  configs:{}
};

export default function reducer(
  state = INITIAL_STATE,
  {payload, type}: Action,
): UserState {
  switch (type) {
    case UserTypes.SET_LOADING:
      return {...state, loading: payload.loading};
    case UserTypes.SET_FAILURE:
      return {...state, loading: false};

    case UserTypes.GET_OUT_REQUEST:
    case UserTypes.GET_OUT_SUCCESS:
      return INITIAL_STATE;

    case UserTypes.LOGIN_REQUEST:
      return {...state, loading: true};
    case UserTypes.LOGIN_GOOGLE_REQUEST:
      return {...state, loading: true};
    case UserTypes.REGISTER_REQUEST:
      return {...state, loading: true};

    case UserTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UserTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload.user,
      };

      case UserTypes.SET_CONFIGS_SUCCESS:
        return {
          ...state,
          configs: {...state?.configs, ...payload.configs},
        };
        
    case UserTypes.UPDATE_USER_REQUEST:
      return {...state, loading: true};
    case UserTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload.user,
      };
    case UserTypes.DELETE_USER_REQUEST:
      return {...state, loading: true};

    case UserTypes.SET_LANGUAGE:
      return {...state, language: payload.language};

    case UserTypes.DELETE_USER_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export const UserActions = {
  setLoading: (loading: boolean): Action => ({
    type: UserTypes.SET_LOADING,
    payload: {loading},
  }),
  setFailure: () => ({
    type: UserTypes.SET_FAILURE,
  }),
  loginRequest: (email: string, password: string): Action<LoginRequest> => ({
    type: UserTypes.LOGIN_REQUEST,
    payload: {email, password},
  }),
  loginSuccess: (user: LoginSuccess): Action<{user: LoginSuccess}> => ({
    type: UserTypes.LOGIN_SUCCESS,
    payload: {user},
  }),
  loginGoogleRequest: (provider:string, name: string, email: string, idToken: string): Action => ({
    type: UserTypes.LOGIN_GOOGLE_REQUEST,
    payload: {provider, email, name, idToken},
  }),
  registerRequest: (
    name: string,
    email: string,
    password: string,
  ): Action<RegisterRequest> => ({
    type: UserTypes.REGISTER_REQUEST,
    payload: {name, email, password},
  }),
  clearAllState: () => ({
    type: UserTypes.CLEAR_ALL_STATE,
  }),
  restoreOwnState: () => ({
    type: UserTypes.RESTORE_OWN_STATE,
  }),
  getOutRequest: () => ({
    type: UserTypes.GET_OUT_REQUEST,
  }),
  getOutSuccess: () => ({
    type: UserTypes.GET_OUT_SUCCESS,
  }),
  deleteUserRequest: () => ({
    type: UserTypes.DELETE_USER_REQUEST,
  }),
  deleteUserSuccess: () => ({
    type: UserTypes.DELETE_USER_SUCCESS,
  }),
  updateUserRequest: (user: UserUpdate): Action => ({
    type: UserTypes.UPDATE_USER_REQUEST,
    payload: {user},
  }),
  updateUserSuccess: (user: UpdateUser): Action => ({
    type: UserTypes.UPDATE_USER_SUCCESS,
    payload: {user},
  }),
  setLanguage: (language: string): Action<{language: string}> => ({
    type: UserTypes.SET_LANGUAGE,
    payload: {language},
  }),
  syncUserData: () => ({
    type: UserTypes.SYNC,
  }),
  setConfigsRequest: (
    configs: Partial<Configs>,
  ): Action<{configs: Partial<Configs>}> => ({
    type: UserTypes.SET_CONFIGS_REQUEST,
    payload: {configs},
  }),
  setConfigsSuccess: (
    configs: Partial<Configs>,
  ): Action<{configs: Partial<Configs>}> => ({
    type: UserTypes.SET_CONFIGS_SUCCESS,
    payload: {configs},
  }),
};

import React, {useMemo} from 'react';
import {Row, Section, TextBase, TextSection} from '@/components/atoms';
import {useTranslation} from 'react-i18next';
import {Activity} from '@/store/modules';
import {metrics} from '@/styles';
import {arrangeBigString} from '@/utils/string';

type Props = {
  activities: Activity[];
  type: 'goal' | 'habit' | 'task';
};

const ListActivitiesDones: React.FC<Props> = ({activities, type}: Props) => {
  const {t} = useTranslation();
  function handleSort(a: any, b: any) {
    if (!a.dones || !b.dones) return 1;
    return (
      b.dones.filter((i: any) => i.done).length -
      a.dones.filter((i: any) => i.done).length
    );
  }
  function renderDone(i: any) {
    if (!i.dones) return i.done ? t('yes') : t('no');
    return i.dones.filter((j: any) => j.done).length;
  }
  function renderTime(i: any) {
    const sec = i.dones
      .filter((j: any) => j.timer || j.stopwatch)
      .reduce(
        (acc: any, item: any) =>
          acc + ((item.timer || 0) + (item.stopwatch || 0)),
        0,
      );

    const min = Math.floor(sec / 60);
    const h = Math.floor(min / 60);
    if (min > 1 && min < 60) {
      return `${min}m`;
    }
    if (min >= 60) {
      const m = min % 60;
      if (m) {
        return `${h}h${m}m`;
      }
      return `${h}h`;
    }
    return '0';
  }
  const {
    title,
    keyTitle,
    textEmpty,
  }: {
    title: string;
    textEmpty: string;
    keyTitle: 'specific' | 'routine' | 'title';
  } = useMemo(() => {
    if (type === 'goal')
      return {
        title: t('goals'),
        keyTitle: 'specific',
        textEmpty: t('statistic:noGoalsPeriod'),
      };
    if (type === 'habit') {
      return {
        title: t('habits'),
        keyTitle: 'routine',
        textEmpty: t('statistic:noHabitsPeriod'),
      };
    }
    return {
      title: t('tasks'),
      keyTitle: 'title',
      textEmpty: t('statistic:noTasksPeriod'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Section>
      <TextSection>{title}</TextSection>
      {activities?.length === 0 ? (
        <TextBase>{textEmpty}</TextBase>
      ) : (
        <Row>
          <TextBase bold style={{flex: 1}}>
            {t('auth:name')}
          </TextBase>
          <TextBase bold right style={{width: metrics.fontSize(80)}}>
            {t('time')}
          </TextBase>
          <TextBase bold right style={{width: metrics.fontSize(80)}}>
            {arrangeBigString(t('statistic:achievements'), 6)}
          </TextBase>
        </Row>
      )}
      {activities?.sort(handleSort).map((i: any) => (
        <Row
          key={i?.id || i?.uid}
          style={{flex: 1, paddingLeft: 0, marginTop: 3}}>
          <TextBase small style={{flex: 1}}>
            {arrangeBigString(i[keyTitle], 25)}
          </TextBase>
          <TextBase small bold right style={{width: metrics.fontSize(80)}}>
            {renderTime(i)}
          </TextBase>
          <TextBase small bold right style={{width: metrics.fontSize(80)}}>
            {renderDone(i)}
          </TextBase>
        </Row>
      ))}
    </Section>
  );
};
export default ListActivitiesDones;

import firebase from 'firebase'
import {formatDate} from '@/utils/date';
import { firebaseApp } from './firebase';

interface IFirestore {
  create(collection: string, data: any): void;
}

class Firestore implements IFirestore {
  private db: firebase.firestore.Firestore;

  constructor() {
    this.db = firebaseApp.firestore();
  }

  create(collection: string, data: any): void {
    const idDoc = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.db
      .collection(collection)
      .doc(idDoc)
      .set({plataform: 'web', ...data});
  }
}
// eslint-disable-next-line
export default new Firestore();

import { startOfMonth, startOfYear, subDays, subYears } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  addYears,
  formatDate,
  formatDateToServer,
  getDateToday,
  getTimeStringFromSecunds,
  isBetweenDate,
  min,
} from "@/utils/date";
import { TextLabel, Section, ViewBase, TextBase, Row, Title } from "../atoms";
import Chart from "react-google-charts";
import metrics, { fontSize } from "@/styles/metrics";
import {
  ActivityService,
  getSequencesActivity,
  getTotalDaysRepetionActivityBetweenInterval,
} from "@/services";
import ListRewardsActivity from "../organisms/ListRewardsActivity";
import Month from "../organisms/Month";
import GrowthByPeriod from "../organisms/GrowthByPeriod";

const Statistics = ({ activity, type }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dates = useMemo(
    () => activity?.dones || activity?.dates || [],
    [activity]
  );
  const arr = Array.from(
    {
      length:
        formatDate(new Date(), "yyyy") - formatDate(activity.startDate, "yyyy"),
    },
    (v, k) => ({
      label: formatDate(addYears(activity.startDate, k), "yyyy"),
      lowerDate: [
        formatDate(addYears(activity.startDate, k), "yyyy-01-01"),
        activity.startDate,
      ].sort((a, b) => a < b)[0],
      upperDate: [
        formatDate(addYears(activity.startDate, k), "yyyy-12-31"),
        activity.endDate,
      ].sort((a, b) => a > b)[0],
    })
  );
  const intervalData = [
    {
      label: t("task:all"),
      lowerDate: activity.startDate,
      upperDate: getDateToday(),
    },
    {
      label: t("task:7days"),
      lowerDate: formatDateToServer(subDays(new Date(), 6)),
      upperDate: getDateToday(),
    },
    {
      label: t("task:thisMonth"),
      lowerDate: formatDateToServer(startOfMonth(new Date())),
      upperDate: getDateToday(),
    },
    {
      label: t("task:30days"),
      lowerDate: formatDateToServer(subDays(new Date(), 29)),
      upperDate: getDateToday(),
    },
    {
      label: t("task:thisYear"),
      lowerDate: formatDateToServer(startOfYear(new Date())),
      upperDate: getDateToday(),
    },
    {
      label: t("task:12month"),
      lowerDate: formatDateToServer(subYears(new Date(), 1)),
      upperDate: getDateToday(),
    },
    ...arr,
  ];
  const [lowerDate, setLowerDate] = useState(intervalData[0].lowerDate);
  const [upperDate, setUpperDate] = useState(intervalData[0].upperDate);
  const [sequencesHabitDone, setSequencesHabitsDone] = useState([]);
  const lastsSequences = [...sequencesHabitDone].filter(Boolean).slice(0, 3);
  const bestsSequences = [...sequencesHabitDone]
    .sort((a, b) => b - a)
    .slice(0, 3);
  const datesByInterval = dates?.filter(
    (i) => lowerDate <= i.date && upperDate >= i.date
  );
  const totalDays = getTotalDaysRepetionActivityBetweenInterval(
    activity,
    lowerDate,
    upperDate
  );
  const countDaysSuccess = Math.min(
    datesByInterval.filter((i) => i.done).length,
    totalDays
  );
  const countDaysFailed = Math.max(totalDays - countDaysSuccess, 0);

  useEffect(() => {
    const sequencesDone = getSequencesActivity(
      activity,
      lowerDate,
      upperDate,
      dates
    );
    setSequencesHabitsDone(sequencesDone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, lowerDate, upperDate]);

  function handleDayPress(date) {
    if (isBetweenDate(date, activity)) {
      const done = !!dates.find((i) => i.date === date)?.done;
      const data = { ...activity, done, date };
      ActivityService.done(data);
    }
  }

  return (
    <>
      <Section>
        <TextLabel>{t("history")}</TextLabel>
        <Month
          dates={dates}
          onPressDate={handleDayPress}
          targetAmount={activity?.goal?.amount}
          date={getDateToday()}
          minDate={activity?.startDate}
          maxDate={formatDateToServer(
            min([getDateToday(), activity?.endDate]),
            "Statistics.tsx 184"
          )}
          isValidDate={(d) => isBetweenDate(d, activity)}
        />
      </Section>

      <GrowthByPeriod
        dates={dates}
        label={`${t("statistic:growth")}`}
        showScore={false}
        showAmount={!!activity?.goal?.unity}
        amountLabel={t(`unity:${activity?.goal?.unity}`)}
      />

      <Row style={{ marginTop: 10 }}>
        {intervalData.map((i) => (
          <div
            onClick={() => {
              setLowerDate(i.lowerDate);
              setUpperDate(i.upperDate);
            }}
            key={i.label}
            style={{ cursor: "pointer" }}
          >
            <TextBase
              small
              style={{
                padding: 5,
                margin: 5,
                marginTop: 0,
                borderRadius: 5,
                backgroundColor:
                  lowerDate === i.lowerDate && upperDate === i.upperDate
                    ? theme.colors.primary
                    : theme.colors.surface,
                color:
                  lowerDate === i.lowerDate && upperDate === i.upperDate
                    ? theme.colors.white
                    : theme.colors.text,
              }}
            >
              {i.label}
            </TextBase>
          </div>
        ))}
      </Row>

      <Section>
        <TextLabel>{t("performance")}</TextLabel>
        <Chart
          width={"400px"}
          height={"300px"}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Esfera", "Esfera"],
            [t("statistic:performed"), countDaysSuccess],
            [t("statistic:notPerformed"), countDaysFailed],
          ]}
          rootProps={{ "data-testid": "1" }}
          options={{
            backgroundColor: theme.colors.surface,
            colors: [theme.colors.green, theme.colors.red],
          }}
        />
      </Section>

      <Row spaceBetween marginTop={theme.metrics.space.small}>
        <ViewBase
          flex={1}
          center
          borderRadius={theme.metrics.space.medium}
          backgroundColor={theme.colors.surface}
          padding={theme.metrics.fontSize(6)}
        >
          <TextBase bold small>
            {t("totalDaysDone")}
          </TextBase>
          <Title bold color={theme.colors.green}>
            {countDaysSuccess}
          </Title>
        </ViewBase>

        <ViewBase
          flex={1}
          center
          marginLeft={theme.metrics.space.smaller}
          borderRadius={theme.metrics.space.medium}
          backgroundColor={theme.colors.surface}
          padding={theme.metrics.fontSize(6)}
        >
          <TextBase bold small>
            {t("totalDaysNotDone")}
          </TextBase>
          <Title bold color={theme.colors.red}>
            {countDaysFailed}
          </Title>
        </ViewBase>
      </Row>

      <Section>
        <TextLabel>{t("biggestSequences")}</TextLabel>
        <ViewBase
          padding={theme.metrics.space.medium}
          borderRadius={theme.metrics.space.medium}
          backgroundColor={theme.colors.surface}
        >
          {bestsSequences.map((item, index) => (
            <Row alignCenter key={`${index}-${item}`}>
              <ViewBase
                height={fontSize(18)}
                backgroundColor={theme.colors.green}
                flex={item / bestsSequences[0] || 0.01}
                marginBottom={fontSize(2)}
                borderRadius={theme.metrics.space.smaller}
              />
              <TextBase marginLeft={3} small color={theme.colors.green} bold>
                {item}
              </TextBase>
            </Row>
          ))}
        </ViewBase>
      </Section>
      <Section>
        <TextLabel>{t("lastSequences")}</TextLabel>
        <ViewBase
          padding={theme.metrics.space.medium}
          borderRadius={theme.metrics.space.medium}
          backgroundColor={theme.colors.surface}
        >
          {lastsSequences.map((item, index) => (
            <Row alignCenter key={`${index}-${item}`}>
              <ViewBase
                height={metrics.fontSize(18)}
                backgroundColor={theme.colors.green}
                flex={item / lastsSequences[0] || 0.01}
                marginBottom={fontSize(2)}
                borderRadius={theme.metrics.space.smaller}
              />
              <TextBase marginLeft={3} small color={theme.colors.green} bold>
                {item}
              </TextBase>
            </Row>
          ))}
        </ViewBase>
      </Section>

      <Row spaceBetween marginTop={theme.metrics.space.smaller}>
        <ViewBase
          flex={1}
          center
          borderRadius={theme.metrics.space.medium}
          backgroundColor={theme.colors.surface}
          padding={theme.metrics.fontSize(6)}
        >
          <TextBase bold small>
            {t("time")}
          </TextBase>
          <Title>
            {getTimeStringFromSecunds(
              datesByInterval.reduce(
                (acc, item) => acc + (item.timer + item.stopwatch || 0),
                0
              ) || 0
            )}
          </Title>
        </ViewBase>
        {activity?.goal?.amount > 0 && (
          <ViewBase
            flex={1}
            center
            marginLeft={theme.metrics.space.smaller}
            borderRadius={theme.metrics.space.medium}
            backgroundColor={theme.colors.surface}
            padding={theme.metrics.fontSize(6)}
          >
            <TextBase bold small>
              {t("amount")} ({t(`unity:${activity?.goal?.unity}`)})
            </TextBase>
            <Title>
              {datesByInterval.reduce(
                (acc, item) => acc + (item.amount || 0),
                0
              ) || 0}
            </Title>
          </ViewBase>
        )}
      </Row>

      {bestsSequences && bestsSequences[0] >= 0 && (
        <ListRewardsActivity
          activity={activity}
          bestSequence={bestsSequences ? bestsSequences[0] : null}
          totalDaysDone={countDaysSuccess}
        />
      )}
    </>
  );
};

export default Statistics;

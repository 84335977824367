import * as React from 'react';
import FlatList from '../FlatList';

interface SectionListProps<T> {
  sections: Array<{data: T[]; title?: string; date?: string}>;
  keyExtractor: (item: T) => string;
  renderItem: (props: {item: T; index: number; section: any}) => any;
  renderSectionHeader: (props: {
    section: {data: T[]; title?: string; date?: string};
  }) => any;
  ListEmptyComponent?: any;
  ListHeaderComponent?: any;
  ListFooterComponent?: any;
}

export default function SectionList<T>({
  sections,
  renderSectionHeader,
  renderItem,
  keyExtractor,
  ListEmptyComponent,
  ListFooterComponent,
  ListHeaderComponent,
}: SectionListProps<T>) {
  if (sections.length === 0) return ListEmptyComponent || null;
  return (
    <React.Fragment>
      {ListHeaderComponent}
      {sections.map(section => (
        <>
          {renderSectionHeader({section})}
          <FlatList
            data={section.data}
            keyExtractor={keyExtractor}
            renderItem={({item, index}) => renderItem({item, index, section})}
          />
        </>
      ))}
      {ListFooterComponent}
    </React.Fragment>
  );
}

import {Action} from '../utils';
import {Category, CategoryState, CategoryTypes} from './type';
import { formatViewCategories } from './saga';

const INITIAL_STATE: CategoryState = {
  categories: [],
  lastSync: null,
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): CategoryState {
  switch (type) {
    case CategoryTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case CategoryTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload.data,
        lastSync: new Date(),
      };
    case CategoryTypes.ADD_CATEGORY_REQUEST:
      return {
        ...state,
        categories: formatViewCategories([...state.categories, payload.category]),
      };
    case CategoryTypes.UPDATE_CATEGORY_REQUEST:
    case CategoryTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map(c =>
          c.uid === payload.category.uid ? payload.category : c,
        ),
      };

    case CategoryTypes.DELETE_CATEGORY_REQUEST:
    case CategoryTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          c => c.uid !== payload.category.uid,
        ),
      };

    default:
      return state;
  }
}

export const CategoryActions = {
  addCategoriesRequest: (category: Category): Action => ({
    type: CategoryTypes.ADD_CATEGORY_REQUEST,
    payload: {category},
  }),
  addCategoriesSuccess: (category: Category): Action => ({
    type: CategoryTypes.ADD_CATEGORY_SUCCESS,
    payload: {category},
  }),
  updateCategoriesRequest: (category: Category): Action => ({
    type: CategoryTypes.UPDATE_CATEGORY_REQUEST,
    payload: {category},
  }),
  updateCategoriesSuccess: (category: Category): Action => ({
    type: CategoryTypes.UPDATE_CATEGORY_SUCCESS,
    payload: {category},
  }),
  deleteCategoriesRequest: (category: Category): Action => ({
    type: CategoryTypes.DELETE_CATEGORY_REQUEST,
    payload: {category},
  }),
  deleteCategoriesSuccess: (category: Category): Action => ({
    type: CategoryTypes.DELETE_CATEGORY_SUCCESS,
    payload: {category},
  }),
  getCategoriesRequest: () => ({
    type: CategoryTypes.GET_CATEGORIES_REQUEST,
  }),
  getCategoriesSuccess: (data: Category[]): Action => ({
    type: CategoryTypes.GET_CATEGORIES_SUCCESS,
    payload: {data},
  }),
  setFailure: () => ({
    type: CategoryTypes.SET_FAILURE,
  }),
  setInitialState: () => ({
    type: CategoryTypes.SET_INITIAL_STATE,
  }),
};

export const PurchaseTypes = {
  GET_PURCHASES_REQUEST: 'purchase/GET_PURCHASES_REQUEST',
  GET_PURCHASES_SUCCESS: 'purchase/GET_PURCHASES_SUCCESS',

  ADD_PURCHASE_REQUEST: 'purchase/ADD_PURCHASE_REQUEST',
  ADD_PURCHASE_SUCCESS: 'purchase/ADD_PURCHASE_SUCCESS',

  CHECK_PREMIUM_REQUEST: 'purchase/CHECK_PREMIUM_REQUEST',

  SET_INITIAL_STATE: 'purchase/SET_INITIAL_STATE',
};

export type Purchase = {
  productId: string;
  platform: string;
  canceled: boolean;
  expired: boolean;
  qntMonth: number;
  price: number;
  createdAt: Date;
  expireIn: Date;
};
export type PurchaseState = {
  purchases: Array<Purchase>;
  lastSync: null | Date;
};

import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal, Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { Label } from "@/styles/global";
import { getDateToday } from "@/utils/date";
import {
  GoalActions,
  HabitActions,
  TaskActions,
} from "@/store/modules";
import { useDispatch } from "react-redux";
import moment from "moment";
import { colors } from "@/styles";
const { TextArea } = Input;

const AddNote = ({ initialDate, setVisible, visible, activity }) => {
  const maxLength = 1024;
  const [date, setDate] = useState(initialDate ?? getDateToday());
  const [note, setNote] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatView = "DD/MM/yyyy";
  const formatServer = "yyyy-MM-DD";
  const getType = () => {
    if (!activity) return null;
    if (activity?.title) return "task";
    if (activity?.routine) return "habit";
    if (activity?.specific) return "goal";
  };

  const activityDate = useMemo(() => {
    if (!getType()) return null;
    if (getType() === "goal")
      return (
        activity?.dates?.find((i) => i.date === date) || {
          note: null,
          date,
        }
      );
    if (getType() === "task")
      return (
        activity?.dones.find((i) => i.date === date) || {
          note: null,
          date: date,
          deleted: false,
          done: false,
        }
      );
    if (getType() === "habit")
      return (
        activity?.dones.find((i) => i.date === date) || {
          note: null,
          date: date,
          deleted: false,
          done: false,
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, date, visible]);

  useEffect(() => {
    setNote(activityDate.note || "");
  }, [activityDate, visible]);

  function handleBack(e) {
    e?.stopPropagation();
    closeModal();
  }

  function handleSubmit(e) {
    e?.stopPropagation();
    if (getType() === "task") {
      dispatch(
        TaskActions.updateTaskDateRequest(activity, { ...activityDate, note })
      );
    }
    if (getType() === "habit") {
      dispatch(
        HabitActions.updateHabitDateRequest(activity, { ...activityDate, note })
      );
    }
    if (getType() === "goal") {
      dispatch(
        GoalActions.updateGoalDateRequest(activity, { ...activityDate, note })
      );
    }
    setNote("");
    closeModal();
  }
  function closeModal(e) {
    e?.stopPropagation();
    setVisible(false);
  }
  return (
    <Modal
      onClick={(e) => e?.stopPropagation()}
      title={t("addNote")}
      open={visible}
      closable
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key={"back"} type="primary" onClick={handleBack}>
          {t("back")}
        </Button>,
        <Button key={"submit"} type="primary" onClick={handleSubmit}>
          {t("submit")}
        </Button>,
      ]}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <Label color="#333">{t("date")}</Label>
        <br />
        <DatePicker
          picker={"date"}
          value={moment(date, formatServer)}
          format={formatView}
          onChange={(value) => setDate(value.format(formatServer))}
        />
        <br />
        <br />
        <Label color="#333">{t("note")}</Label>
        <br />
        <TextArea
          rows={4}
          placeholder={t("placeholderNote")}
          onChange={(e) => setNote(e.target.value)}
          value={note}
          maxLength={maxLength}
        />
        {note && maxLength <= note?.length && (
          <h5 style={{ color: colors.red }}>
            O limite máximo de caracteres é {maxLength}
          </h5>
        )}
      </div>
    </Modal>
  );
};

export default AddNote;

export const dataTimers = [
  {
    time: 25 * 60,
  },
  {
    time: 30 * 60,
  },
  {
    time: 50 * 60,
  },
  {
    time: 60 * 60,
  },
  {
    time: 90 * 60,
  },
  {
    time: 120 * 60,
  },
  {
    time: 180 * 60,
  },
];

export function getLabel(s: number, short= true): string {
  if (!s || s < 0) return '0min';
  const hour = Math.floor(s / (60 * 60));
  const min = Math.floor((s % (60 * 60)) / 60);
  if ((hour || min) && short)
    return `${hour ? `${hour}h` : ''}${min ? `${min}m` : ''}`;
  const sec = s % 60;
  if (short) return `${sec}s`;
  return `${hour ? `${hour}h` : ''}${min ? `${min}m` : ''}${
    sec ? `${sec}s` : ''
  }`;
}

export function getTimeActivity(activity: any): any {
  const startHour = activity?.startHour;
  const endHour = activity?.endHour;
  if (endHour && startHour) {
    const minutes =
      (parseInt(endHour.split(':')[0]) - parseInt(startHour.split(':')[0])) *
        60 +
      (parseInt(endHour.split(':')[1]) - parseInt(startHour.split(':')[1]));
    return {
      time: minutes * 60,
    };
  }
  return null;
}

import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Toast} from '@/services';
import {colors} from '@/styles';
import {Row, TextBase, ViewBase} from '@/components/atoms';
import {validator} from '@/utils/validator';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {CheckList, State} from '@/store/modules';
import CheckBox from '../CheckBox';
import Input from '@/components/Input';
import {Button as ButtonAnt} from 'antd';
import {AiFillEdit, AiOutlineClose} from 'react-icons/ai';
import {ImCheckmark} from 'react-icons/im';
import {Input as InputAnt} from 'antd';
import {getDateToday} from '@/utils/date';

interface Item {
  text: string;
  done: boolean;
}
interface Props {
  data: Array<Item> | CheckList[];
  setData(value: Array<Item> | ((v: Array<Item>) => Array<Item>)): void;
  onlyPremium?: boolean;
  children: any;
  placeholder?: string;
}
const InputCheckList = ({
  data,
  setData,
  onlyPremium,
  children,
  placeholder,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const [input, setInput] = useState('');
  const [edit, setEdit] = useState<{
    index: null | number;
    text: string;
    done: boolean;
  }>({
    index: null,
    text: '',
    done: false,
  });
  const theme = useTheme();
  const premium = useSelector<State>(state => state.user.premium);

  function handleCheckItem(index: number) {
    setData(value =>
      value.map((item, i) =>
        i === index
          ? {
              ...item,
              done: !item.done,
              doneAt: !item.done ? getDateToday() : null,
            }
          : item,
      ),
    );
  }
  function handleDeleteItem(index: number) {
    setData(value => value.filter((item, i) => i !== index));
    setEdit({index: null, text: '', done: false});
  }
  function handleUpdateItem() {
    setData(value =>
      value.map((item, i) =>
        i === edit.index ? {text: edit.text, done: edit.done} : item,
      ),
    );
    setEdit({index: null, text: '', done: false});
  }
  function handleAddNewItem() {
    const isValid = validator([input], t('error:checkListRequired'));
    if (!isValid) {
      return;
    }
    if (onlyPremium && !premium) return Toast.show(t('error:onlyPremium'));
    setData(value => [...value, {text: input, done: false}]);
    setInput('');
  }
  return (
    <ViewBase style={{width: '100%'}}>
      <Row spaceBetween alignCenter>
        <Input
          label="Check-list"
          placeholder={placeholder || t('task:exampleCheckList')}
          value={input}
          onChangeText={setInput}
          onSubmitEditing={handleAddNewItem}
        />
        <ButtonAnt
          style={{
            marginTop: 14,
            backgroundColor: theme.colors.surface,
            color: theme.colors.onBackground,
            borderColor: theme.colors.regular,
          }}
          onClick={handleAddNewItem}>
          {t('add')}
        </ButtonAnt>
      </Row>
      {data.length > 0 &&
        data.map((item, index) => (
          <Row key={item.text} alignCenter spaceBetween>
            <Row
              alignCenter
              style={{
                flex: 0.95,
              }}>
              <CheckBox
                onChangeValue={() => handleCheckItem(index)}
                value={item.done}
                color={theme.colors.text}
              />
              {edit.index === index ? (
                <InputAnt
                  value={edit.text}
                  onChange={e => setEdit(v => ({...v, text: e.target.value}))}
                  onPressEnter={handleUpdateItem}
                  className={
                    theme.title === 'dark' ? 'input-dark' : 'input-light'
                  }
                />
              ) : (
                <TextBase>{item.text}</TextBase>
              )}
            </Row>
            <AiOutlineClose
              style={{color: colors.red, cursor: 'pointer'}}
              size={20}
              onClick={() => handleDeleteItem(index)}
            />
            {edit.index === index ? (
              <ImCheckmark
                size={20}
                style={{color: colors.regular, cursor: 'pointer'}}
                onClick={handleUpdateItem}
              />
            ) : (
              <AiFillEdit
                style={{color: colors.regular, cursor: 'pointer'}}
                size={20}
                onClick={() => {
                  setEdit({...item, index});
                }}
              />
            )}
          </Row>
        ))}
      {children}
    </ViewBase>
  );
};
InputCheckList.defaultProps = {
  onlyPremium: false,
};
export default InputCheckList;



import ReactLoading from 'react-loading';
export * from './Buttons';
export * from './Texts';
export * from './Containers';
export * from './Inputs';
export {default as Icon} from './Icon';

type LoadingProps = {
  size?:number;
  color?: string;
}
export const Loading = ({size=20,color}:LoadingProps) => {
  return <ReactLoading type='spin' color={color} height={size} width={size} />
}
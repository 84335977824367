import React from 'react';
import {Required, InputContainer} from '@/components/atoms';
import {Select as SelectAnt} from 'antd';
import {useTheme} from 'styled-components';
import {Label} from '@/styles/global';
import themes from '@/styles/themes';
const {Option} = SelectAnt;

interface Item {
  label: string;
  value: string;
}
interface Props {
  label: string;
  value: string;
  placeholder?: string;
  setValue(value: string): void;
  renderValue?(value: string): string;
  data: Item[];
  required?: boolean;
  testID?: string;
  height?: string | number;
  theme?: 'dark' | 'default';
}
const Select = ({
  label,
  value,
  renderValue,
  placeholder,
  setValue,
  data,
  required,
  testID,
  height,
  theme,
}: Props): JSX.Element => {
  const {title} = useTheme();
  const titleTheme = theme || title;
  const colorLabel = themes[titleTheme].colors.textLabel;
  return (
    <InputContainer>
      <Label color={colorLabel}>
        {label} {required && <Required>*</Required>}{' '}
      </Label>
      <SelectAnt
        value={value ? value : undefined}
        placeholder={placeholder}
        onChange={value => setValue(value)}
        className={titleTheme === 'dark' ? 'input-dark' : 'input-light'}>
        {data.map(i => (
          <Option
            key={i.value}
            value={i.value}
            // className={theme.title === 'dark' ? 'input-dark': 'input-light'}
            // style={{backgroundColor: theme.colors.background, borderColor:theme.colors.surface}}
          >
            {i.label}
          </Option>
        ))}
      </SelectAnt>

      {/* <ButtonValue
        onClick={() => refSelect?.current?.open()}
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        <TextValue style={{flex: 1}}  >
          {value ? (renderValue ? renderValue(value) : value) : placeholder}
        </TextValue>
        <Icon
          name="chevron-down"
          size={18}
          color={colors.light}
          style={{paddingLeft: 10}}
        />
      </ButtonValue> */}
    </InputContainer>
  );
};

Select.defaultProps = {
  required: false,
  testID: undefined,
  height: undefined,
  renderValue: undefined,
};
export default Select;

import {version} from '../../package.json';

export {LEVELS, REWARDS} from './gamification';
export {SOUNDS} from './sounds';
export {URL} from './url';
export {ROUTES} from './routes';
export {ERROR} from './error';
export {STATIC_PLANS} from './premium';
export const VERSION = version;
export const LIMITS_FREE = {
  tasks: 25,
  habits: 7,
  goals: 7,
};
export const LIMITS_PREMIUM = {
  tasks: 100,
  habits: 50,
  goals: 50,
};
export const QNT_DAYS_SCHEDULE_NOTIFICATION = 5;

export const IMPORTANT = 'Importante';
export const URGENT = 'Urgente';
export const CIRCUMSTANTIAL = 'Circunstancial';
export const HABITO = 'Hábito';
export const GOAL = 'Meta';

export const INFINITY_DATE = '3000-01-01';

export const JUST_TODAY = 'NÃO REPETE';
export const DAILY = 'DIÁRIA';
export const WEEKLY = 'SEMANAL';
export const MONTHLY = 'MENSAL';
export const YEARLY = 'ANUAL';
export const SEG_SEX = 'SEG,TER,QUA,QUI,SEX';
export const SEG_SAB = 'SEG,TER,QUA,QUI,SEX,SAB';
export const CUSTOM = 'CUSTOM';

export enum ModeInterval {
  DAILY = 'daily',
  DAYS3 = 'days3',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
export enum ModeView {
  AGENDA = 'agenda',
  LIST = 'list',
  PENDING = 'pending',
  TIMELINE = 'timeline',
}

export enum Period {
  JUST_TODAY = 'NÃO REPETE',
  DAILY = 'DIÁRIA',
  WEEKLY = 'SEMANAL',
  MONTHLY = 'MENSAL',
  YEARLY = 'ANUAL',
  SEG_SEX = 'SEG,TER,QUA,QUI,SEX',
  SEG_SAB = 'SEG,TER,QUA,QUI,SEX,SAB',
}

export const PERIOD = {
  JUST_TODAY,
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
  SEG_SAB,
  SEG_SEX,
  CUSTOM,
};

export const PERIODS = [
  JUST_TODAY,
  DAILY,
  SEG_SEX,
  SEG_SAB,
  WEEKLY,
  MONTHLY,
  YEARLY,
];

export const MODE_VIEW = {
  agenda: 'agenda',
  list: 'list',
  pending: 'pending',
  timeline: 'timeline',
};
export const MODE_INTERVAL = {
  daily: 'daily',
  days3: 'days3',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const isCategoryAdmin = (uid: string) =>
  CATEGORIES.map(i => i.uid).includes(uid);
  
export const CATEGORIES = [
  {
    name: 'Profissional',
    uid: '60502cb5-e2ea-429c-8d50-77194470882b',
    color: '#0652DD',
  },
  {
    name: 'Intelectual',
    uid: '9bc47131-dfbf-4d0e-a6cd-cfebf0be8b22',
    color: '#8e44ad',
  },
  {
    name: 'Finanças',
    uid: 'e5a0d4e1-02de-4fbd-815d-c30cdfb85c6c',
    color: '#27ae60',
  },
  {
    name: 'Social',
    uid: '89e34bfc-bf9b-4e68-9850-03b2cf793369',
    color: '#FFC312',
  },
  {
    name: 'Relacionamento',
    uid: '44a76ee4-ffd2-40a2-ace9-f3c5c1837f85',
    color: '#e74c3c',
  },
  {
    name: 'Família',
    uid: '18c470c7-c603-4313-a8a2-524fe11021ff',
    color: '#1289A7',
  },
  {
    name: 'Lazer',
    uid: 'f60896ed-d636-4f10-8745-20a7474b424c',
    color: '#F79F1F',
  },
  {
    name: 'Saúde',
    uid: '5bf853aa-fa9e-463e-872d-3842cd2c9cff',
    color: '#006266',
  },
  {
    name: 'Espiritual',
    uid: '1a0bd5dd-6d35-415f-a7e8-96c20fcb72f8',
    color: '#bdc3c7',
  },
  {
    name: 'Emocional',
    uid: 'e77584e2-9e97-48f3-9e03-770522eac761',
    color: '#D980FA',
  },
];

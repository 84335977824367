import * as React from 'react';
import {TextBase, TextSection, ViewBase} from '@/components/atoms';
import {ButtonSection} from '@/components/molecules';
import {Navigation} from '@/services';
import {colors} from '@/styles';

interface LimitReachedProps {
  title: string;
  text: string;
  route?: string;
  titleButton?: string;
  onPress?(): void;
}

const ListEmpty: React.FC<LimitReachedProps> = ({
  title,
  text,
  route,
  titleButton,
  onPress,
}) => {
  return (
    <ViewBase center padding={15}>
      <TextSection center>{title}</TextSection>
      <TextBase marginBottom={5} small>
        {text}
      </TextBase>
      {titleButton && (
        <ButtonSection
          title={titleButton}
          textColor={colors.primary}
          center
          onPress={
            route ? () => Navigation.navigate(route) : onPress || undefined
          }
        />
      )}
    </ViewBase>
  );
};
ListEmpty.defaultProps = {
  titleButton: undefined,
  route: undefined,
  onPress: undefined,
};
export default ListEmpty;

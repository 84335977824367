import {State, HabitModel, HabitState, FilterType} from '@/store/modules';
import {isAfter, isFuture} from '@/utils/date';

export const selectorHabitsActive = (state: State): HabitModel[] =>
  state.habit.habits.filter(i => isAfter(i.endDate, new Date()));
export const selectorHabit = (state: State): HabitState => state.habit;
export const selectorHabitFilter = (state: State): FilterType =>
  state.filter.habit;
export const selectorFutureHabits = (state: State): HabitModel[] =>
  state.habit.habits.filter(i => isFuture(i.endDate));

import React from 'react';

// import { Container } from './styles';
type Props = {
  dataChart: any[];
  upperDate: string;
  numDays: number;
};
const Consistency: React.FC<Props> = ({
  dataChart,
  upperDate,
  numDays,
}: Props) => {
  return null
};

export default Consistency;

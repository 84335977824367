import { isSameId } from '@/utils/uid';
import { Action, check, unCheck, updateDateActivity } from '../utils';
import {
  ActivityInDate,
  Habit,
  HabitState,
  HabitTypes,
  HabitModel,
  AddHabit,
} from './type';

const INITIAL_STATE: HabitState = {
  habits: [],
  loading: false,
  lastSync: null,
};

export default function reducer(
  state = INITIAL_STATE,
  { payload, type }: Action,
): HabitState {
  switch (type) {
    case HabitTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;
    case HabitTypes.ADD_HABIT_SUCCESS:
    case HabitTypes.ADD_HABIT:
      return {
        ...state,
        habits: [...state.habits, payload.habit],
      };
    case HabitTypes.SET_FAILURE:
      return { ...state, loading: false };
    case HabitTypes.GET_HABITS_REQUEST:
      return { ...state, loading: true };

    case HabitTypes.EDIT_HABIT_SUCCESS:
    case HabitTypes.UPDATE_HABIT:
      return {
        ...state,
        habits: state.habits.map(item =>
          isSameId(item, payload.habit)
            ? { ...payload.habit, dones: item.dones }
            : item,
        ),
      };
    case HabitTypes.CHECK_HABIT_REQUEST:
    case HabitTypes.CHECK_HABIT_SUCCESS:
    case HabitTypes.CHECK_HABIT:
      return {
        ...state,
        habits: state.habits.map(item =>
          isSameId(item, payload.habit)
            ? payload.habit.done
              ? (check(item, payload.habit.date) as HabitModel)
              : (unCheck(item, payload.habit.date) as HabitModel)
            : item,
        ),
      };

    case HabitTypes.UPDATE_HABIT_DATE_SUCCESS:
    case HabitTypes.UPDATE_HABIT_DATE:
      return {
        ...state,
        habits: state.habits.map(item =>
          isSameId(item, payload.habit)
            ? (updateDateActivity(
              item,
              payload.data.date,
              payload.data,
            ) as HabitModel)
            : item,
        ),
      };

    case HabitTypes.GET_HABITS_SUCCESS:
      return {
        ...state,
        lastSync: payload.setLastSync ?  new Date() : state.lastSync,
        habits: [
          ...payload.habits.map((i:any)=> ({...i, lastSync: payload.setLastSync ?  new Date() : i.lastSync||null})),
          ...state.habits.filter(
            i => !payload.habits.find((j: Habit) => isSameId(i, j)),
          ),
        ],
        loading: false
      };
    case HabitTypes.GET_HABIT_SUCCESS:
      return {
        ...state,
        habits: [
          ...state.habits.map(
            i =>  isSameId(i, payload.habit) ?  ({...payload.habit, dones: payload.dates, lastSync: new Date()}) : i,
          ),
        ],
      };
    case HabitTypes.DELETE_HABIT_SUCCESS:
    case HabitTypes.DELETE_HABIT:
      return {
        ...state,
        habits: state.habits.filter(item =>
          !isSameId(item, payload.habit)
        ),
      };

    default:
      return state;
  }
}

export const HabitActions = {
  addHabitRequest: (habit: AddHabit): Action<{ habit: AddHabit }> => ({
    type: HabitTypes.ADD_HABIT_REQUEST,
    payload: { habit },
  }),
  addHabitSuccess: (habit: HabitModel): Action<{ habit: HabitModel }> => ({
    type: HabitTypes.ADD_HABIT_SUCCESS,
    payload: { habit },
  }),
  setFailure: () => ({
    type: HabitTypes.SET_FAILURE,
  }),

  deleteHabitRequest: (habit: Habit): Action<{ habit: Habit }> => ({
    type: HabitTypes.DELETE_HABIT_REQUEST,
    payload: { habit },
  }),
  updateHabitRequest: (habit: Habit): Action<{ habit: Habit }> => ({
    type: HabitTypes.EDIT_HABIT_REQUEST,
    payload: { habit },
  }),
  updateHabitSuccess: (habit: Habit): Action<{ habit: Habit }> => ({
    type: HabitTypes.EDIT_HABIT_SUCCESS,
    payload: { habit },
  }),

  getHabitRequest: (id: number) => ({
    type: HabitTypes.GET_HABIT_REQUEST,
    payload: {id}
  }),
  getHabitSuccess: (habit: any, dates: any): Action<{ habit:any; dates: any[] }> => ({
    type: HabitTypes.GET_HABIT_SUCCESS,
    payload: { habit, dates },
  }),
  getHabitsRequest: () => ({
    type: HabitTypes.GET_HABITS_REQUEST,
  }),
  getHabitsSuccess: (habits: HabitModel[], setLastSync?:boolean): Action<{ habits: HabitModel[]; setLastSync?:boolean }> => ({
    type: HabitTypes.GET_HABITS_SUCCESS,
    payload: { habits, setLastSync },
  }),

  checkHabitRequest: (habit: Habit): Action<{ habit: Habit }> => ({
    type: HabitTypes.CHECK_HABIT_REQUEST,
    payload: { habit },
  }),
  checkHabitSuccess: (habit: Habit): Action<{ habit: Habit }> => ({
    type: HabitTypes.CHECK_HABIT_SUCCESS,
    payload: { habit },
  }),
  updateHabitDateRequest: (
    habit: Habit,
    data: ActivityInDate,
  ): Action<{ habit: Habit; data: ActivityInDate }> => ({
    type: HabitTypes.UPDATE_HABIT_DATE_REQUEST,
    payload: { habit, data },
  }),
  updateHabitDateSuccess: (
    habit: Habit,
    data: ActivityInDate,
  ): Action<{ habit: Habit; data: ActivityInDate }> => ({
    type: HabitTypes.UPDATE_HABIT_DATE_SUCCESS,
    payload: { habit, data },
  }),
  deleteHabitSuccess: (habit: Habit): Action<{ habit: Habit }> => ({
    type: HabitTypes.DELETE_HABIT_SUCCESS,
    payload: { habit },
  }),
  setInitialState: () => ({
    type: HabitTypes.SET_INITIAL_STATE,
  }),
};

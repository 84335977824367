import styled from 'styled-components';

type ButtonContainerProps = {
  backgroundColor?: string;
};
export const ButtonContainer = styled.button<ButtonContainerProps>`
  padding: 8px;
  border-radius: 8px;
  width: 48%;
  background-color: ${({backgroundColor, theme}) =>
    backgroundColor || theme.colors.primary};
  justify-content: center;
  align-items: center;
  border: none;
  cursor:pointer;
  &:hover{
    opacity:0.9;
  }
`;

import React, { useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from 'styled-components';
import {State} from '@/store/modules/rootReducers';
import WheelOfLife from '@/components/organisms/Statistics/WheelOfLife';
import TriadTime from '@/components/organisms/Statistics/TriadTime';
import WeeklyGrowth from '@/components/organisms/Statistics/WeeklyGrowth';
import StatisticsGeneral from '@/components/organisms/Statistics/StatisticsGeneral';
import {
  Container,
  Row,
  Section,
  TextSection,
  TextBase,
  Fixed,
  ViewBase,
} from '@/components/atoms';
import {
  selectorWheelOfLife,
  Category,
  selectorUser,
  StatisticActions,
  selectorStatistic,
} from '@/store/modules';
import {arrangeBigString} from '@/utils/string';
import {Gamification} from '@/services';
import {ROUTES} from '@/constants';
import {useTranslation} from 'react-i18next';
import {ButtonSection,BarProgress} from '@/components/molecules';
import Header from '@/components/Header';
import { useHistory } from 'react-router';

const Statistics: React.FC = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sharing] = useState(false);
  const {score, weeklyChart,percent,triadTime} = useSelector(selectorStatistic);
  const {name} = useSelector(selectorUser);
  const wheelOfLife = useSelector<State, (Category & {total: number})[]>(
    selectorWheelOfLife,
  );

  useEffect(()=>{
    dispatch(StatisticActions.getStatisticsRequest())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Container ignorePadding>
      <Fixed>
        <Header/>
      </Fixed>
      <ViewBase style={{padding:'5% 5%'}}>
          <Section style={{paddingTop: 0}}>
            <Row spaceBetween>
              <TextBase color={theme.colors.textSection} bold>
                {arrangeBigString(name, 20).toUpperCase()}
              </TextBase>
                <Row alignCenter>
                  <TextBase bold color={theme.colors.premium}>
                    {t('general:premium')}
                  </TextBase>
                </Row>
            </Row>
            <Row spaceBetween>
              <TextBase bold>{t('statistic:score')}:</TextBase>
              <TextBase>{score}</TextBase>
            </Row>
            <Row spaceBetween marginBottom={10}>
              <TextBase bold>{t('statistic:level')}:</TextBase>
              <TextBase>{Gamification.getLevelUser(score).textIndex}</TextBase>
            </Row>
            <BarProgress
              level={Gamification.getLevelUser(score)}
              score={score}
            />
          </Section>

          <ButtonSection
            title={t('statistic:viewGamitification')}
            onPress={() => history.push(ROUTES.GAMIFICATION)}
          />

          {!sharing && (
            <>
              <Section>
                <TextSection>{t('statistic:rewards')}</TextSection>
                <ButtonSection
                  title={t('statistic:gallery')}
                  onPress={() => history.push(ROUTES.GALLERY)}
                />
                <ButtonSection
                  title={t('statistic:myRewards')}
                  onPress={() => history.push(ROUTES.MY_REWARDS)}
                />
              </Section>
              <Section>
                <TextSection>{t('statistic:rankings')}</TextSection>
                <ButtonSection
                  title={t('statistic:rankingPremium')}
                  onPress={() =>
                    history.push(ROUTES.RANKING, {type: 'premium'})
                  }
                />
                <ButtonSection
                  title={t('statistic:rankingGlobal')}
                  onPress={() =>
                    history.push(ROUTES.RANKING, {type: 'geral'})
                  }
                />
              </Section>
              <Section>
                <TextSection>{t('statistic:reports')}</TextSection>
                <ButtonSection
                  title={t('statistic:viewReportsWeekly')}
                  onPress={() =>
                    history.push(ROUTES.REPORTS, {period: 'weekly'})
                  }
                />
                <ButtonSection
                  title={t('statistic:viewReportsMonthly')}
                  onPress={() =>
                    history.push(ROUTES.REPORTS, {period: 'monthly'})
                  }
                />
              </Section>
            </>
          )}

          <StatisticsGeneral statistics={percent} />
          
          <Section>
            <Row spaceBetween>
              <WeeklyGrowth
                title={t('statistic:weeklyGrowth')}
                weeklyChart={weeklyChart}
                />
              <TriadTime triadTime={triadTime} />
              <WheelOfLife categories={wheelOfLife} />
            </Row>
          </Section>

        </ViewBase>
    </Container>
  );
};
export default Statistics;

import {Activity} from '@/store/modules';

export const TimerTypes = {
  SET_TIMER: 'timer/SET_TIMER',
  SET_STOPWATCH: 'timer/SET_STOPWATCH',
  SET_INITIAL_STATE: 'timer/SET_INITIAL_STATE',
};

export interface Timer {
  activity?: Activity;
  finishAt?: Date | string;
  notificationId?: NodeJS.Timeout;
  time?: number;
  status?: string;
  pausedAt?: string;
  sound?: string|null;
}
export interface Stopwatch {
  activity?: Activity;
  startAt?: Date | string;
  status?: string;
  pausedAt?: string;
  sound?: string|null;
}

export interface TimerState {
  timer: Timer;
  stopwatch: Stopwatch;
}

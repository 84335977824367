import styled from 'styled-components';

export const Menu = styled.div`
  span {
    color: #444;
    cursor: pointer;
    font-weight: 450;
    &:hover {
      opacity: 0.8;
      color: ${({theme}) => theme.colors.primary};
    }
    display: block;
  }
`;

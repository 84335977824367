export const MEDAL = `MEDAL`;
export const TROPHY = `TROPHY`;

export const REWARDS = [
  {
    key: 'add_first_goal',
    image: 'https://flynow.s3.amazonaws.com/rewards/add_first_goal.png',
  },
  {
    key: 'add_first_habit',
    image: 'https://flynow.s3.amazonaws.com/rewards/add_first_habit.png',
  },
  {
    key: 'sequence_habit_3',
    image: 'https://flynow.s3.amazonaws.com/rewards/sequence_habit_3.png',
  },
  {
    key: 'sequence_habit_7',
    image: 'https://flynow.s3.amazonaws.com/rewards/sequence_habit_7.png',
  },
  {
    key: 'done_goal',
    image: 'https://flynow.s3.amazonaws.com/rewards/done_goal.png',
  },
  {
    key: 'get_premium',
    image: 'https://flynow.s3.amazonaws.com/rewards/get_premium.png',
  },
  {
    key: 'level_2',
    image: 'https://flynow.s3.amazonaws.com/rewards/level_2.png',
  },
  {
    key: 'done_activity_30',
    image: 'https://flynow.s3.amazonaws.com/rewards/done_activity_30.png',
  },
  {
    key: 'sequence_habit_30',
    image: 'https://flynow.s3.amazonaws.com/rewards/sequence_habit_30.png',
  },
  {
    key: 'done_activity_100',
    image: 'https://flynow.s3.amazonaws.com/rewards/done_activity_100.png',
  },
  {
    key: 'sequence_habit_100',
    image: 'https://flynow.s3.amazonaws.com/rewards/sequence_habit_100.png',
  },
  {
    key: 'level_3',
    image: 'https://flynow.s3.amazonaws.com/rewards/level_3.png',
  },
  {
    key: '3_ranking_weekly',
    image: 'https://flynow.s3.amazonaws.com/rewards/3_ranking_weekly.png',
  },
  {
    key: 'done_activity_500',
    image: 'https://flynow.s3.amazonaws.com/rewards/done_activity_500.png',
  },
  {
    key: '2_ranking_weekly',
    image: 'https://flynow.s3.amazonaws.com/rewards/2_ranking_weekly.png',
  },
  {
    key: '1_ranking_weekly',
    image: 'https://flynow.s3.amazonaws.com/rewards/1_ranking_weekly.png',
  },
  {
    key: 'level_4',
    image: 'https://flynow.s3.amazonaws.com/rewards/level_4.png',
  },
  {
    key: '3_ranking_monthly',
    image: 'https://flynow.s3.amazonaws.com/rewards/3_ranking_monthly.png',
  },
  {
    key: '2_ranking_monthly',
    image: 'https://flynow.s3.amazonaws.com/rewards/2_ranking_monthly.png',
  },
  {
    key: '1_ranking_monthly',
    image: 'https://flynow.s3.amazonaws.com/rewards/1_ranking_monthly.png',
  },
  {
    key: 'level_5',
    image: 'https://flynow.s3.amazonaws.com/rewards/level_5.png',
  },
  {
    key: '3_ranking_yearly',
    image: 'https://flynow.s3.amazonaws.com/rewards/3_ranking_yearly.png',
  },
  {
    key: '2_ranking_yearly',
    image: 'https://flynow.s3.amazonaws.com/rewards/2_ranking_yearly.png',
  },
  {
    key: '1_ranking_yearly',
    image: 'https://flynow.s3.amazonaws.com/rewards/1_ranking_yearly.png',
  },
  {
    key: 'level_6',
    image: 'https://flynow.s3.amazonaws.com/rewards/level_6.png',
  },
];

export const LEVELS = [
  {
    text: 'Procrastinador',
    textIndex: '1-Procrastinador',
    scoreDown: 0,
    scoreTop: 1000,
  },
  {
    textIndex: '2-Iniciante',
    text: 'Iniciante',
    scoreDown: 1000,
    scoreTop: 10000,
  },
  {
    text: 'Esforçado',
    textIndex: '3-Esforçado',
    scoreDown: 10000,
    scoreTop: 100000,
  },

  {
    text: 'Disciplinado',
    textIndex: '4-Disciplinado',
    scoreDown: 100000,
    scoreTop: 500000,
  },
  {
    text: 'Genial',
    textIndex: '5-Genial',
    scoreDown: 500000,
    scoreTop: 1000000,
  },
  {
    text: 'Invencível',
    textIndex: '6-Invencível',
    scoreDown: 1000000,
    scoreTop: 10000000,
  },
];

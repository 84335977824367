import {SagaIterator} from '@redux-saga/types';
import {
  Activity,
  ActivityInDate,
  Habit,
  HabitModel,
  State,
  Task,
  TaskModel,
} from '@/store/modules';
import {getStatisticActivity} from '@/utils/activity';
import {getDateToday, getDefaultTimer} from '@/utils/date';
import {uid} from '@/utils/uid';
import {put, select} from 'redux-saga/effects';
import {RewardActions} from './reward/duck';
import {Goal} from './goal';

export const selectorActivity = (stt: State, activity: Activity) => {
  if ('routine' in activity) {
    return stt.habit.habits.find(item => isSameId(item, activity));
  }
  if ('title' in activity) {
    return stt.task.tasks.find(item => isSameId(item, activity));
  }
  if ('specific' in activity) {
    return stt.goal.goals.find(item => isSameId(item, activity));
  }
  return null;
};

export interface Action<T = any> {
  type: string;
  payload: T;
}
export function* updateWidget(): SagaIterator<void> {
  try {
    const user = yield select(state => state.user);
    if (!user.premium) return;
  } catch (error) {
    //
    //
  }
}
export function* checkRewardActivity(
  activity: Habit | Task,
): SagaIterator<void> {
  const userId = yield select(stt => stt.user.id);
  const params = {
    user_id: userId as number,
    date: getDateToday(),
    uid: uid(),
    data: {activity: {...activity, dones: undefined}},
  };
  const totalDone = activity.dones?.filter(i => i.done).length + 1;
  if (totalDone === 30) {
    yield put(
      RewardActions.addRewardRequest({
        ...params,
        key: 'done_activity_30',
      }),
    );
  } else if (totalDone === 100) {
    yield put(
      RewardActions.addRewardRequest({
        ...params,
        key: 'done_activity_100',
      }),
    );
  } else if (totalDone === 500) {
    yield put(
      RewardActions.addRewardRequest({
        ...params,
        key: 'done_activity_500',
      }),
    );
  }
  if ('routine' in activity) {
    const {sequencesDone} = getStatisticActivity(activity);
    const sequenceDone = (sequencesDone[0] || 0) + 1;

    const data = {
      user_id: userId as number,
      date: getDateToday(),
      uid: uid(),
      data: {activity: {...activity, dones: undefined}},
    };
    if (sequenceDone === 3)
      yield put(
        RewardActions.addRewardRequest({
          ...data,
          key: 'sequence_habit_3',
        }),
      );
    if (sequenceDone === 7) {
      yield put(
        RewardActions.addRewardRequest({
          ...data,
          key: 'sequence_habit_7',
        }),
      );
    }
    if (sequenceDone === 30) {
      yield put(
        RewardActions.addRewardRequest({
          ...data,
          key: 'sequence_habit_30',
        }),
      );
    }
    if (sequenceDone === 100) {
      yield put(
        RewardActions.addRewardRequest({
          ...data,
          key: 'sequence_habit_100',
        }),
      );
    }
  }
}

type CompareId = {
  id?: number;
  uid?: string;
};
export const isSameId = (
  activityA: CompareId,
  activityB: CompareId,
): boolean => {
  if (activityA?.id && activityB?.id) {
    return activityA.id === activityB.id;
  }
  if (activityA?.uid && activityB?.uid) {
    return activityA.uid === activityB.uid;
  }
  return false;
};

export const check = (
  activity: HabitModel | TaskModel,
  date: string,
): HabitModel | TaskModel => {
  const day = activity?.dones?.find(i => i.date === date);
  const amount = 'goal' in activity ? activity?.goal?.amount : null;
  const timer =
    'startHour' in activity
      ? day?.timer
        ? day?.timer || null
        : getDefaultTimer(activity?.startHour, activity?.endHour) ||
          day?.timer ||
          null
      : day?.timer || null;

  return {
    ...activity,
    dones: day
      ? activity.dones.map(i =>
          i.date === date ? {...i, done: true, amount, timer} : i,
        )
      : [
          ...activity?.dones,
          {date, done: true, note: null, deleted: false, amount, timer},
        ],
  };
};

export const unCheck = (
  activity: HabitModel | TaskModel,
  date: string,
): TaskModel | HabitModel => {
  const day = activity.dones.find(i => i.date === date);
  const amount = 0;
  const timer = null;
  return {
    ...activity,
    dones: day
      ? activity.dones.map(i =>
          i.date === date ? {...i, done: false, amount, timer} : i,
        )
      : [
          ...activity.dones,
          {date, done: false, note: null, deleted: false, amount, timer},
        ],
  };
};
type UpdateDate = {
  note?: string;
  done?: boolean;
  deleted?: boolean;
};
export const updateDateActivity = (
  activity: HabitModel | TaskModel,
  date: string,
  payload: UpdateDate,
): HabitModel | TaskModel => {
  const day = activity.dones.find(i => i.date === date);
  return {
    ...activity,
    dones: day
      ? activity.dones.map(i => (i.date === date ? {...i, ...payload} : i))
      : [
          ...activity.dones,
          {date, note: null, done: null, deleted: false, ...payload},
        ],
  };
};

export const getActivityInDate = (
  activity: HabitModel | TaskModel | Goal,
  date: string,
): ActivityInDate => {
  const def = {
    date,
    done: null,
    note: null,
    amount: null,
    deleted: false,
  };
  if ('dones' in activity) {
    return activity?.dones?.find(i => i.date === date) || def;
  }
  if ('dates' in activity) {
    return activity?.dates?.find(i => i.date === date) || def;
  }
  return def;
};

export const updateDateGoal = (
  activity: Goal,
  date: string,
  payload: UpdateDate,
): Goal => {
  const day = activity?.dates?.find(i => i.date === date);
  return {
    ...activity,
    dates: day
      ? activity?.dates?.map(i => (i.date === date ? {...i, ...payload} : i))
      : [
          ...(activity?.dates || []),
          {date, stopwatch: null, timer: null, note: null, ...payload},
        ],
  };
};

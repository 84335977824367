export const SOUNDS = [
  {
    label: 'Padrão',
    sound: 'default',
    premium: false,
  },
  {
    label: 'Alarme - Clock',
    sound: 'alarm',
    premium: true,
  },
  {
    label: 'Alarme - Morning Clock',
    sound: 'morningclockalarm',
    premium: true,
  },
  {
    label: 'Notificação - Soft',
    sound: 'notification',
    premium: true,
  },
  {
    label: 'Notificação - Positive',
    sound: 'positivenotification',
    premium: true,
  },
  {
    label: 'Notificação - Bell',
    sound: 'bellnotification',
    premium: true,
  },
];


export const BACKGROUND_SOUNDS = [
  {
    title: 'Slow Jazz Coffee',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FSlow%20Jazz%20Coffee.mp3?alt=media&token=efa88f37-2091-402b-894d-d6b39ecfe4d7',
  },
  {
    title: 'Relaxing Chill Lofi',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FRelaxing%20Chill%20Lofi.mp3?alt=media&token=175de937-95b5-46e0-b27c-b44c6a2b5a65',
  },
  {
    title: 'Jazz Piano',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FJazz%20Piano.mp3?alt=media&token=e4716f73-3947-4953-a54d-3e2bf238c95a',
  },
  {
    title: 'Blushes Lofi',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FBlushes%20%5BLo-fi%5D.mp3?alt=media&token=72d74892-8c17-460c-ba31-94d5ac0912a6',
  },
  {
    title: 'Tic Tac',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FTic%20Tac.MP3?alt=media&token=1257ac42-2fcf-4179-9bdd-21b48eecb523',
  },
  {
    title: 'Nature Noise',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FNature%20Noise.MP3?alt=media&token=520bd600-6c50-438a-907f-5fe89943f0aa',
  },
  {
    title: 'Brown Noise',
    url: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/songs%2FBrown%20Noise.MP3?alt=media&token=10b12ac6-474b-461a-8854-6615788407a3',
  },
];
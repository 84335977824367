import React, { useMemo } from "react";
import { Drawer } from "antd";
import Task from "../../forms/Task";
import Habit from "../../forms/Habit";
import Goal from "../../forms/Goal";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../forms/Header";
import Details from "../Details";
import { useTheme } from "styled-components";
import { TextSection } from "@/styles/global";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { DrawerActions } from "@/store/modules";
import Timer from "@/pages/Timer";
import { Row } from "../atoms";
import { getDateToday } from "@/utils/date";
import { Button } from "../molecules";
import { ActivityService } from "@/services";
import Category from "@/forms/Category";

export default function DrawerRight() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { visible, action, payload } = useSelector((state) => state.drawer);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(DrawerActions.closeDrawer());
  };

  const activity = useMemo(
    () => payload?.activity || null,
    [payload?.activity]
  );

  const getType = () => {
    if (!activity) return null;
    if (activity?.title && activity?.date) return "task";
    if (activity?.routine && activity?.date) return "habit";
    if (activity?.specific) return "goal";
    return null
  };

  const activityDate = useMemo(() => {
    if (!getType()) return null;
    if (getType() === "goal")
      return (
        activity?.dates?.find((i) => i.date === getDateToday()) || {
          note: null,
          date: getDateToday(),
        }
      );
    if (getType() === "task")
      return (
        activity?.dones.find((i) => i.date === activity.date) || {
          note: null,
          date: activity.date,
          deleted: false,
          done: false,
        }
      );
    if (getType() === "habit")
      return (
        activity?.dones.find((i) => i.date === activity.date) || {
          note: null,
          date: activity.date,
          deleted: false,
          done: false,
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  function handleDelete() {
    dispatch(DrawerActions.closeDrawer());
    ActivityService.handleDelete(activity);
  }

  function handleEdit() {
    dispatch(
      DrawerActions.setDrawer({
        action: `EDIT_${getType().toUpperCase()}`,
        payload: { activity },
      })
    );
  }
  function handleDuplicate() {
    dispatch(
      DrawerActions.setDrawer({
        action: `ADD_${getType().toUpperCase()}`,
        payload: { activity:{...activity, id:undefined, uid:undefined} },
      })
    );
  }

  const drawer = useMemo(() => {
    switch (action) {
      case "ADD_HABIT":
        return {
          header: <Header />,
          component: <Habit habit={payload?.activity} />,
        };
      case "ADD_GOAL":
        return {
          header: <Header />,
          component: <Goal goal={payload?.activity} />,
        };
      case "ADD_TASK":
        return {
          header: <Header />,
          component: <Task task={payload?.activity} />,
        };
      case "ADD_CATEGORY":
        return {
          title: t(payload?.category ? 'edit': 'addCategory').toUpperCase(),
          component: <Category category={payload?.category} />,
        };
      case "EDIT_HABIT":
        return {
          title: t("habit:editHabit").toUpperCase(),
          component: <Habit habit={payload.activity} isEditing />,
        };
      case "EDIT_GOAL":
        return {
          title: t("goal:editGoal").toUpperCase(),
          component: <Goal goal={payload.activity} isEditing />,
        };
      case "EDIT_TASK":
        return {
          title: t("task:editTask").toUpperCase(),
          component: <Task task={payload.activity} isEditing />,
        };
      case "DETAILS_TASK":
        return {
          title: (
            <Row alignCenter>
              <TextSection color={theme.colors.onBackground}>
                {payload.activity.title}
              </TextSection>
              <div style={{ display: "flex", flex: 1 }} />
              <Button
                title={t("duplicate")}
                onPress={handleDuplicate}
                backgroundColor={theme.colors.primary}
                borderRadius={4}
                width={80}
                fontSize={14}
                marginLeft={10}
              />
              <Button
                title={t("edit")}
                onPress={handleEdit}
                backgroundColor={theme.colors.primary}
                borderRadius={4}
                width={80}
                fontSize={14}
                marginRight={10}
                marginLeft={10}
              />
              <Button
                title={t("delete")}
                onPress={handleDelete}
                backgroundColor={theme.colors.red}
                borderRadius={4}
                width={80}
                fontSize={14}
              />
            </Row>
          ),
          component: (
            <Details
              activity={activity}
              type={getType()}
              activityDate={activityDate}
            />
          ),
          height: "100%",
        };
      case "DETAILS_HABIT":
        return {
          title: (
            <Row alignCenter>
              <TextSection color={theme.colors.onBackground}>
                {payload.activity.routine}
              </TextSection>
              <div style={{ display: "flex", flex: 1 }} />
              <Button
                title={t("duplicate")}
                onPress={handleDuplicate}
                backgroundColor={theme.colors.primary}
                borderRadius={4}
                width={80}
                fontSize={14}
                marginLeft={10}
              />
              <Button
                title={t("edit")}
                onPress={handleEdit}
                backgroundColor={theme.colors.primary}
                borderRadius={4}
                width={100}
                fontSize={15}
                marginRight={10}
                marginLeft={10}
              />
              <Button
                title={t("delete")}
                onPress={handleDelete}
                backgroundColor={theme.colors.red}
                borderRadius={4}
                width={100}
                fontSize={15}
              />
            </Row>
          ),
          component: (
            <Details
              activity={activity}
              type={getType()}
              activityDate={activityDate}
            />
          ),
          height: "100%",
          // closable:true,
          // placement:'bottom'
        };
      case "DETAILS_GOAL":
        return {
          title: (
            <Row alignCenter>
              <TextSection color={theme.colors.onBackground}>
                {payload.activity.specific}
              </TextSection>
              <div style={{ display: "flex", flex: 1 }} />
              <Button
                title={t("duplicate")}
                onPress={handleDuplicate}
                backgroundColor={theme.colors.primary}
                borderRadius={4}
                width={80}
                fontSize={14}
                marginLeft={10}
              />
              <Button
                title={t("edit")}
                onPress={handleEdit}
                backgroundColor={theme.colors.primary}
                borderRadius={4}
                width={100}
                fontSize={15}
                marginRight={10}
                marginLeft={10}
              />
              <Button
                title={t("delete")}
                onPress={handleDelete}
                backgroundColor={theme.colors.red}
                borderRadius={4}
                width={100}
                fontSize={15}
              />
            </Row>
          ),
          component: (
            <Details
              activity={activity}
              type={getType()}
              activityDate={activityDate}
            />
          ),
          height: "100%",
          // closable:true,
          // placement:'bottom',
        };
      case "TIMER":
        return {
          // title:TIMER,
          component: (
            <Timer activity={payload.activity} initialTab={payload.tab} />
          ),
          height: "100%",
          // closable:true,
          // placement:'bottom',
        };
      default:
        return { header: null, component: null };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, payload?.activity]);

  return (
    <Drawer
      title={
        typeof drawer.title === "string" ? (
          <TextSection center color={theme.colors.onBackground}>
            {drawer?.title}
          </TextSection>
        ) : (
          drawer.title
        )
      }
      placement={drawer?.placement || "right"}
      closeIcon={
        drawer?.closable ? (
          <MdClose
            size={30}
            style={{ color: theme.colors.onBackground }}
            color={theme.colors.onBackground}
          />
        ) : null
      }
      closable={drawer?.closable || false}
      onClose={onClose}
      open={visible}
      width={drawer?.width || 720}
      height={drawer?.height}
      className="default-border"
      drawerStyle={{
        background: theme.colors.background,
        borderColor: theme.colors.surface,
      }}
      headerStyle={{
        background: theme.colors.background,
        borderColor: theme.colors.surface,
      }}
    >
      {drawer?.header}
      <div style={{ marginTop: 20 }}>{drawer?.component}</div>
    </Drawer>
  );
}

import React from 'react';
import { ButtonContainer } from './styles';
import Loading from '../Loading';

const Button = ({title,onClick,loading,marginTop,...rest}) => {
  return <ButtonContainer onClick={onClick} style={{marginTop}} {...rest}>
    {loading ? <Loading /> : title}
    </ButtonContainer>;
}

export default Button;
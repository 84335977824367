import styled from 'styled-components';
import { colors } from '../../styles';

export const ButtonContainer = styled.button`
  padding: 12px 20px;
  height:35px;
  width: 100%;
  border-radius: 6px;
  background: ${colors.degrade};
  color:white;
  margin: 10 0px;
  border:none;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.3);
  width: 80%;
  display:flex;
  align-items:center;
  justify-content:center;
  &:hover{
    cursor:pointer;
    opacity:0.8;
  }
`;

import React, {useState} from 'react';

import {useTheme} from 'styled-components';

import {ButtonTransparent, Row, TextBase, ViewBase} from '@/components/atoms';
import {
  addDays,
  addMonths,
  formatDate,
  formatDateToServer,
  getDateToday,
  isSameMonth,
  startOfMonth,
  subDays,
  week,
} from '@/utils/date';
import {useTranslation} from 'react-i18next';
import {metrics} from '@/styles';
import {MdArrowBackIosNew, MdArrowForwardIos} from 'react-icons/md';

type Day = {
  date: string;
  deleted?: boolean;
  done?: boolean;
  amount?: number;
};

interface Props {
  dates: Day[];
  date?: string;
  targetAmount: number;
  minDate: string;
  maxDate: string;
  isValidDate: (date: string) => boolean;
  onPressDate: (date: string) => void;
}

const Month: React.FC<Props> = ({
  date = getDateToday(),
  onPressDate,
  dates,
  targetAmount,
  minDate,
  maxDate,
  isValidDate,
}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [lowerDate, setLowerDate] = useState(startOfMonth(date));
  const indexStart = lowerDate.getDay();
  const month = Array.from({length: 6})
    .map((w, indexW) => {
      return Array.from({length: 7}).map((d, indexD) => {
        const index = indexW * 7 + indexD;
        if (index >= indexStart) {
          const r = formatDateToServer(addDays(lowerDate, index - indexStart));
          if (isSameMonth(lowerDate, r))
            return dates.find(i => i.date === r) || {date: r};
          return null;
        }
        return null;
      });
    })
    .filter(i => i.some(Boolean));

  const onPrev = () => {
    setLowerDate(startOfMonth(subDays(lowerDate, 1)));
  };
  const onNext = () => {
    setLowerDate(startOfMonth(addMonths(lowerDate, 1)));
  };

  const isBetweenPeriod = (dateComp: string) =>
    minDate <= dateComp && dateComp <= maxDate && isValidDate(dateComp);

  const getColorByRate = (score: number) => {
    if (score >= 90) return '#1eaf0e';
    if (score >= 75) return '#59bd44';
    if (score >= 65) return '#a1d88f';
    if (score >= 50) return '#ffd700';
    if (score >= 40) return '#d06f55';
    if (score >= 20) return '#bd4a2f';
    return '#a81c07';
  };

  return (
    <ViewBase
      marginTop={metrics.fontSize(6)}
      paddingTop={metrics.fontSize(6)}
      backgroundColor={theme.colors.surface}
      borderRadius={metrics.fontSize(12)}>
      <Row spaceBetween padding={metrics.space.small}>
        <MdArrowBackIosNew
          color={theme.colors.onBackground}
          size={metrics.fontSize(18)}
          onClick={onPrev}
        />
        <TextBase small>
          {formatDate(lowerDate, 'MMMM yyyy').toUpperCase()}
        </TextBase>
        <MdArrowForwardIos
          color={theme.colors.onBackground}
          size={metrics.fontSize(18)}
          onClick={onNext}
        />
      </Row>
      <Row>
        {week.map(i => (
          <ViewBase flex={1} key={i}>
            <TextBase size={metrics.fontSize(10)} center>
              {t(i)}
            </TextBase>
          </ViewBase>
        ))}
      </Row>
      {month.map((w, index) => (
        <Row key={String(index)}>
          {w.map((d, indexD) => {
            const rate = d
              ? Math.round(((d?.amount || 0) / targetAmount) * 100)
              : 0;
            return (
              <ButtonTransparent
                onClick={() => {
                  const disabled =
                    !d || d?.deleted || !isBetweenPeriod(d?.date);
                  if (!disabled) onPressDate(d?.date);
                }}
                key={String(indexD) + String(d?.date) + String(index)}
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  alignItems: 'center',
                  height: metrics.fontSize(45),
                  padding: 2,
                  justifyContent: 'center',
                }}>
                {d ? (
                  <ViewBase
                    center
                    {...(targetAmount || !isBetweenPeriod(d?.date)
                      ? {}
                      : {
                          height: metrics.fontSize(32),
                          width: metrics.fontSize(50),
                          backgroundColor: d?.done
                            ? theme.colors.green
                            : theme.colors.red,
                          borderRadius: 8,
                        })}>
                    <TextBase
                      small
                      color={
                        !targetAmount && isBetweenPeriod(d?.date)
                          ? theme.colors.white
                          : theme.colors.text
                      }>
                      {formatDate(d.date, 'dd')}
                    </TextBase>
                  </ViewBase>
                ) : (
                  <TextBase small />
                )}
                {d &&
                  targetAmount &&
                  isBetweenPeriod(d?.date) &&
                  !d?.deleted && (
                    <ViewBase
                      backgroundColor={getColorByRate(rate)}
                      borderRadius={metrics.fontSize(8)}>
                      <TextBase smaller bold color="white" padding={1}>
                        {rate}%
                      </TextBase>
                    </ViewBase>
                  )}
              </ButtonTransparent>
            );
          })}
        </Row>
      ))}
    </ViewBase>
  );
};

Month.defaultProps = {
  date: getDateToday(),
};

export default Month;

import styled from 'styled-components';
import logo from '../../assets/images/logo.png';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
  height: 100vh;
  background: ${({theme})=>theme.colors.primary};
`;
export const TextError = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${({theme})=>theme.colors.red};
`
export const ContainerForm = styled.div`
  background: ${({theme})=>theme.colors.background};
  /* box-shadow: 1px 1px 50px 5px ${({theme})=>theme.colors.shadow}; */
  padding:40px;
  border-radius: 5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 570px;
  @media(max-width:500px){
    width: 70%;
  }
`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Logo = styled.img.attrs({src:logo})`
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
`;
type LanguageProps = {
  active:boolean;
}
export const Language = styled.button<LanguageProps>`
  cursor: pointer;
  background: ${({theme,active})=> active ? theme.colors.primary : theme.colors.surface};
  border:none;
  margin-left: 10px;
  font-size:17px;
`;

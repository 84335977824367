import { SagaIterator } from "redux-saga";
import { getLists } from "@/services/api/activity";
import { call, put } from "redux-saga/effects";
import { TaskActions } from "../task";
import { ListActions } from "./duck";
import { mapTask } from "../calendar";


export function* getListsSaga(): SagaIterator<void> {
  const data = yield call(getLists);
  yield put(ListActions.getListsSuccess());
  yield put(TaskActions.getTasksSuccess(data.map(mapTask)));
}
import {createStore, compose, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistStore, persistReducer} from 'redux-persist';
import reducers from './modules/rootReducers';
import sagas from './modules/rootSagas';
import storage from 'redux-persist/lib/storage';

const middlewares = [];
const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({sagaMonitor});

middlewares.push(sagaMiddleware);

const composer = process.env.NODE_ENV === 'development'
  ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
  : compose(applyMiddleware(...middlewares));

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'statistic',
    'user',
    'app',
    'queue',
    'ranking',
    'category',
    'timer',
    'list'
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, composer);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export {store, persistor};

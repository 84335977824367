import React, {forwardRef} from 'react';
import {ContentCard} from './styles';
import {ActivityService} from '@/services';
import {arrangeBigString} from '@/utils/string';
import Actions from '../Actions';
import {CSSProperties, useTheme} from 'styled-components';
import {Row, TextBase} from '@/components/atoms';
import {useSelector} from 'react-redux';
import {selectorCardActivityColorBy, selectorCategories} from '@/store/modules';
import {getBorderColor} from '@/utils/activity';

type Props = {
  activity: any;
  height: number;
  style: CSSProperties;
  className?: string;
  disabled?: boolean;
};
const CardAgenda = (
  {activity, height, style, disabled, ...rest}: Props,
  ref: any,
) => {
  const theme = useTheme();
  const categories = useSelector(selectorCategories);
  const cardActivityColorBy = useSelector(selectorCardActivityColorBy);

  return (
    <ContentCard
      ref={ref}
      borderColor={getBorderColor(activity, cardActivityColorBy, categories)}
      onClick={e => {
        e.stopPropagation();
        if (!disabled) {
          ActivityService.onDetails(activity);
        }
      }}
      {...rest}
      style={{
        height: height,
        ...style,
      }}>
      <Row alignCenter spaceBetween>
        <TextBase size={height < 20 ? 8 : 11} color={theme.colors.text}>
          {activity.done && '✅ '}
          {arrangeBigString(activity.title || activity.routine, 20, '.')}
        </TextBase>
        <Actions activity={activity} />
      </Row>
    </ContentCard>
  );
};

export default forwardRef(CardAgenda);

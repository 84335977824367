import {
  CalendarState,
  CurrentDay,
  State,
  HabitModel,
  TaskModel,
  Goal,
} from '@/store/modules';

export const selectorCalendar = (state: State): CalendarState => state.calendar;
export const selectorCurrentDay = (state: State): CurrentDay =>
  state.calendar.currentDay;
export const selectorAllActivities = (
  state: State,
): {habits: HabitModel[]; tasks: TaskModel[]; goals: Goal[]} => {
  const {habits} = state.habit;
  const {goals} = state.goal;
  const {tasks} = state.task;
  return {habits, goals, tasks};
};

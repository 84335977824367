import {
  app,
  AppState,
  calendar,
  CalendarState,
  category,
  CategoryState,
  TimerState,
  filter,
  FilterState,
  GoalState,
  HabitState,
  modal,
  ModalState,
  purchase,
  PurchaseState,
  queue,
  QueueState,
  RankingState,
  reward,
  ReportState,
  RewardState,
  DrawerState,
  session,
  SessionState,
  StatisticState,
  TaskState,
  user,
  UserState,
  task,
  habit,
  goal,
  statistic,
  report,
  ranking,
  drawer,
  timer,
  list
} from '@/store/modules';
import {combineReducers} from 'redux';
import { ListState } from './list';

export interface State {
  app: AppState;
  session: SessionState;
  queue: QueueState;
  modal: ModalState;
  user: UserState;
  calendar: CalendarState;
  task: TaskState;
  habit: HabitState;
  goal: GoalState;
  statistic: StatisticState;
  report: ReportState;
  ranking: RankingState;
  category: CategoryState;
  purchase: PurchaseState;
  filter: FilterState;
  reward: RewardState;
  drawer:DrawerState
  timer: TimerState
  list: ListState
}

export default combineReducers({
  app,
  session,
  queue,
  modal,
  user,
  calendar,
  task,
  habit,
  goal,
  statistic,
  report,
  ranking,
  category,
  purchase,
  filter,
  reward,
  drawer,
  timer,
  list
});

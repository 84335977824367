import {t} from '@/services/Locale';
import {ERROR} from '../constants';

interface IError {
  handle(err: ErrorRequest): ErrorResponse;
}
export type ErrorRequest = {
  ignoreError: boolean;
  status: number;
  code: string;
  message: string;
  response: any;
  title: string;
};
export interface ErrorResponse {
  status: number;
  code: string;
  title: string;
  message: string;
  ignoreError: boolean;
}

class Error implements IError {
  handle(err: ErrorRequest): ErrorResponse {
    const error = {...err};
    error.ignoreError = error.ignoreError || false;
    error.status = error.status || error.response?.status;
    error.code = error.response?.data?.code;
    const tokenInvalid = error.code === ERROR.TOKEN_INVALID;
    const versionUnavailable = error.code === ERROR.APP_VERSION_UNAVAILABLE;

    const notHaveConnection =
      !!error.message && error.message === 'Network Error';
    const handledByServer =
      error.response?.data?.message || error.response?.data?.title;

    if (tokenInvalid) {
      error.title = t('error:tokenInvalid');
      error.message = t('error:tokenInvalidMsg');
      error.ignoreError = false;
    }
    if (versionUnavailable) {
      error.title = t('error:versionUnavailable');
      error.message = t('error:versionUnavailableMsg');
      error.ignoreError = false;
    }
    if (notHaveConnection) {
      error.title = t('error:noConnection');
      error.message = t('error:noConnectionMsg');
    }
    if (handledByServer) {
      error.title =
        error?.title || error?.response?.data?.title || t('error:unknown');
      error.message = error.response?.data?.message;
    }
    if (error.code === ERROR.LOGIN_ERROR) {
      error.title = t('error:loginError');
      error.message = t('error:loginErrorMsg');
    }
    if (error.code === ERROR.VALIDATION_ERROR) {
      error.title = t('error:validationError');
      error.message = t('error:validationErrorMsg');
    }
    if (error.code === ERROR.USER_ALREADY_EXIST) {
      error.title = t('error:userAlreadyExists');
      error.message = t('error:userAlreadyExistsMsg');
    }
    if (error.code === ERROR.USER_NOT_EXIST) {
      error.title = t('error:userNotExist');
      error.message = t('error:userNotExistMsg');
    }
    if (error.code === ERROR.NOT_FOUND) {
      error.title = t('error:notFound');
      error.message = t('error:notFoundMsg');
    }
    return error;
  }

}
// eslint-disable-next-line
export default new Error();

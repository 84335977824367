import React from 'react';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import {ColumnContainer, Title, TaskList, ButtonAddTask} from './styles';
import Task from './Task';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DrawerActions} from '@/store/modules';

const Column = ({column, tasks, index}: any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const onAddTask = () => {
    dispatch(
      DrawerActions.setDrawer({
        action: 'ADD_TASK',
        payload: {activity: {categories: [column.uid], hasDate: false}},
      }),
    );
  };
  return (
    <Draggable draggableId={String(column.uid)} index={index}>
      {provided => (
        <ColumnContainer {...provided.draggableProps} ref={provided.innerRef}>
          <Title {...provided.dragHandleProps}>{column.name}</Title>
          <Droppable droppableId={String(column.uid)} type="task">
            {provided => (
              <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                {tasks.map((task: any, index: number) => (
                  <Task
                    column={column}
                    key={String(task.id)}
                    task={task}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
          <ButtonAddTask onClick={onAddTask}>{t('task:addTask')}</ButtonAddTask>
        </ColumnContainer>
      )}
    </Draggable>
  );
};

export default Column;

import {Modal as ModalAnt, Button} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {
  ModalActions,
  ModalState,
  RewardActions,
  selectorStatistic,
  selectorUser,
  StatisticActions,
} from '@/store/modules';
import {State} from '@/store/modules/rootReducers';
import ImageShare from '../ImageShare';
import {Analytics, Gamification} from '@/services';
import {useEffect} from 'react';
import {LEVELS} from '@/constants';
import {uid} from '@/utils/uid';
import {getDateToday} from '@/utils/date';

const ModalGlobal = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    modalVisible,
    title,
    description,
    children,
    actions,
    mode,
    type,
    data,
    img,
  } = useSelector<State, ModalState>(state => state.modal);
  const {level, score} = useSelector(selectorStatistic);
  const user = useSelector(selectorUser);

  function closeModal() {
    dispatch(ModalActions.setModalVisible(false));
  }

  useEffect(() => {
    if (!user.token) return;
    const currentLevel = Gamification.getLevelUser(score);
    const isLastLevel = level?.text === LEVELS[LEVELS.length - 1].text;
    if (!isLastLevel && score >= level.scoreTop) {
      const [nextLevel] = Gamification.getLevelUser(score).textIndex.split(
        '-',
      ) as string[];
      dispatch(
        RewardActions.addRewardRequest({
          uid: uid(),
          key: `level_${nextLevel}`,
          date: getDateToday(),
          user_id: user.id,
          data: {
            currentLevel,
            score,
          },
        }),
      );
      dispatch(StatisticActions.setLevel(currentLevel));
      Analytics.setUserProperty(Analytics.events.LEVEL, currentLevel.textIndex);
    }
    if (score < level.scoreDown) {
      dispatch(StatisticActions.setLevel(currentLevel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level.scoreDown, level.scoreTop, score]);

  return (
    <ModalAnt
      open={modalVisible}
      title={title}
      closable
      footer={
        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
          {actions?.map(i => (
            <Button
              key={i.key}
              type="primary"
              onClick={() => {
                i.onPress();
                closeModal();
              }}
              style={{margin: 0, marginTop: 6}}>
              {i.text}
            </Button>
          ))}
        </div>
      }
      onOk={closeModal}
      onCancel={closeModal}>
      {mode === 'share' ? (
        <ImageShare type={type as string} data={data} />
      ) : (
        <>
          {description && <p>{description}</p>}
          {children}
          {img && <img {...img} alt="reward" />}
        </>
      )}
    </ModalAnt>
  );
};
export default ModalGlobal;

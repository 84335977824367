import React, { useEffect, useState } from 'react';
import {Label,InputContainer, Row, TextValue} from '../../styles/global';
import { DatePicker as DatePickerAnt} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { INFINITY_DATE } from '@/constants';
import { Required } from '../atoms';
import themes from '@/styles/themes';

const DatePicker = ({placeholder,value,onChangeValue,label,required, theme:_theme}) => {
  const defaultTheme = useTheme()
  const theme = _theme ? themes[_theme] : defaultTheme

  const {t}= useTranslation()
  const formatView = 'DD/MM/yyyy'
  const formatServer = 'yyyy-MM-DD'
  const [infinity,setInfinity]=useState(value === INFINITY_DATE);
  useEffect(()=>{
    setInfinity(value === INFINITY_DATE)
  },[value])
  return (
    <InputContainer>
      <Label color={theme.colors.textLabel} >{label} {required && <Required>*</Required>}</Label>
      {infinity ? (
        <div style={{border: '1px solid '+theme.colors.surface,padding:'5px 10px'}}>
          <Row spaceBetween>
            <TextValue>{t('never')}</TextValue>
            <Label style={{cursor:'pointer'}} onClick={()=> setInfinity(null)}><b>{t('change')}</b></Label>
          </Row>
        </div>
      ):(
        <DatePickerAnt
          picker={'date'}
          value={value && value !== INFINITY_DATE? moment(value,formatServer) : undefined}
          format={formatView}
          placeholder={placeholder} 
          onChange={value=> onChangeValue(value.format(formatServer))}
          className={theme.title === 'dark' ? 'input-dark': 'input-light'}
          style={{background: theme.colors.background, borderColor:theme.colors.surface, color: theme.colors.onBackground}}
        />
      )
    }
    </InputContainer>
  );
}

export default DatePicker;
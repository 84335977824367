import {api} from '@/services';

interface AddRewardApi {
  uid: string;
  user_id: number;
  type: string;
  description: string;
  date: string;
  action: string;
  key: string;
  data: any;
  createdAt: string;
}
interface RewardApi extends AddRewardApi {
  id: number;
  updateAt: string;
}

export async function listReward(): Promise<RewardApi[]> {
  const {data} = await api.get('rewards');
  return data;
}

export async function syncReward(
  rewards: any[],
): Promise<RewardApi[]> {
  const {data} = await api.post('rewards/sync', {rewards});
  return data;
}

export async function addReward(reward: AddRewardApi): Promise<RewardApi> {
  const {data} = await api.post('rewards', reward);
  return data;
}

export async function updateReward(reward: RewardApi): Promise<RewardApi> {
  const id = reward?.uid;
  const {data} = await api.put(`rewards/${id}`, reward);
  return data;
}

export async function deleteReward(reward: RewardApi): Promise<RewardApi> {
  const id = reward?.uid;
  const {data} = await api.delete(`rewards/${id}`, {data: reward});
  return data;
}

import styled from 'styled-components';

export const ContentWeek = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  /* justify-content: space-between; */
`;
export const ContainerWeek = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ContentDayWeek = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 15px;
  padding: 2px 0;
`;
export const TextWeek = styled.span`
  font-size: 15px;
`;

import { INFINITY_DATE, JUST_TODAY, PERIOD } from "@/constants/index";
import { ROUTES } from "@/constants/routes";
import { store } from "@/store";
import {
  Activity,
  CheckList,
  DrawerActions,
  Goal,
  GoalActions,
  Habit,
  HabitActions,
  HabitModel,
  ModalActions,
  Task,
  TaskActions,
  TaskModel,
} from "@/store/modules";
import { getActivityInDate } from "@/store/modules/utils";
import {
  formatDateToServer,
  formatDateToShow,
  getDateToday,
  subDays,
} from "@/utils/date";
import { t } from "./Locale";
import Navigation from "./Navigation";
import { isSameId } from "@/utils/uid";
type OptionsDelete = {
  deleteNexts?: boolean;
  deleteOnlyDate?: boolean;
  deleteAll?: boolean;
};
class ActivityService {
  isHabit(activity: Activity | HabitModel | TaskModel) {
    return "routine" in activity;
  }

  isGoal(activity: Activity | HabitModel | TaskModel) {
    return "specific" in activity;
  }

  isTask(activity: Activity | HabitModel | TaskModel) {
    return "title" in activity;
  }

  done(activity: any) {
    const data = {
      ...activity,
      done: !activity.done,
    };
    if (this.isTask(activity)) {
      store.dispatch(TaskActions.checkTaskRequest(data as Task));
      if (activity?.reschedule) {
        if (data.done) {
          store.dispatch(
            TaskActions.updateTaskRequest({
              ...data,
              endDate: data.date,
            } as Task)
          );
        } else {
          store.dispatch(
            TaskActions.updateTaskRequest({
              ...data,
              endDate: INFINITY_DATE,
            } as Task)
          );
        }
      }
    }
    if (this.isHabit(activity)) {
      if (this.isHabit(activity)) {
        const body = {
          ...data,
          amount: data?.goal?.amount
            ? data.done
              ? data?.goal?.amount
              : 0
            : null,
        };
        store.dispatch(HabitActions.checkHabitRequest(body));
      }
    }
    if (this.isGoal(activity)) {
      store.dispatch(GoalActions.checkGoalRequest(data as Goal));
    }
  }

  update(activity: any, updateOnlyDate: boolean = false) {
    if (this.isTask(activity)) {
      if(updateOnlyDate){
        this.delete(activity, {deleteOnlyDate:true})
        this.create({
            ...activity,
            period: PERIOD.JUST_TODAY,
            startDate: activity.date,
            endDate: activity.date,
        })
      }else{
        store.dispatch(TaskActions.updateTaskRequest(activity as Task));
      }
    }
    if (this.isHabit(activity)) {
      store.dispatch(HabitActions.updateHabitRequest(activity as Habit));
    }
    if (this.isGoal(activity)) {
      store.dispatch(GoalActions.updateGoalRequest(activity as Goal));
    }
  }

  delete(activity: Activity, options: OptionsDelete, onDelete?: () => void) {
    if (onDelete) onDelete();
    setTimeout(() => {
      if (options.deleteNexts) {
        const data = {
          ...activity,
          endDate: formatDateToServer(
            subDays(activity.date || getDateToday(), 1)
          ),
        };
        if (this.isHabit(activity)) {
          store.dispatch(HabitActions.updateHabitRequest(data as Habit));
        }
        if (this.isTask(activity)) {
          store.dispatch(TaskActions.updateTaskRequest(data as Task));
        }
      }
      if (options.deleteAll) {
        if (this.isHabit(activity)) {
          store.dispatch(HabitActions.deleteHabitRequest(activity as Habit));
        }
        if (this.isTask(activity)) {
          store.dispatch(TaskActions.deleteTaskRequest(activity as Task));
        }
        if (this.isGoal(activity)) {
          store.dispatch(GoalActions.deleteGoalRequest(activity as Goal));
        }
      }
      if (options.deleteOnlyDate) {
        if (this.isHabit(activity)) {
          store.dispatch(
            HabitActions.updateHabitDateRequest(activity as Habit, {
              date: (activity.date as string) || getDateToday(),
              deleted: true,
            })
          );
        }
        if (this.isTask(activity)) {
          store.dispatch(
            TaskActions.updateTaskDateRequest(activity as Task, {
              date: (activity.date as string) || getDateToday(),
              deleted: true,
            })
          );
        }
      }
    }, 500);
  }

  handleDelete(activity: any, onDelete?: () => void) {
    setTimeout(() => {
      const hadJustOneOcorrence =
        this.isGoal(activity) ||
        ("period" in activity && activity.period === JUST_TODAY);
      const dateShow = formatDateToShow(
        (activity.date as string) || getDateToday()
      );
      const params = hadJustOneOcorrence
        ? this.isGoal(activity)
          ? {
              title: `${`${t("delete")} ${t("goal")}`} ?`,
              description: t("task:deleteDesc"),
            }
          : {
              title: t("task:deleteTask"),
              description: t("task:deleteDesc"),
            }
        : this.isHabit(activity)
        ? {
            title: t("habit:deleteHabit"),
            description: t("habit:deleteHabitAlert", { date: dateShow }),
          }
        : {
            title: t("task:deleteTask"),
            description: t("task:deleteTaskAlert", { date: dateShow }),
          };
      store.dispatch(
        ModalActions.setModalContent({
          height: 550,
          mode: "alert",
          ...params,
          actions: hadJustOneOcorrence
            ? [
                { text: t("no"), onPress: () => {} },
                {
                  text: t("yes"),
                  onPress: () =>
                    this.delete(activity, { deleteAll: true }, onDelete),
                },
              ]
            : [
                {
                  text: t("back"),
                  onPress: () => {},
                },
                {
                  testID: `delete_only_date`,
                  text: t("onlyThis"),
                  onPress: () =>
                    this.delete(activity, { deleteOnlyDate: true }, onDelete),
                },
                {
                  testID: `delete_nexts`,
                  text: t("thisAndNext"),
                  onPress: () =>
                    this.delete(activity, { deleteNexts: true }, onDelete),
                },
                {
                  testID: `delete_all`,
                  text: t("allOcorrences"),
                  onPress: () =>
                    this.delete(activity, { deleteAll: true }, onDelete),
                },
              ],
        })
      );
    }, 300);
  }

  saveNote(activity: TaskModel | HabitModel, date: string, note: string) {
    const activityInDay = getActivityInDate(activity, date);
    if (this.isHabit(activity))
      store.dispatch(
        HabitActions.updateHabitDateRequest(activity as Habit, {
          ...activityInDay,
          note,
        })
      );
    if (this.isTask(activity))
      store.dispatch(
        TaskActions.updateTaskDateRequest(activity as Task, {
          ...activityInDay,
          note,
        })
      );
  }

  onDetails(activity: any) {
    if (this.isTask(activity)) {
      if(!activity?.lastSync){
        store.dispatch(TaskActions.getTaskRequest(activity.id))
      }
      store.dispatch(
        DrawerActions.setDrawer({
          action: "DETAILS_TASK",
          payload: { activity },
        })
      );
    }
    if (this.isHabit(activity)) {
      if(!activity?.lastSync){
        store.dispatch(HabitActions.getHabitRequest(activity.id))
      }

      store.dispatch(
        DrawerActions.setDrawer({
          action: "DETAILS_HABIT",
          payload: { activity },
        })
      );
    }
    if (this.isGoal(activity)) {
      store.dispatch(
        DrawerActions.setDrawer({
          action: "DETAILS_GOAL",
          payload: { activity },
        })
      );
    }
  }

  onUpdate(activity: Activity) {
    if (this.isTask(activity)) {
      Navigation.navigate(ROUTES.ADD_TASK, { task: activity });
    }
    if (this.isHabit(activity)) {
      Navigation.navigate(ROUTES.ADD_HABIT, { habit: activity });
    }
    if (this.isGoal(activity)) {
      Navigation.navigate(ROUTES.ADD_GOAL, { goal: activity });
    }
  }

  saveTimer(
    activity: (TaskModel | HabitModel | Goal) & { timer?: number },
    date: string,
    timer: number
  ) {
    if (this.isHabit(activity))
      store.dispatch(
        HabitActions.updateHabitDateRequest(activity as Habit, {
          date,
          timer: (activity?.timer || 0) + timer,
        })
      );
    if (this.isTask(activity))
      store.dispatch(
        TaskActions.updateTaskDateRequest(activity as Task, {
          date,
          timer: (activity?.timer || 0) + timer,
        })
      );
    if (this.isGoal(activity))
      store.dispatch(
        GoalActions.updateGoalDateRequest(activity as Goal, {
          date,
          timer: (activity?.timer || 0) + timer,
        })
      );
  }

  saveStopwatch(
    activity: (TaskModel | HabitModel | Goal) & { stopwatch?: number },
    date: string,
    stopwatch: number
  ) {
    if (this.isHabit(activity))
      store.dispatch(
        HabitActions.updateHabitDateRequest(activity as Habit, {
          date,
          stopwatch: (activity?.stopwatch || 0) + stopwatch,
        })
      );
    if (this.isTask(activity))
      store.dispatch(
        TaskActions.updateTaskDateRequest(activity as Task, {
          date,
          stopwatch: (activity?.stopwatch || 0) + stopwatch,
        })
      );
    if (this.isGoal(activity))
      store.dispatch(
        GoalActions.updateGoalDateRequest(activity as Goal, {
          date,
          stopwatch: (activity?.stopwatch || 0) + stopwatch,
        })
      );
  }
  updateInDate(
    activity: TaskModel | HabitModel | Goal,
    date: string,
    data: any
  ) {
    const body = {
      date,
      ...data,
    };
    if (this.isHabit(activity))
      store.dispatch(
        HabitActions.updateHabitDateRequest(activity as Habit, body)
      );
    if (this.isTask(activity))
      store.dispatch(TaskActions.updateTaskDateRequest(activity as Task, body));
    if (this.isGoal(activity))
      store.dispatch(GoalActions.updateGoalDateRequest(activity as Goal, body));
  }
  create(
    data: Task | Habit | Goal,
  ) {
    const { id, uid,...body} = data
    body.createdAt = getDateToday();
    body.updatedAt = undefined;
    if (this.isHabit(data))
      store.dispatch(
        HabitActions.addHabitRequest(body as any)
      );
    if (this.isTask(data))
      store.dispatch(TaskActions.addTaskRequest(body as any));
    if (this.isGoal(data))
      store.dispatch(GoalActions.addGoalRequest(body as any));
  }

  reschedule(task: any, originDate: string, destDate: string){
    const newTask = {
      ...task,
      checkList: task.checkListInDate || task.checkList,
      period: PERIOD.JUST_TODAY,
      startDate: destDate,
      endDate: destDate,
    };
    this.updateInDate(task, originDate, {
      date: originDate,
      deleted: true,
    });
    this.create(newTask);
  }
  formatTextToChecklist(text: string, checkList: CheckList[]) {
    function formatText(txt: string):string {
      if (checkList.some(i => i.text === txt)) {
        const regex = /\((\d+)\)$/;
        const match = regex.exec(txt);
        if (match && match[1]) {
          const n = parseInt(match[1]) || match[1];
          return formatText(txt.replace(regex, `(${(Number(n) + 1)})`));
        }
        return formatText(`${txt} (1)`);
      }
      return txt;
    }
    return formatText(text);
  }

  addNewItemCheckList(value: string, activity: any, checkList: CheckList[]) {
    const text = this.formatTextToChecklist(value, checkList);
    this.updateInDate(activity, activity.date, {
      checkList: [
        ...checkList.map((i) => ({ done: i.done, text: i.text })),
        {
          done: false,
          text,
        },
      ],
    });
  }

  deleteItemCheckList(item: CheckList, activity: any, checkList: CheckList[]) {
    const list = checkList.filter((i: any) => item.text !== i.text);
    this.updateCheckList(activity, list);
  }

  checkItemCheckList(item: CheckList, activity: any, checkList: CheckList[]) {
    const list = checkList.map((i: any) =>
      item.text === i.text
        ? { text: i.text, done: !i.done }
        : { text: i.text, done: i.done }
    );
    this.updateCheckList(activity, list);
    if (list.length > 0 && list.every((i) => i.done) && !activity.done) {
      setTimeout(() => {
        this.done(activity);
      }, 600);
    }
  }

  rescheduleItemCheckList(date: string, item: CheckList, activity: any, checkList: CheckList[]) {
    const stt = store.getState();
    const dates = activity.title ? stt.task.tasks.find(i=> isSameId( activity, i))?.dones || [] : stt.habit.habits.find(i=> isSameId( activity, i))?.dones || [];
    const inDate = dates.find((d) => d.date === date) as any;
    const checkListInDate = inDate?.checkList || activity.checkList || [];
    this.deleteItemCheckList(item, activity, checkList);
    setTimeout(() => {
      this.updateCheckList({...activity, date: date}, [
        ...checkListInDate,
        {text: item?.text, done: item?.done},
      ]);
    }, 1000);
  }

  updateCheckList(activity: any, list: CheckList[]) {
    if(this.isGoal(activity)){
      store.dispatch(
        GoalActions.updateGoalRequest({ ...activity, achievable: list })
      );
    } else if (this.isHabit(activity)) {
      this.updateInDate(activity, activity.date, { checkList: list });
    } else if (activity?.checkListInDate && activity.period !== JUST_TODAY) {
      this.updateInDate(activity, activity.date, { checkList: list });
    } else {
      store.dispatch(
        TaskActions.updateTaskRequest({ ...activity, checkList: list })
      );
    }
  }
}
// eslint-disable-next-line
export default new ActivityService();

import {Popover} from 'antd';
import {useTranslation} from 'react-i18next';
import {FiMoreVertical} from 'react-icons/fi';
import {useTheme} from 'styled-components';
import ContentActions from './ContentActions';

type Props = {
  activity: any;
};

export default function Actions({activity}: Props) {
  const {t} = useTranslation();
  const theme = useTheme();

  function handleClickMore(e: any) {
    e.stopPropagation();
  }

  return (
    <Popover
      placement="rightTop"
      title={
        <span>
          <b>{t('selectAction')}:</b>
        </span>
      }
      content={<ContentActions activity={activity} />}
      trigger="click">
      <FiMoreVertical
        style={{cursor: 'pointer'}}
        onClick={e => handleClickMore(e)}
        color={theme.colors.onBackground}
        size={12}
      />
    </Popover>
  );
}

import React, {useEffect, useRef, useState} from 'react';
import {Row, TextLabel, Title, ViewBase} from '@/components/atoms';
import {useTheme} from 'styled-components';
import {getTimeStringFromSecunds} from '@/utils/date';
import ActivityService from '@/services/ActivityService';
import Analytics from '@/services/Analytics';
import {
  selectorIsPremium,
  selectorStopwatch,
  TimerActions,
} from '@/store/modules';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Toast from '@/services/Toast';
import {ButtonContainer} from './styles';
import Circle from 'react-circle';
import {addSeconds, differenceInSeconds, getDateToday} from '@/utils/date';
import {isSameId} from '@/utils/uid';
import {Select} from '@/components/molecules';
import {BACKGROUND_SOUNDS} from '@/constants/sounds';

type Props = {
  activity: any;
  setActivity: any;
};
type StatusTimer = null | 'play' | 'pause' | 'finish';

const StopwatchScreen = ({activity, setActivity}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const audio = useRef<HTMLAudioElement | null>(null);
  const theme = useTheme();
  const premium = useSelector(selectorIsPremium);
  const stopwatchState = useSelector(selectorStopwatch);
  const interval = useRef<NodeJS.Timeout>();
  const [, setRender] = useState(Date.now());

  const stopwatch = stopwatchState?.startAt
    ? stopwatchState.status === 'pause' && stopwatchState?.pausedAt
      ? differenceInSeconds(stopwatchState?.pausedAt, stopwatchState.startAt)
      : Math.max(differenceInSeconds(new Date(), stopwatchState?.startAt), 0)
    : 0;

  const hasCurrentStopwatch = () =>
    stopwatchState?.activity && isSameId(stopwatchState?.activity, activity);

  const songs = [
    {
      label: t('none'),
      value: 'none',
    },
    ...BACKGROUND_SOUNDS.map(i => ({label: i.title, value: i.url})),
  ];
  const [sound, setSound] = useState(stopwatchState?.sound || null);

  const [status, setStatus] = useState<StatusTimer>(
    hasCurrentStopwatch()
      ? ((stopwatchState?.status || 'play') as StatusTimer)
      : null,
  );

  useEffect(() => {
    if (hasCurrentStopwatch() && stopwatchState.status === 'play') {
      onStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartTimer = () => {
    stopSound();
    dispatch(
      TimerActions.setStopwatch({
        activity,
        startAt: new Date().toISOString(),
        status: 'play',
        sound,
      }),
    );
  };

  const handleResumeTimer = () => {
    if (stopwatchState?.pausedAt && stopwatchState?.startAt) {
      const timePaused = differenceInSeconds(
        new Date(),
        stopwatchState?.pausedAt,
      );
      const startAt = addSeconds(stopwatchState?.startAt, timePaused);
      dispatch(
        TimerActions.setStopwatch({
          ...stopwatchState,
          startAt,
          status: 'play',
          pausedAt: undefined,
        }),
      );
    }
  };

  function onStart() {
    setStatus('play');
    interval.current = setInterval(() => setRender(Date.now()), 1000);
  }

  function onPause() {
    setStatus('pause');
    clearInterval(interval.current as unknown as number);
    interval.current = undefined;
    dispatch(
      TimerActions.setStopwatch({
        ...stopwatchState,
        status: 'pause',
        pausedAt: new Date().toISOString(),
      }),
    );
  }

  function onStop() {
    setStatus(null);
    clearInterval(interval.current as unknown as number);
    interval.current = undefined;
    dispatch(
      TimerActions.setStopwatch({
        activity: undefined,
        startAt: undefined,
        sound: undefined,
      }),
    );
  }

  function getDate() {
    if ('specific' in activity) return getDateToday();
    return activity.date;
  }

  function onSaveStopwatch() {
    setActivity({
      ...activity,
      stopwatch: (activity?.stopwatch || 0) + stopwatch,
    });
    ActivityService.saveStopwatch(activity, getDate(), stopwatch);
    onStop();
    Analytics.log(Analytics.events.finish_stopwatch);
  }

  function getTitleActivity() {
    if ('title' in activity) return activity?.title;
    if ('routine' in activity) return activity?.routine;
    if ('specific' in activity) return activity?.specific;
    return '';
  }

  const playSound = (url: string) => {
    audio.current = new Audio(url);
    audio.current?.play();
  };

  const stopSound = () => {
    audio.current?.pause();
  };

  return (
    <ViewBase flex={1}>
      <ViewBase flex={1} justifyContent="center">
        <ViewBase height={300} center marginTop={10}>
          <Circle
            progress={Math.floor((stopwatch % 60) * 1.67)}
            size={'300'}
            showPercentage={false}
            progressColor={theme.colors.primary}
            bgColor={theme.colors.surface}
          />
        </ViewBase>
        <ViewBase marginTop={-220} marginBottom={120}>
          <Title center marginTop={50}>
            {getTimeStringFromSecunds(stopwatch)}
          </Title>
        </ViewBase>
        {['play', 'pause'].includes(status as string) && (
          <TextLabel marginTop={15} marginBottom={15} center>
            {getTitleActivity()}
          </TextLabel>
        )}
        <Select
          data={songs}
          label={t('backgroundNoise')}
          value={sound || 'none'}
          setValue={value => {
            if (['play', 'pause', 'finish'].includes(status as string)) {
              dispatch(
                TimerActions.setStopwatch({
                  ...stopwatchState,
                  sound: value !== 'none' ? value : null,
                }),
              );
            } else {
              stopSound();
              if (value !== 'none') playSound(value);
            }
            setSound(value !== 'none' ? value : null);
          }}
          renderValue={value =>
            songs.find(i => i.value === value)?.label as string
          }
        />
        <Row justifyContent="space-between" marginTop={20}>
          {['play', 'pause'].includes(status as string) ? (
            <ButtonContainer
              onClick={() => {
                onStop();
                Analytics.log(Analytics.events.stop_stopwatch);
              }}
              backgroundColor={theme.colors.red}>
              <TextLabel color={theme.colors.white}>{t('cancel')}</TextLabel>
            </ButtonContainer>
          ) : (
            <ViewBase />
          )}
          {status === 'play' ? (
            <ButtonContainer
              onClick={() => {
                onPause();
                Analytics.log(Analytics.events.pause_stopwatch);
              }}
              backgroundColor={theme.colors.regular}>
              <TextLabel color={theme.colors.white}>{t('pause')}</TextLabel>
            </ButtonContainer>
          ) : (
            <ButtonContainer
              onClick={() => {
                if (!premium) {
                  Toast.show(t('error:onlyPremium'));
                  return;
                }
                Analytics.log(
                  status === 'pause'
                    ? Analytics.events.resume_stopwatch
                    : Analytics.events.start_stopwatch,
                );
                onStart();
                if (status !== 'pause') {
                  handleStartTimer();
                } else {
                  handleResumeTimer();
                }
              }}>
              <TextLabel color={theme.colors.white}>
                {status === 'pause' ? t('resume') : t('toStart')}
              </TextLabel>
            </ButtonContainer>
          )}
        </Row>
      </ViewBase>
      {['play', 'pause'].includes(status as string) && (
        <ViewBase center>
          <ButtonContainer
            style={{marginTop: 20, width: '100%'}}
            title={t('finishAndSave')}
            onClick={onSaveStopwatch}>
            <TextLabel color={theme.colors.white}>
              {t('finishAndSave')}
            </TextLabel>
          </ButtonContainer>
        </ViewBase>
      )}
    </ViewBase>
  );
};

export default StopwatchScreen;

export function arrangeBigString(
  string: string,
  tamMax = 11,
  pos = '.',
): string {
  if (!string) {
    return '';
  }
  if (string.length >= tamMax + pos.length) {
    return string.slice(0, tamMax).concat(pos);
  }
  return string;
}

export function parseToNumber(string: string | null | undefined): number {
  if (!string) return 0;
  // eslint-disable-next-line radix
  return parseInt(String(string).replace(/\D/g, '') || '0') as number;
}
export function round(number: number, digits = 1): number {
  return parseFloat(number.toFixed(digits));
}
export function formatToShortNumber(number: number): string|number {
  if (!number) return 0;
  if (number < 1000) return number
  if (number < 1000000) return `${round(number / 1000)}K`;
  if (number < 1000000000) return `${round(number / 1000000)}M`;
  if (number < 1000000000000) return `${round(number / 1000000000)}B`;
  return number;
}
import i18n, {LanguageDetectorModule, Services, InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import Storage from '@/services/Storage';
import en from './en';
import es from './es';
import pt from './pt';

export const AVAILABLE_LANGUAGES = {
  en,
  pt,
  es,
};

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  // If this is set to true, your detect function receives a callback function that you should call with your language,
  // useful to retrieve your language stored in AsyncStorage for example
  init: (
    _services: Services,
    _detectorOptions: object,
    _i18nextOptions: InitOptions,
  ) => {
    /* use services and options */
  },
  detect: () => {
    const language = Storage.getItem('language');
    return language||'pt';
  },
  cacheUserLanguage: (lng: string) => Storage.setItem('language',lng),
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: AVAILABLE_LANGUAGES,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'shared',
  });

import React, {useEffect, useState} from 'react';
import {PERIOD} from '@/constants';
import {ActivityService, Toast} from '@/services';
import {Column} from '@/components/atoms';
import {BlockTimeLabel, CalendarContainer, ContentDays} from './styles';
import AgendaDay from './AgendaDay';
import {DEFAULT_SIZE_CARD, blocks, filterTaskWithoutHours} from './utils';
import {isSameId} from '@/utils/uid';
import {useDispatch} from 'react-redux';
import {ModalActions} from '@/store/modules';
import {useTranslation} from 'react-i18next';

type Props = {
  activities: {date: string; data: any[]}[];
  qntDays: number;
};

const Agenda = ({activities: _activities, qntDays}: Props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [activities, setActivities] = useState(_activities);

  useEffect(() => {
    setActivities(_activities.slice(0, qntDays));
  }, [_activities]);

  const onDragEnd = ({destDate, originDate, task}: any) => {
    const originData: any = activities.find(
      day => day.date === originDate,
    )?.data;
    const destData: any = activities.find(day => day.date === destDate)?.data;
    if (!task) {
      Toast.error('Tarefa não encontrada');
      return;
    }
    const inDate = originData?.filter((i: any) => !isSameId(i, task));
    const newTask = {
      ...task,
      checkList: task.checkListInDate || task.checkList,
      period: PERIOD.JUST_TODAY,
      startDate: destDate,
      endDate: destDate,
    };
    destData?.push(newTask);
    if (task.title && destDate !== originDate) {
      handleTaskMove(task, originDate, destDate, inDate, destData, newTask);
    } else if (task.id && destDate === originDate) {
      updateActivity(task, task.period !== PERIOD.JUST_TODAY, true);
    } else {
      Toast.error('Não foi possível alterar a tarefa');
    }
  };

  const handleTaskMove = (
    task: any,
    originDate: string,
    destDate: string,
    inDate: any[],
    destData: any[],
    newTask: any,
  ) => {
    setActivities(stt =>
      stt.map(i => ({
        ...i,
        data:
          i.date === destDate
            ? destData
            : i.date === originDate
            ? inDate
            : i.data,
      })),
    );

    if (task.period !== PERIOD.JUST_TODAY) {
      ActivityService.updateInDate(task, originDate, {
        date: originDate,
        deleted: true,
      });
      ActivityService.create(newTask);
    } else {
      task.startDate = destDate;
      task.endDate = destDate;
      updateActivity(task, false, false);
    }
  };

  const updateActivity = (
    data: any,
    askIfShouldUpdateAll: boolean,
    updateLocalState: boolean,
  ) => {
    const updateLocalActivities = (data: any) => {
      setActivities(stt =>
        stt.map(i => ({
          ...i,
          data: i.data.map(task => (task.id === data.id ? data : task)),
        })),
      );
    };

    if (
      data.title &&
      data.period !== PERIOD.JUST_TODAY &&
      askIfShouldUpdateAll
    ) {
      updateLocalActivities(data);
      dispatch(
        ModalActions.setModalContent({
          height: 550,
          mode: 'alert',
          title: t('task:updateTask'),
          description: t('task:updateTaskAlert', {date: data.date}),
          actions: [
            {
              testID: `onlyThis`,
              text: t('onlyThis'),
              onPress: () => {
                ActivityService.update(data, true);
              },
            },
            {
              testID: `allOcorrences`,
              text: t('allOcorrences'),
              onPress: () => {
                ActivityService.update(data);
              },
            },
          ],
        }),
      );
    } else {
      if (updateLocalState) updateLocalActivities(data);
      ActivityService.update(data);
    }
  };

  const offsetTop =
    Math.max(
      ...activities.map(i => i.data.filter(filterTaskWithoutHours).length),
    ) * DEFAULT_SIZE_CARD;

  return (
    <CalendarContainer>
      <Column style={{marginTop: offsetTop}}>
        {blocks
          .filter(i => i.includes(':00'))
          .map(i => (
            <BlockTimeLabel key={i}>{i}</BlockTimeLabel>
          ))}
      </Column>
      <ContentDays>
        {activities.map(date => (
          <AgendaDay
            updateActivity={updateActivity}
            key={date.date}
            date={date.date}
            data={date.data}
            offsetTop={offsetTop}
            onDragEnd={onDragEnd}
          />
        ))}
      </ContentDays>
    </CalendarContainer>
  );
};

export default Agenda;

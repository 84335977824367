import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Content, TextBase, ViewBase} from '@/components/atoms';
import {
  ModalActions,
  Reward,
  RewardActions,
  selectorRewards,
} from '@/store/modules';
import {useTranslation} from 'react-i18next';
import { Gamification } from '@/services';
import { Header } from '@/components/molecules';
import { useHistory } from 'react-router';

const Rewards: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const rewards = useSelector(selectorRewards);

  useEffect(()=> {
    dispatch(RewardActions.getRewardRequest())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  function showDetailsReward(reward: Reward) {
    dispatch(
      ModalActions.setModalContent({
        height: 550,
        mode: 'share',
        type: 'Details_Reward',
        data: {reward},
      }),
    );
  }
  const onPressButtonLeft = () => {
    history.back()
  };

  return (
    <Container>
      <Header 
        title={t('statistic:myRewards')}
        onPressButtonLeft={onPressButtonLeft}
      />
      <Content>
        <ViewBase
          flexDirection="row"
          wrap
          flex={1}
          justifyContent="space-between">
          {rewards.sort((a, b) => (b.date > a.date ? 1 : 0)).map((item:Reward) => (
            <ViewBase style={{width: '18%',alignItems:'center', cursor:'pointer'}} padding={3} onClick={() => showDetailsReward(item)}>
              <img
              alt="reward"
              style={{height:60}}
              src={Gamification.getReward(item.key).image}
            />
              <TextBase size={12} padding={5} center>
                {t(`statistic:${item.key}`)}
              </TextBase>
            </ViewBase>
          ))}
        </ViewBase>
      </Content>
    </Container>
  );
};
export default Rewards;

import styled from 'styled-components';
import { colors } from '../../styles';


export const ButtonFloat = styled.div`
  height:55px;
  width:55px;
  border-radius: 18px;
  position:fixed;
  bottom:0;
  right:0;
  background: ${colors.primary};
  color:white;
  font-family: 'Poppins-Bold';
  margin: 10px;
  border:none;
  font-size:20px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.3);
  display:flex;
  align-items:center;
  justify-content:center;
  &:hover{
    cursor:pointer;
    opacity:0.95;
  }
`;

import React, {useEffect, useState} from 'react';
import ColumnMemorizate from '../Memorizate';
import {ListEmpty} from '@/components/molecules';
import {useTranslation} from 'react-i18next';
import {DrawerActions, selectorFilterCalendar} from '@/store/modules';
import {useDispatch, useSelector} from 'react-redux';
type Props = {
  activities: any[];
  qntDays: number;
  loading: boolean;
};
const ListWeek: React.FC<Props> = ({activities, qntDays, loading}) => {
  const dispatch = useDispatch();
  const {filterBy} = useSelector(selectorFilterCalendar);
  const [width, setWidth] = useState((window.innerWidth - 100) / qntDays);
  const countFilters =
    filterBy.status.length + filterBy.types.length + filterBy.categories.length;

  const {t} = useTranslation();
  const empty = activities.every(i => i.data.length === 0);

  const handleResize = () => {
    setWidth((window.innerWidth - 100) / qntDays);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [qntDays]);

  useEffect(() => {
    handleResize();
  }, [qntDays]);

  if (!loading && empty) {
    return (
      <ListEmpty
        title={
          countFilters
            ? t('task:noActivitiesFilter')
            : t('task:noActivitiesInPeriod')
        }
        text={countFilters ? t('removeFiltersToSee') : t('listTasksEmpty')}
        titleButton={t('createTask')}
        onPress={() =>
          dispatch(
            DrawerActions.setDrawer({action: 'ADD_TASK', payload: undefined}),
          )
        }
      />
    );
  }
  return (
    <>
      {activities.slice(0, qntDays).map((item: any, index: number) => (
        <ColumnMemorizate
          day={item.data}
          index={index}
          key={index}
          width={width}
        />
      ))}
    </>
  );
};

export default ListWeek;

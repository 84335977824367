import React from 'react';
import {InputContainer, Required} from '@/components/atoms';
import {Input as InputAnt} from 'antd';
import {useTheme} from 'styled-components';
import {Label} from '@/styles/global';

interface Props {
  label?: string;
  placeholder: string;
  type?: string;
  value: string | undefined;
  onChangeText(value: string): void;
  onSubmitEditing?(): void;
  required?: boolean;
  maxLength?: number;
  multiline?: boolean;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  style?: any;
}
const Input = ({
  label,
  value,
  onChangeText,
  required,
  maxLength = 255,
  onSubmitEditing,
  ...rest
}: Props): JSX.Element => {
  const WrapperInput = rest.type === 'password' ? InputAnt.Password : InputAnt;

  const theme = useTheme();
  return (
    <InputContainer>
      {label && (
        <Label>
          {label} {required && <Required>*</Required>}
        </Label>
      )}
      <WrapperInput
        value={value}
        onChange={e => onChangeText(e.target.value)}
        className={theme.title === 'dark' ? 'input-dark' : 'input-light'}
        maxLength={maxLength}
        onPressEnter={onSubmitEditing}
        {...rest}
      />
      {value && maxLength <= value?.length && (
        <h5 style={{color: theme.colors.red}}>
          O limite máximo de caracteres é {maxLength}
        </h5>
      )}
    </InputContainer>
  );
};

Input.defaultProps = {
  label: undefined,
  required: undefined,
  onSubmitEditing: undefined,
  autoCapitalize: undefined,
  autoCorrect: undefined,
  multiline: undefined,
  style: undefined,
  maxLength: 255,
};

export default Input;

export const ERROR = {
  TOKEN_INVALID: 'TOKEN_INVALID', // 401
  APP_VERSION_UNAVAILABLE: 'APP_VERSION_UNAVAILABLE', // 400

  UNAUTHORIZED: 'UNAUTHORIZED', // 401
  BAD_REQUEST: 'BAD_REQUEST', // 400
  NOT_FOUND: 'NOT_FOUND', // 404
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR', // 500
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE', // 503
  GATEWAY_TIMEOUT: 'GATEWAY_TIMEOUT', // 504

  VALIDATION_ERROR: 'VALIDATION_ERROR', // 404
  LOGIN_ERROR: 'LOGIN_ERROR',
  USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
  USER_NOT_EXIST: 'USER_NOT_EXIST',
  ERROR_GET_PREMIUM: 'ERROR_GET_PREMIUM',
};

import React, {useMemo} from 'react';
import {BarContainer, BarFilled, TextScore, Row} from './styles';

interface BarProgressProps {
  level: {
    scoreTop: number;
    scoreDown: number;
  };
  score: number;
  showRate?: boolean;
}

const BarProgress = ({
  level,
  score = 0,
  showRate,
}: BarProgressProps): JSX.Element => {
  const rate = useMemo<number>(
    () =>
      parseFloat(
        (
          (100 * (score - level?.scoreDown)) /
          (level?.scoreTop - level?.scoreDown)
        ).toFixed(1),
      ),
    [level?.scoreDown, level?.scoreTop, score],
  );
  return (
    <Row>
      <TextScore>{level.scoreDown}</TextScore>
      <BarContainer>
        <BarFilled flex={Math.max(rate / 100, 0.01)} />
      </BarContainer>
      <TextScore>{level.scoreTop}</TextScore>
      {showRate && <TextScore> ({rate}%)</TextScore>}
    </Row>
  );
};

BarProgress.defaultProps = {
  showRate: undefined,
};
export default BarProgress;

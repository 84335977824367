import React from 'react';
import {Fixed} from '@/components/atoms';
import HeaderComponent from '@/components/Header';

type Props = {
  title?: string;
  onPressButtonLeft?(): void;
  children?: JSX.Element;
};

const Header = ({title, onPressButtonLeft, children}: Props) => {
  // const theme = useTheme()
  return (
    <Fixed>
      <HeaderComponent />
      {/* {title && (
        <Row>
          <MdNavigateBefore size={30} onClick={onPressButtonLeft} style={{color:theme.colors.onBackground,cursor:'pointer'}} />
          <Title>{title}</Title>
        </Row>
      )} */}
      {children}
    </Fixed>
  );
};

export default Header;

export default {
  title: 'Estadísticas',
  viewGamitification: 'Comprender la gamificación',
  score: 'Puntuación',
  totalScorePeriod: 'Puntos totales en el período',
  gamification: 'Gamificación',
  level: 'Nivel',
  levels: 'Niveles',
  general: 'General',
  rewards: 'Recompensas',
  noRewards: 'Aún no has obtenido ningún logro :(',
  rankings: 'Clasificaciones',
  rankingPremium: 'Clasificación Premium 👑',
  rankingGlobal: 'Clasificación Global',
  reports: 'Informes',
  viewReportsWeekly: 'Ver informes Semanales',
  viewReportsMonthly: 'Ver informes Mensuales',
  reportWeekly: 'Informe Semanal',
  reportMonthly: 'Informe Mensual',
  reportsWeekly: 'Informes Semanales',
  reportsMonthly: 'Informes Mensuales',
  noReports: 'Aún no hay informes disponibles',
  performed: 'Realizado',
  notPerformed: 'No realizado',
  weeklyGrowth: 'Evolución Semanal',
  growth: 'Evolución',
  rateDay: 'Promedio diario',
  triadTime: 'Tríada del Tiempo',
  wheelOfLife: 'Rueda de la Vida',
  historicMonthly: 'Historial Mensual',
  historicWeekly: 'Historial Semanal',
  consistency: 'Consistencia',

  addScore: '¡Has ganado {{score}} puntos!',

  onlyPremium: 'Solo Premium',
  top: 'TOP',
  yourPosition: 'SU POSICIÓN',
  seeDetails: 'Ver detalles',
  noScore: 'Aún no tienes una puntuación',
  period: 'Periodo',
  noHabitsPeriod: 'No tienes hábitos activos durante el período.',
  noTasksPeriod: 'No tienes ninguna tarea durante el período.',
  noGoalsPeriod: 'No tienes ningún objetivo para este período.',
  noWeekYet: 'No ha completado una semana de uso de la aplicación.',
  achievements: 'Logros',

  tasksDone: '✅ Tasks done',
  habitsDone: '🚀 Hábitos realizados',
  goalsDone: '🎯 Metas cumplidas',
  congratulations: 'Felicidades',
  doneAllActivities: '¡Realizaste todas las actividades del día!',
  descGetPremium:
    '¡Ganaste un DIAMANTE por convertirte en PREMIUM 👑! \n \n¡Todas las funciones premium han sido lanzadas para ti! 🚀',
  getMedal: '¡Ganaste una medalla por alcanzar tu objetivo!',
  nextLevelDesc: `¡Has avanzado de nivel!🚀  ${'\n'}Tu nivel ahora es {{currentLevel}}`,
  currentScore: 'Puntuación actual',
  prevLevel: 'Nivel anterior',
  nextLevel: 'Siguiente nivel',
  currentLevel: 'Nivel actual',

  TRÓFEU: 'TROFEO',
  'MEDALHA DE PRATA': 'MEDALLA DE PLATA',
  'MEDALHA DE OURO': 'MEDALLA DE ORO',
  MEDALHA: 'MEDALLA',
  DIAMANTE: 'DIAMANTE',
  CONQUISTA: 'CONQUISTA',

  gamificationScore: [
    {
      label: 'Agregar tarea no periódica:',
      value: '2 pts',
    },
    {
      label: 'Agregar tarea periódica:',
      value: '10 pts ',
    },
    {
      label: 'Agregar objetivo:',
      value: '10 pts ',
    },
    {
      label: 'Agregar hábito:',
      value: '10 pts ',
    },
    {
      label: 'Completar tarea IMPORTANTE:',
      value: '20 pts ',
    },
    {
      label: 'Completar tarea URGENTE:',
      value: '15 ptos ',
    },
    {
      label: 'Completar tarea CIRCUNSTANCIAL:',
      value: '10 pts ',
    },
    {
      label: 'Finish hábito:',
      value: '20 pts ',
    },
  ],
  gamificationRewards: [
    {
      label: 'Completar un objetivo:',
      value: '1 medalla',
    },
    {
      label: 'Líder de clasificación mensual:',
      value: '1 trofeo',
    },
    {
      label: '2do lugar en el ranking mensual:',
      value: '1 medalla de plata',
    },
    {
      label: '3er lugar en la clasificación mensual:',
      value: '1 medalla de bronce',
    },
    {
      label: 'Líder de clasificación semanal:',
      value: '1 medalla',
    },
  ],

  Procrastinador: 'Procrastinator',
  Iniciante: 'Principiante',
  Esforçado: 'Sin esfurzo',
  Disciplinado: 'Disciplinado',
  Realizador: 'Director',
  Incrível: 'Increíble',
  Magnifico: 'Magnifico',
  Genial: 'Bien',
  Fenomenal: 'Fenomenal',
  Invencível: 'Ivencible',

  add_first_goal: 'Agregar primer objetivo',
  add_first_habit: 'Agregar 1er hábito',
  sequence_habit_3: 'secuencia de 3 días',
  sequence_habit_7: 'secuencia de 7 días',
  sequence_habit_30: 'Secuencia de 30 días',
  done_activity_30: 'Realizar actividad 30 días',
  done_activity_100: 'Realizar actividad 100 días',
  done_activity_500: 'Realizar actividad 500 días',
  sequence_habit_100: 'secuencia de 100 días',
  done_goal: 'Lograr la Meta',
  get_premium: 'Hacer Premium',
  level_2: 'Nivel 2',
  level_3: 'Nivel 3',
  level_4: 'Nivel 4',
  level_5: 'Nivel 5',
  level_6: 'Nivel 6',
  '3_ranking_weekly': '3er lugar - Clasificación semanal',
  '2_ranking_weekly': 'Segundo lugar - Clasificación semanal',
  '1_ranking_weekly': '1er lugar - Clasificación semanal',
  '3_ranking_monthly': '3er lugar - Clasificación mensual',
  '2_ranking_monthly': 'Segundo lugar - Clasificación mensual',
  '1_ranking_monthly': '1er lugar - Clasificación mensual',
  '3_ranking_yearly': '3er lugar - Clasificación anual',
  '2_ranking_yearly': '2do lugar - Clasificación anual',
  '1_ranking_yearly': '1er lugar - Clasificación anual',
  myRewards: 'Mis logros',
  gallery: 'Galería',
  howGet: 'Cómo llegar',
  desc_add_first_goal: 'Agrega tu primer objetivo',
  desc_add_first_habit: 'Agrega tu primer hábito',
  desc_sequence_habit_3: 'Realizar un hábito 3 días consecutivos',
  desc_sequence_habit_7: 'Realizar un hábito 7 días consecutivos',
  desc_sequence_habit_30: 'Realizar un hábito 30 días consecutivos',
  desc_sequence_habit_100: 'Realizar un hábito 100 días consecutivos',
  desc_done_goal: 'Completar un objetivo',
  desc_get_premium: 'Conviértete en Premium',
  desc_level_2: 'Proceder al nivel 2',
  desc_level_3: 'Proceder al nivel 3',
  desc_level_4: 'Proceder al nivel 4',
  desc_level_5: 'Proceder al nivel 5',
  desc_level_6: 'Proceder al nivel 6',
  desc_3_ranking_weekly: 'Gana el 3er lugar en la clasificación semanal',
  desc_2_ranking_weekly: 'Gana el segundo lugar en la clasificación semanal',
  desc_1_ranking_weekly: 'Gana el 1er lugar en la clasificación semanal',
  desc_3_ranking_monthly: 'Gana el 3er lugar en la clasificación mensual',
  desc_2_ranking_monthly: 'Gana el segundo lugar en la clasificación mensual',
  desc_1_ranking_monthly: 'Gana el 1er lugar en la clasificación mensual',
  desc_3_ranking_yearly: 'Gana el 3er lugar en la clasificación anual',
  desc_2_ranking_yearly: 'Gana el segundo lugar en la clasificación anual',
  desc_1_ranking_yearly: 'Gana el 1er lugar en la clasificación anual',

  desc_done_activity_30: 'Haz una tarea o hábito 30 veces',
  desc_done_activity_100: 'Haz una tarea o hábito 100 veces',
  desc_done_activity_500: 'Haz una tarea o hábito 500 veces',

  syncRewardsRequest: 'Sincronizando sus recompensas ...',
  syncRewardsSuccess: '¡Sincronización exitosa!',
  syncRewardsFailure:
    '¡La sincronización no se ha completado, inténtelo de nuevo más tarde!',

  gotReward: '¡Felicitaciones, nuevo logro!',
  reason: 'Razón:',

  reportDailyTitle: '¡Revisión del día! 📊 🚀',
  reportDailyMessage:
    'Actividades totales: {{totalActivities}}\nCompletadas: {{totalDone}}\nPorcentaje: {{ratio}}%',
  } as any;

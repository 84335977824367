import {
  ButtonSectionContainer,
  Row,
  TextBase,
  ViewBase,
} from '@/components/atoms';
import React from 'react';
import {useTheme} from 'styled-components';

type ButtonProps = {
  title: string;
  onPress?(): void;
  testID?: string;
  textColor?: string;
  iconRight?: string;
  center?: boolean;
};
const ButtonSection = ({
  onPress,
  title,
  textColor,
  iconRight,
  center,
  testID,
}: ButtonProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ButtonSectionContainer onClick={onPress}>
      <Row>
        <ViewBase flex={1}>
          <TextBase center={center} color={textColor ?? theme.colors.onSurface}>
            {title}
          </TextBase>
        </ViewBase>
      </Row>
    </ButtonSectionContainer>
  );
};

ButtonSection.defaultProps = {
  testID: undefined,
  textColor: undefined,
  iconRight: undefined,
  center: undefined,
};
export default ButtonSection;

export default {
  unknown: 'Ocorreu um erro :(',
  tryAgainLogin:
    'Tente entrar no aplicativo criando  um conta com email e senha.',
  canceled: 'Cancelado',
  serviceUnavailable: 'Serviço não disponível',
  emailIsRequired: 'O campo email é obrigatório',
  categoryIsRequired: 'O campo nome da categoria é obrigatório',

  checkPasswordAgain: 'As senhas não correspondem',
  msgCheckPasswordAgain: 'Os campos senha e nova senha devem ser iguais',

  validateFailure: 'Falha na validação',
  msgValidateFailure: 'Digite os campos corretamente',

  selectActivities: 'Selecione as atividades que deseja excluir!',
  selectMore: `Selecione mais {{qnt}} atividades.`,
  selectHour: 'Primeiro selecione um horário!',

  selectionDate: 'Não foi possivel selecionar a data',
  periodValid: 'Informe uma opção válida para o campo FREQUÊNCIA',
  limitReached: 'Você atingiu seu limite de {{activity}}!',
  onlyPremium: 'Disponível apenas para usuários Premium 👑',
  checkListRequired: 'Preencha o campo para adicionar um item ao check-list',
  validation: 'Preencha os campos obrigatórios (*)',
  validationEmail: 'Email Inválido :(',
  typeOnlyNumber: 'Digite apenas numeros',
  
  syncRanking: 'Sem Conexão: Não foi possível atualizar o ranking',
  tokenInvalid: 'Faça o login novamente',
  tokenInvalidMsg: 'Sua seção foi expirada, faça o login novamente',
  versionUnavailable: 'Versão descontinuada!',
  versionUnavailableMsg:
    'Essa versão foi descontinuada, atualize o aplicativo na Loja!',

  noConnection: 'Sem conexão',
  noConnectionMsg: 'Verifique sua conexão com a internet',
  requestSubMsg:
    'Verifique sua conexão com internet e tente novamente, caso o erro persistir, entre em contato conosco por email!',
  purchaseSubMsg:
    'Caso obter algum erro durante a compra, entre em contato conosco!',
  openUrl: `Tente abrir o link {{url}} pelo navegador`,

  validationError: 'Dados inválidos :(',
  validationErrorMsg: 'Digite os campos corretamente!',
  loginError: 'Email ou Senha inválidos :(',
  loginErrorMsg: 'Digite seus dados corretamente!',
  userAlreadyExists: 'Usuário já registrado!',
  userAlreadyExistsMsg: 'Tente novamente utilizando outro email',
  userNotExists: 'Usuário não encontrado.',
  userNotExistsMsg: 'O email informado no pertence a nenhum usuário.',
  notFound: 'Recurso não encontrado',
  notFoundMsg: 'O recurso solicitado não foi encontrado',
  limitImport:
    'O limite de tarefas futuras é {{limit}}, desmarque {{minDelete}} tarefas',
}as any;

import {Action} from '../utils';
import {TimerState, TimerTypes, Timer, Stopwatch} from './type';

const INITIAL_STATE: TimerState = {
  timer: {
    activity: undefined,
    finishAt: undefined,
    notificationId: undefined,
    time: undefined,
  },
  stopwatch: {
    activity: undefined,
    startAt: undefined,
  },
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): TimerState {
  switch (type) {
    case TimerTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case TimerTypes.SET_TIMER:
      return {...state, timer: payload.timer};

    case TimerTypes.SET_STOPWATCH:
      return {...state, stopwatch: payload.stopwatch};
    default:
      return state;
  }
}

export const TimerActions = {
  setTimer: (timer: Timer): Action => ({
    type: TimerTypes.SET_TIMER,
    payload: {timer},
  }),
  setStopwatch: (stopwatch: Stopwatch): Action => ({
    type: TimerTypes.SET_STOPWATCH,
    payload: {stopwatch},
  }),
  setInitialState: () => ({
    type: TimerTypes.SET_INITIAL_STATE,
  }),
};

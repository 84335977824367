import {  Action } from "../utils";
import { ListState, ListTypes } from "./type";

const INITIAL_STATE: ListState = {
  lists: [],
  tasks: {},
  data: [],
  loading: false,
  lastSync: null,
};

export default function reducer(
  state = INITIAL_STATE,
  { payload, type }: Action
): ListState {
  switch (type) {
    case ListTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;
      case ListTypes.GET_LISTS_REQUEST:
        return {...state, loading: true};
      case ListTypes.GET_LISTS_SUCCESS:
        return {...state, loading: false, lastSync: new Date()};
    case ListTypes.SET_LISTS_ORDER:
      return {...state, lists: payload.lists};
    case ListTypes.SET_TASKS_ORDER:
      return {...state, tasks: payload.tasks};

    default:
      return state;
  }
}

export const ListActions = {
  setInitialState: () => ({
    type: ListTypes.SET_INITIAL_STATE,
  }),
  setListsOrder: (lists: string[])=> ({
    type: ListTypes.SET_LISTS_ORDER,
    payload: {lists}
  }),
  getListsRequest: ()=> ({
    type: ListTypes.GET_LISTS_REQUEST,
  }),
  getListsSuccess: ()=> ({
    type: ListTypes.GET_LISTS_SUCCESS,
  }),
  setTasksOrder: (tasks: any)=> ({
    type: ListTypes.SET_TASKS_ORDER,
    payload: {tasks}
  }),
};

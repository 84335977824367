const Storage = {
  getItem: (key) => {
    return localStorage.getItem(`${process.env.REACT_APP_STORAGE_PREFIX}${key}`)
  },
  setItem: (key, value) => {
    localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}${key}`,value)
  },
  removeItem: (key) => {
    localStorage.removeItem(`${process.env.REACT_APP_STORAGE_PREFIX}${key}`)
  },
};
export default Storage;

import styled from 'styled-components';

type ButtonSectionContainerProps = {
  backgroundColor?: string;
};
export const ButtonSectionContainer = styled.div<ButtonSectionContainerProps>`
  padding: ${({theme}) => theme.metrics.space.small}px;
  background: ${({backgroundColor, theme}) =>
    backgroundColor || theme.colors.surface};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({theme}) => theme.metrics.space.smaller}px;
  cursor: pointer;
  opacity: 0.95;
`;

export const ButtonTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 38px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonValue = styled.div`
  justify-content: center;
  padding: ${({theme}) => theme.metrics.space.smaller}px;
`;
type OptionProps = {
  active: boolean;
};
export const Option = styled.button<OptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 3px 15px;
  border: none;
  border-radius: 5px;
  background-color: ${({theme, active}) =>
    active ? theme.colors.primary : theme.colors.surface};
  border: 1px solid
    ${({theme, active}) =>
      active ? theme.colors.primary : theme.colors.surfaceLight};
  color: ${({theme, active}) =>
    active ? theme.colors.white : theme.colors.text};
  margin-left: 15px;
  cursor: pointer;
  font-family: 'Poppins';
`;

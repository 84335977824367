import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Drawer } from "antd";
import { useTheme } from "styled-components";
import { TextSection } from "../atoms";

const BottomSheet = forwardRef(
  ({ title, children }: any, ref: any) => {
    const [visible, setVisible] = useState(false);
    const theme = useTheme();
    useImperativeHandle(
      ref,
      () => ({
        open: () => setVisible(true),
        close: () => setVisible(false),
      }),
      []
    );

    const onClose = () => {
      setVisible(false);
    };

    return (
      <Drawer
        title={
          <TextSection center color={theme.colors.onBackground}>
            {title}
          </TextSection>
        }
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={visible}
        key={"bottom"}
        height={500}
        drawerStyle={{
          background: theme.colors.background,
          borderColor: theme.colors.surface,
        }}
        headerStyle={{
          background: theme.colors.background,
          borderColor: theme.colors.surface,
        }}
      >
        {children}
      </Drawer>
    );
  }
);

export default BottomSheet;

export default {
  title: 'Statistics',
  viewGamitification: 'Understand gamification',
  score: 'Score',
  totalScorePeriod: 'Total score in the period',
  gamification: 'Gamification',
  level: 'Level',
  levels: 'Levels',
  general: 'General',
  rewards: 'Rewards',
  noRewards: "You don't have any rewards :(",
  rankings: 'Rankings',
  rankingPremium: 'Premium Ranking 👑',
  rankingGlobal: 'Global Ranking',
  reports: 'Reports',
  viewReportsWeekly: 'View Weekly Reports',
  viewReportsMonthly: 'View Monthly Reports',
  reportWeekly: 'Weekly Report',
  reportMonthly: 'Monthly Report',
  reportsWeekly: 'Weekly Reports',
  reportsMonthly: 'Monthly Reports',
  noReports: 'No reports available yet.',
  performed: 'Performed',
  notPerformed: 'Not Performed',
  weeklyGrowth: 'Weekly Growth',
  growth: 'Growth',
  rateDay: 'Daily average',
  triadTime: 'Triad Time',
  wheelOfLife: 'Wheel Of Life',
  historicMonthly: 'Monthly History',
  historicWeekly: 'Weekly History',
  consistency: 'Consistency',

  addScore: 'You won {{score}} score points!',

  onlyPremium: 'Only Premium',
  top: 'TOP',
  yourPosition: 'YOUR POSITION',
  seeDetails: 'See details',
  noScore: "You still don't have a score",
  period: 'Period',
  noHabitsPeriod: 'You have no active habits during the period.',
  noTasksPeriod: 'You do not have any tasks during the period.',
  noGoalsPeriod: "You don't have any goals for this period.",
  noWeekYet: "You haven't completed a week of using the app.",
  achievements: 'Achievements',

  tasksDone: '✅ Tasks done',
  habitsDone: '🚀 Habits performed',
  goalsDone: '🎯 Goals accomplished',
  congratulations: 'Congratulations',
  doneAllActivities: 'You performed all the activities of the day!',
  descGetPremium: `You won a DIAMOND for becoming PREMIUM 👑 !\n\nAll premium functions have been released for you!🚀 `,
  getMedal: 'You won a medal for reaching your goal!',
  nextLevelDesc: `You have advanced in level!🚀  ${'\n'}Your level is now {{currentLevel}}`,
  currentScore: 'Current Score',
  prevLevel: 'Prev Level',
  nextLevel: 'Next level',
  currentLevel: 'Current Level',

  TRÓFEU: 'TROPHY',
  'MEDALHA DE PRATA': 'SILVER MEDAL',
  'MEDALHA DE OURO': 'GOLD MEDAL',
  MEDALHA: 'MEDAL',
  DIAMANTE: 'DIAMOND',
  CONQUISTA: 'REWARD',

  gamificationScore: [
    {
      label: 'Add non-periodic task:',
      value: '2 pts',
    },
    {
      label: 'Add periodic task:',
      value: '10 pts',
    },
    {
      label: 'Add goal:',
      value: '10 pts',
    },
    {
      label: 'Add habit:',
      value: '10 pts',
    },
    {
      label: 'Complete IMPORTANT task:',
      value: '20 pts',
    },
    {
      label: 'Complete URGENT task:',
      value: '15 pts',
    },
    {
      label: 'Complete CIRCUMSTANTIAL task:',
      value: '10 pts',
    },
    {
      label: 'Finish habit:',
      value: '20 pts',
    },
  ],

  gamificationRewards: [
    {
      label: 'Complete a goal:',
      value: '1 medal',
    },
    {
      label: 'Monthly ranking leader:',
      value: '1 trophy',
    },
    {
      label: '2nd place in the monthly ranking:',
      value: '1 silver medal',
    },
    {
      label: '3rd place in the monthly ranking:',
      value: '1 bronze medal',
    },
    {
      label: 'Weekly ranking leader:',
      value: '1 medal',
    },
  ],

  Procrastinador: 'Procrastinator',
  Iniciante: 'Beginner',
  Esforçado: 'Hardworking',
  Disciplinado: 'Disciplined',
  Realizador: 'Director',
  Incrível: 'Incredible',
  Magnifico: 'Magnificent',
  Genial: 'Brilliant',
  Fenomenal: 'Phenomenal',
  Invencível: 'Invincible',

  add_first_goal: 'Add 1st Goal',
  add_first_habit: 'Add 1st Habit',
  sequence_habit_3: '3 day sequence',
  sequence_habit_7: '7-day sequence',
  sequence_habit_30: 'Sequence of 30 days',
  done_activity_30: 'Perform activity 30 days',
  done_activity_100: 'Perform activity 100 days',
  done_activity_500: 'Perform activity 500 days',
  sequence_habit_100: '100 day sequence',
  done_goal: 'Achieve Goal',
  get_premium: 'Become Premium',
  level_2: 'Level 2',
  level_3: 'Level 3',
  level_4: 'Level 4',
  level_5: 'Level 5',
  level_6: 'Level 6',
  '3_ranking_weekly': '3rd Place - Weekly Ranking',
  '2_ranking_weekly': '2nd Place - Weekly Ranking',
  '1_ranking_weekly': '1st Place - Weekly Ranking',
  '3_ranking_monthly': '3rd Place - Monthly Ranking',
  '2_ranking_monthly': '2nd Place - Monthly Ranking',
  '1_ranking_monthly': '1st Place - Monthly Ranking',
  '3_ranking_yearly': '3rd Place - Annual Ranking',
  '2_ranking_yearly': '2nd Place - Annual Ranking',
  '1_ranking_yearly': '1st Place - Annual Ranking',
  myRewards: 'My Achievements',
  gallery: 'Gallery',
  howGet: 'How to get',
  desc_add_first_goal: 'Add your 1st Goal',
  desc_add_first_habit: 'Add your 1st Habit',
  desc_sequence_habit_3: 'Perform a habit 3 consecutive days',
  desc_sequence_habit_7: 'Perform a habit 7 consecutive days',
  desc_sequence_habit_30: 'Perform a habit 30 consecutive days',
  desc_sequence_habit_100: 'Perform a habit 100 consecutive days',
  desc_done_goal: 'Complete a Goal',
  desc_get_premium: 'Become Premium',
  desc_level_2: 'Proceed to Level 2',
  desc_level_3: 'Proceed to Level 3',
  desc_level_4: 'Proceed to Level 4',
  desc_level_5: 'Proceed to Level 5',
  desc_level_6: 'Proceed to Level 6',
  desc_3_ranking_weekly: 'Win 3rd Place in the Weekly Ranking',
  desc_2_ranking_weekly: 'Win 2nd Place in the Weekly Ranking',
  desc_1_ranking_weekly: 'Win 1st Place in the Weekly Ranking',
  desc_3_ranking_monthly: 'Win 3rd Place in the Monthly Ranking',
  desc_2_ranking_monthly: 'Win 2nd Place in the Monthly Ranking',
  desc_1_ranking_monthly: 'Win 1st Place in the Monthly Ranking',
  desc_3_ranking_yearly: 'Win 3rd place in the Annual Ranking',
  desc_2_ranking_yearly: 'Win 2nd Place in the Annual Ranking',
  desc_1_ranking_yearly: 'Win 1st place in the Annual Ranking',

  desc_done_activity_30: 'Do a task or habit 30 times',
  desc_done_activity_100: 'Do a task or habit 100 times',
  desc_done_activity_500: 'Do a task or habit 500 times',

  syncRewardsRequest: 'Syncing your Rewards...',
  syncRewardsSuccess: 'Sync successful!',
  syncRewardsFailure: 'Sync has not been completed, please try again later!',
  gotReward: 'Congratulations, new achievement!',
  reason: 'Reason: ',

  reportDailyTitle: 'Review of the day! 📊 🚀',
  reportDailyMessage:
    'Total Activities: {{totalActivities}}\nPerformed: {{totalDone}}\nPercentage: {{ratio}}%',
  } as any;

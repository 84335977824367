import Input from '@/components/Input';
import {ViewBase} from '@/components/atoms';
import {InputDate, Select} from '@/components/molecules';
import {Period} from '@/constants';
import {ActivityService, Analytics, Toast} from '@/services';
import {store} from '@/store';
import {CheckList, TaskModel, selectorFutureTasks} from '@/store/modules';
import {metrics} from '@/styles';
import {mapActivityInDate} from '@/utils/activity';
import {getDateToday, isBetweenDate} from '@/utils/date';
import {translatePeriod} from '@/utils/translate';
import {isSameId} from '@/utils/uid';
import {Modal} from 'antd';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

interface Props {
  label: string;
  value: any;
  placeholder: string;
  description?: string;
  setValue(value: any): void;
  data: any[];
  disabled?: boolean;
}

function SelectTask({data, value, label, setValue, placeholder}: Props) {
  const onSelectTask = (id: number) => {
    const task = data.find(i => i.id === id);
    setValue(task || null);
  };
  return (
    <Select
      theme="default"
      label={label}
      data={data.map(i => ({
        value: i.id || i.uid,
        label: `${i.title} (${translatePeriod(i.period)})`,
      }))}
      value={value}
      setValue={onSelectTask as any}
      placeholder={placeholder}
    />
  );
}

const MoveToChecklist = ({task, item, itemDate, visible, close}: any) => {
  const {t} = useTranslation();
  const [fatherTask, setFatherTask] = useState<TaskModel | null>(null);
  const [period, setPeriod] = useState(
    task.period === Period.JUST_TODAY || item
      ? Period.JUST_TODAY
      : Period.DAILY,
  );
  const [date, setDate] = useState(itemDate || getDateToday());
  const tasks = useSelector(selectorFutureTasks).filter(
    i => !isSameId(i, task) && i.endDate >= i.startDate,
  );

  const periods = [
    {
      label: t(`task:checklist-oneday`),
      value: Period.JUST_TODAY,
    },
    {
      label: t(`task:checklist-daily`),
      value: Period.DAILY,
    },
  ];

  const handleMoveTaskToChecklists = async () => {
    if (!fatherTask) return;
    const itemChecklist = [
      {text: task.title, done: false},
      ...(task.checkList || []),
    ];
    if (fatherTask?.period === Period.JUST_TODAY || period === Period.DAILY) {
      const checkList = [...(fatherTask?.checkList || []), ...itemChecklist];
      ActivityService.update({...fatherTask, checkList});
    } else {
      if (!isBetweenDate(date, fatherTask)) {
        return Toast.show(t('error:invalidDate'));
      }
      updateChecklistInDate(fatherTask, date, stt => [
        ...stt,
        ...itemChecklist,
      ]);
    }
    if (task.period === Period.JUST_TODAY)
      ActivityService.delete(task, {deleteAll: true});
    else ActivityService.delete(task, {deleteNexts: true});

    Analytics.log('move_task_to_checklist');
    close();
  };

  const handleMoveChecklist = async () => {
    if (!fatherTask || !item || !itemDate) return;
    const itemChecklist = [{text: item.text, done: false}];
    if (fatherTask?.period === Period.JUST_TODAY || period === Period.DAILY) {
      const checkList = [...(fatherTask?.checkList || []), ...itemChecklist];
      ActivityService.update({...fatherTask, checkList});
    } else {
      if (!isBetweenDate(date, fatherTask)) {
        return Toast.show(t('error:invalidDate'));
      }
      updateChecklistInDate(fatherTask, date, stt => [
        ...stt,
        ...itemChecklist,
      ]);
    }
    const deleteInTask =
      task.period === Period.JUST_TODAY || period === Period.DAILY;
    if (
      deleteInTask &&
      task.checkList &&
      task.checkList.find((i: any) => i.text === item.text)
    ) {
      ActivityService.update({
        ...task,
        checkList: task.checkList.filter((i: any) => i.text !== item.text),
      });
    } else {
      setTimeout(() => {
        updateChecklistInDate(task, itemDate, stt =>
          [...stt].filter(i => i.text !== item.text),
        );
      }, 1000);
    }
    Analytics.log('move_item_checklist_to_checklist');
    close();
  };

  const updateChecklistInDate = (
    task: any,
    date: string,
    updateCheckList: (stt: CheckList[]) => CheckList[],
  ) => {
    const stt = store.getState();
    const dates = stt.task.tasks.find(i => isSameId(task, i))?.dones || [];
    const inDate = dates.find(d => d.date === date) as any;
    const {checkListInDate} = mapActivityInDate(task, inDate);
    ActivityService.updateInDate(task, date, {
      date,
      checkList: updateCheckList([...(checkListInDate || [])]),
    });
  };

  return (
    <Modal
      title={t(item ? 'task:moveToOtherChecklist' : 'task:moveToChecklist')}
      open={visible}
      onCancel={e => {
        e.stopPropagation();
        close();
      }}
      okText={t('task:move')}
      onOk={e => {
        e.stopPropagation();
        if (item && itemDate) handleMoveChecklist();
        else handleMoveTaskToChecklists();
      }}>
      <ViewBase
        onClick={e => {
          e.stopPropagation();
        }}>
        <Input
          label={t('task:itemOfChecklist')}
          placeholder={t('task:selectTask') as string}
          value={item?.text || task?.title}
          disabled
          onChangeText={undefined}
          theme="default"
        />
        <SelectTask
          label={t('task:targetTask')}
          description={t('task:targetTaskDesc') as string}
          placeholder={t('task:selectTask')}
          data={tasks}
          value={fatherTask?.id}
          setValue={setFatherTask}
        />

        {fatherTask && fatherTask?.period !== Period.JUST_TODAY && (
          <Select
            theme="default"
            height={metrics.fontSize(380)}
            label={t('task:periodOfItem')}
            value={period}
            renderValue={value =>
              periods.find(i => i.value === value)?.label ||
              t('task:placeholderLabel')
            }
            setValue={setPeriod as any}
            placeholder={t('task:placeholderLabel')}
            data={periods}
          />
        )}

        {fatherTask &&
          fatherTask?.period !== Period.JUST_TODAY &&
          period === Period.JUST_TODAY && (
            <InputDate
              theme="default"
              label={t('task:startDate')}
              value={date}
              onChangeValue={setDate}
              testID="input_start_date"
            />
          )}
      </ViewBase>
    </Modal>
  );
};

export default MoveToChecklist;

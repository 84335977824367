import React from 'react';
import DatePicker from '@/components/DatePicker';

interface Props {
  label: string;
  value: string;
  onChangeValue(value: string): void;
  required?: boolean;
  placeholder?: string;
  testID?: string;
  theme?: string;
}

const InputDate = ({
  label,
  value,
  onChangeValue,
  required,
  placeholder,
  theme,
}: Props): JSX.Element => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChangeValue={onChangeValue}
      placeholder={placeholder}
      required={required}
      theme={theme}
    />
  );
};

InputDate.defaultProps = {
  showClearDate: undefined,
  required: undefined,
  placeholder: undefined,
  testID: undefined,
};

export default InputDate;

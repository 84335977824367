import {addDays, isSameDay, parseISO} from 'date-fns';
import {useSelector} from 'react-redux';
import {
  Container,
  ContentDayWeek,
  ColumnDay,
  ContentWeek,
  TextWeek,
} from './styles';
import {formatDate, formatDateToServer} from '@/utils/date';
import { selectorCalendar} from '@/store/modules';
import {ModeInterval} from '@/constants';

const Week = ({mode, showDays = true, modeInterval}) => {
  const {lowerDate} = useSelector(selectorCalendar);
  return (
    <Container>
      <ContentWeek mode={mode}>
        {Array.from({
          length:
            modeInterval === ModeInterval.DAYS3
              ? 3
              : modeInterval === ModeInterval.DAILY
              ? 1
              : 7,
      })
          .map((i, index) => ({
            date: formatDateToServer(
              addDays(parseISO(lowerDate), index),
            ),
          }))
          .map((i, index) => (
            <ColumnDay key={i.date}>
              <ContentDayWeek
              // active={isSameDay(parseISO(date), parseISO(i.date))} key={i.date}
              // onClick={() => dispatch(CalendarActions.setCurrentDay(i))}
              >
                <TextWeek
                  isToday={isSameDay(parseISO(i.date), new Date())}
                  size={13}
                  style={{textTransform: 'uppercase'}}
                  // active={isSameDay(parseISO(date), parseISO(i.date))}
                >
                  {formatDate(i.date, 'EEE')}
                </TextWeek>
                {showDays && (
                  <TextWeek
                    isToday={isSameDay(parseISO(i.date), new Date())}
                    size={20}
                    // active={isSameDay(parseISO(date), parseISO(i.date))}
                  >
                    {formatDate(i.date, 'dd')}
                  </TextWeek>
                )}
              </ContentDayWeek>
            </ColumnDay>
          ))}
      </ContentWeek>
    </Container>
  );
};

export default Week;

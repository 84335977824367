export const DrawerTypes = {
  SET_INITIAL_STATE: 'drawer/SET_INITIAL_STATE',
  SET_DRAWER: 'drawer/SET_DRAWER',
  CLOSE_DRAWER: 'drawer/CLOSE_DRAWER',
};


export interface DrawerState {
  action: string | null
  payload: any 
  visible: boolean
}

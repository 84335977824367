import { formatToShortNumber } from '@/utils/string';
import {useMemo} from 'react';
import {Container, Active, TextScore, Row} from './styles';

interface BarProgressProps {
  level: {
    scoreTop: number;
    scoreDown: number;
  };
  score: number;
  width?: number;
  height?: number;
  showRate?: boolean;
  color?:string;
  colorSurface?:string;
}

const BarProgress = ({
  level,
  width,
  score = 0,
  showRate,
  color,
  height,
  colorSurface
}: BarProgressProps): JSX.Element => {
  const rate = useMemo(
    () =>
      (
        (100 * (score - level?.scoreDown)) /
        (level?.scoreTop - level?.scoreDown)
      ).toFixed(1),
    [level?.scoreDown, level?.scoreTop, score],
  );
  return (
    <Row width={width}>
      <TextScore color={color}>{formatToShortNumber(score)}</TextScore>
      <Container colorSurface={colorSurface} height={height}>
        <Active color={color} score={rate}  height={height}/>
      </Container>
      <TextScore color={color}>{formatToShortNumber(level.scoreTop)}</TextScore>
      {showRate && <TextScore marginLeft={20} color={color}>({rate}%) </TextScore>}
    </Row>
  );
};

BarProgress.defaultProps = {
  width: undefined,
  showRate: undefined,
};
export default BarProgress;

export const ModalTypes = {
  SET_MODAL_VISIBLE: 'modal/SET_MODAL_VISIBLE',
  SET_MODAL_CONTENT: 'modal/SET_MODAL_CONTENT',
  SET_ERROR: 'modal/SET_ERROR',
};

export interface ModalState {
  modalVisible: boolean;
  mode?: 'alert' | 'success' | 'error' | 'share';
  alert: boolean;
  error: boolean;
  title?: string;
  animation: null;
  description?: string;
  showButtonOk?: boolean;
  actions?: Array<any>;
  type?: string;
  data?: any;
  img?: any;
  height?: number;
  children?: JSX.Element;
}

import { DrawerState,DrawerTypes } from "./type";

const INITIAL_STATE : DrawerState = {
  action:null, 
  payload:null, 
  visible:false,
};

export default function drawer(state = INITIAL_STATE, {type, payload}:{type:string;payload:any}) {
  switch (type) {
    case DrawerTypes.CLOSE_DRAWER:
    case DrawerTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case DrawerTypes.SET_DRAWER:
      return {...state,visible:true,...payload};

    default:
      return state;
  }
}

export const DrawerActions = {
  setInitialState: () => ({
    type: DrawerTypes.SET_INITIAL_STATE,
  }),
  setDrawer: ({action,payload}:{action:string;payload:any}) => ({
    type: DrawerTypes.SET_DRAWER,
    payload:{action,payload}
  }),
  closeDrawer: () => ({
    type: DrawerTypes.CLOSE_DRAWER,
  }),
};

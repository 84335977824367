/* eslint-disable no-case-declarations */
import {Action} from '../utils';
import {FilterState, FilterTypes, FilterOption} from './type';

const INITIAL_STATE: FilterState = {
  calendar: {
    filterBy: {
      types: [],
      categories: [],
      status: [],
    },
    sortBy: {
      hour: 1,
      status: null,
      label: null,
    },
  },
  list: {
    filterBy: {
      types: [],
      categories: [],
      status: [false],
    },
    sortBy: {
      hour: 1,
      status: null,
      label: null,
    },
  },
  habit: {
    filterBy: {
      types: [],
      categories: [],
      status: [],
    },
    sortBy: {
      hour: 1,
      status: null,
    },
  },
  goal: {
    filterBy: {
      types: [],
      categories: [],
      status: [],
      date: [],
    },
    sortBy: {
      hour: 0,
      status: null,
    },
    date: []
  },
};

export default function reducer(
  state = INITIAL_STATE,
  {
    type,
    payload,
  }: Action<{
    filterBy: any;
    sortBy: any;
    filter: FilterOption;
    hour: number;
    type: string;
    category: string;
    status: boolean;
  }>,
): FilterState {
  switch (type) {
    case FilterTypes.SET_FILTER:
      const sttF2 = state[payload.filter];
      return {
        ...state,
        [payload.filter]: {
          ...sttF2,
          filterBy: {
            ...sttF2.filterBy,
            ...payload.filterBy,
          },
          sortBy: {
            ...sttF2.sortBy,
            ...payload.sortBy,
          },
        },
      };
    case FilterTypes.RESET_FILTER:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export const FilterActions = {
  resetFilter: () => ({
    type: FilterTypes.RESET_FILTER,
  }),
  setFilter: (
    filter: FilterOption,
    sortBy: any,
    filterBy: any,
  ): Action<{filter: FilterOption; sortBy: any, filterBy: any}> => ({
    type: FilterTypes.SET_FILTER,
    payload: {filter, sortBy, filterBy},
  }),
};

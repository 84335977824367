import { Section, TextSection } from '@/components/atoms';
import React from 'react';
import Chart from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type Props = {
  triadTime: any;
};

const ContainerChart = styled.div`
  .chartPie g text{
    fill: ${({ theme }) => theme.colors.onBackground};
  }
`;

const TriadTime: React.FC<Props> = ({ triadTime }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Section>
      <TextSection>{t('statistic:triadTime')}</TextSection>
      <ContainerChart>
        <Chart
          width={'400px'}
          height={'300px'}
          chartType="PieChart"
          loader={<div>Loading...</div>}
          className="chartPie"
          data={[
            ['Esfera', 'Esfera'],
            [t('important'), triadTime.important],
            [t('urgent'), triadTime.urgent],
            [t('circumstantial'), triadTime.circumstantial],
          ]}
          rootProps={{ 'data-testid': '1' }}
          options={{
            backgroundColor: theme.colors.surface,
            colors: [theme.colors.blue, theme.colors.red, theme.colors.yellow],
          }}
        />
      </ContainerChart>

    </Section>
  );
};

export default TriadTime;

import React, {useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useTheme} from 'styled-components';

import {Column, Row, TextBase, TextTime, ViewBase} from '@/components/atoms';
import Actions from '@/components/organisms/Actions';
import {ActivityService} from '@/services';
import {
  Activity,
  selectorApp,
  selectorCategories,
  selectorShowChecklist,
  State,
} from '@/store/modules';
import {getStyleShadow, metrics} from '@/styles';
import {differenceInDays, formatDate, isPast} from '@/utils/date';
import {arrangeBigString} from '@/utils/string';

import {ContentActivity} from './styles';
import CheckBox from '../CheckBox';
import ListItemCheckList from '../ListItemCheckList';
import {getBorderColor} from '@/utils/activity';

interface Props {
  activity: Activity;
  beforeCheckActivity?(activity: Activity): void;
  hideCheck?: boolean;
  width: number;
}

function CardActivity({
  activity,
  beforeCheckActivity,
  hideCheck = false,
  width,
}: Props): JSX.Element {
  const {t} = useTranslation();
  const theme = useTheme();
  const {mode} = useSelector(selectorApp);
  const categories = useSelector(selectorCategories);
  const showCheckList = useSelector(selectorShowChecklist);
  const cardActivityColorBy = useSelector<State, string>(
    state => state.app?.cardActivityColorBy || 'label',
  );
  const checkList =
    'checkListInDate' in activity && activity.checkListInDate
      ? (activity?.checkListInDate as any[])
      : 'checkList' in activity && activity.checkList
      ? activity.checkList
      : null;

  const checkListToRender =
    showCheckList && checkList
      ? (checkList.filter(i => (mode === 'pending' ? !i.done : true)) as any[])
      : null;

  const heightCard = 45 + (checkListToRender?.length || 0) * 22;
  const widthCard = width || (window.innerWidth - 100) / 7;

  const getInfoCard = () => ({
    title:
      'title' in activity
        ? activity.title
        : 'specific' in activity
        ? activity.specific
        : activity.routine,

    borderColor: getBorderColor(activity, cardActivityColorBy, categories),
    textRightTop:
      'title' in activity || 'routine' in activity
        ? activity.startHour
        : formatDate(activity.date, 'dd/MM/yy'),
    textRightBottom:
      'title' in activity || 'routine' in activity
        ? activity.endHour
        : isPast(activity.date)
        ? ''
        : `${differenceInDays(activity.date, new Date())} ${t('days')}`,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {title, borderColor, textRightTop, textRightBottom} = useMemo(
    getInfoCard,
    [activity],
  );

  const shouldShowDailyGoal =
    'routine' in activity && 'goal' in activity && activity?.goal?.amount;

  function handleCheckActivity() {
    if (beforeCheckActivity) beforeCheckActivity(activity);
    ActivityService.done(activity);
  }

  async function handleClickActivity() {
    ActivityService.onDetails(activity);
  }

  const handleCheckItem = (item: any) => {
    if (checkList) {
      ActivityService.checkItemCheckList(item, activity, checkList);
    }
  };

  const getMaxTextLength = () => {
    return Math.floor(width / 10);
  };
  return (
    <ContentActivity
      height={heightCard}
      borderColor={borderColor}
      onClick={e => {
        handleClickActivity();
      }}
      style={{
        ...getStyleShadow(theme),
        margin: 3,
        borderRadius: 7,
        justifyContent: 'center',
        backgroundColor: activity.done
          ? theme.colors.background
          : theme.colors.surface,
        width: widthCard,
      }}>
      <Row spaceBetween alignCenter>
        <Row alignCenter>
          {!hideCheck && (
            <CheckBox
              value={activity.done}
              color={theme.colors.onSurface}
              onChangeValue={handleCheckActivity}
            />
          )}
          <Column>
            <TextBase size={12}>
              {arrangeBigString(title, getMaxTextLength(), '...')}
            </TextBase>
            {shouldShowDailyGoal && (
              <TextBase size={8}>
                {'amount' in activity ? activity.amount : 0}/
                {'goal' in activity ? activity?.goal?.amount : 1}
              </TextBase>
            )}
          </Column>
        </Row>
        <Row alignCenter>
          <Column alignCenter>
            <TextTime size={11}>{textRightTop}</TextTime>
            <TextTime size={11}>{textRightBottom}</TextTime>
          </Column>
          <Actions activity={activity} />
        </Row>
      </Row>

      <ViewBase marginLeft={metrics.space.smaller}>
        {checkListToRender &&
          checkListToRender?.length > 0 &&
          checkListToRender?.map(item => (
            <ListItemCheckList
              key={item.text}
              item={item}
              keyLabel="text"
              value={item.done}
              onCheckItem={handleCheckItem}
              textStyle={{fontSize: 11}}
              style={{height: 20}}
              renderValue={v => arrangeBigString(v, getMaxTextLength())}
            />
          ))}
      </ViewBase>
    </ContentActivity>
  );
}
CardActivity.defaultProps = {
  beforeCheckActivity: undefined,
  hideCheck: false,
  width: undefined,
};
export default CardActivity;


export default {
  general: 'Ajustes',

  profile: 'Perfil',
  updateProfile: 'Editar perfil',
  deleteProfile: 'Eliminar cuenta',
  wantDeleteAccountTitle: '¿Desea eliminar su cuenta?',
  wantDeleteAccountMsg: 'Se eliminarán todos los datos de su aplicación',
  wantDeleteAccountMsgPremium:
    'Ya no tendrá acceso a los beneficios premium (incluso si crea otra cuenta) y se borrarán todos los datos de su aplicación.',

    soundOnCheck: 'Sonido al completar actividad',
    cardActivityColorBy: 'Color del borde de la tarjeta de actividad',
    startOfWeek: 'La semana comienza en',
    showCheckList: 'Mostrar check-list en la tarjeta',
    checkListConfig: 'Configuración de lista de control',
    checkListTaskConfig:
      'Mantener una sola lista de verificación para todas las ocurrencias de la tarea',
    checkListTaskDateConfig: 'Reiniciar lista de verificación diariamente',
    preferencias: 'Preferencias',

    
  theme: 'Tema',
  themeDark: 'Tema oscuro',
  themeDefault: 'Tema predeterminado',
  premium: 'Premium',
  'premium.title': 'Beneficios premium 👑',
  'premium.getError': '¿Tiene alguna pregunta o recibió un error?',
  'premium.support':
    'Póngase en contacto con el servicio de asistencia técnica',
  'premium.month': 'MES',
  'premium.expired': 'SIN',
  'premium.canceled': 'CANCELADO',
  'premium.active': 'ACTIVO',
  'premium.months': 'MESES',

  getPremium: 'Obtener Premium',
  adsPremium: ['VER ESTOS ', '13 BENEFICIOS ', 'DE CONVERTIRSE ', 'PREMIUM 👑'],
  premiumVersion: 'Descubra la versión premium 👑',
  versionWeb: 'Ver la versión web',
  mySubs: 'Mi suscripción',
  'web.title': 'Plataforma web',
  'web.target':
    'Con la Plataforma Web es posible gestionar todas tus tareas, hábitos y objetivos también desde el ordenador, además de la aplicación Flynow.',
  'web.howToAccess': '¿Cómo acceder a la Plataforma Web?',
  'web.disclaimer':
    'La versión web de Flynow solo está disponible para usuarios premium.',
  'web.content':
    'Para acceder a la extensión, vaya al enlace web.appflynow.com o busque en Google "Plataforma web Flynow". ¡Entonces inicie sesión con su cuenta Flynow y utilícela en su tiempo libre! ',
  extension: 'Conoce la extensión',
  'extension.title': 'Extensión',
  'extension.target':
    'Con la extensión puede ver y marcar rápidamente como completadas todas sus tareas, hábitos y objetivos a través de la computadora.',
  'extension.howToAccess': '¿Cómo acceder a la extensión?',
  'extension.disclamer':
    'Flynow Extension solo está disponible para usuarios premium.',
  'extension.content':
    'Para acceder a la extensión, vaya al enlace appflynow.com/extensao o vaya a la tienda de su navegador (tienda web Chrome o complementos del navegador Firefox), luego busque "Flynow - Productividad", instale la extensión e inicie sesión con su cuenta Flynow y úselo cuando quiera! ',
  language: 'Idioma',
  pt: 'Portugués',
  es: 'Español',
  en: 'Inglés',

  notifications: 'Notificaciones',
  nextsNotifications: 'Próximas notificaciones',

  contents: 'Contenido',
  blog: 'Blog',
  ebooks: 'Libros electrónicos: hábitos y metas',

  contribute: 'Contribuir',
  rateApp: '¿Te gusta la aplicación? Nos califica',
  shareApp: 'Comparte la aplicación',
  shareAppDialog: 'Comparte la aplicación con tus amigos :)',
  shareAppTitle:
    'Flynow es una aplicación perfecta para aquellos que buscan mejorar su productividad mediante la gestión de tareas, hábitos y objetivos. La aplicación utiliza los mejores métodos para gestionar tareas, hábitos y objetivos. ',
  shareAppMsg:
    'Flynow es una aplicación perfecta para cualquiera que busque mejorar su productividad. La aplicación utiliza los mejores métodos para administrar tareas, crear hábitos y metas.  NDescargar ahora mismo: https://appflynow.com ',
  followInsta: 'Síguenos en Instragram',

  contact: 'Contacto',
  email: 'Email',
  instagram: 'Instagram',

  terms: 'Términos',
  privacyPolicy: 'Política de privacidad',
  termsOfUse: 'Condiciones de uso',

  version: 'Versión',

  getOut: 'Salir',
} as any;

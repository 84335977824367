import React, {useState, useEffect, useMemo} from 'react';
import {InputContainer, Row, TextBase, TextInput} from '@/components/atoms';
import {
  CUSTOM,
  DAILY,
  JUST_TODAY,
  MONTHLY,
  PERIODS,
  SEG_SAB,
  SEG_SEX,
  WEEKLY,
  YEARLY,
} from '@/constants';
import {useTranslation} from 'react-i18next';
import {Select} from '@/components/molecules';
import {Label} from '@/styles/global';
import {
  addDays,
  formatDate,
  formatDateToServer,
  week,
  addWeeks,
  startOfWeek,
} from '@/utils/date';
import {Week, Day, Increment} from './styles';
import Separator from '../Separator';
import {AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import {translatePeriod} from '@/utils/translate';
import {useTheme} from 'styled-components';
import {parseToNumber} from '@/utils/string';

interface Props {
  label: string;
  startDate: string;
  endDate: string;
  value: string;
  setValue(value: React.SetStateAction<string>): void;
  excludePeriods?: string[];
  testID?: string;
  height?: string | number;
}

const InputPeriod = ({
  label,
  value,
  setValue,
  testID,
  height,
  excludePeriods,
  startDate,
  endDate,
}: Props): JSX.Element => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [step, setStep] = useState(1);
  const [intervalDays, setIntervalDays] = useState(1);
  const [weeks, setWeeks] = useState<string[]>([]);
  const nextsDays = useMemo(() => {
    if (step === 3) {
      const indexs = weeks
        .map(w => week.findIndex(i => i === w))
        .sort((a, b) => a - b);
      const dates = Array.from({length: 4}, (n, k) =>
        indexs.map(i => addDays(addWeeks(startOfWeek(new Date()), k), i)),
      );
      return dates
        .flat()
        .filter(
          d =>
            endDate >= formatDateToServer(d) &&
            formatDateToServer(d) >= startDate,
        )
        .slice(0, 4);
    }
    if (step === 4) {
      return Array.from({length: 4}, (n, k) =>
        addDays(startDate, k * intervalDays),
      ).slice(0, 4);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalDays, step, weeks]);
  const data = useMemo(
    () => {
      const array = PERIODS.filter(i => !excludePeriods?.includes(i))
        .map(i => ({
          value: i,
          label: `${t(i)}`,
        }))
        .concat([
          {
            value: CUSTOM,
            label: t(CUSTOM),
          },
        ]);
      if (intervalDays > 1) {
        array.push({
          value: `EVERY_${intervalDays}_DAYS`,
          label: translatePeriod(value),
        });
      }
      return array;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervalDays, value],
  );

  useEffect(verify, [value]);
  function verify() {
    if (value.indexOf(',') > 0) {
      if (value !== CUSTOM) {
        setWeeks(value.split(',') as string[]);
        setStep(3);
      }
    }
    if (value.includes('EVERY')) {
      const [, days] = value.split('_');
      // eslint-disable-next-line radix
      const daysNumber = parseInt(days) as unknown as number;
      setIntervalDays(daysNumber);
      setStep(4);
    }
    if (value === CUSTOM) {
      setWeeks([]);
      setIntervalDays(1);
    }
  }

  function selectedItem(value: string) {
    setValue(value);
    if (value !== CUSTOM) {
      setWeeks([]);
    } else {
      setStep(2);
    }
  }

  return (
    <InputContainer>
      <Select
        label={t('period')}
        value={value}
        setValue={selectedItem}
        data={data}
      />
      <div style={{marginLeft: 10}}>
        {[2, 3].includes(step) &&
          ![
            JUST_TODAY,
            DAILY,
            YEARLY,
            SEG_SEX,
            SEG_SAB,
            MONTHLY,
            WEEKLY,
          ].includes(value) && (
            <>
              <Label>{t('task:placeholderPeriod')}:</Label>
              <Week>
                {week.map(i => (
                  <Day
                    key={i}
                    active={weeks.includes(i)}
                    onClick={() => {
                      let newValue = [];

                      if (weeks.indexOf(i) >= 0) {
                        newValue = weeks.filter(item => item !== i);
                      } else {
                        newValue = [...weeks, i];
                      }
                      setWeeks(newValue);
                      setValue(newValue.toString());
                    }}>
                    <Label color={weeks.indexOf(i) >= 0 ? '#fff' : '#555'}>
                      {t(i)}
                    </Label>
                  </Day>
                ))}
              </Week>
            </>
          )}

        {step === 2 && <Separator marginTop={10} marginBottom={5} />}
        {[2, 4].includes(step) && (
          <>
            <Label>
              {t('task:placeholderPeriodInterval', {
                x: intervalDays <= 1 ? 'X' : intervalDays,
              })}
            </Label>
            <br />
            <TextBase small style={{marginBottom: 10}}>
              {t('task:placeholderPeriodIntervalDesc')}
            </TextBase>
            <Row alignCenter>
              <Label>{t('task:intervalDays')}</Label>
              <Increment
                onClick={() => {
                  setStep(4);
                  setValue(
                    `EVERY_${
                      intervalDays <= 2 ? intervalDays : intervalDays - 1
                    }_DAYS`,
                  );
                  setIntervalDays(stt => (stt <= 2 ? stt : stt - 1));
                }}>
                <AiOutlineMinus size={20} color={theme.colors.onBackground} />
              </Increment>
              <TextInput
                style={{
                  width: 60,
                  backgroundColor: theme.colors.surface,
                  fontFamily: 'Poppins-Bold',
                  textAlign: 'center',
                  color: theme.colors.textSection,
                  height: 35,
                  borderRadius: 6,
                }}
                // type="number"
                // style={numberStyle}
                value={intervalDays}
                onChange={v =>
                  setIntervalDays(Math.max(parseToNumber(v.target.value), 1))
                }
                // placeholder="00"
                // onFocus={() => setIntervalDays(0)}
                placeholderTextColor={theme.colors.textSection}
              />
              {/* <TextLabel>{intervalDays <= 1 ? "X" : intervalDays}</TextLabel> */}
              <Increment
                onClick={() => {
                  setStep(4);
                  setValue(`EVERY_${intervalDays + 1}_DAYS`);
                  setIntervalDays(stt => stt + 1);
                }}>
                <AiOutlinePlus size={20} color={theme.colors.onBackground} />
              </Increment>
            </Row>
          </>
        )}
        {nextsDays.length > 0 && (
          <>
            <TextBase small marginTop={15} bold>
              {t('task:nextDays')}
            </TextBase>
            <TextBase small>
              {nextsDays.map(i => formatDate(i, 'dd/MM')).join(' , ')}
            </TextBase>
            {/* <Button
                    onPress={sendCustom}
                    title={t('ok')}
                    marginTop={25}
                    borderRadius={6}
                  /> */}
          </>
        )}
      </div>
    </InputContainer>
  );
};

InputPeriod.defaultProps = {
  testID: undefined,
  height: undefined,
  excludePeriods: [],
};
export default InputPeriod;

import {SagaIterator} from '@redux-saga/types';
import Analytics from '@/services/Analytics';
import api from '@/services/api';
import Sentry from '@/services/Sentry';
import {call, put, select} from 'redux-saga/effects';
import {QueueActions} from './duck';

export function* syncQueue(): SagaIterator<void> {
  const user = yield select(state => state.user);
  try {
    const {data} = yield call(api.get, 'sync');
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      yield put({...item, type: item.action});
    }
    yield put(QueueActions.assign({sync: [], lastSync: new Date()}));
    Analytics.log(Analytics.events.QUEUE_SYNC);
  } catch (err:any) {
    Sentry.captureException(err, {
      user,
      description: 'sync in queue/saga.js',
    });
  }
}

import metrics from '@/styles/metrics';
import styled from 'styled-components';
import {Theme} from '@/styles/themes';
import { Gamification } from '@/services';
import { TextBase } from './Texts';

export type ThemeProps = {theme: Theme};

export type ViewBaseProps = ThemeProps & {
  justifyContent?: string;
  backgroundColor?: string;
  height?: number;
  width?: number;
  padding?: number;
  borderRadius?: number;
  flex?: number;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  paddingTop?: number;
  paddingBottom?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingVertical?: number;
  marginVertical?: number;
  marginLeft?: number;
  center?: boolean;
  wrap?: boolean;
  alignItems?: string;
  flexDirection?: string;
};

export const ViewBase = styled.div<ViewBaseProps>`
  display:flex;
  flex-direction: column;
  ${({backgroundColor}) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({height}) => height && `height: ${height}px;`}
  ${({width}) => width && `width: ${width}px;`}
  ${({padding}) => padding && `padding: ${padding}px;`}
  ${({borderRadius}) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({flex}) => flex && `flex: ${flex};`}
  ${({margin}) => margin && `margin: ${margin}px;`}
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  ${({marginLeft}) => marginLeft && `margin-left: ${marginLeft}px;`}
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${({paddingTop}) => paddingTop && `padding-top: ${paddingTop}px;`}
  ${({paddingBottom}) => paddingBottom && `padding-bottom: ${paddingBottom}px;`}
  ${({paddingHorizontal}) =>
    paddingHorizontal &&
    `padding-top: ${paddingHorizontal}px; padding-bottom: ${paddingHorizontal}px;`}
  ${({paddingVertical}) =>
    paddingVertical &&
    `padding-left:${paddingVertical}px; padding-right:${paddingVertical}px;`}
  ${({justifyContent}) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({alignItems}) => alignItems && `align-items: ${alignItems};`}
  ${({flexDirection}) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({center}) => center && 'align-items:center; justify-content:center'}
  ${({marginHorizontal}) =>
    marginHorizontal && `margin: 0 ${marginHorizontal}px;`}
  ${({marginVertical}) => marginVertical && `margin: ${marginVertical}px 0;`}
  ${({wrap}) => wrap && 'flex-wrap: wrap;'}
`;

type IReward = {
  type: string;
};

export const Reward = styled.img.attrs(({type}: IReward) => ({
  src:  Gamification.getReward(type).image,
}))<IReward>`
  align-self: center;
`;

export const Fixed = styled.div`
  position:fixed;
  width:100%;
  background:${({theme})=> theme.colors.background};
  z-index:20;
  .ant-picker-input{
    input{
      coursor:pointer;
      font-size: 15px;
      font-family: 'Poppins-Bold';
      color: ${({theme})=> theme.colors.text};
      width:130px;
    }
  }
`;

type ContainerProps = ViewBaseProps & {ignorePadding?: boolean};
export const Container = styled(ViewBase)<ContainerProps>`
  flex: 1;
  background-color: ${({theme}) => theme.colors.background};
  min-height:100vh;
`;
export const Content = styled(ViewBase)`
  flex: 1;
  padding: ${metrics.space.smaller}px ${metrics.space.medium}px;
  padding-bottom: 0px;
  padding: 5%;
`;
export const ContentValue = styled(ViewBase)`
  padding: ${({theme}) => theme.metrics.space.smaller}px
    ${({theme}) => theme.metrics.space.big}px;
  background: ${({theme}) => theme.colors.surface};
  border-radius: 5px;
  flex-direction: row;
`;
type RowProps = ViewBaseProps & {alignCenter?: boolean; spaceBetween?: boolean};
export const Row = styled(ViewBase)<RowProps>`
  flex-direction: row;
  ${({alignCenter}) => alignCenter && 'align-items: center;'}
  ${({spaceBetween}) => spaceBetween && 'justify-content: space-between;'}
`;
type ColumnProps = ViewBaseProps & {
  alignCenter?: boolean;
  spaceBetween?: boolean;
};
export const Column = styled(ViewBase)<ColumnProps>`
  flex-direction: column;
  ${({alignCenter}) => alignCenter && 'align-items: center;'}
  ${({spaceBetween}) => spaceBetween && 'justify-content: space-between;'}
`;

export const Section = styled(ViewBase)`
  padding: ${metrics.space.smaller}px 0px;
`;
export const Form = styled.form`
  flex: 1;
  padding: ${metrics.space.smaller}px ${metrics.space.medium}px;
  justify-content: center;
`;

export const ContentLabels = styled(ViewBase)`
  flex-direction: row;
  flex-wrap: wrap;
`;
type LabelSelectProps = ThemeProps & {
  selected?: boolean;
};
export const LabelSelect = styled(ViewBase)<LabelSelectProps>`
  padding: 5px ${metrics.space.small}px;
  cursor: pointer;
  border-radius: 20px;
  margin: 3px 1px;
  border: 1px solid ${({theme}) => theme.colors.surface};
  background: ${({selected, theme}) =>
    selected ? theme.colors.primary : 'transparent'};
`;
type ButtonContainerProps = {
  active?:boolean;
}

const ItemLabelContainer = styled.div<ButtonContainerProps>`
  border-radius: ${metrics.fontSize(5)}px;
  width: ${metrics.fontSize(50)}px;
  padding: ${metrics.fontSize(4)}px;
  margin: ${metrics.fontSize(4)}px;
  background-color: ${({active, theme}) =>
    active ? theme.colors.primary : theme.colors.surface};
  justify-content: center;
  cursor:pointer;
  align-items: center;
`;

type ItemLabelProps = {
  active:boolean;
  label:string;
  onPress:any
}
export const ItemLabel = ({active, label, onPress}:ItemLabelProps) => (
  <ItemLabelContainer active={active} onClick={onPress}>
    <TextBase bold={active} color={active ? 'white' : undefined}>
      {label}
    </TextBase>
  </ItemLabelContainer>
);
import {TextLabel} from '@/components/atoms';
import React, {Dispatch, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Color, PickerContainer} from './styles';

const colors = [
  '#1ABC9C',
  '#2ECC71',
  '#3498DB',
  '#9B59B6',
  '#34495E',
  '#16A085',
  '#27AE60',
  '#2980B9',
  '#8E44AD',
  '#2C3E50',
  '#F1C40F',
  '#E67E22',
  '#E74C3C',
  '#95A5A6',
  '#F39C12',
  '#D35400',
  '#7F8C8D',
  '#BDC3C7',
  '#e056fd',
  '#4834d4',
  '#130f40',
  '#6ab04c',
  '#7ed6df',
  '#22a6b3',
];

type Props = {
  color: string;
  setColor: Dispatch<React.SetStateAction<string>>;
};

const InputPickerColor: React.FC<Props> = ({color, setColor}: Props) => {
  const {t} = useTranslation();
  const [visible,setVisible] =useState(false)
  return (
    <div style={{width: '100%', display: 'flex',flexDirection: 'column'}}>
      <TextLabel>{t('color')}</TextLabel>
      <Color
        color={color}
        onClick={() => {
          setVisible(true);
        }}
        />
        {
          visible && (
            <PickerContainer>
              {
                colors.map(color=> (
                  <Color key={color} color={color} onClick={()=> {setColor(color); setVisible(false)}}/>
                ))
              }
            </PickerContainer>
          )
        }
    </div>
  );
};

export default InputPickerColor;

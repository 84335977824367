import React, { useEffect } from "react";
import { ButtonFloat } from "./styles";
import { AiOutlinePlus } from "react-icons/ai";
import DrawerRight from "../../components/DrawerRight";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarActions,
  CategoryActions,
  DrawerActions,
  selectorCalendar,
} from "@/store/modules";
import { ModeInterval } from "@/constants";
import { formatDateToServer } from "@/utils/date";
import { addDays, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";

const FloatButton = () => {
  const dispatch = useDispatch();
  const {action,payload} = useSelector((state) => {
    let payload = undefined
    let action = "ADD_TASK";
    if (state.app.pathname === "/lists") payload = {activity: {hasDate: false}};
    if (state.app.pathname === "/habits") action = "ADD_HABIT";
    if (state.app.pathname === "/goals") action = "ADD_GOAL";
    if (state.app.pathname === "/categories") action = "ADD_CATEGORY";
    return {action,payload};
  });
  const { lowerDate, upperDate } = useSelector(selectorCalendar);

  const modeInterval = useSelector((stt) => stt.app.modeInterval || 'weekly');
  const weekStartsOn = useSelector((stt) => stt.app.startOfWeek || 0);

  const getInterval = (modeInterval, date,weekStartsOn) => {
    if (modeInterval === ModeInterval.MONTHLY) {
      return {
        lowerDate: formatDateToServer(startOfMonth(date)),
        upperDate: formatDateToServer(endOfMonth(date)),
      };
    }
    if (modeInterval === ModeInterval.WEEKLY) {
      return {
        lowerDate: formatDateToServer(startOfWeek(date,{weekStartsOn})),
        upperDate: formatDateToServer(endOfWeek(date,{weekStartsOn})),
      };
    }
    if (modeInterval === ModeInterval.DAYS3) {
      return {
        lowerDate: formatDateToServer(addDays(date,-1)),
        upperDate: formatDateToServer(addDays(date,1)),
      };
    }
    if (modeInterval === ModeInterval.DAILY) {
      return {
        lowerDate: formatDateToServer(date),
        upperDate: formatDateToServer(date),
      };
    }
    return {
      lowerDate: formatDateToServer(startOfWeek(date,{weekStartsOn})),
      upperDate: formatDateToServer(endOfWeek(date,{weekStartsOn})),
    };
  };

  useEffect(() => {
    const interval = getInterval(modeInterval, new Date(),weekStartsOn);
    const lower = lowerDate === upperDate ? interval.lowerDate : lowerDate;
    const upper = upperDate === lowerDate ? interval.upperDate : upperDate;
    if(modeInterval !== ModeInterval.DAILY && lowerDate === upperDate && upperDate === lowerDate){
      dispatch(CalendarActions.setInterval(lower, upper));
      dispatch(CategoryActions.getCategoriesRequest());
    }else{
      dispatch(CalendarActions.getActivitiesRequest(lower, upper));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowerDate, upperDate, modeInterval]);

  return (
    <>
      <ButtonFloat
        onClick={() => dispatch(DrawerActions.setDrawer({ action, payload }))}
      >
        <AiOutlinePlus color="#fff" size={25} />
      </ButtonFloat>
      <DrawerRight />
    </>
  );
};

export default FloatButton;

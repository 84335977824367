import {Section, TextBase, TextSection} from '@/components/atoms';
import Chart from 'react-google-charts';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';

type Props = {
  title: string;
  weeklyChart: any;
};
const ContainerChart = styled.div`
  .chartWeeklyReport rect{
    fill: ${({theme})=> theme.colors.surface};
  }
  .chartWeeklyReport g text{
    fill: ${({ theme }) => theme.colors.onBackground};
  }
`;
const WeeklyGrowth = ({title, weeklyChart}: Props): JSX.Element => {
  const theme = useTheme();
  const {t} = useTranslation();
  const data = weeklyChart.labels.map((i:any,index:number)=> [weeklyChart.labels[index], weeklyChart.datasets[index]]);
  return (
    <Section>
      <TextSection>{title}</TextSection>
      {weeklyChart?.labels?.length > 0 ? (
        <ContainerChart>
          <Chart
            width={'400px'}
            height={'300px'}
            chartType="Bar"
            loader={<div>Loading...</div>}
            className="chartWeeklyReport"
            data={[
              ['Semana', ''],
              ...data,
            ]}
            rootProps={{ 'data-testid': '2' }}
            options={{
              backgroundColor:theme.colors.surface,
            }}
            style={{
              backgroundColor:theme.colors.surface,
            }}
          />
        </ContainerChart>
      ) : (
        <TextBase>{t('statistic:noWeekYet')}</TextBase>
      )}
    </Section>
  );
};

export default WeeklyGrowth;

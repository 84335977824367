import {TextBase, ViewBase} from '@/components/atoms';
import styled from 'styled-components';

export const BarContainer = styled(ViewBase)`
  flex-direction: row;
  height: 20px;
  flex: 1;
  background: ${({theme}) =>
    theme.title === 'dark' ? theme.colors.dark : theme.colors.lighter};
  border-radius: 10px;
  margin: 0px ${({theme}) => theme.metrics.space.smaller}px;
`;
export const BarFilled = styled(ViewBase)`
  height: 20px;
  background: ${({theme}) => theme.colors.primary};
  border-radius: 10px;
`;
export const Row = styled(ViewBase)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${({theme}) => theme.metrics.space.smaller}px;
`;
export const TextScore = styled(TextBase)`
  font-size: ${({theme}) => theme.metrics.font.small}px;
  font-family: 'Poppins-Bold';
  color: ${({theme}) => theme.colors.primary};
`;

import React from 'react';
import {MdOutlineCircle, MdOutlineCheckCircle} from 'react-icons/md';
import { useTheme } from 'styled-components';

const Check = ({value,onChangeValue}) => {
  const theme = useTheme()
  const Component = value ? MdOutlineCheckCircle : MdOutlineCircle
  return (
    <Component
      size={22} 
      style={{cursor:'pointer',marginRight:2, marginLeft:-3,color:theme.colors.onBackground}} 
      onClick={onChangeValue}
    />
  );
}

export default Check;
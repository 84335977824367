import React from 'react';
import {useSelector} from 'react-redux';
import {Column} from './styles';
import {Activity, ModeView, selectorModeView} from '@/store/modules';
import CardActivity from '@/components/molecules/CardActivity';
import {handleSort} from '@/utils/activity';

type Props = {
  day: Activity[];
  index: number;
  width?: number;
};
const ColumnMemorizate = ({day, index, width}: Props) => {
  const mode = useSelector(selectorModeView);
  const {sortBy} = useSelector((state: any) => state.filter.calendar);
  const key = (index: number, activity: any) =>
    `${index}-${activity.uid || activity.id}`;

  return (
    <Column>
      {day
        .sort((a, b) => handleSort(a, b, sortBy))
        .map((activity, index) =>
          mode === ModeView.Pending && activity.done ? null : (
            <CardActivity
              activity={activity}
              key={key(index, activity)}
              width={width}
            />
          ),
        )}
    </Column>
  );
};

export default ColumnMemorizate;

import styled from 'styled-components';

export const ActivityContainer = styled.div`
  margin: 4px;
  height:${({height})=> height || 45}px;
  width:95%;
  padding: 5px;
  background: ${({theme})=> theme.colors.surface};
  border-radius:5px;
  align-items:center;
  border-right: 5px solid ${({borderColor})=> borderColor || 'transparent'};
  cursor: pointer;
`;
export const Label = styled.span`
  /* font-weight: bold; */
  color:${({theme})=> theme.colors.onBackground};
  font-size:12px;
`;

import {ActivityInDate} from '@/store/modules';

export const TaskTypes = {
  GET_TASKS_REQUEST: 'task/GET_TASKS_REQUEST',
  GET_TASKS_SUCCESS: 'task/GET_TASKS_SUCCESS',

  GET_TASK_REQUEST: 'task/GET_TASK_REQUEST',
  GET_TASK_SUCCESS: 'task/GET_TASK_SUCCESS',

  ADD_TASK_REQUEST: 'task/ADD_TASK_REQUEST',
  ADD_TASK_SUCCESS: 'task/ADD_TASK_SUCCESS',
  ADD_TASK: 'ADD_TASK',

  UPDATE_TASK_REQUEST: 'task/UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS: 'task/UPDATE_TASK_SUCCESS',
  UPDATE_TASK: 'UPDATE_TASK',

  DELETE_TASK_REQUEST: 'task/DELETE_TASK_REQUEST',
  DELETE_TASK_SUCCESS: 'task/DELETE_TASK_SUCCESS',
  DELETE_TASK: 'DELETE_TASK',

  CHECK_TASK_REQUEST: 'task/CHECK_TASK_REQUEST',
  CHECK_TASK_SUCCESS: 'task/CHECK_TASK_SUCCESS',
  CHECK_TASK: 'CHECK_TASK',

  UPDATE_TASK_DATE_REQUEST: 'task/UPDATE_TASK_DATE_REQUEST',
  UPDATE_TASK_DATE_SUCCESS: 'task/UPDATE_TASK_DATE_SUCCESS',
  UPDATE_TASK_DATE: 'UPDATE_TASK_DATE',

  ADD_TASKS_PLANNER_REQUEST: 'task/ADD_TASKS_PLANNER_REQUEST',

  SET_FAILURE: 'task/SET_FAILURE',
  SET_INITIAL_STATE: 'task/SET_INITIAL_STATE',
};

export interface Notification {
  hour:string;
  sound:string;
}

export type CheckList = {
  text: string;
  done: boolean;
};
export interface TaskDate {
  id?: number;
  date: string;
  note: string;
  deleted: boolean;
  done: boolean;
  checkList: CheckList[] | null
}

export type TaskModel = {
  id?: number;
  uid?: string;
  user_id: number;
  title: string;
  description: string | null;
  checkList: CheckList[] | null;
  startDate: string;
  endDate: string;
  endHour: string | null;
  startHour: string | null;
  period: string;
  receiveNotification: boolean;
  soundNotification: string | null;
  categories: string[] | null;
  notifications:Notification[];
  label: string;
  createdAt: string;
  updatedAt?: string;
  reschedule: boolean;
  dones: ActivityInDate[];
};

export type Task = TaskModel & {
  date: string;
  done: boolean;
};

export interface TaskState {
  tasks: Array<TaskModel>;
  loading: boolean;
  lastSync: null | Date;
}

export interface AddTask {
  user_id: number;
  uid: string;
  title: string;
  label: string;
  period: string;
  startDate: string;
  endDate: string;
  startHour: string | null;
  endHour: string | null;
  createdAt: string;
  description: string | null;
  receiveNotification: boolean;
  notifications:Notification[];
  soundNotification: string | null;
  checkList: CheckList[] | null;
  categories: string[] | null;
  dones: TaskDate[];
}

export type CheckTask = {
  id: number;
  uid: string;
  done: boolean;
  date: string;
  label: string;
  receiveNotification: boolean;
};
export interface UpdateTask {
  id?: number;
  uid?: string;
  date: string;
  user_id: number;
  title: string;
  label: string;
  period: string;
  startDate: string;
  endDate: string;
  startHour: string | null;
  endHour: string | null;
  createdAt: string;
  description: string | null;
  receiveNotification: boolean;
  soundNotification: string | null;
  checkList: CheckList[] | null;
  categories: string[] | null;
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Row} from '@/components/atoms';

const TabOption = styled.div`
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  margin-right: 4px;
  background-color: ${({theme,selected}) =>  selected ? theme.colors.primary : theme.colors.surface};
  color: ${({theme, selected}) =>
    selected ? theme.colors.onPrimary : theme.colors.onBackground};
  font-size: 13px;
  font-family: 'Poppins-Bold';
  &:hover {
    color: ${({theme, selected}) => selected ? theme.colors.onPrimary : theme.colors.onBackground};
    background-color: ${({theme}) => theme.colors.primary};
  }
`;
const HeaderDetails = ({indexTab, setIndexTab, type, tabs}) => {
  const {t} = useTranslation();
  return (
    <>
      <Row style={{marginTop: -30, marginBottom: 10}}>
        {tabs
          .filter(i =>
            type === 'goal'
              ? ![t('statistic:title'), t('detailsDay')].includes(i.title)
              : true,
          )
          .map((i) => (
            <TabOption
              onClick={() => {
                setIndexTab(tabs.map(i => i.title).indexOf(i.title));
              }}
              selected={i.title === tabs[indexTab].title}>
              {t(i.title).toUpperCase()}
            </TabOption>
          ))}
      </Row>
      {tabs[indexTab]?.Component}
    </>
  );
};
export default HeaderDetails;

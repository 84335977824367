export default {
  unknown: 'Se produjo un error :(',
  tryAgainLogin:
    'Intente ingresar a la aplicación creando una cuenta con email y contraseña.',
  canceled: 'Cancelado',
  serviceUnavailable: 'Servicio no disponible',
  emailIsRequired: 'El campo de email es obligatorio',
  categoryIsRequired: 'El campo de nombre de categoría es obligatorio',

  checkPasswordAgain: 'Las contraseñas no coinciden',
  msgCheckPasswordAgain:
    'Los campos de contraseña y nueva contraseña deben coincidir',

  validateFailure: 'Validación fallida',
  msgValidateFailure: 'Ingrese los campos correctamente',

  selectActivities: '¡Seleccione las actividades que desea eliminar!',
  selectMore: `Seleccionar más actividades de {{qnt}}`,
  selectHour: '¡Primero seleccione una hora!',
  typeOnlyNumber: 'Escriba solo números',
  selectionDate: 'No se pudo seleccionar la fecha',
  periodValid: 'Ingrese una opción válida para el campo Frecuencia',
  limitReached: '¡Has alcanzado el límite de tu {{activity}}!',
  onlyPremium: 'Solo disponible para usuarios Premium 👑',
  checkListRequired:
    'Complete el campo para agregar un elemento a la lista de verificación',
  validation: 'Por favor, complete los campos obligatorios (*)',
  validationEmail: 'Email no válido :(',

  syncRanking: 'Sin conexión: no se puede actualizar la clasificación',
  tokenInvalid: 'Por favor, inicie sesión de nuevo',
  tokenInvalidMsg: 'Su sesión ha expirado, por favor inicie sesión nuevamente',
  versionUnavailable: '¡Versión descontinuada!',
  versionUnavailableMsg:
    '¡Esta versión ha sido descontinuada, actualice la aplicación en la Tienda!',

  noConnection: 'Sin conexión',
  noConnectionMsg: 'Verifique su conexión a Internet',
  requestSubMsg:
    'Verifique su conexión a Internet y vuelva a intentarlo, si el error persiste, contáctenos por email!',
  purchaseSubMsg:
    'Si recibe algún error durante la compra, por favor contáctenos!',
  openUrl: `Intenta abrir el enlace {{url}} desde el navegador`,

  validationError: 'Datos no válidos :(',
  validationErrorMsg: '¡Por favor ingrese los campos correctamente!',
  loginError: 'Correo electrónico o contraseña no válidos :(',
  loginErrorMsg: '¡Ingrese sus datos correctamente!',
  userAlreadyExists: '¡Usuario ya registrado!',
  userAlreadyExistsMsg: 'Inténtelo de nuevo con otro email',
  userNotExists: 'Usuario no encontrado.',
  userNotExistsMsg: 'El email ingresado no pertenece a ningún usuario.',
  notFound: 'Recurso no encontrado',
  notFoundMsg: 'No se encontró el recurso solicitado',
  limitImport:
    'El límite para las tareas futuras es de {{limit}}, desmarque {{minDelete}} tareas',
  } as any;

import React from 'react';

import styled, {useTheme} from 'styled-components';

import {Row} from '@/components/atoms';
import {metrics} from '@/styles';
import {FiPlus} from 'react-icons/fi';

type ButtonContainerProps = {
  backgroundColor?: string;
};
export const ItemLabelTime = styled.div<ButtonContainerProps>`
  border-radius: ${metrics.fontSize(5)}px;
  padding: ${metrics.fontSize(4)}px;
  margin: ${metrics.fontSize(2)}px;
  background-color: ${({backgroundColor}) => backgroundColor};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
type Label = {
  name: string;
  selected: boolean;
  sound: string;
  hour: string;
};

type Props = {
  labels: Label[];
  onAddLabel?(): void;
  onSelectLabel(item: Label): void;
};
const ListLabelOption: React.FC<Props> = ({
  labels,
  onAddLabel,
  onSelectLabel,
}) => {
  const theme = useTheme();
  return (
    <Row wrap>
      {labels.map(i => (
        <ItemLabelTime
          key={String(i.name)}
          onClick={() => onSelectLabel(i)}
          backgroundColor={
            i.selected ? theme.colors.primary : theme.colors.surface
          }>
          <span
            style={{
              color: i.selected
                ? theme.colors.onPrimary
                : theme.colors.onSurface,
            }}>
            {i.name}
          </span>
        </ItemLabelTime>
      ))}

      {onAddLabel && (
        <ItemLabelTime
          onClick={onAddLabel}
          backgroundColor={theme.colors.surface}>
          <FiPlus
            name="plus"
            color={theme.colors.onSurface}
            size={metrics.fontSize(20)}
          />
        </ItemLabelTime>
      )}
    </Row>
  );
};

export default ListLabelOption;

import {TextSection} from '@/components/atoms';
import {fontSize} from '@/styles/metrics';
import styled from 'styled-components';

export const ContainerCardItemList = styled.div`
  height: ${fontSize(40)}px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.surface};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  padding: 8px;
`;
export const ContainerList = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px;
  min-height: 70vh;
  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.surface};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.surfaceLight};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.colors.placeholder};
  }
`;

export const ColumnContainer = styled.div`
  margin: 0 5px;
  border: 1px solid ${({theme}) => theme.colors.surface};
  border-radius: 6px;
  min-width: 200px;
  background-color: ${({theme}) => theme.colors.list};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const Title = styled(TextSection)`
  padding: 6px 12px;
  background-color: ${({theme}) => theme.colors.surface};
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
  border-bottom: 2px solid ${({theme}) => theme.colors.surfaceLight};
`;

export const TaskList = styled.div`
  padding: 10px 5px;
  flex-grow: 1;
  min-height: 50px;
  overflow-y: auto;
  max-height: 600px;

  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.surface};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.surfaceLight};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.colors.placeholder};
  }
`;

export const TaskContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonAddTask = styled.button`
  cursor: pointer;
  border: 1px solid ${({theme}) => theme.colors.surfaceLight};
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.colors.textLabel};
  font-weight: bold;
  padding: 4px 0px;
  font-size: 13px;
`;

import { v4 as uuidv4 } from 'uuid';

export const uid = (): string => uuidv4() as string;
export const idNumber = (): number => Math.floor(Math.random() * 9999);

type CompareId = {
  id?: number;
  uid?: string;
};
export const isSameId = (
  activityA: CompareId,
  activityB: CompareId,
): boolean => {
  if (activityA?.id && activityB?.id) {
    return activityA.id === activityB.id;
  }
  if (activityA?.uid && activityB?.uid) {
    return activityA.uid === activityB.uid;
  }
  return false;
};

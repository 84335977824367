import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";

import {
  Row,
  Section,
  TextBase,
  TextLabel,
  ViewBase,
} from "@/components/atoms";
import { ImageReward } from "@/pages/Statistics/Rewards/styles";
import { Gamification } from "@/services";
import { RewardActions, selectorReward } from "@/store/modules";

// import { Container } from './styles';

type Props = {
  activity: any;
  bestSequence: number;
  totalDaysDone: number;
  rewards: any[];
};

const ListRewardsActivity: React.FC<Props> = ({
  activity,
}: Props) => {
  const { rewards:_rewards, lastSync } = useSelector(selectorReward);
  const rewards = _rewards.filter(i=> JSON.stringify(Object.values(i.data||{})).includes(activity.id ||activity.uid) )
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const allRewards = Gamification.rewards
    .filter((i) =>
      (activity.routine ? ["habit", "activity"] : ["activity"]).some((r) =>
        i.key.includes(r)
      )
    )
    .filter((i) => !i.key.includes("add"));

  useEffect(() => {
    if (!lastSync) {
      dispatch(RewardActions.getRewardRequest());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <TextLabel>{t("statistic:rewards")} </TextLabel>

      <Row wrap>
        {allRewards.map((item) => (
          <ViewBase
            key={item.key}
            style={{
              opacity: rewards.some((i) => i.key === item.key) ? 1 : 0.25,
            }}
            center
            flex={1}
            backgroundColor={theme.colors.surface}
            padding={theme.metrics.fontSize(6)}
            borderRadius={theme.metrics.space.small}
            marginBottom={theme.metrics.space.smaller}
            marginLeft={theme.metrics.space.smaller}
          >
            <ImageReward
              src={Gamification.getReward(item.key).image}
              style={{ height: 70, objectFit: "contain" }}
            />
            <TextBase small center>
              {t(`statistic:${item.key}`)}
            </TextBase>
          </ViewBase>
        ))}
      </Row>
    </Section>
  );
};

export default ListRewardsActivity;

import React from 'react';
import {Label,InputContainer} from '../../styles/global';
import { useTheme } from 'styled-components';
import { Select as SelectAnt} from 'antd';
const { Option } = SelectAnt;

const Select = ({placeholder,value,onChangeValue,label, data}) => {
  const theme = useTheme()
  return (
    <InputContainer>
      <Label>{label}</Label>
      <SelectAnt
        value={value ? value : undefined}
        placeholder={placeholder}
        onChange={value=> onChangeValue(value)}
        className={theme.title === 'dark' ? 'input-dark': 'input-light'}
        >
      {
        data.map(
          i => 
          <Option
          key={i.value}
          value={i.value}
          // className={theme.title === 'dark' ? 'input-dark': 'input-light'}
          // style={{backgroundColor: theme.colors.background, borderColor:theme.colors.surface}}
          >
            {i.label}
          </Option> 
        )
      }
      </SelectAnt>
    </InputContainer>
  );
}

export default Select;
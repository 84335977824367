import {TextBase} from '@/components/atoms';
import colors from '@/styles/colors';
import metrics from '@/styles/metrics';
import styled from 'styled-components';

export const Title = styled.h1`
  font-size: ${Math.round(metrics.font.bigger * 1.2)}px;
  text-align: center;
  font-weight: 500;
  color: ${colors.primary};
  padding: ${metrics.space.big}px 0;
`;
export const Label = styled.span`
  font-size: ${metrics.font.medium}px;
  color: ${colors.primary};
`;
export const Section = styled.div`
  margin-top: ${metrics.space.medium}px;
`;
export const Description = styled.p`
  font-size: ${metrics.font.medium}px;
  font-weight: 300;
  text-align: center;
  color: ${({theme}) => theme.colors.text};
`;
export const Text = styled(TextBase)`
  font-size: ${metrics.font.small}px;
  text-align: center;
  color: ${({theme}) => theme.colors.text};
`;
export const Caption = styled.p`
  font-size: ${metrics.font.smaller}px;
  color: ${colors.primary};
`;
export const Content = styled.div`
  flex: 1;
  padding: ${metrics.space.big}px;
`;

import { getDataChartByWeek } from '@/pages/Statistics/Reports/utils';
import { Section, TextSection } from '@/components/atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-google-charts';
import { useTheme } from 'styled-components';

type Props = {
  dataChart: any;
  period: string;
  lowerDate: string;
  upperDate: string;
};

const Historic: React.FC<Props> = ({
  period,
  dataChart,
  upperDate,
  lowerDate,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const locale = t('locale:react-native-calendars', {
    returnObjects: true,
  }) as { dayNamesShort: string[] };
  const data = getDataChartByWeek(lowerDate, upperDate, period, dataChart).map((i:any)=> i.data.map((i:any)=> i.score));
  const dat = locale.dayNamesShort.map((day:string, index:number) => [day].concat(data.map((item:number,i:number) => data[i][index])))
  return (
    <Section>
      <TextSection>
        {period === 'weekly'
          ? t('statistic:historicWeekly')
          : t('statistic:historicWeekly')}
      </TextSection>
      <Chart
        width={'500px'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['dia'].concat(data.map((i:any, index:number)=> `SEM_${index+1}`)),
          ...dat
        ]}
        rootProps={{ 'data-testid': '2' }}
        options={{
          backgroundColor:theme.colors.background
        }}
      />
    </Section>
  );
};

export default Historic;

import {ViewBase} from '@/components/atoms';
import AddNote from '@/components/molecules/AddNote';
import DailyGoal from '@/components/molecules/DailyGoal';
import {ActivityService} from '@/services';
import {DrawerActions} from '@/store/modules';
import {getDateToday} from '@/utils/date';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Menu} from './styles';
import {Modal} from 'antd';
import {InputDate, InputHour} from '@/components/molecules';
import {PERIOD} from '@/constants';
import MoveToChecklist from '../MoveToChecklist';

type Props = {
  activity: any;
};

export default function ContentActions({activity}: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState(activity.date || getDateToday());
  const [startHour, setStartHour] = useState(activity.startHour || null);
  const [endHour, setEndHour] = useState(activity.endHour || null);
  const [dailyGoal, setDailyGoal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibilityDatePicker, setVisibilityDatePicker] = useState(false);
  const [moveToChecklist, setMoveToChecklist] = useState<any>(null);

  function closePopup() {
    const elementsRightTop: any = document.getElementsByClassName(
      'ant-popover ant-popover-placement-rightTop',
    );
    const elementsRightBottom: any = document.getElementsByClassName(
      'ant-popover ant-popover-placement-rightBottom',
    );
    const elements = [...elementsRightTop, ...elementsRightBottom];
    const classHiden = 'ant-popover-hidden';
    for (const item of elements) {
      if (!item.className.includes(classHiden)) {
        item.className = item.className + ' ' + classHiden;
      }
    }
  }
  function handleDone(e: any) {
    closePopup();
    e.stopPropagation();
    ActivityService.done(activity);
  }
  function handleAddNote(e: any) {
    closePopup();
    e.stopPropagation();
    setVisible(true);
  }
  function handleStartTimer(e: any) {
    closePopup();
    e.stopPropagation();
    dispatch(
      DrawerActions.setDrawer({
        action: `TIMER`,
        payload: {activity: activity, tab: 1},
      }),
    );
  }

  function handleEdit(e: any) {
    closePopup();
    e.stopPropagation();
    const action = activity.specific
      ? 'EDIT_GOAL'
      : activity.routine
      ? 'EDIT_HABIT'
      : 'EDIT_TASK';
    dispatch(
      DrawerActions.setDrawer({
        action: action,
        payload: {activity: activity},
      }),
    );
  }
  function handleDuplicate(e: any) {
    closePopup();
    e.stopPropagation();
    const action = activity.specific
      ? 'ADD_GOAL'
      : activity.routine
      ? 'ADD_HABIT'
      : 'ADD_TASK';
    dispatch(
      DrawerActions.setDrawer({
        action: action,
        payload: {activity: {...activity, id: undefined, uid: undefined}},
      }),
    );
  }
  function handleDelete(e: any) {
    closePopup();
    e.stopPropagation();
    ActivityService.handleDelete(activity);
  }
  function handleAddAmount(e: any) {
    closePopup();
    e.stopPropagation();
    setDailyGoal(true);
  }
  function onCheckAmount(amount: number) {
    setDailyGoal(false);
    const nextAmount = amount + activity?.amount || 0;
    ActivityService.updateInDate(activity, activity.date || getDateToday(), {
      amount: nextAmount,
      done: !activity?.done ? nextAmount >= activity?.goal?.amount : true,
    });
  }

  function onReschedule() {
    const hasChange =
      activity.date !== date ||
      activity.startHour !== startHour ||
      activity.endHour !== endHour;

    if (!hasChange) {
      return;
    }
    // alterar dia de uma tarefa recorrente
    if (activity.title && activity.period !== PERIOD.JUST_TODAY) {
      ActivityService.reschedule(
        {...activity, startHour, endHour},
        activity.date,
        date,
      );
    }
    if (activity.title && activity.period === PERIOD.JUST_TODAY) {
      ActivityService.update({
        ...activity,
        startHour,
        endHour,
        startDate: date,
        endDate: date,
      });
    }
  }

  function handleChangeDate(e: any) {
    e.stopPropagation();
    setVisibilityDatePicker(true);
    closePopup();
  }

  function handleTransformInChecklist(e: any) {
    e.stopPropagation();
    closePopup();
    setMoveToChecklist({task: activity});
  }

  return (
    <Menu>
      <span onClick={handleDone}>
        {activity.done ? t('notDone') : t('done')}
      </span>
      {activity?.goal?.amount && (
        <span onClick={handleAddAmount}>{t('add') + ' ' + t('dailyGoal')}</span>
      )}
      <span onClick={handleAddNote}>{t('addNote')}</span>
      <span onClick={handleStartTimer}>{t('timer')}</span>
      {activity.title && activity.date && (
        <ViewBase marginTop={6} marginBottom={6}>
          <span onClick={handleChangeDate}>{t('task:reschedule')}</span>
        </ViewBase>
      )}
      {activity.title && (
        <ViewBase marginTop={6} marginBottom={6}>
          <span onClick={handleTransformInChecklist}>
            {t('task:moveToChecklist')}
          </span>
        </ViewBase>
      )}
      <span onClick={handleDuplicate}>{t('duplicate')}</span>
      <span onClick={handleEdit}>{t('edit')}</span>
      <span onClick={handleDelete}>{t('delete')}</span>
      {dailyGoal && (
        <DailyGoal
          title={t('dailyGoal')}
          onCheckAmount={onCheckAmount}
          unity={activity?.goal?.unity}
          amount={activity?.amount}
          amountTotal={activity?.goal?.amount}
          setVisible={setDailyGoal}
          visible={dailyGoal}
          activity={activity}
        />
      )}

      {visible && (
        <AddNote
          setVisible={setVisible}
          visible={visible}
          initialDate={activity?.date}
          activity={activity}
        />
      )}
      {moveToChecklist && (
        <MoveToChecklist
          {...(moveToChecklist || {})}
          visible={!!moveToChecklist}
          close={() => setMoveToChecklist(null)}
        />
      )}

      <Modal
        title={t('task:reschedule')}
        open={visibilityDatePicker}
        onCancel={e => {
          e.stopPropagation();
          setVisibilityDatePicker(false);
          onReschedule();
        }}
        onOk={e => {
          e.stopPropagation();
          setVisibilityDatePicker(false);
          onReschedule();
        }}>
        <ViewBase
          onClick={e => {
            e.stopPropagation();
          }}>
          <InputDate
            label={t('task:startDate')}
            value={date}
            onChangeValue={setDate}
            testID="input_start_date"
          />
          <InputHour
            startHour={startHour}
            setStartHour={setStartHour}
            endHour={endHour}
            setEndHour={setEndHour}
          />
        </ViewBase>
      </Modal>
    </Menu>
  );
}

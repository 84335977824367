import React, {forwardRef, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {TextLabel, ViewBase} from '@/components/atoms';
import {InputDate} from '@/components/molecules';
import Button from '@/components/molecules/Button';
import {metrics} from '@/styles';
import {getDateToday} from '@/utils/date';
import BottomSheet from '@/components/BottomSheet';
import {PickerTimeComponent} from '@/pages/Timer/PickerTime';

type Props = {
  item: {
    stopwatch?: number;
    timer?: number;
    date: string;
  };
  handleSave(item: {timer: number; date: string}): void;
};

const AddTimer = forwardRef(({item, handleSave}: Props, ref: any) => {
  const {t} = useTranslation();
  const [timer, setTimer] = useState(item?.timer ?? item?.stopwatch ?? 0);
  const [date, setDate] = useState(item?.date);
  useEffect(() => {
    if (item) {
      setTimer(item?.timer ?? item?.stopwatch ?? 0);
      setDate(item?.date ?? getDateToday());
    }
  }, [item]);

  const label = 'time';
  const submit = () => {
    handleSave({timer, date});
  };
  return (
    <BottomSheet
      ref={ref}
      height={metrics.fontSize(400)}
      title={t(label).toUpperCase()}>
      <ViewBase center>
        <ViewBase width={500}>
          {date && (
            <ViewBase height={metrics.fontSize(60)}>
              <InputDate
                label={t('date')}
                value={date}
                onChangeValue={setDate}
              />
            </ViewBase>
          )}
          <TextLabel>{t('time')}</TextLabel>
          <PickerTimeComponent time={timer} setTime={setTimer} />
          <Button
            title={t('save')}
            marginTop={metrics.space.medium}
            onPress={submit}
            borderRadius={metrics.fontSize(6)}
          />
        </ViewBase>
      </ViewBase>
    </BottomSheet>
  );
});

export default AddTimer;

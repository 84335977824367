import { metrics} from '@/styles';
import {
  InputContainer,
  Row,
  TextValue,
  ViewBase,
} from '@/components/atoms';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import Select from '../Select';
import { Switch } from 'antd';
import { Label } from '@/styles/global';
import Input from '../Input';
import { useState } from 'react';
import { parseToNumber } from '@/utils/string';

interface Props {
  goal: any;
  setGoal(value: any): void;
}

const InputDailyGoal = ({
  goal,
  setGoal,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const theme = useTheme();
  function handleChangeDailyGoal() {
      setGoal((stt:any)=> stt ? null :({
        amount: 1,
        unity: 'count'
      }));
  }
  const [units] = useState([
    'time',
    'count',
    'min',
    'hour',
    'km',
    'page',
    'copo',
  ]);
  const setUnity = (v:string)=> setGoal((stt:any)=> ({...stt, unity: v}))
  const setAmount = (v:string)=> setGoal((stt:any)=> ({...stt, amount: parseToNumber(v)}))

  const showUnity = (u:string) => {
    return t(`unity:${u}`).replace('unity:', '');
  };

  return (
    <>
      <InputContainer  >
        <Row alignCenter spaceBetween>
          <ViewBase>
            <Label>{t('dailyGoal')}</Label>
            <TextValue
              style={{
                fontSize: 14,
                color: theme.colors.placeholder,
                paddingLeft: metrics.space.small,
              }}>
              {  t('exampleDailyGoal')}
            </TextValue>
          </ViewBase>
          <Switch defaultChecked={goal} onChange={handleChangeDailyGoal} style={{backgroundColor: theme.colors.surface, color: theme.colors.onBackground, borderColor: theme.colors.regular}} />
        </Row>
        
        {goal && (
          <div style={{marginLeft: 10}}>
            <Input
          value={goal?.amount ||''}
          onChangeText={setAmount}
          label={t('amount')}
          placeholder="Ex: 5"
        />
        <Select
          value={goal?.unity||''}
          setValue={setUnity}
          data={units.map(i => ({value: i, label: showUnity(i)}))}
          label={t('unity')}
          placeholder={`Ex: ${t('unity:km')}`}
        />
          </div>
        )}
      </InputContainer>
    </>
  );
};

export default InputDailyGoal;

import styled from 'styled-components';

export const Section = styled.div`
  padding: ${({theme}) => theme.metrics.space.small}px;
`;
export const ContentFilter = styled.div`
  margin-left: ${({theme}) => theme.metrics.space.small}px;
  margin-top: ${({theme}) => theme.metrics.space.small}px;
`;
type TextTagProps = {
  selected: boolean;
};
export const TextTag = styled.span<TextTagProps>`
  color: ${({selected, theme}) =>
    selected ? theme.colors.onPrimary : theme.colors.text};
  font-weight: ${({selected}) => (selected ? 'bold' : 'normal')};
`;

import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {TaskContainer} from './styles';
import CardActivity from '@/components/molecules/CardActivity';

const Task = ({task, column, index}: any) => {
  return (
    <Draggable draggableId={task.id + '#' + String(column.uid)} index={index}>
      {provided => (
        <TaskContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <CardActivity activity={task} width={250} />
        </TaskContainer>
      )}
    </Draggable>
  );
};

export default Task;

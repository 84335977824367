import {TextBase} from '@/components/atoms';
import styled from 'styled-components';

export const ButtonTransparent = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  cursor:pointer;
`;

export const TextHeaderTable = styled(TextBase)`
  margin-bottom: 10px;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.textSection};
  font-family: 'Poppins-Bold';
  font-size: ${({theme}) => theme.metrics.font.medium}px;
`;

export const ContentWeek = styled.div`
  flex: 1;
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({theme}) => theme.metrics.space.small}px
    ${({theme}) => theme.metrics.space.medium}px;
`;

type TextSubHeaderProps = {
  active: boolean;
};
export const TextSubHeader = styled(TextBase)<TextSubHeaderProps>`
  color: ${({active, theme}) =>
    active ? theme.colors.onPrimary : theme.colors.text};
  font-weight: ${({active}) => (active ? 'bold' : 'normal')};
  background-color: ${({theme,active})=> active ? theme.colors.primary : theme.colors.surface};
  padding: 4px;
  border-radius: 4px;
  width: 75%;
  text-align: center;;
`;

export const ROUTES = {
  WELCOME: `WELCOME`,
  TUTORIAL_TASKS: `TUTORIAL_TASKS`,
  TUTORIAL_HABITS: `TUTORIAL_HABITS`,
  TUTORIAL_GOALS: `TUTORIAL_GOALS`,

  LOGIN: `Login`,
  RESET_PASSWORD: `ResetPassword`,
  REGISTER: `Register`,
  RECOVERY_PASSWORD: `RecoveryPassword`,

  TASKS: `Tasks`,
  ADD_TASK: `AddTask`,
  TASK_DETAILS: `TaskDetails`,

  HABITS: `habits`,
  ADD_HABIT: `AddHabit`,
  HABIT_DETAILS: `HabitDetails`,

  GOALS: `goals`,
  ADD_GOAL: `AddGoal`,
  GOAL_DETAILS: `GoalDetails`,

  STATISTICS: `statistics`,
  RANKING: `ranking`,
  GAMIFICATION: `gamification`,
  REPORTS: `reports`,
  REPORT: `report`,
  GALLERY: `gallery`,
  MY_REWARDS: `my-rewards`,

  GENERAL: `settings`,
  EDIT_PROFILE: `edit-profile`,
  PREMIUM: `Premium`,
  WEB: `Web`,
  EXTENTION: `Extention`,
  PURCHASES: `Purchases`,
  NOTIFICATIONS: `Notifications`,
  CATEGORIES: `categories`,
  ADD_CATEGORY: `add-category`,
};

import {call, put} from 'redux-saga/effects';
import {Gamification, api, Analytics} from '@/services';
import {RankingActions} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import {Action} from '../utils';

export function* getRanking({payload}: Action): SagaIterator<void> {
  const {period, type} = payload;
  try {
    const {data} = yield call(api.get, 'ranking', {params: {period, type}});
    const ranking = data.map((item: any, index: number) => ({
      ...item,
      level: Gamification.getLevelUser(item.geral).textIndex,
      position: item.position || index + 1,
    }));
    yield put(RankingActions.getRankingSuccess(ranking));
    Analytics.setUserProperty(`Ranking_${period}`);
  } catch (err) {
    yield put(RankingActions.setFailure());
  }
}

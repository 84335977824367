import styled from 'styled-components';
import {metrics} from '@/styles';
import {ViewBase, ViewBaseProps} from '@/components/atoms';

type ContentProps = ViewBaseProps & {borderColor?: string};
export const ContentActivity = styled(ViewBase)<ContentProps>`
  /* flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  height: ${({height})=> height}px;
  padding: ${metrics.space.smaller}px;
  border-right: 7px solid ${({borderColor}) => borderColor};
  border-radius: 10px;
  cursor:pointer;
  box-shadow: 1px 1px 3px 1px ${({theme})=>theme.colors.shadow};
`;

export default {
  noGoalsFilter: '¡Sin goles según los filtros aplicados!',
  noGoals: 'Todavía no tienes ningún objetivo',
  addGoal: 'Agregar objetivo',
  howToAddGoal: 'Cómo crear metas',
  editGoal: 'Editar objetivo',
  total: 'Total',
  performed: 'Completado',

  specific: '¿Cuál es tu objetivo?',
  measurable: '¿Qué tan grande es tu meta?',
  date: '¿Cuál es la fecha límite?',
  relevant: '¿Por qué es importante este objetivo?',
  habits: 'Hábitos relacionados',
  tasks: 'Tareas relacionadas',
  noneHabit: '¡No se asociaron hábitos con este objetivo!',
  noneTask: '¡No se han asociado tareas con este objetivo!',

  placeholderSpecific: 'Ex: Leer 2 libros en enero',
  placeholderMeasurable: 'Ex: 2',
  placeholderDate: 'Ex: 31/01/2021',
  placeholderRelevant: 'Ex: Adquirir conocimiento',
  placeholderHabits: 'Seleccionar hábitos',
  placeholderTasks: 'Seleccionar tareas',
  metricProgressBy: 'Medir el progreso por',
  selectHabit: 'Seleccionar hábito',
} as any;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from 'styled-components';
import {differenceInSeconds, getTimeStringFromSecunds} from '@/utils/date';
import {TextLabel} from '@/components/atoms';
import {
  DrawerActions,
  selectorDrawer,
  selectorStopwatch,
  selectorTimer,
  Stopwatch,
  Timer,
  TimerActions,
} from '@/store/modules';
import {getLabel} from './utils';
import NotificationLocal from '@/services/NotificationLocal';

const FloatTimer: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const timer = useSelector(selectorTimer);
  const stopwatch = useSelector(selectorStopwatch);
  const [show, setShow] = useState(false);
  const [sentNotification, setSentNotification] = useState(false);
  const [render, setRender] = useState(0);
  const interval = useRef<NodeJS.Timeout>();
  const {action} = useSelector(selectorDrawer);
  const audio = useRef<HTMLAudioElement | null>(null);

  function getTime() {
    if (timer.finishAt) {
      if (timer.status === 'pause' && timer?.pausedAt)
        return differenceInSeconds(timer?.finishAt, timer?.pausedAt);
      return differenceInSeconds(timer?.finishAt || new Date(), new Date());
    }
    if (stopwatch.startAt) {
      if (stopwatch.status === 'play')
        return differenceInSeconds(new Date(), stopwatch.startAt);
      if (stopwatch.status === 'pause' && stopwatch?.pausedAt)
        return differenceInSeconds(stopwatch?.pausedAt, stopwatch.startAt);
    }
    return 0;
  }
  const time = getTime();

  const timeString =
    (time < 0 ? '+' : '') +
    (60 * 60 <= Math.abs(time)
      ? getTimeStringFromSecunds(Math.abs(time))
      : getTimeStringFromSecunds(Math.abs(time)).replace('00:', ''));

  const hasTimer = (stt: Timer) => stt?.activity && stt?.finishAt;

  const hasStopwatch = (stt: Stopwatch) => stt?.activity && stt?.startAt;

  const playTimer = useMemo(
    () => hasTimer(timer) || hasStopwatch(stopwatch),
    [timer, stopwatch],
  );

  useEffect(() => {
    if (playTimer && action !== 'TIMER') {
      setShow(true);
    } else setShow(false);
  }, [playTimer, action]);

  useEffect(() => {
    if (show) {
      interval.current = setInterval(() => setRender(Date.now()), 1000);
    } else if (interval.current) {
      clearInterval(interval.current);
    }
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [show]);

  function getTitleActivity() {
    if (!timer?.activity) return '';
    if ('title' in timer?.activity) return timer?.activity?.title;
    if ('routine' in timer?.activity) return timer?.activity?.routine;
    if ('specific' in timer?.activity) return timer?.activity?.specific;
    return '';
  }

  function getMessageNotification() {
    return `${getLabel(timer?.time || 0)} - ${getTitleActivity()}`;
  }

  useEffect(() => {
    if ((time || 0) <= 0 && show && hasTimer(timer) && !sentNotification) {
      NotificationLocal.push(getMessageNotification());
      setSentNotification(true);
      dispatch(
        TimerActions.setTimer({
          ...timer,
          status: 'finish',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  const playSound = (url: string, onEnded: () => void) => {
    audio.current = new Audio(url);
    audio.current?.play?.().then(() => {
      audio.current?.addEventListener('ended', onEnded);
    });
  };

  const playSondRecursive = (url: string) => {
    playSound(url, () => {
      playSondRecursive(url);
    });
  };

  const stopSound = () => {
    audio.current?.pause();
  };

  useEffect(() => {
    const status = timer?.status || stopwatch?.status;
    const sound = timer?.sound || stopwatch?.sound;
    if (status && sound) {
      if (status === 'play') {
        stopSound();
        playSondRecursive(sound);
      }
      if (status === 'pause') {
        stopSound();
      }
    } else if (audio.current) {
      stopSound();
    }
  }, [timer, stopwatch]);

  function onPressTimer() {
    if (timer?.finishAt) {
      dispatch(
        DrawerActions.setDrawer({
          action: `TIMER`,
          payload: {activity: timer?.activity, tab: 1},
        }),
      );
    } else if (stopwatch?.activity) {
      dispatch(
        DrawerActions.setDrawer({
          action: `TIMER`,
          payload: {activity: stopwatch?.activity, tab: 2},
        }),
      );
    }
  }

  return (
    <>
      {show && (
        <div
          onClick={onPressTimer}
          style={{
            cursor: 'pointer',
            height: 80,
            width: 80,
            position: 'absolute',
            left: 10,
            bottom: 10,
            backgroundColor: theme.colors.primary,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'flex-end',
            borderRadius: 60,
          }}>
          <TextLabel color={theme.colors.onPrimary}>{timeString}</TextLabel>
        </div>
      )}
    </>
  );
};
export default FloatTimer;

import {Action} from '../utils';
import {PurchaseState, PurchaseTypes} from './type';

const INITIAL_STATE: PurchaseState = {
  purchases: [],
  lastSync: null,
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): PurchaseState {
  switch (type) {
    case PurchaseTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case PurchaseTypes.GET_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: payload.data,
        lastSync: new Date(),
      };
    case PurchaseTypes.ADD_PURCHASE_REQUEST:
      return {
        ...state,
        purchases: [...state.purchases, payload.purchase],
      };

    default:
      return state;
  }
}

export const PurchaseActions = {
  checkPremiumRequest: () => ({
    type: PurchaseTypes.CHECK_PREMIUM_REQUEST,
  }),
  setInitialState: () => ({
    type: PurchaseTypes.SET_INITIAL_STATE,
  }),
};

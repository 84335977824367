import React, {  useState } from "react";
import { Button, Modal, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Label } from "@/styles/global";
import { formatToShortNumber, parseToNumber } from "@/utils/string";
import { Row } from "@/components/atoms";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "@/store/modules";
const { TextArea } = Input;

const Item = styled.div`
  padding: 6px 10px;
  background: ${({active,theme})=> active ? theme.colors.primary : '#eee'};
  color: ${({active})=> active ? '#fff' : '#333'};
  margin-right: 4px;
  border-radius: 6px;
  font-weight: ${({active})=> active ? 'bold' : 'regular'};
  cursor: pointer;
`;

const DailyGoal = ({ title, onCheckAmount, unity, amount, amountTotal, setVisible, visible, activity }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const suggestions = useSelector(
    state => state.user?.configs?.suggestions ?? {},
  );
  const listSugggestions =
    suggestions?.dailyGoal?.[activity?.id ?? activity?.uid] ?? [];

  const [input, setInput] = useState(String(amount || 1));
  const label = unity ? `${t('amount')} (${t(`unity:${unity}`)})` : t('amount');
  const suggests = [
    ...new Set(
      [
        ...listSugggestions,
        amountTotal,
        amount,
        Math.floor(amount / 2),
        Math.floor(amountTotal / 2),
        Math.floor(amountTotal / 5),
        Math.floor(amountTotal / 12),
        Math.floor(amountTotal / 20),
        1,
      ]
        .filter(Boolean)
        .sort((a, b) => b - a),
    ),
  ];

  function closeModal(e) {
    e?.stopPropagation();
    setVisible(false);
  }

  function handleSubmit(e){
    e.stopPropagation();
    if(input){
      onCheckAmount(parseFloat(input));
      if (!Number.isNaN(parseFloat(input)) && parseFloat(input) > 0) {
        dispatch(
          UserActions.setConfigsRequest({
            suggestions: {
              ...suggestions,
              dailyGoal: {
                ...suggestions?.dailyGoal,
                [activity?.id ?? activity?.uid]: [
                  ...new Set([parseFloat(input), ...listSugggestions]),
                ],
              },
            },
          }),
        );
      }
    }
  }
  return (
    <Modal
      onClick={(e) => e?.stopPropagation()}
      title={title}
      open={visible}
      closable
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key={"back"} type="primary" onClick={closeModal}>
          {t("back")}
        </Button>,
        <Button key={"submit"} type="primary" onClick={handleSubmit}>
          {t("submit")}
        </Button>,
      ]}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <Label color="#333">{label}</Label>
        <br />
        <TextArea
          rows={4}
          placeholder={t('placeholderNote')}
          onChange={(e) => setInput(String(parseToNumber(e.target.value)))}
          value={input}
          onPressEnter={handleSubmit}
        />
        <Label color="#333">{t('suggestions')}:</Label>
           <Row >
          {suggests.map(i => (
            // eslint-disable-next-line eqeqeq
            <Item key={i} active={i == input} onClick={() => setInput(String(i))}>
              <span>{formatToShortNumber(i)}</span>
            </Item>
          ))}
        </Row>
      </div>
    </Modal>
  );
};

export default DailyGoal;

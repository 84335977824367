import {all, takeLatest} from 'redux-saga/effects';
import {
  CalendarSaga,
  CalendarTypes,
  CategorySaga,
  CategoryTypes,
  GoalSaga,
  GoalTypes,
  HabitSaga,
  HabitTypes,
  ListTypes,
  ModalSaga,
  ModalTypes,
  PurchaseSaga,
  PurchaseTypes,
  QueueSaga,
  QueueTypes,
  RankingSaga,
  RankingTypes,
  ReportSaga,
  ReportTypes,
  RewardSaga,
  RewardTypes,
  StatisticSaga,
  StatisticTypes,
  TaskSaga,
  TaskTypes,
  UserSaga,
  UserTypes,
} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import { getListsSaga } from './list/saga';

export default function* rootSaga(): SagaIterator<void> {
  return yield all([
    takeLatest(UserTypes.LOGIN_REQUEST, UserSaga.login),
    takeLatest(UserTypes.REGISTER_REQUEST, UserSaga.register),
    takeLatest(UserTypes.LOGIN_GOOGLE_REQUEST, UserSaga.loginGoogle),
    takeLatest(UserTypes.GET_OUT_REQUEST, UserSaga.getOut),
    takeLatest(UserTypes.CLEAR_ALL_STATE, UserSaga.clearAllState),
    takeLatest(UserTypes.RESTORE_OWN_STATE, UserSaga.restoreOwnState),
    takeLatest(UserTypes.SYNC, UserSaga.syncUserData),
    takeLatest(UserTypes.SET_CONFIGS_REQUEST, UserSaga.setConfigs),
    takeLatest(UserTypes.UPDATE_USER_REQUEST, UserSaga.updateUser),
    takeLatest(UserTypes.DELETE_USER_REQUEST, UserSaga.deleteUser),
    takeLatest(UserTypes.SET_LANGUAGE, UserSaga.setLanguage),

    takeLatest(PurchaseTypes.CHECK_PREMIUM_REQUEST, PurchaseSaga.checkPremium),

    takeLatest(
      CalendarTypes.GET_ACTIVITIES_REQUEST,
      CalendarSaga.getActivities,
    ),
    takeLatest(
      ListTypes.GET_LISTS_REQUEST,
      getListsSaga,
    ),
    takeLatest(TaskTypes.ADD_TASK_REQUEST, TaskSaga.addTask),
    takeLatest(TaskTypes.UPDATE_TASK_REQUEST, TaskSaga.updateTask),
    takeLatest(TaskTypes.UPDATE_TASK_DATE_REQUEST, TaskSaga.updateTaskDate),
    takeLatest(TaskTypes.DELETE_TASK_REQUEST, TaskSaga.deleteTask),
    takeLatest(TaskTypes.CHECK_TASK_REQUEST, TaskSaga.checkTask),
    takeLatest(TaskTypes.GET_TASK_REQUEST, TaskSaga.getTask),

    takeLatest(HabitTypes.ADD_HABIT_REQUEST, HabitSaga.addHabit),
    takeLatest(HabitTypes.DELETE_HABIT_REQUEST, HabitSaga.deleteHabit),
    takeLatest(HabitTypes.EDIT_HABIT_REQUEST, HabitSaga.updateHabit),
    takeLatest(HabitTypes.UPDATE_HABIT_DATE_REQUEST, HabitSaga.updateHabitDate),
    takeLatest(HabitTypes.CHECK_HABIT_REQUEST, HabitSaga.checkHabit),
    takeLatest(HabitTypes.GET_HABITS_REQUEST, HabitSaga.getHabits),
    takeLatest(HabitTypes.GET_HABIT_REQUEST, HabitSaga.getHabit),

    takeLatest(GoalTypes.GET_GOALS_REQUEST, GoalSaga.getGoals),
    takeLatest(GoalTypes.ADD_GOAL_REQUEST, GoalSaga.addGoal),
    takeLatest(GoalTypes.EDIT_GOAL_REQUEST, GoalSaga.updateGoal),
    takeLatest(GoalTypes.CHECK_GOAL_REQUEST, GoalSaga.checkGoal),
    takeLatest(GoalTypes.DELETE_GOAL_REQUEST, GoalSaga.deleteGoal),
    takeLatest(GoalTypes.UPDATE_GOAL_DATE_REQUEST, GoalSaga.updateGoalDate),

    takeLatest(
      StatisticTypes.GET_STATISTICS_REQUEST,
      StatisticSaga.getStatistics,
    ),

    takeLatest(CategoryTypes.ADD_CATEGORY_REQUEST, CategorySaga.addCategory),
    takeLatest(
      CategoryTypes.UPDATE_CATEGORY_REQUEST,
      CategorySaga.updateCategory,
    ),
    takeLatest(
      CategoryTypes.DELETE_CATEGORY_REQUEST,
      CategorySaga.deleteCategory,
    ),
    takeLatest(
      CategoryTypes.GET_CATEGORIES_REQUEST,
      CategorySaga.getCategories,
    ),

    takeLatest(RewardTypes.ADD_REWARD_REQUEST, RewardSaga.addReward),
    takeLatest(RewardTypes.DELETE_REWARD_REQUEST, RewardSaga.deleteReward),
    takeLatest(RewardTypes.GET_REWARDS_REQUEST, RewardSaga.getRewards),
    takeLatest(RewardTypes.SYNC_REWARDS_REQUEST, RewardSaga.syncRewards),

    takeLatest(RankingTypes.GET_RANKING_REQUEST, RankingSaga.getRanking),

    takeLatest(ModalTypes.SET_ERROR, ModalSaga.setError),

    takeLatest(ReportTypes.GET_REPORTS_REQUEST, ReportSaga.getReports),
    takeLatest(ReportTypes.CREATE_REPORT_REQUEST, ReportSaga.createReport),
    takeLatest(QueueTypes.SYNC, QueueSaga.syncQueue),
  ]);
}

export default {
  noActivitiesFilter: 'No hay actividades según los filtros aplicados',
  doneAllActivities: `¡Felicitaciones! 🚀 \n¡Realizaste todas las actividades del día! 🎯`,
  noActivitiesToday: `No tienes ninguna actividad para el día`,
  noActivitiesInPeriod: `No tienes ninguna actividad para el período`,
  noTaskInThisList: `No tienes ninguna tarea en esta lista`,
  noTaskInThisListFilter: `No hay tareas según los filtros.`,
  removeFiltersToSeeList: `Elimina los filtros aplicados para ver las tareas existentes de esta lista.`,
  
  addTask: 'Agregar tarea',
  howToAddTask: 'Cómo administrar tareas',
  editTask: 'Editar tarea',
  deleteTask: 'Eliminar tarea',
  updateTask: 'Editar tarea',
  updateTaskAlert: `Esta tarea se repite con frecuencia. ¿Quieres editar solo esta ocurrencia ({{date}}) o también sus ocurrencias futuras?`,
  deleteDesc: '¿Está seguro de que desea eliminar esta actividad?',
  deleteTaskAlert: `Esta tarea se repite con frecuencia. ¿Desea eliminar solo esta ocurrencia ({{date}}) o también sus ocurrencias futuras? `,
  title: 'Título',
  label: 'Tipo',
  categories: 'Categorías',
  description: 'Descripción',
  startDate: 'Día',
  endDate: 'Fin',
  hour: 'Hora',
  start: 'Inicio',
  end: 'Fin',
  checkList: 'Lista de verificación',
  notification: 'Notificación',
  period: 'Frecuencia',
  notRepeat: 'No repetir',
  daily: 'Diario',
  segSex: 'LUN a VIE',
  segSab: 'LUN to SAB',
  weekly: 'Semanal',
  monthly: 'Mensual',
  yearly: 'Anual',
  custom: 'Personalizado',

  all: "Todo el período",
  "7days": "7 días",
  "30days": "30 días",
  thisMonth: "Este mes",
  thisYear: "Este año",
  "12month": "12 meses",

  hasDate: 'Establecer fecha',
  hasDateDesc:
    'Si no está activada, la tarea es visible en la pantalla de listas',
  inbox: 'Entrada',
  allTask: 'Todos',

  placeholderTitle: 'Ex: Study for exam',
  placeholderLabel: 'Seleccionar tipo',
  placeholderCategories: 'Ex: Intellectual, Professional',
  placeholderDescription: 'Ej: Capítulo 1, 2 y 3',
  placeholderPeriod: 'Seleccione los días de la semana',
  placeholderPeriodDesc:
    'La actividad se repetirá en todos los días seleccionados, hasta la fecha de finalización definida.',
  placeholderPeriodInterval: 'Repetir cada {{x}} días',
  everyXdays: 'Cada {{x}} días',
  intervalDays: 'Rango de días:',
  nextDays: 'Siguientes ocurrencias:',
  placeholderPeriodIntervalDesc:
    'La actividad se repetirá intercalando el número de días informados, hasta la fecha de finalización definida.',
  errorIntervalDays: 'Ingrese el rango de días',
  placeholderNotification: 'Notificaciones de tiempo de actividad',
  exampleCheckList: 'Ex: Task 1',

  addNewCategory: 'Agregar nueva categoría',
  newCategory: 'Nueva categoría',
  placeholderCategory: 'Ex: Proyecto X',
  'multiSelect.task.description':
    '¡Crear tareas que estén en línea con sus objetivos aumenta sus posibilidades de lograr sus objetivos!',
  'multiSelect.task.textButtonAdd': 'Agregar nueva tarea',
  'multiSelect.task.dataVoid':
    '¡No tienes ninguna tarea, agrega una nueva tarea ahora mismo!',

  'multiSelect.habit.description':
    '¡Crear hábitos que estén alineados con tus objetivos aumenta tus posibilidades de lograr tus objetivos!',
  'multiSelect.habit.textButtonAdd': 'Agregar nuevo hábito',
  'multiSelect.habit.dataVoid':
    '¡No tienes ningún hábito, agrega un nuevo hábito ahora mismo!',
  'multiSelect.category.description':
    'Seleccione las categorías que se refieren a su actividad, según la herramienta Rueda de la vida.',
    reschedule: 'Reprogramar',
    placeholderReschedule:
      'Reprograme la tarea para el día siguiente mientras no esté completa.',
      transformInTask: 'Transformar en Tarea',
      moveToChecklist: 'Mover a el Checklist',
      moveToOtherChecklist: 'Mover a otro Checklist',
      move: 'Muevete',
      targetTask: 'Tarea objetivo',
      targetTaskDesc:
        'Tarea a la que pertenecerá el elemento de la lista de verificación.',
      itemOfChecklist: 'Elemento de la lista de verificación',
      selectTask: 'Seleccionar tarea',
      periodOfItem: 'Frecuencia del artículo',
      periodOfItemDesc:
        'Frecuencia que el elemento de la lista de verificación {{item}} debe tener en la lista de verificación de la tarea objetivo ({{task}})',
      'checklist-oneday': 'Sólo una ocurrencia',
      'checklist-daily': 'Todas las ocurrencias',
  
}as any;

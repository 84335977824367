export const UserTypes = {
  LOGIN_REQUEST: 'user/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',

  LOGIN_GOOGLE_REQUEST: 'user/LOGIN_GOOGLE_REQUEST',

  REGISTER_REQUEST: 'user/REGISTER_REQUEST',
  REGISTER_SUCCESS: 'user/REGISTER_SUCCESS',

  UPDATE_USER_REQUEST: 'user/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'user/UPDATE_USER_SUCCESS',

  DELETE_USER_REQUEST: 'user/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'user/DELETE_USER_SUCCESS',

  RECOVERY_REQUEST: 'user/RECOVERY_REQUEST',
  RECOVERY_SUCCESS: 'user/RECOVERY_SUCCESS',

  SET_CONFIGS_REQUEST: 'user/SET_CONFIGS_REQUEST',
  SET_CONFIGS_SUCCESS: 'user/SET_CONFIGS_SUCCESS',

  SET_LANGUAGE: 'user/SET_LANGUAGE',

  SET_LOADING: 'user/SET_LOADING',
  SET_FAILURE: 'user/SET_FAILURE',

  GET_OUT_REQUEST: 'user/GET_OUT_REQUEST',
  GET_OUT_SUCCESS: 'user/GET_OUT_SUCCESS',
  CLEAR_ALL_STATE: 'user/CLEAR_ALL_STATE',
  RESTORE_OWN_STATE: 'user/RESTORE_OWN_STATE',
  SYNC: 'user/SYNC',
};

export type LoginRequest = {
  email: string;
  password: string;
};
export type LoginSuccess = {
  token: string;
  id: number;
  email: string;
  name: string;
  premium: boolean;
};
export type RegisterRequest = {
  name: string;
  email: string;
  password: string;
};

export type Configs = {
  token?: string;
  tokenNotification?: string;
  syncInSilent?: boolean;
  syncLocalDBInProgress?: boolean;
  syncLocalDBSuccess?: boolean;
  syncLocalDBFinishAt?: string;
  syncLocalDBStartAt?: string;
  startOfWeek?: number;
  cardActivityColorBy?: 'label' | 'categories';
  soundOnCheck?: boolean;
  showCheckList?: boolean;
  suggestions?: any;
  syncCalendarInterval?: number;
};

export interface UserState {
  premium: boolean;
  email: string;
  name: string;
  password: string;
  token: string;
  createdAt: string;
  id: number;
  loading: boolean;
  lastSync: null | Date;
  lastDateCheckPremium: null | Date;
  language?: string;
  scheduleDailyNotification?: boolean;
  syncReward?: boolean;
  configs: Configs
}
export interface UpdateUser {
  premium?: boolean;
  email?: string;
  name?: string;
  password?: string;
  token?: string;
  createdAt?: string;
  id?: number;
  loading?: boolean;
  lastSync?: null | Date;
  language?: string;
  scheduleDailyNotification?: boolean;
  syncReward?: boolean;
}

export const FilterTypes = {
  RESET_FILTER: 'filter/RESET_FILTER',
  SET_FILTER: 'filter/SET_FILTER',
};

export type FilterBy = {
  types: Array<string>;
  categories: Array<string>;
  status: Array<boolean>;
  date?: Array<string>;
};
export type SortBy = {
  hour: number | null;
  status: boolean | null;
  label?: string | null;
};
export interface FilterType {
  filterBy: FilterBy;
  sortBy: SortBy;
}
export type FilterOption = 'calendar' | 'habit' | 'goal' | 'list';

export interface FilterState {
  calendar: FilterType;
  list: FilterType;
  habit: FilterType;
  goal: FilterType & {date: string[] };
}

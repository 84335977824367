import React, {useState} from 'react';
import {Row, TextSection} from '@/components/atoms';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {selectorStopwatch} from '@/store/modules';
import {useSelector} from 'react-redux';
import StopwatchScreen from './StopwatchScreen';
import TimerScreen from './TimerScreen';
import {getDateToday} from '@/utils/date';
import {getActivityInDate} from '@/store/modules/utils';

interface Props {
  initialTab?: number;
  activity?: any;
}

const Timer = ({initialTab, activity: _activity}: Props) => {
  const {t} = useTranslation();
  const stopwatch = useSelector(selectorStopwatch);
  const [tab, setTab] = useState(
    initialTab ? initialTab : stopwatch?.activity ? 2 : 1,
  );
  const activityInDay = getActivityInDate(
    _activity,
    _activity.date || getDateToday(),
  );
  const [activity, setActivity] = useState({
    ..._activity,
    timer: _activity?.timer || activityInDay.timer,
    stopwatch: _activity?.stopwatch || activityInDay.stopwatch,
  });

  const theme = useTheme();

  return (
    <>
      <Row flex={1} justifyContent="space-around" style={{marginTop: -25}}>
        <div onClick={() => setTab(1)}>
          <TextSection
            color={
              tab === 1
                ? theme.colors.onBackground
                : theme.colors.inactiveTabBar
            }>
            {t('timer')}
          </TextSection>
        </div>
        <div onClick={() => setTab(2)}>
          <TextSection
            color={
              tab === 2
                ? theme.colors.onBackground
                : theme.colors.inactiveTabBar
            }>
            {t('stopwatch')}
          </TextSection>
        </div>
      </Row>
      <div>
        {tab === 1 && (
          <TimerScreen activity={activity} setActivity={setActivity} />
        )}
        {tab === 2 && (
          <StopwatchScreen activity={activity} setActivity={setActivity} />
        )}
      </div>
    </>
  );
};

export default Timer;

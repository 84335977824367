export default {
  welcome: 'Bienvenido!',
  target:
    'Esta aplicación tiene como objetivo ayudarlo a administrar sus tareas, hábitos y objetivos. ',
  tip: 'para maximizar sus resultados, cree hábitos y objetivos comunes. Por ejemplo, si quieres adquirir el hábito de la lectura, además de crear este hábito, crea metas (Mensuales, Trimestrales y Anuales) para este hábito. ',
  warningBold: 'Por favor, lea atentamente',
  warningText:
    'la siguiente información para maximizar sus resultados usando esta aplicación.',

  introTask:
    'Califique sus tareas como IMPORTANTES, URGENTES o CIRCUNSTANCIALES',
  introImportant:
    'Tareas importantes, que te traerán resultados y te llevarán a tus metas. Ejemplos: estudiar nuevo idioma, cuidado de la salud. ',
  introUrgent:
    'Tareas inesperadas de última hora que tienen una fecha límite corta o que ya han vencido. Ejemplos: reuniones urgentes, problemas a resolver. ',
  introCircumstantial:
    'Tareas innecesarias que no lo llevan a sus metas ni le brindan resultados. Ejemplo: demasiada red social. ',
  tipTask:
    'Realiza horarios mensuales y semanales priorizando tareas importantes. Además, planifique su día la noche anterior. Finalmente, elimine las posibles interrupciones de su entorno para aumentar su enfoque. ',

  introHabit:
    'Los hábitos tardan una media de 66 días en crearse. Se crean repitiendo 3 pasos: Activador, Rutina y Recompensa. ',
  contentHabit:
    'El Gatillo es un estímulo para activar el hábito, es decir, algo para recordar para realizar la rutina, generalmente el gatillo puede ser un Tiempo, Lugar, Sentimiento, o una acción realizada previamente. La rutina es el comportamiento que se debe realizar con frecuencia. Finalmente, la Recompensa es el beneficio que se adquiere al realizar la rutina. ',
  howToCreateHabit: '¿Cómo crear hábitos?',
  setupHabit: 'Establecer el disparador, la rutina y la recompensa.',

  introGoal:
    'Establezca metas efectivas utilizando el método SMART. Que consiste en crear metas de manera específica, medible, alcanzable, relevante y temporal. ',
  howToCreateGoal: '¿Cómo crear metas INTELIGENTES?',
  setupGoal:
    'Específico: ¿Cuál es exactamente el objetivo?  \nMedible: ¿Qué tamaño de meta?  \nAlcanzable: ¿Cómo se alcanzará la meta?  \nRelevante: ¿Qué importancia tiene el objetivo?  \nTemporal: ¿Cuándo se alcanzará el objetivo? ',
  tipGoal:
    ' \n # Cree metas y hábitos relacionados.  \n\tEjemplo:  \n\tMeta: Perder 8 kg en 3 meses.  \n\tHábito: Realice actividad física  \n # Divida las metas grandes en otras metas más pequeñas.',
}as any;

import React, { useState } from "react";

import { endOfWeek, endOfYear, startOfYear } from "date-fns";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import {
  ButtonTransparent,
  Row,
  Section,
  TextBase,
  TextLabel,
  TextSection,
  ViewBase,
} from "@/components/atoms";
import {
  addMonths,
  addYears,
  endOfMonth,
  formatDate,
  formatDateToServer,
  getDateToday,
  startOfMonth,
  startOfWeek,
  subWeeks,
} from "@/utils/date";

import { metrics } from "@/styles";
import ListItemMenu from "../molecules/ListItemMenu";

type Props = {
  title: string;
  label: string;
  dates: any[];
  amountLabel: string;
  showScore: boolean;
  showAmount: boolean;
};
const GrowthByPeriod = ({
  title,
  label,
  showScore,
  showAmount,
  amountLabel,
  dates,
}: Props): JSX.Element => {
  const upperDate = getDateToday();
  const theme = useTheme();
  const { t } = useTranslation();
  const [mode, setMode] = useState(showAmount ? "amount" : "done");
  const options = [
    {
      ...(showScore
        ? {
            label: t("statistic:score"),
            selected: mode === "score",
            value: "score",
          }
        : null),
    },
    {
      ...(showAmount
        ? {
            label: amountLabel,
            selected: mode === "amount",
            value: "amount",
          }
        : null),
    },
    {
      label: t("statistic:performed"),
      selected: mode === "done",
      value: "done",
    },
    {
      label: t("time"),
      selected: mode === "time",
      value: "time",
    },
  ].filter((i) => i.label) as {label:string; value: string}[];
  const periods = ["weekly", "monthly", "yearly"];
  const [period, setPeriod] = useState("weekly");

  const labels = ["1", "2", "3", "4", "5"]
    .map((i, k) => {
      if (period === "weekly") {
        return formatDate(startOfWeek(subWeeks(upperDate, 1 + k)), "dd/MM");
      }
      if (period === "monthly") {
        return formatDate(
          startOfMonth(addMonths(upperDate, (1 + k) * -1)),
          "MMM"
        ).toUpperCase();
      }
      if (period === "yearly") {
        return formatDate(
          startOfYear(addYears(upperDate, k * -1)),
          "yyyy"
        ).toUpperCase();
      }
      return i
    })
    .reverse();

  const reduceItem = (acc: any, item: any) => {
    if (mode === "time") {
      const time = ((item.timer || 0) + (item.stopwatch || 0)) / (60 * 60);
      return time + acc;
    }
    if (mode === "amount") {
      return (item.amount ? item.amount : item.done ? 1 : 0) + acc;
    }
    if (mode === "done") {
      return (item.done ? 1 : 0) + acc;
    }
  };

  const datasets = Array.from({ length: 5 }, (v, k) =>
    dates
      .filter((i) => {
        if (period === "weekly") {
          return (
            i.date >=
              formatDateToServer(startOfWeek(subWeeks(upperDate, 1 + k))) &&
            i.date <= formatDateToServer(endOfWeek(subWeeks(upperDate, 1 + k)))
          );
        }
        if (period === "monthly") {
          return (
            i.date >=
              formatDateToServer(
                startOfMonth(addMonths(upperDate, (1 + k) * -1))
              ) &&
            i.date <=
              formatDateToServer(endOfMonth(addMonths(upperDate, (1 + k) * -1)))
          );
        }
        if (period === "yearly") {
          return (
            i.date >=
              formatDateToServer(startOfYear(addYears(upperDate, k * -1))) &&
            i.date <= formatDateToServer(endOfYear(addYears(upperDate, k * -1)))
          );
        }
        return false
      })
      .reduce((acc, item) => reduceItem(acc, item), 0)
  ).reverse();

  return (
    <Section>
      {title && <TextSection>{title}</TextSection>}
      {label && <TextLabel>{label}</TextLabel>}
      <ListItemMenu
        data={options}
        onSelectItem={(v: any) => setMode(v.value)}
        value={mode}
      />

      {labels?.length > 0 ? (
        <ViewBase
          backgroundColor={theme.colors.surface}
          borderRadius={metrics.fontSize(8)}
        >
          <Row height={350} style={{alignItems: 'flex-end', justifyContent: 'center'}}>
             {datasets.map((data,index)=> (
              <ViewBase width={100} marginLeft={10}>
                <ViewBase height={300* (data/Math.max(...datasets))} backgroundColor={theme.colors.primary} >
                  <TextBase smaller bold center color='white'>{Math.round(data)}</TextBase>
                </ViewBase>
                <TextBase small bold center>{labels[index]}</TextBase>
              </ViewBase>
              ))}
          </Row>
          <ViewBase padding={metrics.fontSize(5)} center>
            <Row center>
              {periods
                .map((i) => ({ label: t(`task:${i}`), value: i }))
                .map((i) => (
                  <ButtonTransparent
                    onClick={() => setPeriod(i.value)}
                    key={i.label}
                    style={{
                      justifyContent: "center",
                      padding: metrics.space.smaller,
                      margin: metrics.space.smaller,
                      borderRadius: metrics.space.smaller,
                      borderWidth: 1,
                      borderColor:
                        i.value === period
                          ? theme.colors.primary
                          : theme.colors.onSurface,
                    }}
                  >
                    <TextBase
                      smaller
                      center
                      bold
                      color={
                        i.value === period
                          ? theme.colors.primary
                          : theme.colors.onSurface
                      }
                    >
                      {i.label}
                    </TextBase>
                  </ButtonTransparent>
                ))}
            </Row>
          </ViewBase>
        </ViewBase>
      ) : (
        <TextBase>{t("statistic:noWeekYet")}</TextBase>
      )}
    </Section>
  );
};

export default GrowthByPeriod;

import {call, put, select} from 'redux-saga/effects';
import {api, Sentry, Toast} from '@/services';
import {SagaIterator} from '@redux-saga/types';
import {UserActions} from '../user/duck';
import {AppActions} from '../app/duck';
import { t } from '@/services/Locale';
import { getUserPremium } from '@/services/api/user';



export function* checkPremium(): SagaIterator<void> {
  const user = yield select(state => state.user);
  api.defaults.headers.Authorization = user.token;
  try {
    const premium = yield call(getUserPremium);
    yield put(AppActions.addAtribute({lastCheckPremium: new Date()}));
    if(!premium){
      yield put(UserActions.getOutRequest())
      Toast.error(t('error:onlyPremium'))
    }
  } catch (err:any) {
    yield put(UserActions.setFailure());
    Sentry.captureException(err, {
      user,
      description: 'checkPremium in purchase/saga.js',
    });
  }
}


export const ListTypes = {
  SET_INITIAL_STATE: 'list/SET_INITIAL_STATE',
  SET_LISTS_ORDER: 'list/SET_LISTS_ORDER',
  SET_TASKS_ORDER: 'list/SET_TASKS_ORDER',
  GET_LISTS_REQUEST: 'list/GET_LISTS_REQUEST',
  GET_LISTS_SUCCESS: 'list/GET_LISTS_SUCCESS',
};


export interface ListState {
  lists: string[]
  tasks: any;
  data: any[]
  loading: boolean;
  lastSync: null | Date;
}


import i18next from 'i18next';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export function useLanguage() {
  const [language, setLanguage] = useState('pt');
  const params = useParams();

  useEffect(() => {
    handleSetLanguage(params?.ln || 'pt');
  }, [params]);

  function handleSetLanguage(ln: string) {
    setLanguage(ln);
    document.documentElement.setAttribute('lang', ln);
    i18next.changeLanguage(ln);
  }

  return {
    languages: [
      {
        id: 'pt',
        icon: '🇧🇷',
      },
      {
        id: 'en',
        icon: '🇺🇸',
      },
      {
        id: 'es',
        icon: '🇪🇸',
      },
    ],
    language,
    setLanguage: handleSetLanguage,
  };
}

export function useParams() {
  const {search} = useLocation();
  const [params, setParams] = useState<any>(getParams());

  function getParams() {
    if (search) {
      return JSON.parse(
        '{"' +
          decodeURI(
            search?.substring(1)?.replace(/&/g, '","')?.replace(/=/g, '":"'),
          ) +
          '"}',
      );
    }
    return {};
  }
  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return params;
}

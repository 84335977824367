export default {
  signin: 'Entrar',
  signinWithGoogle: 'Entrar com Google',
  signinWithApple: 'Entrar com Apple',
  register: 'Registrar',
  recoverPassword: 'Recuperar contraseña',

  name: 'Nombre',
  email: 'Email',
  password: 'Contraseña',
  newPassword: 'Nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  placeholderName: 'Ingrese su nombre',
  placeholderEmail: 'Ingrese su email',
  placeholderPassword: 'Ingrese su contraseña',
  placeholderConfirmPassword: 'Confirme su contraseña',
  placeholderCode: 'Ingrese el código de verificación',

  forgotPassword: '¿Olvidó su contraseña?',
  resetPassword: 'Resetar Senha',
  registerNow: '¿No tienes una cuenta? Regístrate ahora',
  goToLogin: '¿Ya tienes una cuenta? Acceso',

  titleValidateCode: 'Validar código',
  titleNewPassowrd: 'Nueva contraseña',

  code: 'Código',
  codeSent: '¡Código enviado!',
  sendCode: 'Enviar código',
  checkCode: 'Validar',
  msgCodeSent: 'Recibirá un código en su email en 60 segundos.',
  codeInvalid: '¡Código no válido!',
  msgCodeInvalid: 'Revise su email e ingrese el código correcto.',

  success: '¡Éxito!',
  confirm: 'Confirmar',
  changePasswordSuccess: '¡La contraseña se cambió correctamente!',
} as any;

import React from 'react';

import {useTheme} from 'styled-components';

import {TextBase, ButtonTransparent, Row} from '@/components/atoms';
import {metrics} from '@/styles';

type Label = {
  label: string;
  value: string;
};

type Props = {
  data: Label[];
  onSelectItem(item: Label): void;
  value: string;
};
const ListItemMenu: React.FC<Props> = ({data, onSelectItem, value}) => {
  const theme = useTheme();
  return (
    <Row>
      {data.map(i => (
        <ButtonTransparent
          style={{
            padding: metrics.space.smaller,
            margin: metrics.space.smaller,
            marginTop: 0,
            borderRadius: metrics.space.smaller,
            backgroundColor:
              i.value === value ? theme.colors.primary : theme.colors.surface,
          }}
          onClick={() => {
            onSelectItem(i);
          }}
          key={i.label}>
          <TextBase
            small
            color={
              i.value === value ? theme.colors.onPrimary : theme.colors.text
            }>
            {i.label}
          </TextBase>
        </ButtonTransparent>
      ))}
    </Row>
  );
};

export default ListItemMenu;

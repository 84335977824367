import metrics from '@/styles/metrics';
import styled from 'styled-components';

export interface TextBaseProps {
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  marginHorizontal?: number;
  marginVertical?: number;
  padding?: number;
  paddingTop?: number;
  margin?: number;
  size?: number;
  bold?: boolean;
  color?: string;
  textAlign?: string;
  center?: boolean;
  right?: boolean;
  small?: boolean;
  smaller?: boolean;
  underline?: boolean;
  lineThrough?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
}

export const TextBase = styled.span<TextBaseProps>`
  /* letter-spacing: -0.5px; */
  text-align: ${({center}) => (center ? 'center' : 'left')};
  font-size: ${({size}) => size || metrics.font.medium}px;
  font-family: ${({bold}) => (bold ? 'Poppins-Bold' : 'Poppins')};
  color: ${({color, theme}) => color || theme.colors.text};
  ${({padding}) => padding && `padding: ${padding}px;`}
  ${({margin}) => margin && `margin: ${margin}px;`}
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${({marginRight}) => marginRight && `margin-right: ${marginRight}px;`}
  ${({marginLeft}) => marginLeft && `margin-left: ${marginLeft}px;`}
  ${({marginHorizontal}) =>
    marginHorizontal && `margin: 0 ${marginHorizontal}px;`}
  ${({marginVertical}) => marginVertical && `margin: ${marginVertical}px 0;`}
  text-align: ${({center, right}) =>
    center ? 'center' : right ? 'right' : 'left'};
  ${({textAlign}) => textAlign && `text-align: ${textAlign};`}
  ${({small}) => small && `font-size: ${metrics.font.small}px;`}
  ${({smaller}) => smaller && `font-size: ${metrics.font.smaller}px;`}
  ${({lineThrough}) => lineThrough && `text-decoration-line: line-through;`}
  ${({underline}) => underline && `text-decoration-line: underline;`}
  ${({uppercase}) => uppercase && `text-transform: uppercase;`}
  ${({lowercase}) => lowercase && `text-transform: lowercase;`}
`;

export const Title = styled(TextBase)`
  font-size: ${metrics.font.bigger}px;
  color: ${({color, theme}) => color || theme.colors.onBackground};
  font-family: 'Poppins-Bold';
`;

export const TextSection = styled(TextBase)`
  font-size: ${metrics.font.big}px;
  color: ${({color, theme}) => color || theme.colors.textSection};
  margin-bottom: ${({marginBottom}) => marginBottom || metrics.space.smaller}px;
  font-family: 'Poppins-Bold';
`;
export const TitleModal = styled(TextSection)`
  text-align: center;
  margin: ${metrics.space.smaller}px 0;
`;
export const TextSubHeader = styled(TextBase)`
  text-align: center;
  font-size: ${metrics.font.smaller}px;
  margin-top: ${metrics.space.small}px;
  align-self: flex-end;
`;

export const TextTime = styled(TextBase)``;

export const TextLabel = styled(TextBase)`
  color: ${({color, theme}) => color || theme.colors.textLabel};
  text-align: ${({center}) => (center ? 'center' : 'left')};
  font-family: 'Poppins-Bold';
`;

export const TextValue = styled(TextBase)`
  text-align: ${({center}) => (center ? 'center' : 'left')};
  margin-top: -3px;
`;

export const Value = styled(TextBase)`
  margin-top: ${metrics.space.smaller}px;
  color: ${({theme}) => theme.colors.text};
  padding: ${metrics.space.smaller}px ${metrics.space.big}px;
  background: ${({theme}) => theme.colors.surface};
  border-radius: 5px;
`;

export const Required = styled(TextBase)`
  font-size: ${metrics.font.medium}px;
  color: ${({theme}) => theme.colors.red};
  font-family: 'Poppins-Bold';
`;

import React from 'react';
import {arrangeBigString} from '@/utils/string';
import {formatDateToShow} from '@/utils/date';
import {LEVELS} from '@/constants/gamification';
import {t} from '@/services/Locale';
import {Goal, Habit, Task} from '@/store/modules';
import { Reward } from '@/components/atoms';

function getDetails(type: string, data: any) {
  if (type === 'Done_All_Activities') {
    const habits = data?.activities.filter((i: Habit) => i.routine).length;
    const goals = data?.activities.filter((i: Goal) => i.specific).length;
    const tasks = data?.activities.filter((i: Task) => i.title).length;
    return [
      {
        label: t('statistic:tasksDone'),
        value: tasks,
      },
      {
        label: t('statistic:habitsDone'),
        value: habits,
      },
      {
        label: t('statistic:goalsDone'),
        value: goals,
      },
    ].filter(i => i.value);
  }
  if (type === 'done_goal' || type === 'add_first_goal') {
    const {specific, measurable, date} = data?.goal;
    return [
      {
        label: t('specific'),
        value: arrangeBigString(specific, 30),
      },
      {
        label: t('measurable'),
        value: arrangeBigString(measurable, 30),
      },
      {label: t('date'), value: formatDateToShow(date)},
    ];
  }
  if (type === 'add_first_habit' || type.includes('sequence_habit_')) {
    const {routine, reward} = data.habit ? data.habit : data.activity;
    return [
      {
        label: t('habit'),
        value: arrangeBigString(routine, 30),
      },
      {
        label: t('reward'),
        value: arrangeBigString(reward, 30),
      },
    ];
  }
  if (type.includes('done_activity_')) {
    const activity = data?.activity?.title || data?.activity?.routine;
    const label = data?.activity?.title ? t('task') : t('habit');
    return [
      {
        label,
        value: arrangeBigString(activity, 30),
      },
    ];
  }
  if (type.includes('level_')) {
    const {score, currentLevel} = data;
    const indexLevel = LEVELS.findIndex(i => i.text === currentLevel.text);
    return [
      {label: t('statistic:currentScore'), value: score},
      {
        label: t('statistic:prevLevel'),
        value: LEVELS[indexLevel - 1]?.textIndex,
      },
      {
        label: t('statistic:currentLevel'),
        value: currentLevel.textIndex,
      },
    ];
  }
}

export function getDataImageView(
  type: string,
  name: string,
  data: any,
): {
  title: string;
  description?: any;
  date?: string;
  details?: any;
  media?: JSX.Element | undefined;
} {
  switch (type) {
    case 'Done_All_Activities':
      return {
        title: `${t('statistic:congratulations')}, ${
          name.toString().split(' ')[0]
        }!`,
        description: t('statistic:doneAllActivities'),
        date: formatDateToShow(new Date()),
        details: getDetails(type, data),
      };
    case 'Details_Reward':
      return {
        title: t(`statistic:${data?.reward?.key}`),
        details: getDetails(type, data),
        date: formatDateToShow(data?.reward?.date),
        media: (
          <Reward
            type={data?.reward?.key}
            style={{height: 120, marginTop: -15}}
          />
        ),
      };
    case 'get_premium':
      return {
        title: t('statistic:gotReward'),
        description: t('statistic:reason') + t('statistic:get_premium'),
        media: (
          <Reward
            type="get_premium"
            style={{height: 90, marginBottom: 15}}
          />
        ),
      };
    case 'done_goal':
      return {
        title: t('statistic:gotReward'),
        description: t('statistic:reason') + t('statistic:done_goal'),
        date: formatDateToShow(new Date()),
        details: getDetails(type, data),
        media: (
          <Reward
            type="done_goal"
            style={{height: 90, marginBottom: 15}}
          />
        ),
      };
    case 'add_first_goal':
      return {
        title: t('statistic:gotReward'),
        description: t('statistic:reason') + t('statistic:add_first_goal'),
        date: formatDateToShow(new Date()),
        details: getDetails(type, data),
        media: (
          <Reward
            type="add_first_goal"
            style={{height: 90,   marginBottom: 15}}
          />
        ),
      };
    case 'add_first_habit':
      return {
        title: t('statistic:gotReward'),
        description: t('statistic:reason') + t('statistic:add_first_habit'),
        date: formatDateToShow(new Date()),
        details: getDetails(type, data),
        media: (
          <Reward
            type="add_first_habit"
            style={{height: 90,  marginBottom: 15}}
          />
        ),
      };
    default:
      if (type.includes('level_')) {
        return {
          title: t('statistic:gotReward'),
          description: t('statistic:nextLevelDesc', {
            currentLevel: data?.currentLevel?.text,
          }),
          date: formatDateToShow(new Date()),
          details: getDetails(type, data),
          media: (
            <Reward
              type={type}
              style={{height: 90,  marginBottom: 15}}
            />
          ),
        };
      }
      if (type.includes('done_activity_')) {
        return {
          title: t('statistic:gotReward'),
          description: t('statistic:reason') + t(`statistic:${type}`),
          date: formatDateToShow(new Date()),
          details: getDetails(type, data),
          media: (
            <Reward
              type={type}
              style={{height: 90,  marginBottom: 15}}
            />
          ),
        };
      }
      if (type.includes('sequence_habit_')) {
        return {
          title: t('statistic:gotReward'),
          description: t('statistic:reason') + t(`statistic:${type}`),
          date: formatDateToShow(new Date()),
          details: getDetails(type, data),
          media: (
            <Reward
              type={type}
              style={{height: 90,  marginBottom: 15}}
            />
          ),
        };
      }
      return {
        title: '',
      };
  }
}

export const RankingTypes = {
  GET_RANKING_REQUEST: 'ranking/GET_RANKING_REQUEST',
  GET_RANKING_SUCCESS: 'ranking/GET_RANKING_SUCCESS',
  SET_PERIOD: 'ranking/SET_PERIOD',
  SET_FAILURE: 'ranking/SET_FAILURE',
  SET_INITIAL_STATE: 'ranking/SET_INITIAL_STATE',
};

export type UserRanking = {
  geral: number;
  monthly: number;
  weekly: number;
  yearly: number;
  level: string;
  position: number;
  user: {
    premium: boolean;
    id: number;
    name: string;
    email: string;
  };
};
export interface RankingState {
  period: 'yearly' | 'monthly' | 'weekly';
  weekly: Array<UserRanking>;
  monthly: Array<UserRanking>;
  yearly: Array<UserRanking>;
  geral: Array<UserRanking>;
}

import { EVENTS } from '@/constants/analytics';
import {differenceInDays, getDateToday} from '@/utils/date';
import FirebaseAnalytics, {IFirebaseAnalytics} from './FirebaseAnalytics';

type User = {
  email: string;
  id: number;
  name: string;
  premium: boolean;
  createdAt: string;
};

interface IAnalytics {
  setUserProperty(id: string, value?: string): void;
  log(id: string, params?: {[key: string]: any}): void;
  setCurrentScreen(screen: string): void;
  setUser(user: User): void;
}

class Analytics implements IAnalytics {
  private analytics: IFirebaseAnalytics;

  public events: typeof EVENTS;


  constructor() {
    this.analytics = FirebaseAnalytics;
    this.events = EVENTS;
  }

  setUserProperty(id: string, value = '1') {
    this.analytics.logEvent(id);
    this.analytics.setUserProperty(id, value?.toString());
  }

  setUser(user: User) {
    const qntDays = differenceInDays(new Date(), user.createdAt);
    const lastDate = getDateToday();
    this.analytics.setUserProperty(
      this.events.TEST,
      user.id % 2 === 0 ? 'A' : 'B',
    );
    this.analytics.setUserId(user.id?.toString());
    this.analytics.setUserProperty(
      this.events.PREMIUM,
      user.premium ? '1' : '0',
    );
    this.analytics.setUserProperty(this.events.QNT_DAYS, String(qntDays));
    this.analytics.setUserProperty(this.events.LAST_DATE, lastDate);
  }

  log(id: string, params?: {[key: string]: any}) {
    this.analytics.logEvent(id, params);
  }

  setLanguage(value: string) {
    this.analytics.setUserProperty(this.events.LANGUAGE, value);
  }

  setCurrentScreen(screen: string) {
    this.analytics.setCurrentScreen(screen);
  }
}
// eslint-disable-next-line
export default new Analytics();

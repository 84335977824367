export const URL = {
  POST_TUTORIAL_APP: `https://appflynow.com/blog/como-o-app-flynow-pode-te-ajudar-a-ser-mais-produtivo`,
  VIDEO_TUTORIAL_APP: `https://youtu.be/2lFTKMIvVTg?utm_source=appflynow&utm_medium=tutorialapp`,
  BLOG: `https://appflynow.com/blog?utm_source=appflynow&utm_medium=geral&utm_campaign=conteudos`,
  EBOOKS_COMBO: `https://appflynow.com/guia-completo-habitos-e-metas?utm_source=appflynow&utm_medium=geral&utm_campaign=conteudos`,
  PRIVACY_POLICE: `https://appflynow.com/politica-de-privacidade?utm_source=appflynow&utm_medium=geral&utm_campaign=termos`,
  TERMS: `https://appflynow.com/termos-de-uso?utm_source=appflynow&utm_medium=geral&utm_campaign=termos`,
  INSTA_APP: `instagram://user?username=app.flynow`,
  INSTA_WEB: `https://www.instagram.com/app.flynow`,
  EMAIL: `mailto:app.flynow@gmail.com`,
  APP_FRASES_ANDROID: `https://play.google.com/store/apps/details?id=com.flynow.frases&utm_source=appflynow_ads&utm_medium=appflynow_ads`,
  APP_FRASES_IOS: `https://apps.apple.com/app/apple-store/id1565042754?pt=122223947&ct=appflynow_ads&mt=8`,

  RIBON_DONE_AL: `https://app.ribon.io/integrations/welcome?company_id=73&utm_source=flynow&utm_medium=tarefas`,
  RIBON_REWARD: `https://app.ribon.io/integrations/welcome?company_id=73&utm_source=flynow&utm_medium=premios`,
};

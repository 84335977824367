export default {
  welcome: 'Bem vindo!',
  target:
    'Este aplicativo tem como objetivo te auxiliar na gestão de suas tarefas, hábitos e metas. ',
  tip: 'para potencializar seus resultados, crie hábitos e metas em comum. Por exemplo, caso você queira adquirir o hábito de ler, além de criar esse hábito, crie metas (Mensais, Trimestrais e Anuais) para este hábito.',
  warningBold: 'Leia com atenção ',
  warningText:
    'as informações seguintes para potencializar seus resultados utilizando esse aplicativo.',

  introTask:
    'Classifique suas tarefas como IMPORTANTE, URGENTE ou CIRCUNSTANCIAL',
  introImportant:
    'Tarefas significativas, que vão te trazer resultados e te levar ao seus objetivos. Exemplos: Estudar novo idioma, Cuidar da saúde.',
  introUrgent:
    'Tarefas inesperadas, de última hora, que têm um prazo curto ou já esgotado. Exemplos: Reuniões urgentes, Problemas a resolver.',
  introCircumstantial:
    'Tarefas desnecessárias, que não te levam ao seus objetivos nem trazem resultados. Exemplo: Rede social em excesso.',
  tipTask:
    'Faça planejamentos mensais e semanais priorizando as tarefas importantes. Além disso, planeje seu dia na noite anterior. Por fim, elimine possíveis interrupções do seu ambiente para aumentar o seu foco.',

  introHabit:
    'Os hábitos demoram em média 66 dias para serem criados. Eles são criados com a repetição de 3 etapas: Gatilho, Rotina e Recompensa.',
  contentHabit:
    'O Gatilho é um estímulo para ativar o hábito, ou seja, algo para lembrar de realizar a rotina, geralmente o gatilho pode ser um Horário, Lugar, Sentimento, ou uma ação realizada anteriormente. Já a Rotina é o comportamento que deve ser realizado frequentemente. Por fim, a Recompensa é o que benefício adquirido pela realização da rotina.',
  howToCreateHabit: 'Como criar hábitos?',
  setupHabit: 'Defina o gatilho, rotina e recompensa.',

  introGoal:
    'Defina metas eficazes utilizando o método SMART. No qual consiste em criar metas de forma específica, mensurável, alcançável, relevante e temporal.',
  howToCreateGoal: 'Como criar metas SMART?',
  setupGoal:
    'Específica: O que é exatamente a meta? \nMensurável: Qual tamanho da meta? \nAlcançável: Como a meta será alcançada? \nRelevante: Qual a importancia da meta? \nTemporal: Quando a meta será cumprida?',
  tipGoal:
    '\n# Crie metas e hábitos relacionados.\n\tExemplo:\n\tMeta: Emagrecer 8 kg em 3 meses.\n\tHábito: Realizar atividade física\n# Divida metas grandes, em outras metas menores.',
}as any;

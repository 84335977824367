import { TextBase, ViewBase } from '@/components/atoms';
import styled from 'styled-components';

type Props = {
  premium?: boolean;
  owner?: boolean;
};
export const Card = styled(ViewBase)<Props>`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({theme}) => theme.metrics.space.small}px;
  background: ${({theme}) => theme.colors.primary};
  border-radius: 12px;
  ${({premium, theme}) =>
    premium &&
    `
      background-color:  ${theme.colors.premium};
  `};
`;
export const CardUserPosition = styled(ViewBase)`
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  flex-wrap: wrap;
  flex: 1;
  background: ${({theme}) => theme.colors.surface};
  padding: ${({theme}) => theme.metrics.space.small}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Position = styled(TextBase)<Props>`
  color: ${({owner, theme}) =>
    owner ? theme.colors.primary : theme.colors.white};
  /* font-weight: bold; */
  width: ${({theme}) => Math.round(theme.metrics.font.bigger * 1.8)}px;
  text-align: center;
  font-size: ${({theme}) => Math.round(theme.metrics.font.bigger * 1.2)}px;
`;

export const Username = styled(TextBase)<Props>`
  font-size: ${({theme}) => theme.metrics.font.medium}px;
  color: ${({premium, theme}) =>
    premium ? theme.colors.premium : theme.colors.onSurface};
  text-transform: capitalize;
`;
export const Label = styled(TextBase)<Props>`
  font-size: ${({theme}) => theme.metrics.font.small}px;
  color: ${({premium, theme}) =>
    premium ? theme.colors.premium : theme.colors.onSurface};
`;

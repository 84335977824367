import * as SentryR from '@sentry/react';

interface ISentry {
  init(): void;
  setUser(user: SentryR.User | null): void;
  captureException(error: Error, context: {[key: string]: any}): void;
}
interface SentryModule {
  init(config: SentryR.BrowserOptions): void;
  setUser(user: SentryR.User | null): void;
  captureException(error: Error, context: {[key: string]: any}): void;
}

class Sentry implements ISentry {
  private sentry: SentryModule;

  constructor() {
    this.sentry = SentryR;
  }

  init() {
    this.sentry.init({
      dsn: "https://94b6ee76c2c2481c8ead33c21e6a5ee7@o377601.ingest.sentry.io/5812546",
      environment: process.env.NODE_ENV ? 'development' : 'production',
      enabled:true,
    });
  }

  setUser(user: SentryR.User | null) {
    this.sentry.setUser(user);
  }

  captureException(error: Error, context: {[key: string]: any}) {
    this.sentry.captureException(error, {...context, extra: context});
  }
}
// eslint-disable-next-line
export default new Sentry();

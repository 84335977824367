import {State, TaskModel} from '@/store/modules';
import {isFuture} from '@/utils/date';
import { createSelector } from 'reselect';

export const selectTasks = (state: State) => state.task.tasks;

export const selectorTasksWithoutDate = createSelector(
  [selectTasks],
  (tasks: TaskModel[]) => tasks.filter(i => i.startDate === null)
);

export const selectorFutureTasks = createSelector(
  [selectTasks],
  (tasks: TaskModel[]) => tasks.filter(i => isFuture(i.endDate))
);


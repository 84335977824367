import firebase from 'firebase';
import { firebaseApp } from './firebase';

export interface IFirebaseAnalytics {
  logEvent(id: string, params?: any): void;
  setCurrentScreen(screenName: string): void;
  setUserProperty(name: string, value: string): void;
  setUserId(id: string): void;
}

class FirebaseAnalytics implements IFirebaseAnalytics {
  private analytics: firebase.analytics.Analytics;

  constructor() {
    this.analytics = firebaseApp.analytics();
  }

  logEvent(id: string, params?: any): void {
    this.analytics.logEvent(id, params);
  }

  setCurrentScreen(screenName: string): void {
    this.analytics.setCurrentScreen(screenName);
  }

  setUserProperty(name: string, value: string): void {
    this.analytics.setUserProperties({name:value});
  }

  setUserId(id: string): void {
    this.analytics.setUserId(id);
  }
}
// eslint-disable-next-line
export default new FirebaseAnalytics();

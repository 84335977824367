
export const SessionTypes = {
  SET_NETWORK: 'app/SET_NETWORK',
  SET_INITIAL_STATE: 'app/SET_INITIAL_STATE',
};

export interface SessionState {
  network:
    | any
    | {
        isInternetReachable: null;
      };
  version: string;
}

import { isSameId, check, unCheck, updateDateActivity, Action } from "../utils";
import { TaskState, TaskTypes, Task, AddTask, TaskModel } from "./type";
import { ActivityInDate } from "../habit/type";

const INITIAL_STATE: TaskState = {
  tasks: [],
  loading: false,
  lastSync: null,
};

export default function reducer(
  state = INITIAL_STATE,
  { payload, type }: Action
): TaskState {
  switch (type) {
    case TaskTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;

    case TaskTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        lastSync: new Date(),
        tasks: [
          ...payload.tasks,
          ...state.tasks.filter(
            (i) => !payload.tasks.find((j: TaskModel) => isSameId(i, j))
          ),
        ],
      };

      case TaskTypes.GET_TASK_SUCCESS:
      return {
        ...state,
        tasks: [
          ...state.tasks.map(
            i =>  isSameId(i, payload.task) ?  ({...payload.task, dones: payload.dates, lastSync: new Date()}) : i,
          ),
        ],
      };

    case TaskTypes.ADD_TASK_SUCCESS:
    case TaskTypes.ADD_TASK:
      return { ...state, tasks: [...state.tasks, payload.task] };

    case TaskTypes.UPDATE_TASK_SUCCESS:
    case TaskTypes.UPDATE_TASK:
      return {
        ...state,
        tasks: state.tasks.map((task: TaskModel) =>
          isSameId(task, payload.task) ? { ...task, ...payload.task } : task
        ),
      };

    case TaskTypes.UPDATE_TASK_DATE_SUCCESS:
    case TaskTypes.UPDATE_TASK_DATE:
      return {
        ...state,
        tasks: state.tasks.map((item) =>
          isSameId(item, payload.task)
            ? (updateDateActivity(
                item,
                payload.data.date,
                payload.data
              ) as TaskModel)
            : item
        ),
      };

    case TaskTypes.DELETE_TASK_SUCCESS:
    case TaskTypes.DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(
          (task: TaskModel) => !isSameId(task, payload.task)
        ),
      };

    case TaskTypes.CHECK_TASK_REQUEST:
    case TaskTypes.CHECK_TASK_SUCCESS:
    case TaskTypes.CHECK_TASK:
      return {
        ...state,
        tasks: state.tasks.map((item) =>
          isSameId(item, payload.task)
            ? payload.task.done
              ? (check(item, payload.task.date) as TaskModel)
              : (unCheck(item, payload.task.date) as TaskModel)
            : item
        ),
      };

    default:
      return state;
  }
}

export const TaskActions = {
  getTasksSuccess: (tasks: TaskModel[]): Action<{ tasks: TaskModel[] }> => ({
    type: TaskTypes.GET_TASKS_SUCCESS,
    payload: { tasks },
  }),
  getTaskRequest: (id: number) => ({
    type: TaskTypes.GET_TASK_REQUEST,
    payload: {id}
  }),
  getTaskSuccess: (task: any, dates: any): Action<{ task:any; dates: any[] }> => ({
    type: TaskTypes.GET_TASK_SUCCESS,
    payload: { task, dates },
  }),
  setFailure: () => ({
    type: TaskTypes.SET_FAILURE,
  }),
  addTaskRequest: (task: AddTask): Action<{ task: AddTask }> => ({
    type: TaskTypes.ADD_TASK_REQUEST,
    payload: { task },
  }),
  addTaskSuccess: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.ADD_TASK_SUCCESS,
    payload: { task },
  }),

  updateTaskRequest: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.UPDATE_TASK_REQUEST,
    payload: { task },
  }),
  updateTaskSuccess: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.UPDATE_TASK_SUCCESS,
    payload: { task },
  }),
  updateTaskDateRequest: (
    task: Task,
    data: ActivityInDate
  ): Action<{ task: Task; data: ActivityInDate }> => ({
    type: TaskTypes.UPDATE_TASK_DATE_REQUEST,
    payload: { task, data },
  }),
  updateTaskDateSuccess: (
    task: Task,
    data: ActivityInDate
  ): Action<{ task: Task; data: ActivityInDate }> => ({
    type: TaskTypes.UPDATE_TASK_DATE_SUCCESS,
    payload: { task, data },
  }),

  deleteTaskRequest: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.DELETE_TASK_REQUEST,
    payload: { task },
  }),
  deleteTaskSuccess: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.DELETE_TASK_SUCCESS,
    payload: { task },
  }),
  checkTaskRequest: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.CHECK_TASK_REQUEST,
    payload: { task },
  }),
  checkTaskSuccess: (task: Task): Action<{ task: Task }> => ({
    type: TaskTypes.CHECK_TASK_SUCCESS,
    payload: { task },
  }),
  setInitialState: () => ({
    type: TaskTypes.SET_INITIAL_STATE,
  }),
};

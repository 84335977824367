import {Action} from '../utils';
import {RankingState, RankingTypes, UserRanking} from './type';

const INITIAL_STATE: RankingState = {
  period: 'weekly',
  weekly: [],
  monthly: [],
  geral: [],
  yearly:[]
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): RankingState {
  switch (type) {
    case RankingTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;
    case RankingTypes.SET_PERIOD:
      return {...state, period: payload.period};

    case RankingTypes.GET_RANKING_SUCCESS:
      return {
        ...state,
        [state.period]: payload.data,
      };

    default:
      return state;
  }
}

export const RankingActions = {
  setPeriod: (period: string): Action => ({
    type: RankingTypes.SET_PERIOD,
    payload: {period},
  }),
  getRankingRequest: (period: string, type: string): Action => ({
    type: RankingTypes.GET_RANKING_REQUEST,
    payload: {period, type},
  }),
  getRankingSuccess: (data: UserRanking[]): Action => ({
    type: RankingTypes.GET_RANKING_SUCCESS,
    payload: {data},
  }),
  setFailure: () => ({
    type: RankingTypes.SET_FAILURE,
  }),
  setInitialState: () => ({
    type: RankingTypes.SET_INITIAL_STATE,
  }),
};

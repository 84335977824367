import {InputContainer, Row, ViewBase} from '@/components/atoms';
import {Toast} from '@/services';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import Select from '../Select';
import {Button} from 'antd';
import {Label} from '@/styles/global';
import ListLabelOption from '../ListLabelOption';
import {formatDate, setHourAndMinutesInDate} from '@/utils/date';
import {subMinutes} from 'date-fns';
import {useState} from 'react';
import TimePicker from '@/components/TimePicker';
import {SOUNDS} from '@/constants';
import {metrics} from '@/styles';
import {parseToNumber} from '@/utils/string';

interface Props {
  suggests: string[];
  setNotifications: any;
  notifications: any[];
}

const Notifications = ({
  notifications = [],
  setNotifications,
  suggests = [],
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [edit, setEdit] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const [hour, setHour] = useState('');
  const [sound, setSound] = useState('default');
  const listNotifications = notifications
    .map(i => ({...i, name: i.hour}))
    .sort((a: any, b: any) => parseToNumber(a.hour) - parseToNumber(b.hour));

  const getSuggests = () => {
    const [startHour, endHour] = suggests;
    const array = [];
    if (startHour) {
      array.push(
        formatDate(
          subMinutes(setHourAndMinutesInDate(startHour, new Date()), 10),
          'HH:mm',
        ),
      );
      array.push(startHour);
    }
    if (endHour) {
      array.push(endHour);
    }
    return array.filter(i => !notifications.some(n => n.hour === i));
  };
  function handleAddNotification() {
    setOpen(true);
    setEdit(false);
  }
  const handleDelete = () => {
    setNotifications((stt: any) =>
      stt.filter((i: any) => i.hour !== edit?.hour),
    );
    setEdit(false);
    setOpen(false);
  };

  const handleAdd = () => {
    if (!hour) {
      return Toast.error(t('error:selectHour'));
    }
    if (!edit && listNotifications.some(i => i.hour === hour)) {
      Toast.error(t('error:hourAlreadyExist'));
    } else {
      if (edit) {
        setNotifications((stt: any) =>
          stt.map((i: any) => (i.hour === edit?.hour ? {sound, hour} : i)),
        );
      } else {
        setNotifications((stt: any) => [...stt, {sound, hour}]);
      }
      setOpen(false);
    }
    setEdit(false);
  };

  return (
    <InputContainer>
      <Row alignCenter spaceBetween>
        <ViewBase>
          <Label>{t('general:notifications')}</Label>
          <ListLabelOption
            labels={listNotifications}
            onAddLabel={handleAddNotification}
            onSelectLabel={item => {
              setEdit(item);
              setHour(item.hour);
              setSound(item.sound);
              setOpen(true);
            }}
          />
        </ViewBase>
      </Row>

      {open && (
        <>
          <div style={{marginLeft: 10}}>
            {getSuggests().length > 0 && (
              <Row
                alignItems="center"
                marginTop={metrics.space.small}
                marginBottom={metrics.space.smaller}>
                <Label>{t('suggestions')}:</Label>
                {getSuggests().map(i => (
                  <div
                    key={i}
                    onClick={() => setHour(i)}
                    style={{
                      padding: metrics.space.smaller,
                      marginLeft: metrics.space.smaller,
                      backgroundColor:
                        hour === i
                          ? theme.colors.primary
                          : theme.colors.surface,
                      borderRadius: metrics.space.small,
                      color:
                        hour === i
                          ? theme.colors.onPrimary
                          : theme.colors.onSurface,
                    }}>
                    <span>{i}</span>
                  </div>
                ))}
              </Row>
            )}

            <TimePicker
              label={t('hour')}
              value={hour}
              onChangeValue={setHour}
              placeholder="Ex: 07:00"
            />
            <Select
              label={t('sound')}
              value={sound as string}
              setValue={setSound}
              placeholder={t('task:placeholderLabel')}
              data={SOUNDS.map(i => ({...i, value: i.sound}))}
            />
            <Row spaceBetween>
              <div></div>
              <Row>
                {edit && (
                  <Button
                    style={{
                      backgroundColor: theme.colors.surface,
                      color: theme.colors.onBackground,
                      borderColor: theme.colors.red,
                      marginRight: 5,
                    }}
                    onClick={handleDelete}>
                    {t('delete')}
                  </Button>
                )}
                <Button
                  style={{
                    backgroundColor: theme.colors.surface,
                    color: theme.colors.onBackground,
                    borderColor: theme.colors.regular,
                  }}
                  onClick={handleAdd}>
                  {t(edit ? 'edit' : 'add')}
                </Button>
              </Row>
            </Row>
          </div>
        </>
      )}
    </InputContainer>
  );
};

export default Notifications;

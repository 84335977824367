export default {
  noHabitsFilter: 'No habits according to applied filters',
  noHabits: "You don't have any active habits yet.",
  addHabit: 'Add Habit',
  howToAddHabit: 'How to create habits',
  editHabit: 'Edit Habit',
  deleteHabit: 'Delete Habit',
  deleteHabitAlert:
    'The habit repeats itself frequently. Do you want to delete only this occurrence ({{date}}) or also its future occurrences?',
  trigger: 'Trigger',
  routine: 'Routine',
  reward: 'Reward',
  placeholderCheckListHabit: 'Reading, Workout',
} as any;


export default {
  general: 'Geral',

  profile: 'Perfil',
  updateProfile: 'Editar Perfil',
  deleteProfile: 'Excluir Conta',
  wantDeleteAccountTitle: 'Deseja excluir sua conta?',
  wantDeleteAccountMsg: 'Todos os seus dados do aplicativo serão apagados',
  wantDeleteAccountMsgPremium:
    'Você não terá mais acesso aos benefícios premium (mesmo criando outra conta) e todos os seus dados do aplicativo serão apagados.',


  soundOnCheck: 'Som ao concluir atividade',
  cardActivityColorBy: 'Cor da borda do Card de Atividades',
  startOfWeek: 'Semana começa em',
  showCheckList: 'Mostrar check-list no card',
  checkListConfig: 'Configuração do check-list',
  checkListTaskConfig:
    'Manter check-list único para todas as ocorrências da tarefa',
  checkListTaskDateConfig: 'Reiniciar o check-list diariamente',
  preferences: 'Preferências',
  
  theme: 'Tema',
  themeDark: 'Tema Escuro',
  themeDefault: 'Tema Padrão',
  premium: 'Premium',
  'premium.title': 'Benefícios Premium 👑',
  'premium.getError': 'Tem alguma dúvida, ou obteve algum erro?',
  'premium.support': 'Entre em contato com o suporte',
  'premium.month': 'MÊS',
  'premium.expired': 'VENCIDA',
  'premium.canceled': 'CANCELADA',
  'premium.active': 'ATIVA',
  'premium.months': 'MESES',

  getPremium: 'Obter Premium',
  premiumVersion: 'Conheça a versão premium 👑',
  adsPremium: ['CONFIRA OS ', '13 BENEFÍCIOS ', 'DE SE TORNAR ', 'PREMIUM 👑'],
  versionWeb: 'Conheça a versão web',
  mySubs: 'Minha Assinatura',
  'web.title': 'Plataforma Web',
  'web.target':
    'Com a Plataforma Web é possível gerenciar todas suas tarefas, hábitos e metas também pelo computador, além do aplicativo Flynow.',
  'web.howToAccess': 'Como acessar a Plataforma Web?',
  'web.disclamer':
    'A Versão web do Flynow é disponível apenas para usuários premium.',
  'web.content':
    'Para acessar a extensão, acesse o link web.appflynow.com ou pesquise no Google "Flynow plataforma web". Em seguida, faça o login com sua conta do Flynow e utilize à vontade!',
  extension: 'Conheça a extensão',
  'extension.title': 'Extensão',
  'extension.target':
    'Com a extensão é possível visualizar e marcar como concluída rapidamente todas as suas tarefas, hábitos e metas pelo computador.',
  'extension.howToAccess': 'Como acessar a Extensão?',
  'extension.disclamer':
    'A Extensão do Flynow é disponível apenas para usuários premium.',
  'extension.content':
    'Para acessar a extensão, acesse o link appflynow.com/extensao ou vá na loja do seu navegador (Chrome web store ou Firefox browser add-ons ), em seguida, busque por "Flynow - Produtividade", instale a extensão e faça o login com sua conta do Flynow e utilize à vontade!',

  language: 'Idioma',
  pt: 'Português',
  es: 'Espanhol',
  en: 'Inglês',

  notifications: 'Notificações',
  nextsNotifications: 'Próximas Notificações',

  contents: 'Conteúdos',
  blog: 'Blog',
  ebooks: 'Ebooks: Hábitos e Metas',

  contribute: 'Contribua',
  rateApp: 'Gostou do App? Nos Avalie',
  shareApp: 'Compartilhe o app',
  shareAppDialog: 'Compartilhe o app com seus amigos :)',
  shareAppTitle:
    'Flynow é um aplicativo perfeito para quem buscar melhorar sua produtividade, através do gerenciamento de tarefas, hábitos e metas. O aplicativo utiliza os melhores métodos para gestão de tarefas, hábitos e metas.',
  shareAppMsg:
    'Flynow é um aplicativo perfeito para quem buscar melhorar sua produtividade. O aplicativo utiliza os melhores métodos para a gestão de tarefas, criação de hábitos e metas.\nBaixem agora mesmo: https://appflynow.com',
  followInsta: 'Siga-nos no Instragram',

  contact: 'Contato',
  email: 'Email',
  instagram: 'Instagram',

  terms: 'Termos',
  privacyPolicy: 'Política de privacidade',
  termsOfUse: 'Termos de uso',

  version: 'Versão',

  getOut: 'Sair',
}as any;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef} from 'react';

import {DownOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {DatePicker, Dropdown, Space} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {FiFilter, FiSettings} from 'react-icons/fi';
import {MdNavigateBefore, MdNavigateNext} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from 'styled-components';

import {Option, Row} from '@/components/atoms';
import BottomSheet from '@/components/BottomSheet';
import Filter from '@/components/Filter';
import {
  AppActions,
  CalendarActions,
  selectorApp,
  selectorCalendar,
  selectorFilterCalendar,
} from '@/store/modules';
import {Label} from '@/styles/global';

import Preferences from './Preferences';
import {getDateToday, getIntervalDate} from '../../utils/date';
import {ModeView} from '@/constants';
import {AiOutlineOrderedList} from 'react-icons/ai';

type Props = {};
const PanelControl: React.FC<Props> = () => {
  const {t} = useTranslation();
  const refFilter = useRef<any>();
  const refSort = useRef<any>();
  const refPreferences = useRef<any>();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {lowerDate, upperDate} = useSelector(selectorCalendar);
  const {
    mode = ModeView.LIST,
    modeInterval = 'weekly',
    startOfWeek = 0,
  } = useSelector(selectorApp);
  const {filterBy} = useSelector(selectorFilterCalendar);
  const countFilters =
    filterBy.status.length + filterBy.types.length + filterBy.categories.length;

  function setModeView(mode: string) {
    dispatch(AppActions.addAtribute({mode}));
  }
  function setModeInterval(modeInterval: string) {
    dispatch(AppActions.addAtribute({modeInterval}));
    const dateRef =
      lowerDate <= getDateToday() && upperDate >= getDateToday()
        ? getDateToday()
        : lowerDate;
    const interval = getIntervalDate(
      mode as string,
      modeInterval,
      dateRef,
      startOfWeek,
    );
    dispatch(
      CalendarActions.setInterval(interval.lowerDate, interval.upperDate),
    );
  }

  const handlePrevWeek = () => {
    dispatch(CalendarActions.prev(modeInterval as string, startOfWeek));
  };

  const handleNextWeek = () => {
    dispatch(CalendarActions.next(modeInterval as string, startOfWeek));
  };

  const onOpenFilter = () => {
    refFilter.current.open();
  };

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: t('list'),
      onClick: () => setModeView('list'),
    },
    {
      key: '1',
      label: t('agenda'),
      onClick: () => setModeView('agenda'),
    },
    {
      key: '2',
      label: t('pending'),
      onClick: () => setModeView('pending'),
    },
  ];

  const goToday = () => {
    const {lowerDate, upperDate} = getIntervalDate(
      mode,
      modeInterval,
      getDateToday(),
      startOfWeek,
    );
    dispatch(CalendarActions.setInterval(lowerDate, upperDate));
  };

  return (
    <Row alignCenter style={{padding: '0 1%', marginTop: 5}}>
      <Label style={{marginLeft: 15}}>{t('modeView')}:</Label>
      <Dropdown menu={{items}}>
        <a onClick={e => e.preventDefault()}>
          <Space>
            <Option style={{fontSize: 12}} active={false}>
              {t(mode as string)} <DownOutlined />
            </Option>
          </Space>
        </a>
      </Dropdown>
      <div />
      <Dropdown
        menu={{
          items: [
            {
              key: '2',
              label: t('daily'),
              onClick: () => setModeInterval('daily'),
            },
            {
              key: '3',
              label: t('days3'),
              onClick: () => setModeInterval('days3'),
            },
            {
              key: '0',
              label: t('weekly'),
              onClick: () => setModeInterval('weekly'),
            },
            {
              key: '1',
              label: t('monthly'),
              onClick: () => setModeInterval('monthly'),
            },
          ],
        }}>
        <a onClick={e => e.preventDefault()}>
          <Space>
            <Option style={{fontSize: 12}} active={false}>
              {t(modeInterval as string)}{' '}
              <DownOutlined style={{marginLeft: 3}} />
            </Option>
          </Space>
        </a>
      </Dropdown>

      {!(upperDate >= getDateToday() && getDateToday() >= lowerDate) && (
        <Option
          style={{
            fontSize: 12,
            color: theme.colors.primary,
            borderColor: theme.colors.primary,
          }}
          onClick={goToday}
          active={false}>
          {t('goToday')}
        </Option>
      )}

      <div style={{display: 'flex', flex: 1}} />

      <Option
        active={false}
        onClick={onOpenFilter}
        style={{
          fontSize: 12,
        }}>
        {t('filter')}
        <FiFilter color={theme.colors.onSurface} style={{marginLeft: 3}} />
        {countFilters > 0 && (
          <span
            style={{
              color: theme.colors.white,
              padding: 1,
              borderRadius: 10,
              fontFamily: 'Poppins-Bold',
              fontSize: 8,
              backgroundColor: theme.colors.primary,
              marginLeft: -3,
              marginBottom: -7,
            }}>
            {countFilters}
          </span>
        )}
      </Option>
      <Option
        active={false}
        onClick={() => refSort.current.open()}
        style={{
          fontSize: 12,
        }}>
        {t('sort')}
        <AiOutlineOrderedList
          color={theme.colors.onSurface}
          style={{marginLeft: 3}}
        />
      </Option>

      <Option
        active={false}
        onClick={() => {
          refPreferences.current.open();
        }}
        style={{fontSize: 12}}>
        {t('general:preferences')}
        <FiSettings color={theme.colors.onSurface} style={{marginLeft: 3}} />
      </Option>

      <Filter ref={refFilter} type="calendar" filter />
      <Filter ref={refSort} type="calendar" sort />

      <BottomSheet ref={refPreferences as any} title={t('general:preferences')}>
        <Preferences />
      </BottomSheet>
      <MdNavigateBefore
        size={35}
        onClick={handlePrevWeek}
        style={{color: theme.colors.text, cursor: 'pointer'}}
      />
      <MdNavigateNext
        size={35}
        onClick={handleNextWeek}
        style={{color: theme.colors.text, cursor: 'pointer'}}
      />
      <DatePicker
        picker={
          modeInterval === 'monthly'
            ? 'month'
            : modeInterval === 'weekly'
            ? 'week'
            : 'date'
        }
        value={moment(lowerDate)}
        onChange={value => {
          if (!value) return;
          const interval = getIntervalDate(
            mode as string,
            modeInterval as string,
            value.format('yyyy-MM-DD'),
            startOfWeek,
          );
          dispatch(
            CalendarActions.setInterval(interval.lowerDate, interval.upperDate),
          );
        }}
        format="MMMM yyyy"
        style={{
          border: `1px solid ${theme.colors.surfaceLight}`,
          borderRadius: 5,
          backgroundColor: theme.colors.surface,
          textTransform: 'capitalize',
          fontFamily: 'Poppins-Regular',
          fontWeight: '200',
          color: 'white',
          fontSize: 15,
          height: 33,
        }}
        size="large"
        // className="input_week"
      />
    </Row>
  );
};

export default PanelControl;

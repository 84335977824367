import {Category, State} from '@/store/modules';
import {Section, TextBase, TextSection} from '@/components/atoms';
import React from 'react';
import {useSelector} from 'react-redux';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import Chart from 'react-google-charts';

type Props = {
  categories: (Category & {total: number})[];
};

const ContainerChart = styled.div`
  .chartPie g text {
    fill: ${({theme}) => theme.colors.onBackground};
  }
`;

const WheelOfLife: React.FC<Props> = ({categories}: Props) => {
  const {t} = useTranslation();
  const premium = useSelector<State>(state => state.user.premium);
  const theme = useTheme();
  const categoriesList = categories
    ?.filter(i => (premium ? !!i?.total : true))
    .sort((a, b) => b.total - a.total);
  return (
    <Section>
      <TextSection>
        {t('statistic:wheelOfLife')}
        <TextBase color={theme.colors.premium} size={15}>
          {!premium && `(${t('statistic:onlyPremium')} 👑)`}
        </TextBase>
      </TextSection>
      <ContainerChart>
        <Chart
          width={'400px'}
          height={'300px'}
          chartType="PieChart"
          className="chartPie"
          loader={<div>Loading Chart</div>}
          data={[
            ['Categoria', 'Total'],
            ...categoriesList.map(i => [i.name, i.total]),
          ]}
          rootProps={{'data-testid': '1'}}
          options={{
            backgroundColor: theme.colors.surface,
            colors: categoriesList.map(i => i.color as string),
          }}
        />
      </ContainerChart>
    </Section>
  );
};

export default WheelOfLife;

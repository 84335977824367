import styled from 'styled-components';

import logo from '../../assets/images/logo.png';

export const Container = styled.div`
  height: 60px;
  background: ${({theme}) => theme.colors.degrade};
  box-shadow: 1px 1px 10px 1px ${({theme}) => theme.colors.shadow};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Right = styled.div`
  display: flex;
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 20px;
`;
export const Logo = styled.img.attrs({src: logo})`
  width: 38px;
  height: 38px;
  margin-right: 15px;
`;

type ItemProps = {
  active?: boolean;
};
export const Item = styled.h3<ItemProps>`
  color: ${({theme}) => theme.colors.onSurface};
  font-size: 0.95rem;
  ${({active, theme}) =>
    active &&
    `
    font-family: 'Poppins-Bold';
    border-bottom: 1px solid ${theme.colors.primary};
    margin-top:3px;
    color: ${theme.colors.primary};
  `}
  margin: 2px 12px;
  &:hover {
    cursor: pointer;
    color: ${({theme}) => theme.colors.primary};
  }
`;
export const TextLogo = styled.h2`
  color: ${({theme}) => theme.colors.primary};
  font-family: 'Poppins-Bold';
  margin: 0px;
`;

import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Container, Fixed, Loading, ViewBase} from '@/components/atoms';
import Week from '@/components/Week2';
import {
  ModeInterval,
  ModeView,
  selectorAllActivities,
  selectorApp,
  selectorCalendar,
  selectorFilterCalendar,
  selectorModeInterval,
  selectorModeView,
} from '@/store/modules';
import {getActivities, handleFilter} from '@/utils/activity';
import ListMonth from './ListMonth';
import ListWeek from './ListWeek';
import PanelControl from './PanelControl';
import {Content} from './styles';
import Header from '@/components/Header';
import {colors} from '@/styles';
import {getDateToday} from '@/utils/date';
import Agenda from '@/components/organisms/Agenda';

type PropsListActivities = {
  activities: any[];
  loading: boolean;
};

function SwitchModeViewActivities({activities, loading}: PropsListActivities) {
  const modeInterval = useSelector(selectorModeInterval);
  const modeView = useSelector(selectorModeView);
  const qntDays =
    modeInterval === ModeInterval.Daily
      ? 1
      : modeInterval === ModeInterval.Days3
      ? 3
      : 7;
  if (modeInterval === ModeInterval.Monthly)
    return <ListMonth activities={activities} />;
  if (modeView === ModeView.Agenda) {
    return <Agenda activities={activities} qntDays={qntDays} />;
  }
  return (
    <ListWeek activities={activities} qntDays={qntDays} loading={loading} />
  );
}

function Tasks() {
  const {currentWeek, loading, upperDate, lowerDate} =
    useSelector(selectorCalendar);
  const {tasks, habits, goals} = useSelector(selectorAllActivities);
  const {
    mode,
    modeInterval = ModeInterval.Weekly,
    startOfWeek = 0,
  } = useSelector(selectorApp);
  const {filterBy} = useSelector(selectorFilterCalendar);
  const activities = useMemo(() => {
    return getActivities({
      tasks,
      habits,
      goals,
      upperDate,
      lowerDate,
      mode,
    }).map(i => ({
      ...i,
      data: i.data.filter(a => handleFilter(a, filterBy)),
    }));
  }, [tasks, goals, habits, upperDate, lowerDate, mode, filterBy, startOfWeek]);

  useEffect(() => {
    if (!currentWeek.find(i => i.date === getDateToday())) return;
    if (!loading && mode === ModeView.Agenda) {
      window.scroll(40, (new Date().getHours() - 1) * 60);
    }
    if (loading && mode === ModeView.Agenda) {
      window.scroll(40, 0);
    }
  }, [loading, mode, currentWeek]);

  return (
    <Container>
      <Fixed>
        <Header />
        <PanelControl />
        <Week
          mode={mode}
          modeInterval={modeInterval}
          showDays={modeInterval !== ModeInterval.Monthly}
        />
        {loading && (
          <ViewBase center>
            <Loading size={35} color={colors.primary} />
          </ViewBase>
        )}
      </Fixed>
      <Content style={{marginTop: 45}}>
        <SwitchModeViewActivities loading={loading} activities={activities} />
      </Content>
    </Container>
  );
}

export default Tasks;

import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  /* padding: 5px 0; */
`;
export const ContentWeek = styled.div`
  display:flex;
  flex: 1;
  flex-direction: row;
  ${({mode})=> mode === 'agenda' && `
    margin-left: 53px;
    margin-right: 20px;
  `}
`;
export const ContentDayWeek = styled.div`
  /* padding-bottom:5px; */
  flex-direction: column;
  /* border-right: 1px solid ${({theme})=> theme.colors.placeholder}; */
  align-items: center;
  justify-content:center;
  flex:1;
  ${({active,theme}) =>
    active &&
    `
    border-radius: 50px;
    background: ${theme.colors.degrade};
  `};
text-align:center;
`;
export const ColumnDay = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content:center;
  flex:1;
`;
export const TextWeek = styled.h4`
  margin:4px;
  text-align:center;
  color: ${({isToday, active,theme}) =>
    active ? theme.colors.white: isToday ? theme.colors.primary : theme.colors.text };
  font-weight: ${({isToday, active}) =>
    isToday || active ? 'bold' : 'normal'};
  font-size: ${({size}) => size || 20}px;
`;
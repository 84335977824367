import styled from 'styled-components';
// CONTAINERS
export const ViewBase = styled.div`
  display:flex;
  flex-direction: column;
  ${({backgroundColor}) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({height}) => height && `height: ${height}px;`}
  ${({width}) => width && `width: ${width}px;`}
  ${({padding}) => padding && `padding: ${padding}px;`}
  ${({flex}) => flex && `display:flex; flex: ${flex};`}
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${({paddingTop}) => paddingTop && `padding-top: ${paddingTop}px;`}
  ${({paddingBottom}) => paddingBottom && `padding-bottom: ${paddingBottom}px;`}
  ${({justifyContent}) =>
    justifyContent && `display:flex;justify-content: ${justifyContent};`}
  ${({alignItems}) => alignItems && `display:flex;align-items: ${alignItems};`}
  ${({flexDirection}) => flexDirection && `display:flex;flex-direction: ${flexDirection};`}
  ${({center}) => center && `display:flex;align-items:center; justify-content:center`}
  ${({marginHorizontal}) =>
    marginHorizontal && `margin: 0 ${marginHorizontal}px;`}
  ${({marginVertical}) => marginVertical && `margin: ${marginVertical}px 0;`}
  ${({row}) => row && `display:flex;flex-direction: row;`}
  ${({column}) => column && `display:flex;flex-direction: column;`}
`;
export const Container = styled.div.attrs({
  // forceInset: {bottom: 'always'},
})`
  flex: 1;
  background-color: ${({theme})=>theme.colors.background};
  min-height:100vh;
`;
export const Content = styled.div`
  flex: 1;
  background: ${(props) => props.color || props.theme.colors.background};
  padding: 10px 15px;
  padding-bottom: 0;
`;
export const Section = styled.div`
  padding: 20px;
  background: ${({theme})=>theme.colors.surface};
  border-radius:10px;
`;
export const Column = styled(ViewBase)`
  flex-direction: column;
  ${({alignCenter}) => alignCenter && 'align-items: center;'}
  ${({spaceBetween}) => spaceBetween && 'justify-content: space-between;'}
`;

export const Row = styled.div`
  display:flex;
  flex-direction: row;
  ${({alignCenter}) => alignCenter && 'align-items: center;'}
  ${({spaceBetween}) => spaceBetween && 'justify-content: space-between;'}
`;
export const Fixed = styled.div`
  position:fixed;
  width:100%;
  background:${({theme})=> theme.colors.background};
  z-index:20;
`;

export const InputContainer = styled.div`
  display:flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  /* background-color: ${({theme})=> theme.colors.background}; */
`;

export const Form = styled.form`
  flex: 1;
  /* background-color: ${({theme})=>theme.colors.regular}; */
  padding: 5px 15px;
  justify-content: center;
`;

export const TextSubHeader = styled.h3`
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
  align-self: flex-end;
  font-family: 'Poppins-Bold';
  color: ${({theme})=>theme.colors.dark};
`;

export const TextInfo = styled.p`
  padding: 15px 5px;
  font-size: 15px;
  color: ${({theme})=>theme.colors.text};
  font-family: 'Poppins-Bold';
  text-align: ${(props) => (props.right ? 'right' : 'center')};
`;
export const TextActivity = styled.p`
  font-size: 15px;
  color: ${(props) => props.color || props.theme.colors.text};
  padding-left: ${(props) => (props.padding ? props.padding : 0)}px;
`;
export const TextTime = styled.span`
  font-size: 11px;
  margin:0;
  padding:0;
  color: ${(props) => props.color || props.theme.colors.text};
`;

export const Label = styled.span`
  font-family: 'Poppins-Bold';
  margin-bottom:2px;
  color:${({color,theme})=> color || theme.colors.onBackground};
`;

export const TextBase = styled.span`
  text-align: ${({center}) => (center ? 'center' : 'left')};
  font-size: ${({size}) => size || 15}px;
  font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
  color: ${({color, theme}) => color || theme.colors.onBackground};
  ${({padding}) => padding && `padding: ${padding}px;`}
  ${({margin}) => margin && `margin: ${margin}px;`}
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
  ${({marginRight}) => marginRight && `margin-right: ${marginRight}px;`}
  ${({marginLeft}) => marginLeft && `margin-left: ${marginLeft}px;`}
  ${({marginHorizontal}) =>
    marginHorizontal && `margin: 0 ${marginHorizontal}px;`}
  ${({marginVertical}) => marginVertical && `margin: ${marginVertical}px 0;`}
  ${({textAlign}) => textAlign && `text-align: ${textAlign};`}
`;

export const TextSection = styled.h3`
  font-size: 21px;
  color: ${(props) => props.color || props.theme.colors.primary};
  font-family: 'Poppins-Bold';
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const TextValue = styled.p`
  font-size: 15px;
  color: ${({placeholder,theme}) => (placeholder ? theme.colors.placeholder : theme.colors.text)};
  text-align: ${({center}) => (center ? 'center' : 'left')};
  margin:0;
`;

export const Required = styled.p`
  font-size: 15px;
  color: ${({theme})=>theme.colors.red};
  font-family: 'Poppins-Bold';
`;

export const Title = styled.h1`
  font-size: 25px;
  color: ${({theme})=>theme.colors.onBackground};
  text-align: center;
`;
export const Option = styled.button`
  font-size: 15px;
  padding: 3px 15px;
  border:none;
  border-radius:5px;
  background-color: ${({theme,active})=>active ?theme.colors.primary : theme.colors.surface};
  border: 1px solid ${({theme,active})=>active ?theme.colors.surface : theme.colors.regular};
  color: ${({theme,active})=>active ?theme.colors.white : theme.colors.text};
  margin-left: 15px;
  cursor:pointer;
`;

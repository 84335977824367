import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Content, TextLabel, ViewBase} from '@/components/atoms';
import {
  Category as ICategory,
  CategoryActions,
  selectorCategories,
  DrawerActions,
} from '@/store/modules';
import {useTranslation} from 'react-i18next';
import {CardCategory} from './styles';
import {Button, Header} from '@/components/molecules';

const Category: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(selectorCategories);
  useEffect(() => {
    dispatch(CategoryActions.getCategoriesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        {categories.map((item: ICategory) => (
          <CardCategory
            key={item.uid}
            color={item.color as string}
            onClick={() => {
              dispatch(
                DrawerActions.setDrawer({
                  action: `ADD_CATEGORY`,
                  payload: {category: item},
                }),
              );
            }}
            style={{cursor: item.user_id === null ? 'default' : 'pointer'}}>
            <TextLabel>{t(item.name)}</TextLabel>
          </CardCategory>
        ))}
        <ViewBase center>
          <Button
            title={t('addCategory')}
            marginTop={20}
            onPress={() =>
              dispatch(
                DrawerActions.setDrawer({action: `ADD_CATEGORY`, payload: {}}),
              )
            }
          />
        </ViewBase>
      </Content>
    </Container>
  );
};

export default Category;

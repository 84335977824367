import React from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { DrawerActions } from '@/store/modules';

const Header = ()=> {
  const dispatch = useDispatch()
  const theme = useTheme()
  const {t}=useTranslation()
  const {action} = useSelector(state=> state.drawer)
  const getColor = (ac)=> ac === action ? theme.colors.primary : theme.colors.onBackground;
  return(
    <Menu 
    style={{background:theme.colors.background, borderColor: theme.colors.surface}}
    onClick={e=> dispatch(DrawerActions.setDrawer({action: e.key}))} selectedKeys={[action]} mode="horizontal">
      <Menu.Item key="ADD_TASK" style={{color:getColor('ADD_TASK')}}>
        {t('task').toUpperCase()}
      </Menu.Item>
      <Menu.Item key="ADD_HABIT" style={{color:getColor('ADD_HABIT')}}>
        {t('habit').toUpperCase()}
      </Menu.Item>
      <Menu.Item key="ADD_GOAL" style={{color:getColor('ADD_GOAL')}}>
        {t('goal').toUpperCase()}
      </Menu.Item>
    </Menu>
);}
export default Header;

class NotificationLocal{
  public ids: number[];
  constructor(){
    this.ids = []
  }
  requestPermission(){
    return Notification.requestPermission()
  }

  push(title:string){
    const icon = 'https://appflynow.com/static/logo-866ef3bb8e091031896ddaf8affbb1f0.png'
      new Notification(title,{icon})
  }

}
// eslint-disable-next-line
export default new NotificationLocal();
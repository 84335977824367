export default {
  noActivitiesFilter: 'Nenhuma atividade de acordo com os filtros aplicados',
  doneAllActivities: `Parabéns! 🚀 \nVocê realizou todas atividades do dia! 🎯`,
  noActivitiesToday: `Você não possui nenhuma atividade para o dia`,
  noActivitiesInPeriod: `Você não possui nenhuma atividade durante o período`,
  noTaskInThisList: `Você não possui nenhuma Tarefa nesta lista`,
  noTaskInThisListFilter: `Nenhuma tarefa de acordo com os filtros.`,
  removeFiltersToSeeList: `Remova os filtros aplicados para ver as tarefas existentes desta lista.`,
  
  addTask: 'Adicionar Tarefa',
  howToAddTask: 'Como gerenciar as tarefas',
  editTask: 'Editar Tarefa',
  deleteTask: 'Apagar Tarefa',
  updateTask: 'Editar Tarefa',
  updateTaskAlert: `Essa tarefa se repete frequentemente. Você deseja editar somente essa ocorrência ({{date}}) ou também suas futuras ocorrências?`,
    deleteDesc: 'Tem certeza que deseja excluir essa atividade?',
  deleteTaskAlert: `Essa tarefa se repete frequentemente. Você deseja apagar somente essa ocorrência ({{date}}) ou também suas futuras ocorrências?`,
  title: 'Título',
  label: 'Tipo',
  categories: 'Categorias',
  description: 'Descrição',
  startDate: 'Dia',
  endDate: 'Fim',
  hour: 'Horário',
  start: 'Início',
  end: 'Fim',
  checkList: 'Check-list',
  notification: 'Notificação',
  notifications: "Notificações",
  period: 'Frequência',
  notRepeat: 'Não repete',
  daily: 'Diária',
  segSex: 'SEG á SEX',
  segSab: 'SEG á SAB',
  weekly: 'Semanal',
  monthly: 'Mensal',
  yearly: 'Anual',
  custom: 'Personalizado',

  all: 'Todo o Período',
  '7days': '7 Dias',
  '30days': '30 Dias',
  thisMonth: 'Este Mês',
  thisYear: 'Este Ano',
  '12month': '12 Meses',

  hasDate: 'Definir Data',
  hasDateDesc: 'Se não ativado, a tarefa fica visivel na tela listas',
  inbox: 'Entrada',
  allTask: 'Todas',

  placeholderTitle: 'Ex: Estudar para prova',
  placeholderLabel: 'Selecione o Tipo',
  placeholderCategories: 'Ex: Intelectual, Profissional',
  placeholderDescription: 'Ex: Capítulo 1,2 e 3',
  placeholderPeriod: 'Selecione os dias da semana',
  placeholderPeriodDesc:
    'A atividade se repetirá todos os dias selecionados, até a data de fim definida.',
  placeholderPeriodInterval: 'Repetir a cada {{x}} dias',
  everyXdays: 'A cada {{x}} dias',
  intervalDays: 'Intervalo de dias:',
  nextDays: 'Próximas ocorrências:',
  placeholderPeriodIntervalDesc:
    'A atividade se repetirá intercalando a quantidade de dias informada, até a data de fim definida.',
  errorIntervalDays: 'Informe o intervalo de dias!',
  placeholderNotification: 'Notificações no horário da atividade',
  exampleCheckList: 'Ex: Tarefa 1',

  addNewCategory: 'Adicionar nova Categoria',
  newCategory: 'Nova Categoria',
  placeholderCategory: 'Ex: Projeto X',
  'multiSelect.task.description':
    'Criar tarefas que estejam alinhadas com seus objetivos aumentam as chances de atingir suas metas!',
  'multiSelect.task.textButtonAdd': 'Adicionar nova Tarefa',
  'multiSelect.task.dataVoid':
    'Você não possui nenhuma tarefa, adicione uma nova tarefa agora mesmo!',

  'multiSelect.habit.description':
    'Criar hábitos que estejam alinhados com seus objetivos aumentam as chances de atingir suas metas!',
  'multiSelect.habit.textButtonAdd': 'Adicionar novo Hábito',
  'multiSelect.habit.dataVoid':
    'Você não possui nenhum hábito, adicione um novo hábito agora mesmo!',

  'multiSelect.category.description':
    'Selecione as categorias referente a sua atividade, baseado na ferramenta Roda da Vida.',
    reschedule: 'Reagendar',
    placeholderReschedule:
      'Reagendar a tarefa para o dia seguinte, enquanto ela não for concluída.',
      transformInTask: 'Transformar em Tarefa',
  moveToChecklist: 'Mover para Checklist',
  moveToOtherChecklist: 'Mover p/ outro Checklist',
  move: 'Mover',
  targetTask: 'Tarefa Alvo',
  targetTaskDesc: 'Tarefa no qual o item do checklist irá pertencer.',
  itemOfChecklist: 'Item do Checklist',
  selectTask: 'Selecionar Tarefa',
  periodOfItem: 'Frequência do item',
  periodOfItemDesc:
    'Frequência que o item do checklist {{item}} deve ter no checklist na tarefa alvo ({{task}})',
  'checklist-oneday': 'Somente uma ocorrência',
  'checklist-daily': 'Todas ocorrências',
  
}as any;

export default {
  unknown: 'An error has occurred :(',
  tryAgainLogin:
    'Try to log into the app by creating an account with email and password.',
  canceled: 'Canceled',
  serviceUnavailable: 'Service Unavailable',
  emailIsRequired: 'The email field is required.',
  categoryIsRequired: 'The category name field is required.',
  typeOnlyNumber: 'Type only numbers',
  checkPasswordAgain: 'Enter your password again',
  msgCheckPasswordAgain:
    'The password and new password fields must be the same',

  validateFailure: 'Validation failed',
  msgValidateFailure: 'Enter the fields correctly',

  selectActivities: 'Select the activities you want to delete!',
  selectMore: 'Select more {{qnt}} activities',
  selectHour: 'First select a time!',

  selectionDate: 'Unable to select date',
  periodValid: 'Enter a valid option for the PERIOD field',
  limitReached: "You've reached your {{activity}} limit!",
  onlyPremium: 'Only available to Premium users 👑',
  checkListRequired: 'Fill in the field to add an item to the checklist',
  validation: 'Please fill in the required fields (*)',
  validationEmail: 'Invalid Email :(',
  syncRanking: 'No Connection: Unable to update ranking',

  tokenInvalid: 'Please login again',
  tokenInvalidMsg: 'Your session has expired, please login again',
  versionUnavailable: 'Discontinued version!',
  versionUnavailableMsg:
    'This version has been discontinued, please update the app in the Store!',

  noConnection: 'No connection',
  noConnectionMsg: 'Check your internet connection',
  requestSubMsg: `Check your internet connection and try again, if the error persists, contact us by email!`,
  purchaseSubMsg: 'If you get any error during purchase, please contact us!',
  openUrl: `Try opening the {{url}} link from the browser`,

  validationError: 'Invalid data :(',
  validationErrorMsg: 'Please enter fields correctly!',
  loginError: 'Invalid email or password :(',
  loginErrorMsg: 'Please enter your data correctly!',
  userAlreadyExists: 'User already registered!',
  userAlreadyExistsMsg: 'Please try again using another email',
  userNotExists: 'User not found.',
  userNotExistsMsg: 'The email address entered does not belong to any user.',
  notFound: 'Resource not found',
  notFoundMsg: 'The requested resource was not found',
  limitImport:
    'The limit for future tasks is {{limit}}, uncheck {{minDelete}} tasks',
} as any;

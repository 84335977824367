import metrics from '@/styles/metrics';
import styled from 'styled-components';

export const InputContainer = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  margin-bottom: ${metrics.space.smaller}px;
`;

export const TextInput = styled.input.attrs(({theme}) => ({
  placeholderTextColor: theme.colors.placeholder,
}))`
  color: ${({theme}) => theme.colors.text};
  font-size: ${({theme}) => theme.metrics.font.medium}px;
  padding: ${({theme}) => theme.metrics.space.smaller}px;
  margin: 0px;
  height: ${({theme}) => theme.metrics.component.input}px;
  border-radius: 3px;
  padding:10px ;
  border: 2px solid ${({theme}) => theme.colors.surface};
`;
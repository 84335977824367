import {
  GOAL,
  HABITO,
  CIRCUMSTANTIAL,
  URGENT,
  IMPORTANT,
  JUST_TODAY,
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
  CUSTOM,
  SEG_SEX,
  SEG_SAB,
} from "@/constants";

export default {
  task: "Task",
  tasks: "Tasks",

  habit: "Habit",
  habits: "Habits",
  trigger: "Trigger",
  reward: "Reward",
  routine: "Routine",
  exampleHabit: "Reading",
  exampleTrigger: "Leave book by the bed",
  exampleRoutine: "Read 20 pages",
  exampleReward: "Get knowledge",

  exampleDailyGoal: "Ex: 20 pages",
  dailyGoal: "Daily Goal",
  addProgress: 'Add Progress',
  placeholderAmount: 'Ex: 30',
  unity: "Unit",
  amount: "Amount",
  suggestions: "Suggestions",
  field: "Field",

  goal: "Goal",
  goals: "Goals",

  important: "Important",
  urgent: "Urgent",
  circumstantial: "Circumstantial",
  [HABITO]: "Habit",
  [GOAL]: "Goal",
  [IMPORTANT]: "Important",
  [URGENT]: "Urgent",
  [CIRCUMSTANTIAL]: "Circumstantial",

  [JUST_TODAY]: "Do not repeat",
  [DAILY]: "Daily",
  [WEEKLY]: "Weekly",
  [MONTHLY]: "Monthly",
  [YEARLY]: "Yearly",
  [CUSTOM]: "Custom",
  [SEG_SEX]: "MON to FRI",
  [SEG_SAB]: "MON to SAT",

  DOM: `SUN`,
  SEG: `MON`,
  TER: `TUE`,
  QUA: `WED`,
  QUI: `THU`,
  SEX: `FRI`,
  SAB: `SAT`,

  to: "To",
  or: "OR",
  change: "Change",
  clean: "Clean",
  never: "Never",

  decrescent: "Decrescent",
  crescent: "Crescent",
  filter: "Filter",
  sort: "Sort",

  update: "Update",
  updateApp: "Update the app!",
  updateAppStore: "Update the app in the Store",
  updateAppDesc:
    "This version has been discontinued, to continue using Flynow, please update the app to the latest version!",

  tip: "TIP:",
  example: "EXAMPLE",
  next: "Next",
  previous: "Previous",
  back: "Go Back",
  onlyThis: "Only this occurrence",
  thisAndNext: "This and the next ones",
    allOcorrences: 'All occurrences',
  days: "days",
  daily: 'Daily',
  days3: '3 Days',
  week: "Week",
  weekly: 'Weekly',
  monthly: 'Monthly',

  select: "Select",
  save: "Save",
  yes: "YES",
  no: "NO",
  ok: "OK",
  share: "Share",
  add: "Add",
  confirm: "Confirm",
  cancel: "Cancel",
  addNote: "Add Note",
  edit: "Edit",
  delete: "Delete",
  duplicate: 'Duplicate',
  thisYear: 'This year',
  past: 'Past',
  future: 'Future',
  searchByTitle: 'Search by title...',
  searchActivity: 'Search Activity',
  tools: 'Tools',

  today: 'Today',
  yesterday: 'Yesterday',
  tomorrow: 'Tomorrow',
  activities: 'Activities',
  


  selectAction: "Choose an Action",
  addNew: "Add new:",
  maskAs: "Mark as {{status}}",
  detailsDay: "Details of the day",
  details: "Details",
  date: "Date",
  status: "Status",
  plan: "Plan",
  points: "Points",
  note: "Note",
  notes: "Notes",
  listVoid: "No item in the period!",
  placeholderNote: "Enter a note for this specific day",

  descPremiumDeleteActivities:
    "With the premium version you have unlimited activities and many other features",
  deleteActivity: "Delete {{activity}}",
  descDelete:
    "The limit for future {{activity}} is {{limit}} and you already have {{total}}.\nDelete {{deleteMin}} {{activity}} to add another one.",

  statisticHabit: "Habit Statistics",
  statisticTask: "Task Statistics",
  history: "Historic",
  performance: "Performance",
  totalDaysDone: "Total days performed",
  totalDaysNotDone: "Total days not taken",
  currentSequence: "Current Sequence",
  biggerSequence: "Longest Sequence",
  biggestSequences: 'Biggest Sequences',
  lastSequences: 'Last Sequences',
  detailsHabit: "Habit Details",
  detailsTask: "Task Details",
  noActivitiesAdded: "No activities have been added!",

  title: "Title",
  label: "Label",
  categories: "Categories",
  description: "Description",
  startDate: "Day",
  endDate: "End",
  hour: "Hour",
  start: "Start",
  end: "End",
  startHour: "Start Hour",
  endHour: "End Hour",
  achievable: "Check List",
  checkList: "Check list",
  checkListDaily: 'Daily check list',
  progress: "Progress",
  notification: "Notification",
  notifications: "Notifications",
  active: "Active",
  inactive: "Inactive",
  period: "Period",
  sound: "Sound",
  soundNotification: "Notification Sound",
  soundDefault: "Default system sound",
  selectNotification: "Select a notification",

  specific: "Goal",
  measurable: "Amount",
  relevant: "Importance",

  Profissional: "Professional",
  Intelectual: "Intellectual",
  Finanças: "Finances",
  Social: "Social",
  Relacionamento: "Relationship",
  Família: "Family",
  Lazer: "Leisure",
  Saúde: "Health",
  Espiritual: "Spiritual",
  Emocional: "Emotional",

  "sound.default": "Default",
  "sound.alarm": "Alarm - Clock",
  "sound.morningclockalarm": "Alarm - Morning Clock",
  "sound.notification": "Notification - Soft",
  "sound.positivenotification": "Notification - Positive",
  "sound.bellnotification": "Notification - Bell",
  none: 'None',
  backgroundNoise: 'Background sound',
  
  discardChanges: "Discard changes?",
  discardChangesDesc:
    "You have unsaved changes, are you sure you want to exit?",
  done: "Done",
  notDone: "Not Done",
  message: "Message",
  actions: "Actions",
  selectDate: "Select Date",
  agenda: "Agenda",
  pending: "Pending",
  list: "List",
  lists: "Lists",
  modeView: "View Mode",

  import: "Import",
  importCalendar: "Import Calendar",
  eventsFromCalendar: "Events from Calendar",
  askImportCalendar: "Want to import your calendar activities?",
  selectCalendars: "Selecione o(s) calendário(s):",
  selectEvents: "Selecione o(s) calendário(s):",
  emptyListEvents: "No events available.",

  color: "Color",
  selectionColor: "Select Color",
  addCategory: "New Category",
  custom: "Custom",
  all: "All Period",
  "7days": "7 Days",
  "30days": "30 Days",
  thisMonth: "This Month",
  "12month": "12 Month",

  general: "General",
  others: "Others",
  fullDay: "Full Day",
  restoreNotifications: "Restore Notifications",
  restoreNotificationsMsg:
    "All notifications will be scheduled in the next few seconds...",
  testNotification: "Test Notification",

  detailsGoal: "Goal Details",
  statisticGoal: "Goal Statistics",

  timer: "Timer",
  stopwatch: "Stopwatch",
  reset: "Reset",
  pause: "Pause",
  resume: "Resume",
  toStart: "Start",
  time: "Time",
  finishAndSave: "Finish and Save",
  placeholderTimerNotification: "Notification sound when time expires.",
  focusTimerFinished: "Focus timer finished! 🚀🎉",
  submit: "Submit",
  listTasksEmpty:
    'Task management makes you more productive and brings you closer to your goals, create new tasks!',
  shareThisReward: 'Share this achievement with your friends!',
  removeFiltersToSee: 'To view activity for this date, remove filters!',
  createTask: 'Create Task',
  createHabit: 'Create new Habit',
  createGoal: 'Create Goal',
  listEmptyRewards: 'No achievements yet :(',
  listEmptyRewardsText:
    'Create goals, tasks and habits to achieve your first achievement!',
  listEmptyHabitsText:
    'Habits help you maintain discipline in the pursuit of your goals, create a new habit!',
  listEmptyGoalsText:
    'Goals direct you in pursuit of your goals, create your first goal!',
  onlyPremiumText:
    'Become premium to have access to this and many other features that will improve your productivity!',

  listEmptyNotifications: 'No notifications scheduled',
  listEmptyNotificationsText:
    'If you have tasks or habits with notifications enabled, click Reset Notifications to reschedule your notifications.',
  listEmptyReportText:
    'You have not yet completed a full period of use of the app, for the creation of the report.',

  addTimeManual: 'Add time manually',
  goToday: "Go to today's date",
  extraTime: 'Extra Time',
  doWantSaveTime: 'Save extra time?',
  newList: 'New List',
  columns: 'Columns',

} as any;

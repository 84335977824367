import React from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Separator1, TextSeparator} from './styles';

interface Props {
  marginTop?: number;
  marginBottom?: number;
}

const Separator = ({marginTop, marginBottom}: Props): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Container marginTop={marginTop} marginBottom={marginBottom}>
      <Separator1 />
      <TextSeparator>{t('or')}</TextSeparator>
      <Separator1 />
    </Container>
  );
};
Separator.defaultProps = {
  marginTop: 20,
  marginBottom: 20,
};

export default Separator;

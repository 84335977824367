import styled from 'styled-components';

export const Week = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;
type DayProps = {
  active?: boolean;
};
export const Day = styled.div<DayProps>`
  padding: 5px ${({theme}) => theme.metrics.space.small}px;
  border-radius: 50px;
  margin: 3px 1px;
  background: ${({theme, active}) =>
    active ? theme.colors.primary : theme.colors.surface};
`;
export const Increment = styled.div`
  padding: 6px;
  background-color: ${({theme}) => theme.colors.surface};
  border-radius: 50px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
`;
export default {
  noGoalsFilter: 'Nenhuma Meta de acordo com os filtros aplicados!',
  noGoals: 'Você ainda não possui nenhuma meta',
  addGoal: 'Adicionar Meta',
  howToAddGoal: 'Como criar metas',
  editGoal: 'Editar Meta',
  total: 'Total',
  performed: 'Concluídas',

  specific: 'Qual o seu objetivo?',
  measurable: 'Qual o tamanho da sua meta?',
  date: 'Qual a data limite?',
  relevant: 'Por que essa meta é importante?',
  habits: 'Hábitos relacionados',
  tasks: 'Tarefas relacionadas',
  noneHabit: 'Nenhum hábito foi associado a essa meta!',
  noneTask: 'Nenhuma tarefa foi associada a essa meta!',

  placeholderSpecific: 'Ex: Ler 2 livros em Jan.',
  placeholderMeasurable: 'Ex: 2',
  placeholderDate: 'Ex: 31/01/2021',
  placeholderRelevant: 'Ex: Adquirir conhecimento',
  placeholderHabits: 'Selecione hábitos',
  placeholderTasks: 'Selecione tarefas',
  metricProgressBy: 'Medir progresso por',
  selectHabit: 'Selecionar Hábito',
}as any;

import {api} from '@/services';

interface AddUserApi {
  name: string;
  email: string;
  password: string;
  language: string;
  createdAt: string;
  updatedAt?: string;
}
interface UpdateUserApi {
  name?: string;
  email?: string;
  password?: string;
  language?: string;
}
interface Session {
  email: string;
  password: string;
  provider: string;
}
interface RecoveryPassword {
  code: string;
}
export interface UserApi extends AddUserApi {
  id: number;
  premium: boolean;
}
export interface LoginApi {
  token: string;
  user: UserApi;
}

export async function login(user: Session): Promise<LoginApi> {
  const {data} = await api.post('session', user);
  return data;
}

export async function addUser(user: AddUserApi): Promise<UserApi> {
  const {data} = await api.post(`user`, user);
  return data;
}

export async function updateUser(user: UpdateUserApi): Promise<UserApi> {
  const {data} = await api.put(`user`, user);
  return data;
}

export async function deleteUser(): Promise<UserApi> {
  const {data} = await api.delete(`user`);
  return data;
}
export async function sendEmailForResetPassword(
  email: string,
): Promise<RecoveryPassword> {
  const {data} = await api.put(`request-new-password`, {email});
  return data;
}
export async function resetPassword(
  email: string,
  password: string,
  code: string
): Promise<UserApi> {
  const {data} = await api.put('reset-new-password', {email, password, code});
  return data;
}

export async function getUserPremium(): Promise<boolean> {
  const {data} = await api.get(`user/premium`);
  return data;
}
export async function getUser(): Promise<boolean> {
  const {data} = await api.get(`user`);
  return data;
}

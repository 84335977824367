import styled from 'styled-components';
import Gamification from '@/services/Gamification';
import {ButtonTransparent, ViewBase} from '@/components/atoms';

export const Legend = styled(ViewBase)`
  width: 15px;
  height: 15px;
  margin-left: 12px;
  margin-right: 2px;
`;
export const ButtonReward = styled(ButtonTransparent)`
  align-items: center;
  margin-right: 8px;
`;

type IReward = {
  type: string;
};
export const Reward = styled.img.attrs(({type}: IReward) => ({
  src: Gamification.getReward(type).image
}))<IReward>`
  align-self: center;
`;

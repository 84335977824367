import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  differenceInDays,
  differenceInWeeks,
  parseISO,
  formatDateToShow,
  week,
} from '@/utils/date';
import WheelOfLife from '@/components/organisms/Statistics/WheelOfLife';
import TriadTime from '@/components/organisms/Statistics/TriadTime';
import StatisticsGeneral from '@/components/organisms/Statistics/StatisticsGeneral';
import ListActivitiesDones from '@/components/organisms/Statistics/ListActivitiesDones';
import Consistency from '@/components/organisms/Statistics/Consistency';
import WeeklyGrowth from '@/components/organisms/Statistics/WeeklyGrowth';
import Historic from '@/components/organisms/Statistics/Historic';
import Rewards from '@/components/organisms/Statistics/Rewards';
import {
  Row,
  Loading,
  Section,
  TextSection,
  TextBase,
  ViewBase,
  Container,
  Content,
} from '@/components/atoms';
import { selectorReport, State } from '@/store/modules';
import { useTranslation } from 'react-i18next';
import { Header } from '@/components/molecules';
import { getDataChartByWeek } from './utils';
import { useLocation } from 'react-router';

type ReportParam = {
  lowerDate: string;
  upperDate: string;
  period: string;
  text: string;
};

const Report = ():JSX.Element => {
  const { t } = useTranslation();
  const locale = t('locale:react-native-calendars', {
    returnObjects: true,
  }) as { dayNamesShort: any };
  const location = useLocation<ReportParam>();
  const { lowerDate, upperDate, period } = location.state
  const [sharing] = useState(false);
  const [loading, setLoading] = useState(true);
  const title =
    period === 'weekly'
      ? t('statistic:reportWeekly')
      : (t('statistic:reportMonthly') as string);
  const qntWeek = differenceInWeeks(upperDate, lowerDate) + 1;
  const {
    score,
    triadTime,
    wheelOfLife,
    rewards,
    tasks,
    habits,
    goals,
    dataChart,
  } = useSelector<State, any>(state =>
    selectorReport(state, lowerDate, upperDate),
  );

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Container>
      <Header />
        <Content>
          <>
            <Section>
              <TextSection>{sharing ? title : t('details')}</TextSection>
              <Row>
                <TextBase bold>{t('statistic:period')}: </TextBase>
                <TextBase>
                  {formatDateToShow(lowerDate)} {t('to')}{' '}
                  {formatDateToShow(upperDate)}
                </TextBase>
              </Row>
            </Section>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Section>
                  <TextSection>{t('statistic:score')}</TextSection>
                  <Row>
                    <TextBase bold>
                      {t('statistic:totalScorePeriod')}:
                    </TextBase>
                    <TextBase> {score}</TextBase>
                  </Row>
                </Section>

                <Rewards rewards={rewards} />

                <StatisticsGeneral statistics={{ habits, goals, tasks }} />
              
              <ViewBase>
                  <Historic
                    period={period}
                    lowerDate={lowerDate}
                    upperDate={upperDate}
                    dataChart={dataChart}
                    />
                </ViewBase>

                {period === 'monthly' && (
                  <Row spaceBetween>
                    <WeeklyGrowth
                      weeklyChart={{
                        labels: locale.dayNamesShort,
                        datasets: week.map((i, day) => {
                          const allDay = dataChart.filter(
                            (j: any) => parseISO(j.date).getDay() === day,
                          );
                          const totalScoreDay = allDay.reduce(
                            (acc: number, j: any) => acc + j.score,
                            0,
                          );
                          return totalScoreDay / allDay.length;
                        }),
                      }}
                      title={t('statistic:rateDay')}
                    />

                    <WeeklyGrowth
                      title={t('statistic:weeklyGrowth')}
                      weeklyChart={{
                        labels: ['1', '2', '3', '4', '5', '6']
                          .map(i => `${t('week').slice(0, 3)}.${i}`)
                          .slice(0, qntWeek),
                        datasets: getDataChartByWeek(
                          lowerDate,
                          upperDate,
                          period,
                          dataChart,
                        ).map((i: any) =>
                          i.data.reduce(
                            (acc: number, item: any) => acc + item.score,
                            0,
                          ),
                        ),
                      }}
                    />
                  </Row>
                )}
              
                <Row spaceBetween>
                  <TriadTime triadTime={triadTime} />
                  <WheelOfLife categories={wheelOfLife} />
                </Row>

                {!sharing && (
                  <>
                    <Consistency
                      numDays={differenceInDays(upperDate, lowerDate)}
                      dataChart={dataChart}
                      upperDate={upperDate}
                    />
                    <ListActivitiesDones
                      type="habit"
                      activities={habits?.data}
                    />
                    <ListActivitiesDones
                      type="task"
                      activities={tasks?.data}
                    />
                    <ListActivitiesDones
                      type="goal"
                      activities={goals?.data}
                    />
                  </>
                )}
              </>
            )}
          </>
        </Content>
    </Container>
  );
};
export default Report;

import {toast} from 'react-toastify';
import { colors } from '../styles';

const Toast = {
  show:(message:string)=> {
    toast.info('🚀  '+message)
  },
  error:(message:string)=>{
    toast.error(message,{style:{background:colors.red}})
  }
}
export default Toast;
import {call, put, select} from 'redux-saga/effects';
import {
  Analytics,
  api,
  Toast,
} from '@/services';
import i18next from 'i18next';
import {
  UserActions,
  TaskActions,
  HabitActions,
  AppActions,
  GoalActions,
  StatisticActions,
  QueueActions,
  RankingActions,
  ReportActions,
  ModalActions,
  LoginRequest,
  selectorLanguage,
  Configs,
  ListActions,
} from '@/store/modules';
import * as Api from '@/services/api/user';
import {SagaIterator} from '@redux-saga/types';
import {CategoryActions} from '../category/duck';
import {PurchaseActions} from '../purchase/duck';
import {Action} from '../utils';
import {FilterActions} from '../filter/duck';
import {CalendarActions} from '../calendar/duck';
import {RewardActions} from '../reward/duck';
import Storage from '@/services/Storage';
import { t } from '@/services/Locale';


export function* syncUserData(): SagaIterator<void> {
  try {
    yield put(CalendarActions.getActivitiesRequest());
    yield put(RewardActions.getRewardRequest());
    yield put(StatisticActions.getStatisticsRequest());
    yield put(CategoryActions.getCategoriesRequest());
    Analytics.log(Analytics.events.SYNC_USER);
  } catch (err:any) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* login({
  payload: {email, password},
}: Action<LoginRequest>): SagaIterator<void> {
  try {
    const data: Api.LoginApi = yield call(Api.login, {email, password, provider: 'email_and_password'});
    if(!data.user.premium){
      Toast.error(t('error:onlyPremium'))
      return;
    }
    api.defaults.headers.Authorization = data.token;
    yield put(UserActions.loginSuccess({...data.user, token: data.token}));
    Analytics.setUser(data.user);
    Storage.setItem('token',data.token)
    // Navigation.navigate('activities')
  } catch (err:any) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* loginGoogle({payload}: Action): SagaIterator<void> {
  try {
    const language = yield select(state => state.app.language);
    const data = yield call(Api.login, {
      ...payload,
      language,
    });
    if(!data.user.premium){
      Toast.error(t('error:onlyPremium'))
      return;
    }
    api.defaults.headers.Authorization = data.token;
    yield put(UserActions.loginSuccess({...data.user, token: data.token}));
    Analytics.setUser(data.user);
    Analytics.setUserProperty(Analytics.events.REGISTER_GOOGLE);
    Storage.setItem('token',data.token)
    // Navigation.navigate('activities')
  } catch (err) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* register({payload}: Action): SagaIterator<void> {
  try {
    const language = yield select(selectorLanguage);
    yield call(Api.addUser, {
      ...payload,
      language,
      syncReward: true,
      scheduleDailyNotification: false,
    });
    yield put(UserActions.loginRequest(payload.email, payload.password));
    Analytics.setUserProperty(Analytics.events.REGISTER_EMAIL);
  } catch (err) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* getOut(): SagaIterator<void> {
  try {
    Analytics.log(Analytics.events.LOGOUT);
    localStorage.clear()
    yield call(clearAllState)
  } catch (err:any) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}

export function* setConfigs({
  payload,
}: Action<{configs: Configs}>): SagaIterator<void> {
  const {configs} = payload;
  yield put(UserActions.setConfigsSuccess(configs));
  try {
    const user = yield call(Api.getUser)
      yield call(Api.updateUser, {
        configs: ({...user?.configs, ...configs}),
      } as any);
  } catch (err: any) {
  }
}

export function* clearAllState(): SagaIterator<void> {
  try {
    yield put(FilterActions.resetFilter());
    yield put(UserActions.getOutSuccess());
    yield put(AppActions.setInitialState());
    yield put(GoalActions.setInitialState());
    yield put(HabitActions.setInitialState());
    yield put(QueueActions.setInitialState());
    yield put(RankingActions.setInitialState());
    yield put(ReportActions.setInitialState());
    yield put(StatisticActions.setInitialState());
    yield put(TaskActions.setInitialState());
    yield put(CategoryActions.setInitialState());
    yield put(PurchaseActions.setInitialState());
    yield put(RewardActions.setInitialState());
    yield put(ListActions.setInitialState());
  } catch (err:any) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* restoreOwnState(): SagaIterator<void> {
  try {
    yield put(FilterActions.resetFilter());
    yield put(GoalActions.setInitialState());
    yield put(HabitActions.setInitialState());
    yield put(ReportActions.setInitialState());
    yield put(StatisticActions.setInitialState());
    yield put(RankingActions.setInitialState());
    yield put(TaskActions.setInitialState());
    yield put(CategoryActions.setInitialState());
    yield put(PurchaseActions.setInitialState());
    yield put(RewardActions.setInitialState());
    yield put(ListActions.setInitialState());
  } catch (err) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}

export function* updateUser({payload}: Action): SagaIterator<void> {
  const {user} = payload;
  try {
    const data = yield call(Api.updateUser, user);
    Analytics.setUser(data);
    yield put(UserActions.updateUserSuccess(data));
  } catch (err) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* deleteUser(): SagaIterator<void> {
  try {
    yield call(Api.deleteUser);
    yield put(UserActions.deleteUserSuccess());
    Analytics.setUserProperty(Analytics.events.DELETE_USER);
  } catch (err) {
    yield put(UserActions.setFailure());
    yield put(ModalActions.setError(err));
  }
}
export function* setLanguage(action: Action): SagaIterator<void> {
  const {language} = action.payload;
  try {
    yield put(
      AppActions.addAtribute({
        language,
      }),
    );
    i18next.changeLanguage(language);
    Analytics.setLanguage(language);
    Analytics.log(Analytics.events.LANGUAGE);
    yield call(Api.updateUser, {language});
  } catch (err) {
    yield put(UserActions.setFailure());
  }
}

import {Level} from '@/services/Gamification';

export const StatisticTypes = {
  GET_STATISTICS_REQUEST: 'statistic/GET_STATISTICS_REQUEST',
  GET_STATISTICS_SUCCESS: 'statistic/GET_STATISTICS_SUCCESS',
  SET_FAILURE: 'statistic/SET_FAILURE',
  ADD_SCORE: 'statistic/ADD_SCORE',
  SET_LEVEL: 'statistic/SET_LEVEL',
  SET_INITIAL_STATE: 'statistic/SET_INITIAL_STATE',
};

export type PerformeStatistic = {
  done: number;
  total: number;
};
export type SelectorStatistics = {
  tasks: PerformeStatistic;
  habits: PerformeStatistic;
  goals: PerformeStatistic;
};
export type SelectorTriadTime = {
  circumstantial: number;
  important: number;
  urgent: number;
};
export type SelectorWeeklyChart = {
  labels: string[];
  datasets: number[];
};
export interface StatisticState {
  score: number;
  level: Level;
  percent: {
    tasks: PerformeStatistic;
    habits: PerformeStatistic;
    goals: PerformeStatistic;
  };
  dataChart: {
    labels: string[];
    datasets: number[];
  };
  triadTime: {
    circumstantial: number;
    important: number;
    urgent: number;
  };
  weeklyChart: {
    labels: string[];
    datasets: number[];
  };
}

export default {
  general: 'Settings',

  profile: 'Profile',
  updateProfile: 'Edit Profile',
  deleteProfile: 'Delete Account',
  wantDeleteAccountTitle: 'Do you want to delete your account?',
  wantDeleteAccountMsg: 'All your app data will be erased',
  wantDeleteAccountMsgPremium:
    'You will no longer have access to premium benefits (even if you create another account) and all your app data will be erased.',

    soundOnCheck: 'Sound when completing activity',
    cardActivityColorBy: 'Activity Card border color',
    startOfWeek: 'Week starts in',
    showCheckList: 'Show checklist on the card',
    checkListConfig: 'Checklist configuration',
    checkListTaskConfig:
      'Keep a single checklist for all occurrences of the task',
    checkListTaskDateConfig: 'Restart check-list daily',
    preferences: 'Preferences',
    
  theme: 'Theme',
  themeDark: 'Dark Theme',
  themeDefault: 'Default Theme',
  premium: 'Premium',
  'premium.title': 'Premium Benefits 👑',
  'premium.getError': 'Do you have any questions or got an error?',
  'premium.support': 'Contact support',
  'premium.month': 'MONTH',
  'premium.months': 'MONTHS',
  'premium.expired': 'EXPIRED',
  'premium.canceled': 'CANCELED',
  'premium.active': 'ACTIVE',

  getPremium: 'Get Premium',
  adsPremium: ['LOOK AT ', '13 BENEFITS ', 'TO BECOME ', 'PREMIUM 👑'],
  premiumVersion: 'Discover the premium version 👑',
  versionWeb: 'Discover the web version',
  mySubs: 'My subscription',
  'web.title': 'Web Platform',
  'web.target':
    'With the Web Platform it is possible to manage all your tasks, habits and goals also from the computer, in addition to the Flynow app.',
  'web.howToAccess': 'How to access the Web Platform?',
  'web.disclamer': 'Flynow Web Version is only available to Premium Users.',
  'web.content':
    'To access the extension, go to the web.appflynow.com link or search on Google for "Flynow plataforma web". Then login with your Flynow account and use it at your leisure!',
  extension: 'Discover the extension',
  'extension.title': 'Extension',
  'extension.target':
    'With the extension you can quickly view and mark as completed all your tasks, habits and goals by the computer.',
  'extension.howToAccess': 'How to access the Extension?',
  'extension.disclamer': 'Flynow Extension is only available to premium users.',
  'extension.content':
    'To access the extension, go to the link appflynow.com/extensao or go to your browser store (Chrome web store or Firefox browser add-ons ), then search for "Flynow - Productivity", install the extension and do the login with your Flynow account and use it at your leisure!',
  language: 'Language',
  pt: 'Portuguese',
  es: 'Spanish',
  en: 'English',

  notifications: 'Notifications',
  nextsNotifications: 'Nexts Notifications',

  contents: 'Contents',
  blog: 'Blog',
  ebooks: 'Ebooks: Habits and Goals',

  contact: 'Contact',
  email: 'Email',
  instagram: 'Instagram',

  contribute: 'Contribute',
  rateApp: 'Did you like the App? rate us',
  shareApp: 'Share the app',
  shareAppDialog: 'Share the app with your friends :)',
  shareAppTitle:
    'Flynow is a perfect app for those looking to improve their productivity by managing tasks, habits and goals. The app uses the best methods for managing tasks, habits and goals.',
  shareAppMsg:
    'Flynow is a perfect app for anyone looking to improve their productivity. The app uses the best methods for managing tasks, creating habits and goals.\nDownload now: https://appflynow.com',
  followInsta: 'Follow us on Instagram',

  terms: 'Terms',
  privacyPolicy: 'Privacy Policy',
  termsOfUse: 'Terms of use',

  version: 'Version',

  getOut: 'Go out',
} as any;

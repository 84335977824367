/* eslint-disable radix */
import {
  Row,
  Section,
  TextBase,
  TextSection,
  ViewBase,
} from '@/components/atoms';
import {metrics} from '@/styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';

type Props = {
  statistics: any;
};
const StatisticsGeneral: React.FC<Props> = ({statistics}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  function getPercentDone(activity: any) {
    if (!activity) return 'N/D';
    const {total, done} = activity;
    if (total === 0) {
      return 'N/D';
    }
    const percentDone = parseInt(((done / total) * 100).toFixed(0));
    if (percentDone > 100) {
      return `100%`;
    }
    return `${percentDone}%`;
  }

  function getPercentFailure(activity: any) {
    if (!activity) return 'N/D';
    const {total, done} = activity;
    if (total === 0) {
      return 'N/D';
    }
    const percentFailure = parseInt((100 - (done / total) * 100).toFixed(0));
    if (percentFailure < 0) {
      return `0%`;
    }
    return `${percentFailure}%`;
  }

  const {tasks, habits, goals} = useMemo(() => {
    return {
      tasks: {
        ...statistics.tasks,
        percentDone: getPercentDone(statistics.tasks),
        percentNotDone: getPercentFailure(statistics.tasks),
      },
      habits: {
        ...statistics.habits,
        percentDone: getPercentDone(statistics.habits),
        percentNotDone: getPercentFailure(statistics.habits),
      },
      goals: {
        ...statistics.goals,
        percentDone: getPercentDone(statistics.goals),
        percentNotDone: getPercentFailure(statistics.goals),
      },
    };
  }, [statistics]);

  return (
    <Section>
      <TextSection>{t('statistic:title')}</TextSection>
      <ViewBase
        padding={metrics.space.small}
        backgroundColor={theme.colors.surface}
        borderRadius={metrics.space.small}>
        <Row spaceBetween>
          <ViewBase justifyContent="space-around">
            <TextBase />
            <TextBase bold smaller>
              {t('statistic:performed')}:{' '}
            </TextBase>
            <TextBase bold smaller>
              {t('statistic:notPerformed')}:{' '}
            </TextBase>
          </ViewBase>

          <ViewBase alignItems="center">
            <TextBase bold size={theme.metrics.font.small}>
              {t('tasks')}
            </TextBase>
            <TextBase bold small color={theme.colors.green}>
              {tasks.done} [{tasks.percentDone}]
            </TextBase>
            <TextBase bold small color={theme.colors.red}>
              {tasks.total - tasks.done} [{tasks.percentNotDone}]
            </TextBase>
          </ViewBase>

          <ViewBase alignItems="center">
            <TextBase bold size={theme.metrics.font.small}>
              {t('habits')}
            </TextBase>
            <TextBase bold small color={theme.colors.green}>
              {habits.done} [{habits.percentDone}]
            </TextBase>
            <TextBase bold small color={theme.colors.red}>
              {habits.total - habits.done} [{habits.percentNotDone}]
            </TextBase>
          </ViewBase>

          <ViewBase alignItems="center">
            <TextBase bold size={theme.metrics.font.small}>
              {t('goals')}
            </TextBase>
            <TextBase bold small color={theme.colors.green}>
              {goals.done} [{goals.percentDone}]
            </TextBase>
            <TextBase bold small color={theme.colors.red}>
              {goals.total - goals.done} [{goals.percentNotDone}]
            </TextBase>
          </ViewBase>
        </Row>
      </ViewBase>
    </Section>
  );
};

export default StatisticsGeneral;

import {call, put, select} from 'redux-saga/effects';
import {Toast, Analytics} from '@/services';
import {getDateToday} from '@/utils/date';
import {uid} from '@/utils/uid';
import {t} from '@/services/Locale';
import {
  GoalActions,
  StatisticActions,
  Reward,
  HabitActions,
} from '@/store/modules';
import {SagaIterator} from '@redux-saga/types';
import * as Api from '@/services/api/goal';
import {Action, isSameId} from '../utils';
import {RewardActions} from '../reward/duck';

export function* addGoal(action: Action): SagaIterator<void> {
  Toast.show(t('statistic:addScore', {score: 10}));
  yield put(StatisticActions.addScore(10));
  const firstGoal = yield select(stt => stt.goal.goals.length === 1);
  const {goal} = action.payload;
  Analytics.setUserProperty(Analytics.events.ADD_GOAL, getDateToday());
  if (firstGoal) {
    yield put(
      RewardActions.addRewardRequest({
        uid: uid(),
        user_id: goal.user_id,
        date: getDateToday(),
        key: 'add_first_goal',
        data: {goal},
      }),
    );
  }
  const data = yield call(Api.addGoal, goal);
  yield put(GoalActions.addGoalSuccess({...goal, ...data}));
}

export function* getGoals(): SagaIterator<void> {
  try {
    const data = yield call(Api.listGoals);
    yield put(GoalActions.getGoalsSuccess(data, true));
    const habitsToFetch: string[] = data
      .filter((i: any) => i.progress && i.progress.includes('habit_'))
      .map((i: any) => i.progress.split('habit_')[1]);
    const uniqHabits = habitsToFetch
      .map(i => (i.includes('-') ? i : parseInt(i)))
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      });

    for (let id of uniqHabits) {
      yield put(HabitActions.getHabitRequest(id as number));
    }
    Analytics.log(Analytics.events.GET_GOALS);
  } catch (err) {
    yield put(GoalActions.setFailure());
  }
}

export function* updateGoal(action: Action): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.UPDATE_GOAL);
  const {goal} = action.payload;
  yield call(Api.updateGoal, goal);
  yield put(GoalActions.updateGoalSuccess(goal));
}

export function* checkGoal(action: Action): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.CHECK_GOAL, getDateToday());
  const {goal} = action.payload;
  if (goal.done) {
    yield put(
      RewardActions.addRewardRequest({
        uid: uid(),
        user_id: goal.user_id,
        date: getDateToday(),
        key: 'done_goal',
        data: {goal},
      }),
    );
  } else {
    const reward: Reward = yield select(state =>
      state.reward.rewards.find(
        (i: Reward) => i.key === 'done_goal' && isSameId(i?.data?.goal, goal),
      ),
    );
    if (reward) {
      yield put(RewardActions.deleteRewardRequest(reward));
    }
  }
  yield call(Api.checkGoal, goal);
  yield put(GoalActions.checkGoalSuccess(goal));
}

export function* deleteGoal(action: Action): SagaIterator<void> {
  Analytics.setUserProperty(Analytics.events.DELETE_GOAL);
  const {goal} = action.payload;
  yield call(Api.deleteGoal, goal);
  yield put(GoalActions.deleteGoalSuccess(goal));
}
export function* updateGoalDate(action: Action): SagaIterator<void> {
  const {goal, data} = action.payload;
  yield call(Api.updateGoalDate, goal, data);
  yield put(GoalActions.updateGoalDateSuccess(goal, data));
}

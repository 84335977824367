import {Action} from '../utils';
import {ModalState, ModalTypes} from './type';

const INITIAL_STATE: ModalState = {
  modalVisible: false,
  alert: false,
  error: false,
  animation: null,
  data: null,
};

export default function reducer(
  state = INITIAL_STATE,
  {type, payload}: Action,
): ModalState {
  switch (type) {
    case ModalTypes.SET_MODAL_VISIBLE:
      if (payload.modalVisible === false) {
        return INITIAL_STATE;
      }
      return {...state, modalVisible: payload.modalVisible};
    case ModalTypes.SET_MODAL_CONTENT:
      return {
        ...INITIAL_STATE,
        modalVisible: true,
        ...payload.content,
        showButtonOk: !payload.content.actions,
      };
    default:
      return state;
  }
}

export const ModalActions = {
  setModalVisible: (modalVisible: boolean): Action => ({
    type: ModalTypes.SET_MODAL_VISIBLE,
    payload: {modalVisible},
  }),
  setModalContent: (content: any): Action => ({
    type: ModalTypes.SET_MODAL_CONTENT,
    payload: {content},
  }),
  setError: (error: any): Action => ({
    type: ModalTypes.SET_ERROR,
    payload: {error},
  }),
};

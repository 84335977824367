import {useEffect, useMemo, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';

import {
  Row,
  TextBase,
  TextLabel,
  TextSection,
  ViewBase,
} from '@/components/atoms';
import {ActivityService, Analytics} from '@/services';
import {CheckList, TaskDate} from '@/store/modules';
import {metrics} from '@/styles';
import {isTask} from '@/utils/activity';
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  formatDate,
  formatDateToServer,
  formatDateToShow,
  getDateToday,
  parseISO,
  startOfMonth,
} from '@/utils/date';
import {arrangeBigString} from '@/utils/string';
import SectionList from '../molecules/SectionList';
import {ListEmpty} from '../molecules';
import CheckBox from '../molecules/CheckBox';
import {isSameDay} from 'date-fns';
import {MdNavigateBefore, MdNavigateNext} from 'react-icons/md';

// import { Container } from './styles';

type Dates = TaskDate & {
  checkList: CheckList[];
};
type Props = {
  activity: any;
  dates: Dates[];
  initialDate: string | null;
};
const CheckLists = ({dates, initialDate, activity}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [monthNotes, setMonthNotes] = useState(
    startOfMonth(initialDate ?? getDateToday()),
  );
  const defaultCheckList = isTask(activity)
    ? activity?.checkList && activity.checkListDaily
      ? activity.checkList || []
      : []
    : activity.checkList;

  const mockDates = Array.from(
    {length: differenceInDays(endOfMonth(monthNotes), monthNotes) + 1},
    (_, k) => ({
      date: formatDateToServer(addDays(monthNotes, k)),
      checkList: defaultCheckList || [],
    }),
  );
  const datesList = useMemo(() => {
    const checklists = dates.filter(
      i =>
        parseISO(i.date) >= monthNotes &&
        parseISO(i.date) <= endOfMonth(monthNotes),
    );
    return mockDates
      .reverse()
      .map(date => checklists.find(i => i.date === date.date) || date)
      .filter(i => i?.checkList?.length > 0);
  }, [dates, monthNotes]);

  const getStringTitle = (date: string) => {
    const today = getDateToday();
    if (today === date) return t('today');
    if (isSameDay(parseISO(date), addDays(today, 1))) return t('tomorrow');
    if (isSameDay(parseISO(date), addDays(today, -1))) return t('yesterday');
    return formatDateToShow(date);
  };

  const checkItemChecklist = (
    item: CheckList,
    date: string,
    checklist: CheckList[],
  ) => {
    ActivityService.checkItemCheckList(item, {...activity, date}, checklist);
    Analytics.log('check_item_checklist');
  };

  useEffect(() => {
    const indexSectionToday = datesList.findIndex(
      item => item.date === getDateToday(),
    );
    if (indexSectionToday >= 0) {
      const scrollRef = document.getElementsByClassName('ant-drawer-body')?.[0];
      const y = datesList
        .slice(0, indexSectionToday)
        .reduce((acc, item) => acc + (item?.checkList?.length * 44 + 25), 0);
      setTimeout(() => {
        scrollRef?.scrollTo({top: y, left: 10, behavior: 'smooth'});
      }, 500);
    }
  }, []);

  return (
    <ViewBase style={{padding: metrics.space.small}}>
      <Row spaceBetween alignCenter>
        <MdNavigateBefore
          style={{
            color: theme.colors.onBackground,
            padding: 5,
            cursor: 'pointer',
          }}
          size={30}
          onClick={() => setMonthNotes(addMonths(monthNotes, -1))}
        />
        <TextLabel>
          {formatDate(monthNotes, 'MMMM yyyy').toLocaleUpperCase()}
        </TextLabel>
        <MdNavigateNext
          style={{
            color: theme.colors.onBackground,
            padding: 5,
            cursor: 'pointer',
          }}
          size={30}
          onClick={() => setMonthNotes(addMonths(monthNotes, 1))}
        />
      </Row>
      <SectionList
        sections={datesList.map(i => ({date: i.date, data: i.checkList}))}
        keyExtractor={(item: any) => `${item.text}-${item.date}`}
        ListEmptyComponent={
          <ListEmpty
            title={`${t('task:noActivitiesInPeriod')}`}
            text={t('listTasksEmpty')}
          />
        }
        renderItem={({item, section}) => (
          <Row
            alignCenter
            style={{
              height: 40,
              backgroundColor: theme.colors.surface,
              padding: metrics.space.smaller,
              marginBottom: metrics.space.smaller,
              borderRadius: metrics.space.smaller,
              opacity: item.done ? 0.62 : 1,
            }}>
            <CheckBox
              color={theme.colors.text}
              value={item.done}
              onChangeValue={() =>
                checkItemChecklist(item, section.date, section.data)
              }
            />
            <TextBase small={item.text.length > 40} style={{width: '90%'}}>
              {arrangeBigString(item.text, 100)}
            </TextBase>
          </Row>
        )}
        renderSectionHeader={({section}) => (
          <TextSection right color={theme.colors.textSection}>
            {getStringTitle(section.date as string)}
          </TextSection>
        )}
      />
    </ViewBase>
  );
};

export default CheckLists;

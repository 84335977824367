import {format, parseISO} from 'date-fns';
import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Input, Button as ButtonAnt} from 'antd';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {getDateToday, getTimeStringFromSecunds} from '@/utils/date';
import {DrawerActions} from '@/store/modules';
import {Row, Column, TextValue, TextLabel, ViewBase} from '../atoms';
import {ActivityService} from '@/services';
import AddNote from '../molecules/AddNote';
import DailyGoal from '../molecules/DailyGoal';
import ListCheckList from '../molecules/ListCheckList';
import {validator} from '@/utils/validator';
import {isHabit, isTask} from '@/utils/activity';
import AddTimer from '../organisms/AddTimer';
import { JUST_TODAY } from '@/constants';

const DetailsDay = ({activity, type, activityDate}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const addTimeRef = useRef();
  const [visible, setVisible] = useState(false);
  const [dailyGoal, setDailyGoal] = useState(false);
  const checkList =
    activityDate?.checkList ||
    activity?.checkListInDate ||
    activity?.checkList ||
    [];

  function handleSaveNote() {
    setVisible(true);
  }

  function handleAddAmount(e) {
    setDailyGoal(true);
  }
  function onCheckAmount(amount) {
    setDailyGoal(false);
    const nextAmount = amount + (activityDate?.amount || 0);
    ActivityService.updateInDate(
      activity,
      activityDate.date || getDateToday(),
      {
        amount: nextAmount,
        done: !activity?.done ? nextAmount >= activity?.goal?.amount : true,
      },
    );
  }

  const handleCheckItem = ({item}) => {
    ActivityService.checkItemCheckList(item, activity, checkList);
  };

  const handleDeleteItem = item => {
    ActivityService.deleteItemCheckList(item, activity, checkList);
  };
  const handleUpdateItem = item => {
    const list = checkList.map((i, index) =>
      item.index === index ? {text: item.text, done: item.done} : i,
    );
    const data = {
      ...activity,
      checkListInDate: activity.period === JUST_TODAY ? null : checkList,
    }
    ActivityService.updateCheckList(data, list);
  };

  const onAddNewItem = text => {
    const isValid = validator([text], t('error:checkListRequired'));
    if (!isValid) {
      return;
    }
    ActivityService.addNewItemCheckList(text, activity, checkList);
  };

  const handleAddTime = ({timer}) => {
    ActivityService.updateInDate(
      activity,
      activityDate.date || getDateToday(),
      {
        date: activityDate.date || getDateToday(),
        timer,
        stopwatch: null,
      },
    );
    addTimeRef.current.close();
    dispatch(DrawerActions.closeDrawer());
  };

  return (
    <>
      {visible && (
        <AddNote
          setVisible={setVisible}
          visible={visible}
          initialDate={activity.date}
          activity={activity}
        />
      )}
      {dailyGoal && (
        <DailyGoal
          title={t('dailyGoal')}
          onCheckAmount={onCheckAmount}
          unity={activity?.goal?.unity}
          amount={activityDate?.amount || activity?.amount || 0}
          amountTotal={activity?.goal?.amount}
          setVisible={setDailyGoal}
          visible={dailyGoal}
          activity={activity}
        />
      )}
      <Column
        style={{
          margin: 5,
          paddingBottom: 5,
          borderBottom: '1px solid ' + theme.colors.surface,
        }}>
        <TextLabel>{t('date')}</TextLabel>
        <TextValue>
          {format(parseISO(activityDate.date), 'dd/MM/yyyy')}
        </TextValue>
      </Column>
      <Column
        style={{
          margin: 5,
          paddingBottom: 5,
          borderBottom: '1px solid ' + theme.colors.surface,
        }}>
        <TextLabel>{t('status')}</TextLabel>
        <Row>
          <TextValue>
            {activityDate.done
              ? t('statistic:performed')
              : t('statistic:notPerformed')}
          </TextValue>
          <div style={{display: 1, flex: 1}} />
          <ButtonAnt
            style={{
              backgroundColor: theme.colors.surface,
              color: theme.colors.onBackground,
              borderColor: theme.colors.regular,
            }}
            onClick={() => {
              ActivityService.done({...activity, done: activityDate.done});
            }}>
            {t(activityDate.done ? 'notDone' : 'done')}
          </ButtonAnt>
        </Row>
      </Column>
      {activity?.goal?.amount > 0 && (
        <Column
          style={{
            margin: 5,
            paddingBottom: 5,
            borderBottom: '1px solid ' + theme.colors.surface,
          }}>
          <TextLabel>{t('dailyGoal')}</TextLabel>
          <Row>
            <Input
              className={theme.title === 'dark' ? 'input-dark' : 'input-light'}
              placeholder={t('placeholderAmount')}
              value={activityDate?.amount}
              disabled
            />
            <ButtonAnt
              style={{
                backgroundColor: theme.colors.surface,
                color: theme.colors.onBackground,
                borderColor: theme.colors.regular,
              }}
              onClick={handleAddAmount}>
              {t('add')}
            </ButtonAnt>
          </Row>
        </Column>
      )}

      <Column
        style={{
          margin: 5,
          paddingBottom: 5,
          borderBottom: '1px solid ' + theme.colors.surface,
        }}>
        <TextLabel>{t('note')}</TextLabel>
        <Row>
          <Input
            className={theme.title === 'dark' ? 'input-dark' : 'input-light'}
            placeholder={t('placeholderNote')}
            value={activityDate?.note}
            disabled
          />
          <ButtonAnt
            style={{
              backgroundColor: theme.colors.surface,
              color: theme.colors.onBackground,
              borderColor: theme.colors.regular,
            }}
            onClick={handleSaveNote}>
            {t('edit')}
          </ButtonAnt>
        </Row>
      </Column>

      {(isTask(activity) || isHabit(activity)) && checkList && (
        <ListCheckList
          label={
            activity?.checkList && !activity?.checkListDaily
              ? t('checkList')
              : t('checkListDaily')
          }
          data={checkList}
          handleCheckActivity={handleCheckItem}
          handleAddNewItem={onAddNewItem}
          handleDeleteItem={handleDeleteItem}
          handleUpdateItem={handleUpdateItem}
          initialShowAdd={true}
          activity={{
            ...activity,
            checkListInDate: activity.period === JUST_TODAY ? null : checkList,
          }}
        />
      )}

      <Column
        style={{
          margin: 5,
          paddingBottom: 5,
          borderBottom: '1px solid ' + theme.colors.surface,
        }}>
        <TextLabel>{t('time')}</TextLabel>
        <Row>
          <ViewBase flex={1}>
            <TextValue>
              {getTimeStringFromSecunds(
                (activityDate?.timer || 0) + (activityDate?.stopwatch || 0),
              )}
            </TextValue>
          </ViewBase>
          <ButtonAnt
            style={{
              backgroundColor: theme.colors.surface,
              color: theme.colors.onBackground,
              borderColor: theme.colors.regular,
            }}
            onClick={() => {
              addTimeRef.current.open();
            }}>
            {t('edit')}
          </ButtonAnt>
          <ButtonAnt
            style={{
              backgroundColor: theme.colors.surface,
              color: theme.colors.onBackground,
              borderColor: theme.colors.regular,
            }}
            onClick={() => {
              dispatch(
                DrawerActions.setDrawer({
                  action: `TIMER`,
                  payload: {activity, tab: 1},
                }),
              );
            }}>
            {t('toStart')}
          </ButtonAnt>
        </Row>

        <AddTimer
          ref={addTimeRef}
          handleSave={handleAddTime}
          item={{
            date: activityDate.date,
            timer: (activityDate?.timer || 0) + (activityDate?.stopwatch || 0),
          }}
        />
      </Column>
    </>
  );
};

export default DetailsDay;

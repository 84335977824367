import styled from 'styled-components';

export const DetailsHabit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const ContainerCard = styled.div`
  margin-bottom:5px;
  margin-top:10px;
  border-radius:10px;
  padding:10px;
  background: ${({theme})=> theme.colors.surface};
  color: ${({theme})=> theme.colors.onBackground}
`;

import {Colors} from '../colors';
import metrics, {Metrics} from '../metrics';
import colorsDefault from './default';
import colorsDark from './dark';

export type ColorsTheme = Colors & {
  background: string;
  onBackground: string;
  surface: string;
  onSurface: string;
  placeholder: string;
  surfaceLight: string;
  regular: string;
  list: string;
  
  textSection: string;
  textLabel: string;
  text: string;

  activeTabBar: string;
  inactiveTabBar: string;

  shadow: string;
};

export interface Theme {
  title: 'default' | 'dark';
  colors: ColorsTheme;
  metrics: Metrics;
}

const themeDefault = {
  title: 'default',
  colors: colorsDefault,
  metrics,
} as Theme;

const themeDark = {
  title: 'dark',
  colors: colorsDark,
  metrics,
} as Theme;

export default {
  default: themeDefault,
  dark: themeDark,
} as {
  default: Theme;
  dark: Theme;
};

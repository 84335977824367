import {AppState, CardActivityColorBy, Language, ModeInterval, ModeView, State} from '@/store/modules';

export const selectorStartOfWeek = (state: State): number => state.app?.startOfWeek || 0;
export const selectorApp = (state: State): AppState => state.app;
export const selectorLanguage = (state: State): Language =>
  state.app?.language || Language.Pt;
export const selectorTheme = (state: State): string =>
  state.app.theme || 'default';
export const selectorStorageVersion = (state: State): number =>
  state.app.storageVersion;
export const selectorCardActivityColorBy = (state: State): CardActivityColorBy =>
  state.app?.cardActivityColorBy || CardActivityColorBy.Label;
export const selectorModeView = (state: State): ModeView =>
  state.app?.mode || ModeView.List;
export const selectorModeInterval = (state: State): ModeInterval =>
  state.app?.modeInterval || ModeInterval.Weekly;
export const selectorShowChecklist = (state: State): boolean =>
  state.app?.showCheckList ?? true;
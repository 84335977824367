import {VERSION} from '@/constants';
import axios from 'axios';

const urlProduction = 'https://api.appflynow.com';
// const urlDev = 'http://localhost:3333';

const api = axios.create({
  headers: {
    appversion: VERSION,
    platform: 'web',
  },
  baseURL: urlProduction,
  // baseURL: urlDev,
});

export default api;

import React, {useRef} from 'react';
import Filter from '@/components/Filter';
import {ContentLabels, LabelSelect, Option, Row} from '@/components/atoms';
import {
  ListActions,
  selectorCategories,
  selectorFilterList,
  selectorList,
} from '@/store/modules';
import {useTranslation} from 'react-i18next';
import {FiColumns, FiFilter} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from 'styled-components';
import BottomSheet from '@/components/BottomSheet';
import {metrics} from '@/styles';
import {TextTag} from '@/components/Filter/styles';

type Props = {
  initialColumns: string[];
};
const Panel = ({initialColumns}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const refColumns = useRef<any>();
  const refFilter = useRef<any>();
  const {filterBy} = useSelector(selectorFilterList);
  const categories = useSelector(selectorCategories);
  const list = useSelector(selectorList);
  const dispatch = useDispatch();
  const columns = list.lists.length ? list.lists : initialColumns;

  const countFilters =
    filterBy.status.length + filterBy.types.length + filterBy.categories.length;

  const onOpenFilter = () => {
    refFilter.current.open();
  };
  const onOpenColumns = () => {
    refColumns.current.open();
  };
  const onSelectColumn = (category: any) => {
    let array = [...columns];
    if (columns.indexOf(category.uid) >= 0) {
      array = array.filter(item => item !== category.uid);
    } else {
      array.push(category.uid);
    }
    dispatch(ListActions.setListsOrder(array));
  };

  return (
    <Row style={{marginTop: 10}}>
      <Filter ref={refFilter} type="list" filter />
      <BottomSheet ref={refColumns} title={t('columns')}>
        <ContentLabels style={{marginBottom: metrics.space.smaller}}>
          {[{name: t('task:inbox'), uid: 'null'}, ...categories].map(
            category => (
              <LabelSelect
                key={category.uid}
                selected={columns.indexOf(category.uid) >= 0}
                onClick={() => onSelectColumn(category)}>
                <TextTag selected={columns?.indexOf(category.uid) >= 0}>
                  {t(category.name).toUpperCase()}
                </TextTag>
              </LabelSelect>
            ),
          )}
        </ContentLabels>
      </BottomSheet>
      <Option
        active={false}
        onClick={onOpenFilter}
        style={{
          fontSize: 12,
        }}>
        {t('filter')}
        <FiFilter color={theme.colors.onSurface} style={{marginLeft: 3}} />
        {countFilters > 0 && (
          <span
            style={{
              color: theme.colors.white,
              padding: 1,
              borderRadius: 10,
              fontFamily: 'Poppins-Bold',
              fontSize: 8,
              backgroundColor: theme.colors.primary,
              marginLeft: -3,
              marginBottom: -7,
            }}>
            {countFilters}
          </span>
        )}
      </Option>

      <Option
        active={false}
        onClick={onOpenColumns}
        style={{
          fontSize: 12,
        }}>
        {t('columns')}
        <FiColumns color={theme.colors.onSurface} style={{marginLeft: 3}} />
      </Option>
    </Row>
  );
};

export default Panel;

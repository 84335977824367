import React, {CSSProperties, useMemo} from 'react';
// eslint-disable-next-line import/no-duplicates
import {addWeeks, isSameMonth, parseISO} from 'date-fns';
import {formatDate, formatDateToServer, getCurrentWeek} from '../../utils/date';
import {ContentWeek, TextWeek, ContentDayWeek, ContainerWeek} from './styles';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { CurrentDay, selectorStartOfWeek } from '@/store/modules';
import { useSelector } from 'react-redux';

export interface DateStyle {
  date: string;
  dayOfWeek: string;
  dateContainerStyle?: CSSProperties;
  textStyle?: CSSProperties;
}

interface Props {
  onChangeWeek(week: Array<CurrentDay>): void;
  currentWeek: Array<DateStyle>;
  onDateSelected?(day: CurrentDay): void;
  isDateBetweenValidPeriod?(date: string): boolean;
  showCurrentMonth?: boolean;
}

const Week = ({
  currentWeek,
  onChangeWeek,
  onDateSelected,
  isDateBetweenValidPeriod,
  showCurrentMonth,
}:Props): JSX.Element => {
  const weekStartsOn = useSelector(selectorStartOfWeek) 
  const theme = useTheme()
  const currentMonthString = useMemo(() => {
    if (
      isSameMonth(parseISO(currentWeek[0].date), parseISO(currentWeek[6].date))
    ) {
      return formatDate(currentWeek[0].date, 'MMMM').toUpperCase();
    }
    return `${formatDate(currentWeek[0].date, 'MMM')}/${formatDate(
      currentWeek[6].date,
      'MMM',
    )}`.toUpperCase();
  }, [currentWeek]);

  function changeWeek(number: number) {
    const newDateWeek = addWeeks(parseISO(currentWeek[0].date), number);
    const newWeek = getCurrentWeek(formatDateToServer(newDateWeek),weekStartsOn);
    onChangeWeek(newWeek);
  }
  function isBetweenPeriod(d: string) {
    if (isDateBetweenValidPeriod) return isDateBetweenValidPeriod(d);
    return true;
  }
  return (
    <>
      <ContainerWeek>
      <MdNavigateBefore size={30} onClick={()=> changeWeek(-1)}   style={{color:theme.colors.onBackground,cursor:'pointer'}} />
        <ContentWeek>
          {currentWeek?.map((i) => (
              <ContentDayWeek 
                key={i.date}
                style={{flex: 1, cursor: onDateSelected ? 'pointer' : 'default',...i?.dateContainerStyle }}
                onClick={() => {
                  if (isBetweenPeriod(i.date)) {
                    if (onDateSelected) onDateSelected(i);
                  }
                }}
              >
                <TextWeek style={i?.textStyle}>
                {formatDate(i.date, 'EEE').toUpperCase()}
                </TextWeek>
                <TextWeek style={i?.textStyle}>
                {formatDate(i.date, 'dd').toUpperCase()}
                </TextWeek>
              </ContentDayWeek>
          ))}
        </ContentWeek>
        <MdNavigateNext size={30} onClick={()=> changeWeek(1)} style={{color:theme.colors.onBackground,cursor:'pointer'}}/>
      </ContainerWeek>
      {showCurrentMonth && (
        <TextWeek style={{textAlign: 'right'}}>
          {currentMonthString}
        </TextWeek>
      )}
    </>
  );
};

Week.defaultProps = {
  onDateSelected: () => {},
  isDateBetweenValidPeriod: () => true,
  showCurrentMonth: false,
};

export default Week;

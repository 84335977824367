import history from "@/history";

class Navigation {
  navigate(name: string, params?: any) {
    history.push(name)
    // document.location.replace(name)
  }

  goBack() {
    // navigationRef.current?.dispatch(CommonActions.goBack());
  }

  push(name: string, params?: any) {
    // navigationRef.current?.push(name, params);
    // navigationRef.current?.dispatch(StackActions.push(name, params));
  }
}
// eslint-disable-next-line
export default new Navigation();

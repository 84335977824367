import {MdOutlineCircle, MdOutlineCheckCircle} from 'react-icons/md';
import {useTheme} from 'styled-components';

interface Props {
  onChangeValue(value: boolean): void;
  value: boolean;
  testID?: string;
  color?: string;
  disabled?: boolean;
}
const CheckBox = ({
  onChangeValue,
  value,
  color = '#666',
  disabled,
  testID,
}: Props): JSX.Element => {
  const theme = useTheme();
  const Component = value ? MdOutlineCheckCircle : MdOutlineCircle;
  return (
    <Component
      size={22}
      style={{cursor: 'pointer', marginRight: 2, color: theme.colors.onSurface}}
      onClick={e => {
        e.stopPropagation();
        onChangeValue(!value);
      }}
    />
  );
};

CheckBox.defaultProps = {
  color: '#666',
  disabled: false,
  testID: undefined,
};

export default CheckBox;

import * as React from 'react';
import {ViewBase} from '@/components/atoms';

interface FlatListProps<T> {
  data: Array<T>;
  keyExtractor: (item: T) => string;
  renderItem: (props: {item: T; index: number}) => any;
  ListEmptyComponent?: any;
  ListHeaderComponent?: any;
  ListFooterComponent?: any;
}

export default function FlatList<T>({
  data,
  renderItem,
  keyExtractor,
  ListEmptyComponent,
  ListFooterComponent,
  ListHeaderComponent,
}: FlatListProps<T>) {
  if (data.length === 0) return ListEmptyComponent || null;
  return (
    <React.Fragment>
      {ListHeaderComponent}
      {data.map((item, index) => (
        <ViewBase key={keyExtractor && keyExtractor(item)}>
          {renderItem({item, index})}
        </ViewBase>
      ))}
      {ListFooterComponent}
    </React.Fragment>
  );
}

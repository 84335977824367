import React, {forwardRef, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';

import {Row, TextInput, TextLabel, ViewBase} from '@/components/atoms';
import {Button} from '@/components/molecules';
import {Toast} from '@/services';
import {metrics} from '@/styles';
import {getTimeFromSecunds} from '@/utils/date';

import {getLabel} from './utils';
import BottomSheet from '@/components/BottomSheet';
import {ImMinus, ImPlus} from 'react-icons/im';

type PropsPicker = {
  time: number;
  setTime: any;
  minTime?: number;
  hideSec?: boolean;
  custom?: {
    iconSize?: number;
    timeSize?: number;
    timeSizeDesc?: number;
  };
  children?: any;
};

export const PickerTimeComponent = ({
  time,
  setTime,
  minTime,
  custom,
  children,
}: PropsPicker) => {
  const {hours, minutes, secunds} = getTimeFromSecunds(time);
  const [h, setH] = useState<number | undefined>(Number(hours));
  const [m, setM] = useState<number | undefined>(Number(minutes));
  const [s, setS] = useState<number | undefined>(Number(secunds));
  const theme = useTheme();
  function formatTime(value: number) {
    if (Number.isNaN(value)) return 0;
    return Math.max(minTime ?? 0, value);
  }

  const parseNumber = (n: string | number | undefined) => {
    if (n === undefined) return 0;
    // eslint-disable-next-line radix
    const digit = parseInt(String(n));
    if (typeof digit === 'number') return digit;
    // eslint-disable-next-line radix
    return parseInt(String(n).replace(/\D/g, '')) as number;
  };

  const customStyle = {
    iconSize: custom?.iconSize ?? 30,
    timeSize: custom?.timeSize ?? undefined,
    timeSizeDesc: custom?.timeSizeDesc ?? undefined,
  };

  const numberStyle: any = {
    width: 45,
    backgroundColor: theme.colors.surface,
    fontSize: metrics.font.medium,
    fontFamily: 'Poppins-Bold',
    textAlign: 'center',
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
    color: theme.colors.textSection,
    height: 45,
  };
  const iconStyle = {
    marginLeft: 5,
    width: 45,
    padding: 8,
    backgroundColor: theme.colors.surface,
    color: theme.colors.onBackground,
    cursor: 'pointer',
  };
  useEffect(() => {
    const currentTime =
      parseNumber(s) + parseNumber(m) * 60 + parseNumber(h) * 60 * 60;
    if (currentTime < 0) {
      setH(0);
      setM(0);
      setS(0);
    }
    setTime(formatTime(currentTime));
  }, [h, m, s]);
  return (
    <ViewBase center>
      <ViewBase padding={metrics.space.big}>
        <Row center>
          <ImPlus
            name="plus"
            size={metrics.fontSize(customStyle.iconSize)}
            style={iconStyle as any}
            onClick={() => setTime((stt: number) => stt + 60 * 60 || 0)}
          />
          <ImPlus
            name="plus"
            size={metrics.fontSize(customStyle.iconSize)}
            style={iconStyle as any}
            onClick={() => setTime((stt: number) => stt + 60 || 0)}
          />
          <ImPlus
            name="plus"
            size={metrics.fontSize(customStyle.iconSize)}
            style={iconStyle as any}
            onClick={() => setTime((stt: number) => stt + 1 || 0)}
          />
        </Row>
        <Row center>
          <TextInput
            style={numberStyle}
            value={hours}
            onChange={v => setH(parseNumber(v.target.value))}
            placeholder="00"
            onFocus={() => setH(undefined)}
            placeholderTextColor={theme.colors.textSection}
          />
          <TextInput
            placeholder="00"
            style={numberStyle}
            value={minutes}
            onChange={v => {
              setM(parseNumber(v.target.value));
            }}
            onFocus={() => setM(undefined)}
            placeholderTextColor={theme.colors.textSection}
          />
          <TextInput
            placeholder="00"
            style={numberStyle}
            value={secunds}
            onChange={v => setS(parseNumber(v.target.value))}
            onFocus={() => setS(undefined)}
            placeholderTextColor={theme.colors.textSection}
          />
        </Row>
        <Row center>
          <ImMinus
            name="minus"
            size={metrics.fontSize(customStyle.iconSize)}
            style={iconStyle as any}
            onClick={() => setTime((stt: number) => Math.max(stt - 60 * 60, 0))}
          />
          <ImMinus
            name="minus"
            size={metrics.fontSize(customStyle.iconSize)}
            style={iconStyle as any}
            onClick={() => setTime((stt: number) => Math.max(stt - 60, 0))}
          />
          <ImMinus
            name="minus"
            size={metrics.fontSize(customStyle.iconSize)}
            style={iconStyle as any}
            onClick={() => setTime((stt: number) => Math.max(stt - 1, 0))}
          />
        </Row>
      </ViewBase>
      <TextLabel center>{getLabel(time, false)}</TextLabel>
      {children}
    </ViewBase>
  );
};

type Props = {
  time: number;
  setTime: any;
  onAddTime: any;
};
const PickerTime = ({time, setTime, onAddTime}: Props, ref: any) => {
  const {t} = useTranslation();
  return (
    <BottomSheet ref={ref} title={t('time')}>
      <PickerTimeComponent time={time} setTime={setTime}>
        <Button
          title="OK"
          width={300}
          marginTop={metrics.space.medium}
          borderRadius={metrics.fontSize(6)}
          onPress={() => {
            if (!time) {
              return Toast.show(t('error:validationError'));
            }
            ref.current.close();
            setTime(0);
            onAddTime(time);
          }}
        />
      </PickerTimeComponent>
    </BottomSheet>
  );
};

export default forwardRef(PickerTime);

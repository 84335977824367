import {ModalActions} from '@/store/modules';
import {Section, ViewBase, TextSection, TextBase} from '@/components/atoms';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useTheme} from 'styled-components';
import {ButtonReward, Reward} from './styles';

type Props = {
  rewards: {
    id: string;
    key: string;
  }[];
};

const Rewards: React.FC<Props> = ({rewards}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  function showDetailsReward(reward: any) {
    dispatch(
      ModalActions.setModalContent({
        height: 550,
        mode: 'share',
        type: 'Details_Reward',
        data: {reward},
      }),
    );
  }
  return (
    <Section>
      <TextSection>{t('statistic:rewards')}</TextSection>
      <ViewBase
        style={{
          backgroundColor: theme.colors.surface,
          padding: theme.metrics.space.small,
          borderRadius: 10,
        }}
      >
        {rewards.length === 0 && (
          <TextBase>{t('statistic:noRewards')}</TextBase>
        )}
        {rewards.map(item=> (
          <ButtonReward key={item.id} onClick={() => showDetailsReward(item)}>
            <Reward
                type={item.key}
                style={{height: 75}}
              />
          </ButtonReward>
        ))}
      </ViewBase>
    </Section>
  );
};

export default Rewards;

import styled from 'styled-components';

type CardCategoryProps = {
  color?: string | null;
};
export const CardCategory = styled.div<CardCategoryProps>`
  cursor:pointer;
  background: ${({theme}) => theme.colors.surface};
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  border-right: 10px solid ${({color, theme}) => color || theme.colors.surface};
`;
export const Color = styled.div<CardCategoryProps>`
  background: ${({color, theme}) => color || theme.colors.surface};
  border-radius: 10px;
  height: 50px;
  width: 50px;
  margin-right: 5px;
  margin-top: 5px;
`;
export const PickerContainer = styled.div`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

import colors from './colors';
import metrics from './metrics';
import {Theme} from './themes';

export function getStyleShadow(theme: Theme): any {
  return {
    elevation: 5,
    shadowColor: theme.colors.shadow,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  };
}
export {colors, metrics};

import styled from 'styled-components';

type ContainerRewardProps = {
  opacity?: number;
};
export const ContainerReward = styled.div<ContainerRewardProps>`
  padding: 10px;
  margin: 10px;
  background-color: ${({theme}) => theme.colors.surface};
  height: 150px;
  flex: 1;
  border-radius: 7px;
  align-items: center;
  opacity: ${({opacity}) => opacity || 1};
`;
export const ImageReward = styled.img`
  height: 80%;
  width: 80%;
`;

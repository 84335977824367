import {compareAsc} from '@/utils/date';
import {Action, isSameId, updateDateGoal} from '../utils';
import {Goal, GoalState, GoalTypes,GoalDate} from './type';

const INITIAL_STATE: GoalState = {
  lastSync: null,
  goals: [],
  loading: false,
};

export default function reducer(
  state = INITIAL_STATE,
  {payload, type}: Action,
): GoalState {
  switch (type) {
    case GoalTypes.SET_INITIAL_STATE:
      return INITIAL_STATE;
    case GoalTypes.SET_FAILURE:
      return {...state, loading: false};
    case GoalTypes.GET_GOALS_REQUEST:
      return {...state, loading: true};

    case GoalTypes.ADD_GOAL:
    case GoalTypes.ADD_GOAL_SUCCESS:
      return {
        ...state,
        goals: [...state.goals, {...payload.goal, targetAmount: payload.goal.amount  }].sort((a, b) =>
          compareAsc(a.date, b.date),
        ),
      };

    case GoalTypes.EDIT_GOAL_SUCCESS:
    case GoalTypes.UPDATE_GOAL:
      return {
        ...state,
        goals: state.goals.map(i =>
          isSameId(i, payload.goal) ? {...i, targetAmount: payload.goal.amount, ...payload.goal} : i,
        ),
      };

    case GoalTypes.CHECK_GOAL_REQUEST:
    case GoalTypes.CHECK_GOAL:
    case GoalTypes.CHECK_GOAL_SUCCESS:
      return {
        ...state,
        goals: state.goals.map(item =>
          isSameId(item, payload.goal)
            ? {...item, done: payload.goal.done}
            : item,
        ),
      };

    case GoalTypes.DELETE_GOAL:
    case GoalTypes.DELETE_GOAL_SUCCESS:
      return {
        ...state,
        goals: state.goals.filter(item => !isSameId(item, payload.goal)),
      };

    case GoalTypes.GET_GOALS_SUCCESS:
      return {
        ...state,
        lastSync: payload.setLastSync ?  new Date() : state.lastSync,
        goals: [
          ...payload.goals,
          ...state.goals.filter(
            i => !payload.goals.find((j: Goal) => isSameId(i, j)),
          ),
        ],
        loading: false
      };

        case GoalTypes.UPDATE_GOAL_DATE_REQUEST:
        case GoalTypes.UPDATE_GOAL_DATE_SUCCESS:
          return {
            ...state,
            goals: state.goals.map(item =>
              isSameId(item, payload.goal)
                ? (updateDateGoal(item, payload.data.date, payload.data) as Goal)
                : item,
            ),
          };

    default:
      return state;
  }
}

export const GoalActions = {
  addGoalRequest: (goal: Goal): Action => ({
    type: GoalTypes.ADD_GOAL_REQUEST,
    payload: {goal},
  }),
  addGoalSuccess: (goal: Goal): Action => ({
    type: GoalTypes.ADD_GOAL_SUCCESS,
    payload: {goal},
  }),
  setFailure: () => ({
    type: GoalTypes.SET_FAILURE,
  }),

  updateGoalRequest: (goal: Goal): Action => ({
    type: GoalTypes.EDIT_GOAL_REQUEST,
    payload: {goal},
  }),
  checkGoalRequest: (goal: Goal): Action => ({
    type: GoalTypes.CHECK_GOAL_REQUEST,
    payload: {goal},
  }),
  checkGoalSuccess: (goal: Goal): Action => ({
    type: GoalTypes.CHECK_GOAL_SUCCESS,
    payload: {goal},
  }),
  updateGoalSuccess: (goal: Goal): Action => ({
    type: GoalTypes.EDIT_GOAL_SUCCESS,
    payload: {goal},
  }),

  deleteGoalRequest: (goal: Goal): Action => ({
    type: GoalTypes.DELETE_GOAL_REQUEST,
    payload: {goal},
  }),
  deleteGoalSuccess: (goal: Goal): Action => ({
    type: GoalTypes.DELETE_GOAL_SUCCESS,
    payload: {goal},
  }),

  getGoalsRequest: () => ({
    type: GoalTypes.GET_GOALS_REQUEST,
  }),
  getGoalsSuccess: (goals: Goal[],setLastSync?:boolean): Action => ({
    type: GoalTypes.GET_GOALS_SUCCESS,
    payload: {goals,setLastSync},
  }),
  setInitialState: () => ({
    type: GoalTypes.SET_INITIAL_STATE,
  }),
  updateGoalDateRequest: (
    goal: Goal,
    data: GoalDate,
  ): Action<{goal: Goal; data: GoalDate}> => ({
    type: GoalTypes.UPDATE_GOAL_DATE_REQUEST,
    payload: {goal, data},
  }),
  updateGoalDateSuccess: (
    goal: Goal,
    data: GoalDate,
  ): Action<{goal: Goal; data: GoalDate}> => ({
    type: GoalTypes.UPDATE_GOAL_DATE_SUCCESS,
    payload: {goal, data},
  }),
};

import React from 'react';

import {useTheme} from 'styled-components';

import {ButtonTransparent, Loading, TextBase} from '@/components/atoms';
import {getStyleShadow} from '@/styles';

interface ButtonProps {
  title: string;
  onPress(e: any): void;
  loading?: boolean;
  backgroundColor?: string;
  borderRadius?: number;
  height?: number;
  marginTop?: number;
  marginLeft?: number;
  testID?: string;
  width?: number | string;
  fontSize?: number;
}

const Button: React.FC<ButtonProps> = ({
  title,
  onPress,
  marginTop,
  loading,
  borderRadius,
  backgroundColor,
  fontSize,
  ...rest
}: ButtonProps) => {
  const theme = useTheme();
  return (
    <ButtonTransparent
      onClick={onPress}
      style={{
        ...rest,
        ...getStyleShadow(theme),
        marginTop,
        borderRadius,
        backgroundColor: backgroundColor ?? theme.colors.primary,
      }}>
      {loading ? (
        <Loading color="#fff" size={23} />
      ) : (
        <TextBase color={theme.colors.white} size={fontSize}>
          {title}
        </TextBase>
      )}
    </ButtonTransparent>
  );
};

Button.defaultProps = {
  borderRadius: 8,
  marginTop: 0,
  loading: false,
  backgroundColor: undefined,
  testID: undefined,
  width: undefined,
};

export default Button;

import React, {useEffect, useRef} from 'react';
import {Row} from '@/components/atoms';
import {useTranslation} from 'react-i18next';
import TimePicker from '@/components/TimePicker';
import {addMinutes, formatHourToShow, setHours, setMinutes} from '@/utils/date';
import {addHours} from 'date-fns';

interface Props {
  startHour: string | null;
  endHour: string | null;
  setStartHour(value: any): void;
  setEndHour(value: any): void;
}
const InputHour = ({
  startHour,
  endHour,
  setStartHour,
  setEndHour,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const lastStartHour = useRef<string | null>(startHour);

  useEffect(() => {
    if (!!startHour && !endHour) {
      const {hour, minute} = getHourAndMinute(startHour);
      const valueHour = setHours(setMinutes(new Date(), minute), hour);
      setEndHour(formatHourToShow(addHours(valueHour, 1)));
    }
    if (startHour && endHour && startHour >= endHour) {
      const {hour, minute} = getHourAndMinute(startHour);
      const valueHour = setHours(setMinutes(new Date(), minute), hour);
      setEndHour(formatHourToShow(addMinutes(valueHour, 60)));
    }
    lastStartHour.current = startHour;
  }, [startHour]);

  function getHourAndMinute(dateTime: string) {
    const [hour, minutes] = dateTime
      .split(':')
      // eslint-disable-next-line radix
      .map(i => parseInt(i)) as number[];
    return {hour, minute: minutes};
  }

  return (
    <Row style={{width: '100%'}}>
      <TimePicker
        label={t('start')}
        value={startHour}
        onChangeValue={setStartHour}
        placeholder="Ex: 07:00"
      />
      <TimePicker
        label={t('end')}
        value={endHour}
        onChangeValue={setEndHour}
        placeholder="Ex: 08:30"
      />
    </Row>
  );
};
export default InputHour;

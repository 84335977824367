import {
  getScoreBetweenInteval,
  getTotalDaysRepetionActivityBetweenInterval,
  getTriadTime,
  isBetweenInteval,
} from '@/services/Statistics';
import {ActivityInDate, Category, State} from '@/store/modules';
import {addDays, formatDateToServer, differenceInDays} from '@/utils/date';

export const selectorReport = (
  state: State,
  lowerDate: string,
  upperDate: string,
): any => {
  const goalsInPeriod = state.goal.goals.filter(item =>
    isBetweenInteval(item.date, lowerDate, upperDate),
  );
  const tasks = state.task.tasks.filter(
    i => i.startDate <= upperDate && i.endDate >= lowerDate,
  );
  const habits = state.habit.habits.filter(
    i => i.startDate <= upperDate && i.endDate >= lowerDate,
  );

  const tasksInPeriod = tasks.map(task => ({
    ...task,
    dones: task.dones.filter(i =>
      isBetweenInteval(i.date, lowerDate, upperDate),
    ),
  }));
  const habitsInPeriod = habits.map(habit => ({
    ...habit,
    dones: habit.dones.filter(i =>
      isBetweenInteval(i.date, lowerDate, upperDate),
    ),
  }));
  const goalsDoneInPeriod = goalsInPeriod.filter(item => item.done);
  const totalTask = tasksInPeriod.reduce(
    (total, task) =>
      total +
      getTotalDaysRepetionActivityBetweenInterval(task, lowerDate, upperDate),
    0,
  );
  const totalHabit = habits.reduce(
    (total, habit) =>
      total +
      getTotalDaysRepetionActivityBetweenInterval(habit, lowerDate, upperDate),
    0,
  );

  const totalTaskDone = tasksInPeriod.reduce(
    (total, task) => total + task.dones.filter(i => i.done).length,
    0,
  );
  const totalHabitDone = habitsInPeriod.reduce(
    (total, habit) => total + habit.dones.filter(i => i.done).length,
    0,
  );

  const triadTime = getTriadTime(lowerDate, upperDate, tasksInPeriod);
  const rewards = state.reward.rewards.filter(i =>
    isBetweenInteval(i.date, lowerDate, upperDate),
  );
  const countPointsChart = differenceInDays(upperDate, lowerDate);
  const dataChart = [];
  let datePoint = lowerDate;
  for (let i = 0; i <= countPointsChart; i += 1) {
    dataChart.push({
      date: datePoint,
      score: getScoreBetweenInteval(datePoint, datePoint, {
        tasks: tasksInPeriod,
        habits: habitsInPeriod,
        goals: goalsInPeriod,
      }),
    });
    datePoint = formatDateToServer(addDays(datePoint, 1));
  }

  const score = getScoreBetweenInteval(lowerDate, upperDate, {
    tasks: tasksInPeriod,
    habits: habitsInPeriod,
    goals: goalsInPeriod,
  });

  const mapTotalCategories = [
    ...tasksInPeriod,
    ...habitsInPeriod,
    ...goalsInPeriod,
  ]
    .filter(i => i?.categories)
    .map(i => {
      const total =
        'dones' in i
          ? i?.dones?.filter((j: ActivityInDate) => j.done).length
          : i?.specific && i?.done
          ? 1
          : 0;
      return {
        categories: i?.categories?.map((k: string) => ({uid: k, total})) || [],
      };
    })
    .map(i => i.categories)
    .flat();

  const wheelOfLife = state.category.categories.map(i => ({
    ...i,
    total: mapTotalCategories
      .filter(l => l.uid === i.uid)
      .reduce((acc, item) => acc + item?.total || 0, 0),
  })) as (Category & {total: number})[];

  return {
    score,
    triadTime,
    rewards,
    wheelOfLife,
    tasks: {
      total: totalTask,
      done: totalTaskDone,
      data: tasksInPeriod,
    },
    habits: {
      total: totalHabit,
      done: totalHabitDone,
      data: habitsInPeriod,
    },
    goals: {
      total: goalsInPeriod.length,
      done: goalsDoneInPeriod.length,
      data: goalsInPeriod,
    },
    dataChart,
  };
};

import shared from './shared';
import welcome from './welcome';
import auth from './auth';
import error from './error';
import general from './general';
import tab from './tab';
import task from './task';
import habit from './habit';
import goal from './goal';
import statistic from './statistic';
import locale from './locale';
import unity from './unity';

export default {
  shared,
  welcome,
  auth,
  error,
  general,
  tab,
  task,
  habit,
  goal,
  statistic,
  locale,
  unity
}as any;

import {TextBase, ViewBase} from '@/components/atoms';
import styled from 'styled-components';

export const Container = styled(ViewBase)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({marginTop}) => marginTop || 0}px;
  margin-bottom: ${({marginBottom}) => marginBottom || 0}px;
`;
export const Separator1 = styled(ViewBase)`
  border: 0.5px solid ${({theme}) => theme.colors.surface};
  flex: 1;
  width: 100px;
`;
export const TextSeparator = styled(TextBase)`
  font-size: ${({theme}) => theme.metrics.font.smaller}px;
  color: ${({theme}) => theme.colors.light};
  padding: 0 ${({theme}) => theme.metrics.space.small}px;
`;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  addDays,
  endOfMonth,
  endOfWeek,
  isPast,
  startOfMonth,
  startOfWeek,
  formatDate,
  formatDateToServer,
} from '@/utils/date';
import { Container, Content, TextSection, ViewBase } from '@/components/atoms';
import { Analytics } from '@/services';
import { ROUTES } from '@/constants';
import { useTheme } from 'styled-components';
import { selectorUser } from '@/store/modules';
import { useTranslation } from 'react-i18next';
import { ButtonSection, Header } from '@/components/molecules';
import { useHistory, useLocation } from 'react-router';

type RouteParams = {
    period: string;
};
export type ReportParam = {
  lowerDate: string;
  upperDate: string;
  period: string;
  text: string;
};
type Dates = {
  title: string;
  data: Array<ReportParam>;
};

const Reports: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { createdAt, premium } = useSelector(selectorUser);
  const location = useLocation<RouteParams>();
  const period = location.state.period;

  const dates = useMemo<Dates[]>(() => {
    let lower = formatDateToServer(createdAt);
    const periods = [] as { title: string; data: any[] }[];
    const getStartOfPeriod = period === 'weekly' ? startOfWeek : startOfMonth;
    const getEndOfPeriod = period === 'weekly' ? endOfWeek : endOfMonth;
    while (isPast(getEndOfPeriod(lower))) {
      const lowerDate = formatDateToServer(getStartOfPeriod(lower));
      const upperDate = formatDateToServer(getEndOfPeriod(lower));
      const title = formatDate(
        upperDate,
        period === 'weekly' ? "MMM 'de' yyyy" : 'yyyy',
      ).toUpperCase();
      const text = formatDate(
        upperDate,
        period === 'weekly' ? 'dd/MM' : 'MMMM',
      );
      const section = periods.find(i => i.title === title);
      if (section) {
        section.data.push({
          lowerDate,
          upperDate,
          period,
          text: text.toUpperCase(),
        });
      } else
        periods.push({
          title,
          data: [
            {
              lowerDate,
              upperDate,
              period,
              text: text.toUpperCase(),
            },
          ],
        });
      lower = formatDate(addDays(upperDate, 1), 'yyyy-MM-dd');
    }
    return periods.reverse().map(i => ({ ...i, data: i.data.reverse() }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  function handleNavigateToReport(item: ReportParam) {
    Analytics.setUserProperty(`${Analytics.events.CLICK_REPORT}${period}`);
    if (premium)
      Analytics.setUserProperty(`${Analytics.events.VIEW_REPORT}${period}`);
    else Analytics.setUserProperty(Analytics.events.NO_PERMISSION_VIEW_REPORT);
    history.push(ROUTES.REPORT, item);
  }

  return (
    <Container>
      <Header />
      <Content>
        {dates.length === 0 && (
          <ViewBase center>
            <TextSection color={theme.colors.primary} center>
              {t('statistic:noReports')}
            </TextSection>
          </ViewBase>
        )}
        {
          dates.map(section => (
            <>
              <TextSection color={theme.colors.textSection}>{section.title}</TextSection>
              {section.data.map(item => (
                  <ButtonSection
                    title={item.text}
                    onPress={() => handleNavigateToReport(item)}
                    center
                  />
                ))}
            </>
          ))
        }
      </Content>
    </Container>
  );
};
export default Reports;

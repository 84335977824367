import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, TextBase, TitleModal, ViewBase} from '@/components/atoms';
import {Analytics} from '@/services';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Button} from '@/components/molecules';
import {ModalActions, selectorUser} from '@/store/modules';
import {URL} from '@/constants';
import {Content, Description, Label, Text, Section} from './styles';
import {getDataImageView} from './utils';

interface Props {
  type: string;
  data: any;
}
type Item = {
  label: string;
  value: string;
};
const ImageShare = ({type, data}: Props): JSX.Element => {
  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch()
  const {name, language} = useSelector(selectorUser);
  const {title, description, date, details, media} = useMemo(
    () => getDataImageView(type, name, data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, data],
  );
  const showButtonDonation = type !== 'Details_Reward' && language === 'pt';

  function handleDonation() {
    Analytics.setUserProperty(Analytics.events.donation_click, '1');
    Analytics.setUserProperty(
      `${Analytics.events.donation_click_after_}${type}`,
      '1',
    );
    const url = type === 'Done_All_Activities' ? URL.RIBON_DONE_AL : URL.RIBON_REWARD;
    window.open(url,'_blank');
    dispatch(ModalActions.setModalVisible(false))
  }

  return (
    <>
        <Content>
          <ViewBase center>
          <TitleModal
            style={{fontSize: theme.metrics.font.big, marginBottom:20}}
            >
            {title}
          </TitleModal>
          {!!media && media}
          {description && <Description>{description}</Description>}
          </ViewBase>
          <ViewBase style={{paddingTop: 20, justifyContent: 'center'}}>
            {date && (
              <Section>
                <Row spaceBetween>
                  <Label>{t('date')}:</Label>
                  <Text>{date}</Text>
                </Row>
              </Section>
            )}
            {!!details && (
              <Section style={{marginBottom: 30}}>
                <Label>{t('details')}:</Label>
                {details.map((i: Item) => (
                  <Row
                    key={i.label}
                    spaceBetween
                    style={{paddingLeft: 10, marginTop: 10}}>
                    <Text size={14}>{i.label}</Text>
                    <Text size={14}>{i.value}</Text>
                  </Row>
                ))}
              </Section>
            )}
          </ViewBase>
      {showButtonDonation && (
        <>
          <TextBase marginTop={20} small>
            Como incentivo, você ganhou créditos para fazer uma doação sem
            gastar nada. Escolha a causa que vai receber a sua doação!
          </TextBase>
          <ViewBase center>
            <Button
              title="Escolher causa e doar de graça!"
              testID="button_donation"
              onPress={handleDonation}
              backgroundColor="#00cdb4"
              borderRadius={6}
              marginTop={10}
              />
            </ViewBase>
        </>
      )}
        </Content>
    </>
  );
};
export default ImageShare;

import React, {useMemo, useState} from 'react';
import {Container} from '../styles';
import {Row} from '@/styles/global';
import {
  ButtonTransparent,
  TextBase,
  TextSection,
  ViewBase,
} from '@/components/atoms';
import {metrics} from '@/styles';
import {useTheme} from 'styled-components';
import {
  formatDate,
  formatDateToShow,
  getDateToday,
  parseISO,
} from '@/utils/date';
import {arrangeBigString} from '@/utils/string';
import {
  DrawerActions,
  State,
  selectorAllActivities,
  selectorApp,
  selectorCategories,
  selectorFilterCalendar,
} from '@/store/modules';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'antd';
import ColumnMemorizate from '../Memorizate';
import {
  getActivities,
  getBorderColorActivity,
  getTextColorByBackground,
  handleFilter,
} from '@/utils/activity';
import {MdClose} from 'react-icons/md';
import {Button, ListEmpty} from '@/components/molecules';
import {useTranslation} from 'react-i18next';

type Props = {
  activities: any[];
};

type ModalProps = {
  visible: boolean;
  setVisible: any;
  activities: {date: string; data: any[]};
};
const ModalActivitiesInDate: React.FC<ModalProps> = ({
  visible,
  setVisible,
  activities,
}: ModalProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleCancel = () => {
    setVisible(false);
  };
  const {tasks, habits, goals} = useSelector(selectorAllActivities);
  const {mode, startOfWeek = 0} = useSelector(selectorApp);
  const {filterBy} = useSelector(selectorFilterCalendar);
  const countFilters =
    filterBy.status.length + filterBy.types.length + filterBy.categories.length;
  const data = useMemo(() => {
    return getActivities({
      tasks,
      habits,
      goals,
      upperDate: activities.date,
      lowerDate: activities.date,
      mode,
    }).map(i => ({
      ...i,
      data: i.data.filter(a => handleFilter(a, filterBy)),
    }));
  }, [tasks, goals, habits, mode, filterBy, startOfWeek, activities.date]);

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      onOk={handleCancel}
      title={null}
      footer={null}
      closeIcon={<MdClose size={20} color={theme.colors.onBackground} />}
      bodyStyle={{
        backgroundColor: theme.colors.background,
      }}>
      <ViewBase height={20} />
      <Row style={{justifyContent: 'space-between'}}>
        <TextSection>
          {t('date')}: {formatDateToShow(activities.date)}
        </TextSection>
        <Button
          title={'+'}
          onPress={() => {
            dispatch(
              DrawerActions.setDrawer({
                action: 'ADD_TASK',
                payload: {startDate: activities.date},
              }),
            );
          }}
          width={50}
        />
      </Row>
      {data && data?.length > 0 && (
        <ColumnMemorizate day={data[0].data} index={0} width={400} />
      )}
      {data && data[0] && data[0]?.data?.length === 0 && (
        <ListEmpty
          title={
            countFilters
              ? t('task:noActivitiesFilter')
              : t('task:noActivitiesInPeriod')
          }
          text={countFilters ? t('removeFiltersToSee') : t('listTasksEmpty')}
          titleButton={t('createTask')}
          onPress={() =>
            dispatch(
              DrawerActions.setDrawer({action: 'ADD_TASK', payload: undefined}),
            )
          }
        />
      )}
    </Modal>
  );
};

const ListMonth: React.FC<Props> = ({activities}) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState<any>({} as any);
  const categories = useSelector(selectorCategories);
  const cardActivityColorBy = useSelector<State, string>(
    state => state.app?.cardActivityColorBy || 'label',
  );
  const weekStartOn = useSelector<State, number>(
    state => state.app?.startOfWeek || 0,
  );
  const diff = parseISO(activities[0].date).getDay() - weekStartOn;
  const indexStart = diff < 0 ? 6 : diff;
  const month = Array.from({length: 6}).map((w, indexW) => {
    return Array.from({length: 7}).map((d, indexD) => {
      const index = indexW * 7 + indexD;
      if (index >= indexStart) {
        return activities[index - indexStart];
      }
      return null;
    });
  });

  const maxByWeek = month.map(i =>
    Math.max(...i.map(j => j?.data?.length || 0)),
  );

  const onPressDate = (params: any) => {
    if (params) {
      setVisible(true);
      setList(params);
    }
  };

  return (
    <Container style={{flex: 1}}>
      {month.map((w, index) => (
        <Row key={String(index)}>
          {w.map((d: any, indexD) => (
            <ButtonTransparent
              onClick={d ? () => onPressDate(d) : () => {}}
              key={String(indexD) + d?.date}
              style={{
                display: 'flex',
                flex: 1,
                cursor: d ? 'pointer' : 'default',
                flexDirection: 'column',
                // alignItems: 'center',
                height: metrics.fontSize(
                  Math.max(50, 20 + maxByWeek[index] * 20),
                ),
                backgroundColor: d ? theme.colors.surface : undefined,
                margin: 1,
                padding: 2,
              }}
              // disabled={!d}
            >
              {d?.date ? (
                <TextBase
                  small
                  bold
                  center
                  color={
                    getDateToday() === d?.date
                      ? theme.colors.primary
                      : undefined
                  }>
                  {formatDate(d?.date, 'dd')}
                </TextBase>
              ) : (
                <TextBase small />
              )}
              {d?.data?.map((item: any) => {
                const backgroundColor = getBorderColorActivity(
                  item,
                  categories,
                  cardActivityColorBy,
                );
                return (
                  <ViewBase
                    key={item.id || item.uid}
                    marginBottom={3}
                    borderRadius={4}
                    style={{flex: 1, width: '95%'}}
                    backgroundColor={backgroundColor}>
                    <TextBase
                      lineThrough={item.done}
                      size={metrics.fontSize(10)}
                      color={getTextColorByBackground(backgroundColor)}
                      style={{paddingLeft: 5}}>
                      {arrangeBigString(
                        item.title || item.routine || item.specific,
                        15,
                      )}
                    </TextBase>
                  </ViewBase>
                );
              })}
            </ButtonTransparent>
          ))}
        </Row>
      ))}

      <ModalActivitiesInDate
        visible={visible}
        setVisible={setVisible}
        activities={list}
      />
    </Container>
  );
};

export default ListMonth;

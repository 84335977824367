import styled from 'styled-components';

type ColorProps = {
  color: string;
};
export const Color = styled.button<ColorProps>`
  background: ${({color, theme}) => color || theme.colors.surface};
  border-radius: 10px;
  height: 50px;
  width: 50px;
  margin-right: 5px;
  margin-top: 5px;
  border: none;
`;
export const PickerContainer = styled.div`
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

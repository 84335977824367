/* eslint-disable import/no-duplicates */
import {
  startOfWeek as _startOfWeek,
  addDays as _addDays,
  format as _format,
  parseISO,
  Locale,
  addMonths as _addMonths,
  addSeconds as _addSeconds,
  setHours as _setHours,
  setMinutes as _setMinutes,
  subDays as _subDays,
  addWeeks as _addWeeks,
  subWeeks as _subWeeks,
  addYears as _addYears,
  addMinutes as _addMinutes,
  differenceInMinutes as _differenceInMinutes,
  differenceInSeconds as _differenceInSeconds,
  differenceInDays as _differenceInDays,
  differenceInWeeks as _differenceInWeeks,
  differenceInMonths as _differenceInMonths,
  differenceInYears as _differenceInYears,
  getWeekOfMonth as _getWeekOfMonth,
  endOfMonth as _endOfMonth,
  endOfWeek as _endOfWeek,
  startOfMonth as _startOfMonth,
  isAfter as _isAfter,
  isPast as _isPast,
  isFuture as _isFuture,
  isSameMonth as _isSameMonth,
  isDate as _isDate,
  min as _min,
  compareAsc as _compareAsc,
} from 'date-fns';

import {pt, es} from 'date-fns/locale';
import {JUST_TODAY, DAILY, WEEKLY, MONTHLY, YEARLY, MODE_VIEW, MODE_INTERVAL} from '@/constants/index';
import {CurrentDay} from '@/store/modules/calendar/type';
import i18next from 'i18next';
import Firestore from '@/services/Firestore';
import {ActivityModel} from '@/store/modules';
import {formatObjectError} from './object';

export {parseISO};

export function getDefaultTimer(startHour: string|null, endHour: string|null) {
  if (!startHour || !endHour) return null;
  const startParts = startHour.split(':');
  const endParts = endHour.split(':');

  const startHourInt = parseInt(startParts[0]);
  const startMinuteInt = parseInt(startParts[1]);
  const endHourInt = parseInt(endParts[0]);
  const endMinuteInt = parseInt(endParts[1]);

  const hourDifference = endHourInt - startHourInt;
  const minuteDifference = endMinuteInt - startMinuteInt;

  const totalDifference = hourDifference * 60 + minuteDifference;
  if (totalDifference > 0) {
    return totalDifference * 60;
  }
  return null;
}

export const getIntervalDate = (
  mode: string,
  modeInterval = 'weekly',
  dateRef?: string,
  weekStartsOn?: number,
) => {
  const date = dateRef || getDateToday();
  if (MODE_VIEW.list === mode || MODE_VIEW.agenda === mode|| MODE_VIEW.pending === mode) {
    if (MODE_INTERVAL.weekly === modeInterval) {
      return {
        lowerDate: formatDateToServer(
          startOfWeek(date,  {weekStartsOn: weekStartsOn || 0}),
        ),
        upperDate: formatDateToServer(
          endOfWeek(date, {weekStartsOn: weekStartsOn || 0}),
        ),
      };
    }
    if (MODE_INTERVAL.monthly === modeInterval) {
      return {
        lowerDate: formatDateToServer(startOfMonth(date)),
        upperDate: formatDateToServer(endOfMonth(date)),
      };
    }
    if (MODE_INTERVAL.days3 === modeInterval) {
      return {
        lowerDate: formatDateToServer(subDays(date, 1)),
        upperDate: formatDateToServer(addDays(date, 1)),
      };
    }
    return {
      lowerDate: date,
      upperDate: date,
    };
  }
  if (MODE_VIEW.timeline === mode) {
    return {
      lowerDate: getDateToday(),
      upperDate: formatDateToServer(addDays(getDateToday(), 5)),
    };
  }
  return {
    lowerDate: date,
    upperDate: date,
  };
};


export const week = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

export function getLocale(): Locale | undefined {
  return (
    {
      en: undefined,
      es,
      pt,
    }[i18next.language] || undefined
  );
}

export function setHourAndMinutesInDate(hour:string, date?: string | Date): Date {
  const [h, m] = hour.split(':').map((i:string) => parseInt(i));
  return setMinutes(setHours(date ? new Date(date) : new Date(), h), m);
}

function getDate(date: Date | string) {
  if (typeof date === 'string') return parseISO(date);
  return date;
}

export function formatDate(
  date: Date | string,
  formaterString: string,
): string {
  if (typeof date === 'string')
    return _format(parseISO(date), formaterString, {
      locale: getLocale(),
    });
  try {
    return _format(date, formaterString, {locale: getLocale()});
  } catch (error) {
    Firestore.create(
      'formatDateError',
      formatObjectError(error, {
        date,
        dateString: JSON.stringify(date),
        formaterString,
        typeDate: typeof date,
      }),
    );
    return '';
  }
}
function getStringFormatHourToShow() {
    return 'HH:mm';
  // return 'hh:mm aaa';
}

export function formatHourToShow(date: string | Date): string {
  if (!date) return '';
  if (typeof date === 'string' && date.includes(':')) {
    const [h, m] = date.split(':').map(i => parseInt(i));
    return formatDate(
      setMinutes(setHours(new Date(), h), m),
      getStringFormatHourToShow(),
    ).toUpperCase();
  }
  return formatDate(date, getStringFormatHourToShow()).toUpperCase();
}

export function getDateToday(): string {
  return formatDateToServer(new Date());
}

export function formatDateToServer(date: string | Date): string {
  return formatDate(date, 'yyyy-MM-dd');
}

export function formatDateToShow(date: string | Date): string {
  if (!date) return 'dd/MM/yyyy';
  return formatDate(date, 'dd/MM/yyyy');
}

export function getDayOfWeek(date: Date): string {
  const dayWeek = date.getDay();
  return week[dayWeek];
}

export function getTimeStringFromSecunds(sec: number): string {
  const {hours, minutes, secunds} = getTimeFromSecunds(sec);
  return `${hours}:${minutes}:${secunds}`;
}
export function getTimeFromSecunds(sec: number) {
  const parseString = (n: number) => (n > 9 ? String(n) : `0${n}`);
  if (!sec || sec <= 0)
    return {
      hours: '00',
      minutes: '00',
      secunds: '00',
    };
  const hours = parseString(Math.floor(sec / 60 / 60));
  const minutes = parseString(Math.floor((sec / 60) % 60));
  const secunds = parseString(Math.floor(sec % 60));
  return {hours, minutes, secunds};
}

export function isBetweenDate(date: string, activity: ActivityModel): boolean {
  if ('specific' in activity) {
    return activity.date === date;
  }
  if (date >= activity.startDate && activity.endDate >= date) {
    if (activity.dones) {
      const day = activity.dones.find(i => i.date === date);
      if (day?.deleted === true) return false;
    }
    if (activity.period === DAILY) {
      return true;
    }
    if (activity.period === JUST_TODAY && ('reschedule' in activity ? !activity?.reschedule : true) && activity.startDate === date) {
      return true;
    }
    if (
      activity.period === JUST_TODAY &&
      'reschedule' in activity &&
      activity?.reschedule &&
      ((activity.startDate <= date && date === getDateToday()) ||
      (getDateToday() < date && activity.startDate >= date))
    ) {
      return true;
    }
    if (activity.period === WEEKLY) {
      return parseISO(activity.startDate).getDay() === parseISO(date).getDay();
    }
    if (activity.period === MONTHLY) {
      return (
        _format(parseISO(activity.startDate), 'dd') ===
        _format(parseISO(date), 'dd')
      );
    }
    if (activity.period === YEARLY) {
      return (
        _format(parseISO(activity.startDate), 'dd-MM') ===
        _format(parseISO(date), 'dd-MM')
      );
    }
    if (activity.period.includes('EVERY')) {
      const [, days,] = activity.period.split('_');
      const difInDays = differenceInDays(date, activity.startDate);
      return difInDays % parseInt(days) === 0;
    }
    const periods = activity.period.split(',');
    return periods.indexOf(week[parseISO(date).getDay()]) >= 0;
  }
  return false;
}

export function getCurrentWeek(date: string,weekStartsOn=0): Array<CurrentDay> {
  const currentWeek = week.map((dayOfWeek, i) => {
    const d = _format(addDays(startOfWeek(parseISO(date),{weekStartsOn}), i), 'yyyy-MM-dd');
    return {
      dayOfWeek: formatDate(d, 'EEE').toUpperCase(),
      date: d,
    };
  });
  return currentWeek;
}
export function fixObjectNotifications(notifications: any) {
  if (!notifications) return null;
  const array = notifications
    ?.map((i:any) => ({
      sound: i?.sound || 'default',
      hour: i?.hour
        ? i?.hour?.date
          ? formatDate(i?.hour?.date, 'HH:mm')
          : i.hour
        : null,
    }))
    .filter((i:any) => Boolean(i?.hour));
  return array?.length > 0 ? array : null;
}

export const startOfWeek = (date: string | Date, options?: any): Date =>
  _startOfWeek(getDate(date), options);

export const addDays = (date: string | Date, amount: number): Date =>
  _addDays(getDate(date), amount);
export const addSeconds = (date: string | Date, amount: number): Date =>
  _addSeconds(getDate(date), amount);
export const addMonths = (date: string | Date, amount: number): Date =>
  _addMonths(getDate(date), amount);
export const setHours = (date: string | Date, amount: number): Date =>
  _setHours(getDate(date), amount);
export const setMinutes = (date: string | Date, amount: number): Date =>
  _setMinutes(getDate(date), amount);
export const subDays = (date: string | Date, amount: number): Date =>
  _subDays(getDate(date), amount);
export const addYears = (date: string | Date, amount: number): Date =>
  _addYears(getDate(date), amount);
export const addMinutes = (date: string | Date, amount: number): Date =>
  _addMinutes(getDate(date), amount);
export const addWeeks = (date: string | Date, amount: number): Date =>
  _addWeeks(getDate(date), amount);
export const subWeeks = (date: string | Date, amount: number): Date =>
  _subWeeks(getDate(date), amount);

export const differenceInSeconds = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _differenceInSeconds(getDate(dateLeft), getDate(dateRight));
export const differenceInMinutes = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _differenceInMinutes(getDate(dateLeft), getDate(dateRight));
export const differenceInDays = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _differenceInDays(getDate(dateLeft), getDate(dateRight));
export const differenceInWeeks = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _differenceInWeeks(getDate(dateLeft), getDate(dateRight));
export const differenceInMonths = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _differenceInMonths(getDate(dateLeft), getDate(dateRight));
export const differenceInYears = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _differenceInYears(getDate(dateLeft), getDate(dateRight));

export const getWeekOfMonth = (date: string | Date): number =>
  _getWeekOfMonth(getDate(date));
export const endOfMonth = (date: string | Date): Date =>
  _endOfMonth(getDate(date));
export const endOfWeek = (date: string | Date, options?: any): Date =>
  _endOfWeek(getDate(date), options);
export const startOfMonth = (date: string | Date): Date =>
  _startOfMonth(getDate(date));

export const isAfter = (
  dateLeft: string | Date,
  dateRight: string | Date,
): boolean => _isAfter(getDate(dateLeft), getDate(dateRight));

export const isPast = (dateLeft: string | Date): boolean =>
  _isPast(getDate(dateLeft));
export const isFuture = (dateLeft: string | Date): boolean =>
  _isFuture(getDate(dateLeft));
export const isSameMonth = (
  dateLeft: string | Date,
  dateRight: string | Date,
): boolean => _isSameMonth(getDate(dateLeft), getDate(dateRight));
export const isDate = (dateLeft: string | Date): boolean =>
  _isDate(getDate(dateLeft));

export const min = (array: (string | Date)[]): Date =>
  _min(array.map(i => getDate(i)) as Date[]);
export const compareAsc = (
  dateLeft: string | Date,
  dateRight: string | Date,
): number => _compareAsc(getDate(dateLeft), getDate(dateRight));

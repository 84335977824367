import { call, put, select } from 'redux-saga/effects';
import {
  formatDateToServer,
  startOfWeek,
  endOfWeek,
} from '@/utils/date';
import { Analytics } from '@/services';
import { ListActivities, listActivities } from '@/services/api/activity';
import {
  TaskActions,
  HabitActions,
  CalendarActions,
  GoalActions,
  ModalActions,
} from '@/store/modules';
import { SagaIterator } from '@redux-saga/types';
import { Action } from '../utils';
import { StatisticActions } from '../statistic/duck';
import { HABITO } from '@/constants';

export function* getActivities(
  action: Action<{ lowerDate: string; upperDate: string }>,
): SagaIterator<void> {
  try {
    const { lowerDate, upperDate } = action.payload;
    const params = { lowerDate, upperDate };
    const shouldBeGetAll = !lowerDate || !upperDate;
    if (shouldBeGetAll) {
      params.lowerDate = formatDateToServer(startOfWeek(new Date()));
      params.upperDate = formatDateToServer(endOfWeek(new Date()));
    }
    const data: ListActivities = yield call(listActivities, params);
    if (shouldBeGetAll) {
      yield put(StatisticActions.getStatisticsRequest());
    }
    yield put(TaskActions.getTasksSuccess(data.tasks.map(mapTask)));
    yield put(HabitActions.getHabitsSuccess(data.habits.map(mapHabit), false));
    yield put(GoalActions.getGoalsSuccess(data.goals, false));
    yield put(CalendarActions.getActivitiesSuccess());
    Analytics.log(Analytics.events.GET_ACTIVITIES);
  } catch (error: any) {
    yield put(CalendarActions.setFailure());
    yield put(ModalActions.setError({ ...error, ignoreError: true }));
  }
}
export function* updateWidget(): SagaIterator<void> {
  try {
    const user = yield select(state => state.user);
    if (!user.premium) return;
  } catch (error) {
    //
    //
  }
}

export function mapTask(item: any) {
  const notifications =
    item.notifications ||( (item?.receiveNotification && item?.startHour)
      ? [{ sound: item.soundNotification, hour: item.startHour }]
      : null);
  return {
    ...item,
    notifications
  }
}

export function mapHabit(item: any) {
  const notifications =
    item.notifications || ((item?.receiveNotification && item?.startHour)
      ? [{ sound: item.soundNotification, hour: item.startHour }]
      : null);
  return {
    ...item,
    label: HABITO,
    notifications
  }
}

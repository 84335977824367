import { useEffect, useMemo, useState } from "react";
import {
  CIRCUMSTANTIAL,
  IMPORTANT,
  INFINITY_DATE,
  JUST_TODAY,
  Period,
  URGENT,
} from "@/constants";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CategoryActions,
  DrawerActions,
  selectorCategories,
  selectorCategoriesToEdit,
  selectorCurrentDay,
  selectorUser,
  TaskActions,
} from "@/store/modules";
import {
  Button,
  Input,
  Select,
  InputCheckList,
  InputPeriod,
  MultiSelect,
} from "@/components/molecules";
import { fixObjectNotifications, getDateToday } from "@/utils/date";
import { validatorTask } from "@/utils/validator";
import InputDate from "@/components/molecules/InputDate";
import InputHour from "@/components/molecules/InputHour";
import Notifications from "@/components/molecules/Notifications";
import { useTheme } from "styled-components";
import {
  InputContainer,
  Row,
  TextBase,
  TextValue,
  ViewBase,
} from "@/components/atoms";
import { Switch } from "antd";
import { Label } from "@/styles/global";
import CheckBox from "@/components/molecules/CheckBox";
  import { metrics } from "@/styles";
import { Analytics } from "@/services";

const Task = ({ isEditing, task }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.task);
  const categoriesList = useSelector(selectorCategories);
  const theme = useTheme();

  const currentDay = useSelector(selectorCurrentDay);
  const { id } = useSelector(selectorUser);
  const [title, setTitle] = useState(task?.title || "");
  const [label, setLabel] = useState(task?.label || IMPORTANT);
  const [date, setDate] = useState(task?.startDate || currentDay.date);
  const [checkList, setCheckList] = useState(task?.checkList || []);
  const [endDate, setEndDate] = useState(task?.endDate || INFINITY_DATE);
  const [startHour, setStartHour] = useState(task?.startHour || "");
  const [endHour, setEndHour] = useState(task?.endHour || "");
  const [description, setDescription] = useState(task?.description || "");
  const [period, setPeriod] = useState(task?.period || JUST_TODAY);
  const [reschedule, setReschedule] = useState(task?.reschedule || false);
  const categoriesEdit = useSelector((state) =>
    selectorCategoriesToEdit(state, task?.categories || [])
  );
  const [hasDate, setHasDate] = useState(
    isEditing ? !!task.startDate : task?.hasDate ?? true
  );
  const [categories, setCategories] = useState(categoriesEdit || []);
  const [notifications, setNotifications] = useState(
    fixObjectNotifications(task?.notifications) || []
  );
  const [checkListDaily, setCheckListDaily] = useState(
    task?.checkListDaily || false
  );
  const labels = useMemo(
    () => [
      { label: t("important"), value: IMPORTANT },
      { label: t("urgent"), value: URGENT },
      { label: t("circumstantial"), value: CIRCUMSTANTIAL },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  async function handleAddTask() {
    const idsCategories = categories.map((i) => i.uid);

    const newTask = {
      user_id: id,
      title,
      label,
      date: isEditing ? task.date : date,
      createdAt: isEditing ? task.createdAt : getDateToday(),
      startHour: startHour === "Início" ? null : startHour,
      endHour: endHour === "Término" ? null : endHour,
      description,
      categories: idsCategories.length > 0 ? idsCategories : null,
      period,
      checkList: checkList.length > 0 ? checkList : null,
      checkListDaily,
      notifications: fixObjectNotifications(notifications),
      reschedule: !!(period === JUST_TODAY && reschedule),
      startDate: !hasDate ? null : date,
      endDate: !hasDate
        ? null
        : period === JUST_TODAY && !reschedule
        ? date
        : period === JUST_TODAY && reschedule && !task?.done
        ? INFINITY_DATE
        : endDate,
      uid: task?.uid,
      dones: task?.dones || [],
      done: isEditing ? task.done : false,
    };
    const data = isEditing
      ? { id: task?.id, ...newTask }
      : { ...newTask, dones: [] };
    const isValid = validatorTask(data);
    if (!isValid) {
      return;
    }
    if (isEditing) {
      dispatch(TaskActions.updateTaskRequest({ id: task?.id, ...newTask }));
    } else {
      dispatch(TaskActions.addTaskRequest({ ...newTask, dones: [] }));
      if(!hasDate){
        Analytics.log('add_task_in_list');
      }
    }
    dispatch(DrawerActions.closeDrawer());
  }

  useEffect(() => {
    dispatch(CategoryActions.getCategoriesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditing && period !== Period.JUST_TODAY && date === endDate) {
      setEndDate(INFINITY_DATE);
    }
  }, [period]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Input
        label={t("title")}
        required
        value={title}
        onChangeText={setTitle}
        placeholder={t("task:placeholderTitle")}
      />
      <Select
        testID="select_type"
        label={t("task:label")}
        value={label}
        setValue={setLabel}
        placeholder={t("task:placeholderLabel")}
        data={labels}
        required
      />
      <MultiSelect
        type="category"
        label={t("task:categories")}
        value={categories}
        placeholder={t("task:placeholderCategories")}
        setValue={setCategories}
        data={categoriesList}
        titleModal={t("task:categories")}
        getId={(item)=> item.uid}
      />
      <Input
        label={t("description")}
        value={description}
        onChangeText={setDescription}
        placeholder={t("task:placeholderDescription")}
        maxLength={2000}
      />

      <InputCheckList data={checkList} setData={setCheckList}>
        {checkList.length > 0 && (
          <ViewBase style={{ marginLeft: 10 }}>
            <Label>{t("general:checkListConfig")}</Label>
            <Row alignCenter>
              <CheckBox
                onChangeValue={(v) => setCheckListDaily(!v)}
                value={!checkListDaily}
                color={theme.colors.text}
              />
              <TextBase small>{t("general:checkListTaskConfig")}</TextBase>
            </Row>
            <Row alignCenter>
              <CheckBox
                onChangeValue={setCheckListDaily}
                value={checkListDaily}
                color={theme.colors.text}
              />
              <TextBase small>{t("general:checkListTaskDateConfig")}</TextBase>
            </Row>
          </ViewBase>
        )}
      </InputCheckList>

      <Row spaceBetween style={{ width: "100%" }}>
        <ViewBase>
          <Label>{t("task:hasDate")}</Label>
          <TextValue small placeholder marginLeft={metrics.space.small}>
            {t("task:hasDateDesc")}
          </TextValue>
        </ViewBase>
        <Switch
          defaultChecked={hasDate}
          onChange={() => setHasDate((stt) => !stt)}
          style={{
            backgroundColor: theme.colors.surface,
            color: theme.colors.onBackground,
            borderColor: theme.colors.regular,
          }}
        />
      </Row>

      {hasDate && (
        <>
          {period === JUST_TODAY && (
            <InputDate
              label={t("task:startDate")}
              value={date}
              onChangeValue={setDate}
              testID="input_start_date"
            />
          )}

          <InputHour
            startHour={startHour}
            setStartHour={setStartHour}
            endHour={endHour}
            setEndHour={setEndHour}
          />

          <Notifications
            suggests={[startHour, endHour]}
            notifications={notifications}
            setNotifications={setNotifications}
          />

          <InputPeriod
            testID="select_period"
            label={t("task:period")}
            value={period}
            setValue={setPeriod}
            height={650}
            startDate={date}
            endDate={endDate}
          />

          {JUST_TODAY === period && (
            <InputContainer>
              <Row alignCenter spaceBetween>
                <ViewBase>
                  <Label>{t("task:reschedule")}</Label>
                  <TextValue
                    style={{
                      fontSize: 14,
                      color: theme.colors.placeholder,
                      paddingLeft: theme.metrics.space.small,
                    }}
                  >
                    {t("task:placeholderReschedule")}
                  </TextValue>
                </ViewBase>
                <Switch
                  defaultChecked={reschedule}
                  onChange={() => setReschedule((stt) => !stt)}
                  style={{
                    backgroundColor: theme.colors.surface,
                    color: theme.colors.onBackground,
                    borderColor: theme.colors.regular,
                  }}
                />
              </Row>
            </InputContainer>
          )}

          {period !== JUST_TODAY && (
            <>
              <InputDate
                label={t("task:startDate")}
                value={date}
                onChangeValue={setDate}
              />
              <InputDate
                label={t("task:endDate")}
                value={endDate}
                onChangeValue={setEndDate}
                showClearDate
              />
            </>
          )}
        </>
      )}
      <Button
        title={isEditing ? t("save") : t("add")}
        marginTop={20}
        onPress={handleAddTask}
        loading={addLoading}
      />
    </div>
  );
};

export default Task;

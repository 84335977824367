import {Action} from '../utils';
import {AppState, AppTypes, ModeInterval, ModeView} from './type';

const initialTheme = 'default';

export const INITIAL_STATE: AppState = {
  theme: initialTheme,
  getAllActivities: false,
  showImportCalendar: false,
  showRateApp: false,
  storageVersion: 4,
  showPremiumVersion: {
    attemp: 0,
    lastDate: null,
  },
  dueDatePremium: null,
  language: null,
  mode: ModeView.List,
  modeInterval: ModeInterval.Weekly,
};

const reducer = (state = INITIAL_STATE, {type, payload}: Action): AppState => {
  switch (type) {
    case AppTypes.SET_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
        theme: state.theme || INITIAL_STATE.theme,
        showRateApp: state.showRateApp,
        language: state.language,
        showPremiumVersion:
          state.showPremiumVersion || INITIAL_STATE.showPremiumVersion,
      };

    case AppTypes.ADD_ATRIBUTE:
      return {...state, ...payload};

    default:
      return state;
  }
};

export const AppActions = {
  addAtribute: (atribute: {[key: string]: any}): Action => ({
    type: AppTypes.ADD_ATRIBUTE,
    payload: atribute,
  }),
  setInitialState: () => ({
    type: AppTypes.SET_INITIAL_STATE,
  }),
};
export default reducer;

import React, { useMemo, useState } from "react";
import {
  Row,
  TextBase,
  TextLabel,
  TextSection,
  Value,
  ViewBase,
} from "@/components/atoms";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import {
  addMonths,
  endOfMonth,
  formatDate,
  formatDateToShow,
  startOfMonth,
} from "@/utils/date";
import { parseToNumber } from "@/utils/string";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { Button } from "antd";

interface Props {
  dates: Day[];
  title: string;
  renderItem(day: Day): string;
  titleAddNew?: string;
  onAddNew?: any;
}

type Day = {
  note: string;
  timer: number;
  stopwatch: number;
  date: string;
};
const Notes = ({ dates, title, renderItem, titleAddNew, onAddNew }: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [monthNotes, setMonthNotes] = useState(startOfMonth(new Date()));
  const datesList = useMemo(
    () =>
      dates
        .filter(
          (i) =>
            parseISO(i.date) >= monthNotes &&
            parseISO(i.date) <= endOfMonth(monthNotes)
        )
        .sort((a, b) => parseToNumber(b.date) - parseToNumber(a.date)),
    [dates, monthNotes]
  );

  return (
    <ViewBase
      style={{
        padding: theme.metrics.space.medium,
        marginBottom: theme.metrics.space.small,
        backgroundColor: theme.colors.background,
      }}
    >
      <Row alignCenter spaceBetween>
        <TextSection marginTop={10}>{title}</TextSection>
        {onAddNew && (
          <Button
            style={{
              color: theme.colors.primary,
              borderColor: theme.colors.primary,
              backgroundColor: theme.colors.background,
            }}
            onClick={onAddNew}
          >
            {titleAddNew}
          </Button>
        )}
      </Row>
      <Row spaceBetween alignCenter>
        <MdNavigateBefore
          style={{
            color: theme.colors.onBackground,
            padding: 5,
            cursor: "pointer",
          }}
          size={30}
          onClick={() => setMonthNotes(addMonths(monthNotes, -1))}
        />
        <TextLabel>
          {formatDate(monthNotes, "MMMM yyyy").toLocaleUpperCase()}
        </TextLabel>
        <MdNavigateNext
          style={{
            color: theme.colors.onBackground,
            padding: 5,
            cursor: "pointer",
          }}
          size={30}
          onClick={() => setMonthNotes(addMonths(monthNotes, 1))}
        />
      </Row>
      {datesList.map((i) => (
        <ViewBase marginTop={10} key={i.date}>
          <TextBase bold size={14}>
            {formatDateToShow(i.date)}
          </TextBase>
          <Value>{renderItem(i)}</Value>
        </ViewBase>
      ))}
      {datesList.length === 0 && (
        <TextBase center marginTop={10}>
          {t("listVoid")}
        </TextBase>
      )}
    </ViewBase>
  );
};

export default Notes;

export default {
  signin: 'Sign in',
  signinWithGoogle: 'Sign in Google',
  signinWithApple: 'Sign in Apple',
  register: 'Register',
  recoverPassword: 'Recover Password',

  name: 'Name',
  email: 'Email',
  password: 'Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  placeholderName: 'Type your name',
  placeholderEmail: 'Type your email',
  placeholderPassword: 'Type your password',
  placeholderConfirmPassword: 'Type your password again',
  placeholderCode: 'Enter the verification code',

  forgotPassword: 'Forgot your password?',
  resetPassword: 'Reset Password',
  registerNow: "Don't have an account? Register now",
  goToLogin: 'Already have an account? Sign in',

  titleValidateCode: 'Validate Code',
  titleNewPassowrd: 'New Password',

  code: 'Code',
  codeSent: 'Code sent!',
  sendCode: 'Send Code',
  checkCode: 'Validate',
  msgCodeSent: 'You will receive a code in your email within 60 seconds.',
  codeInvalid: 'Invalid code!',
  msgCodeInvalid: 'Check your email and enter the correct code.',

  success: 'Success!',
  confirm: 'Confirm',
  changePasswordSuccess: 'Password changed successfully!',
} as any;

import { Row, Section, Option, TextLabel } from "@/components/atoms";
import { Analytics } from "@/services";
import {
  AppActions,
  CalendarActions,
  UserActions,
  selectorApp,
  selectorCalendar,
  selectorTheme,
  selectorUser,
} from "@/store/modules";
import { formatDateToServer, getIntervalDate, addDays } from "@/utils/date";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const Preferences: React.FC = () => {
  const currentTheme = useSelector(selectorTheme);
  const { language } = useSelector(selectorUser);
  const {lowerDate} = useSelector(selectorCalendar)
  const { showCheckList = false, startOfWeek = 0, cardActivityColorBy='label',mode, modeInterval } = useSelector(selectorApp);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function setLanguage(language: string) {
    dispatch(UserActions.setLanguage(language));
  }
  function setStartOfWeek(startOfWeek: number) {
    dispatch(
      AppActions.addAtribute({
        startOfWeek: startOfWeek,
      })
    );
  const interval = getIntervalDate(mode as string, modeInterval as string, formatDateToServer(addDays(lowerDate,1)), startOfWeek);
  dispatch(
    CalendarActions.setInterval(interval.lowerDate, interval.upperDate)
  );
  }
  function handleShowCheckList() {
    dispatch(AppActions.addAtribute({ showCheckList: !showCheckList }));
  }
  function setBorderCardColor(cardActivityColorBy:string) {
    dispatch(AppActions.addAtribute({ cardActivityColorBy: cardActivityColorBy }));
  }

  function setTheme(newTheme: string) {
    dispatch(
      AppActions.addAtribute({
        theme: newTheme,
      })
    );
    Analytics.setUserProperty(Analytics.events.THEME, newTheme);
  }

  return (
    <>
      <Section>
        <TextLabel>{t("general:theme")}</TextLabel>
        <Row>
          <Option
            active={currentTheme === "dark"}
            onClick={() => setTheme("dark")}
            style={{fontSize:13}}
          >
            {t("general:themeDark")}
          </Option>
          <Option
            active={currentTheme === "default"}
            onClick={() => setTheme("default")}
            style={{fontSize:13}}

          >
            {t("general:themeDefault")}
          </Option>
        </Row>
      </Section>

      <Section>
        <TextLabel>{t("general:language")}</TextLabel>
        <Row>
          <Option active={language === "pt"} onClick={() => setLanguage("pt")} style={{fontSize:13}}>
            {t("general:pt")}
          </Option>
          <Option active={language === "en"} onClick={() => setLanguage("en")} style={{fontSize:13}}>
            {t("general:en")}
          </Option>
          <Option active={language === "es"} onClick={() => setLanguage("es")} style={{fontSize:13}}>
            {t("general:es")}
          </Option>
        </Row>
      </Section>
      <Section>
        <TextLabel>{t("general:showCheckList")}</TextLabel>
        <Row>
          <Option
            active={showCheckList === true}
            onClick={() => handleShowCheckList()}
            style={{fontSize:13}}
          >
            {t("yes")}
          </Option>
          <Option
            active={showCheckList === false}
            onClick={() => handleShowCheckList()}
            style={{fontSize:13}}
          >
            {t("no")}
          </Option>
        </Row>
      </Section>
      <Section>
        <TextLabel>{t("general:startOfWeek")}</TextLabel>
        <Row>
          <Option active={startOfWeek === 0} onClick={() => setStartOfWeek(0)} style={{fontSize:13}}>
            {t("DOM")}
          </Option>
          <Option active={startOfWeek === 1} onClick={() => setStartOfWeek(1)} style={{fontSize:13}}>
            {t("SEG")}
          </Option>
        </Row>
      </Section>
      <Section>
        <TextLabel>{t("general:cardActivityColorBy")}</TextLabel>
        <Row>
          <Option active={cardActivityColorBy === 'label'} onClick={() => setBorderCardColor('label')} style={{fontSize:13}}>
            {t("label")}
          </Option>
          <Option active={cardActivityColorBy === 'categories'} onClick={() => setBorderCardColor('categories')} style={{fontSize:13}}>
            {t("categories")}
          </Option>
        </Row>
      </Section>
    </>
  );
};

export default Preferences;

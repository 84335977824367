export default {
  noGoalsFilter: 'No goals according to applied filters',
  noGoals: "You don't have any active goals yet.",
  addGoal: 'Add Goal',
  howToAddGoal: 'How to create goals',
  editGoal: 'Edit Goal',
  total: 'Total',
  performed: 'Performed',

  specific: 'What is your goal?',
  measurable: 'How big is your goal?',
  date: 'What is the deadline?',
  relevant: 'Why is this goal important?',
  habits: 'Related habits',
  tasks: 'Related tasks',
  noneHabit: 'No habits were associated with this goal!',
  noneTask: 'No tasks were associated with this goal!',

  placeholderSpecific: 'Ex: Read 2 books in Jan.',
  placeholderMeasurable: 'Ex: 2',
  placeholderDate: 'Ex: 01/31/2021',
  placeholderRelevant: 'Ex: Acquire knowledge',
  placeholderHabits: 'Select habits',
  placeholderTasks: 'Select tasks',
  metricProgressBy: 'Measure progress by',
  selectHabit: 'Select Habit',
} as any;
